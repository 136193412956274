let StainlessSteel = [
  {
    id: '5d7f90f84ef28185885139d6',
    nid: 155,
    name: 'Sandstone',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/sandstone/sandstone.jpg',
    surfaceLabel: "Pattern",
    patterns: [
      {
        id: '5d7f90f99e57a4e14528daaf',
        nid: 157,
        name: 'No Pattern',
        fullName: 'Stainless Steel with Sandstone finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/none.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/sandstone/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/sandstone/no-pattern-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.56
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.56
          }
        ]
      },
      {
        id: '5d7f90fa12c7e895a9095a07',
        nid: 208,
        name: 'Champagne Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Stainless Steel with Sandstone finish and Champagne Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/sandstone/champagne-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/sandstone/champagne-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/sandstone/champagne-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.56
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.56
          }
        ]
      },
      {
        id: '5d7f90fbb630e8e892a72e85',
        nid: 209,
        name: 'Chardonnay Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Stainless Steel with Sandstone finish and Chardonnay Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/sandstone/chardonnay-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/sandstone/chardonnay-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/sandstone/chardonnay-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.56
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.56
          }
        ]
      },
      {
        id: '5d7f90fcf7d01597fe359bba',
        nid: 936,
        name: 'Dallas Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Stainless Steel with Sandstone finish and Dallas Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/sandstone/dallas-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/sandstone/dallas-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/sandstone/dallas-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.56
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.56
          }
        ]
      },
      {
        id: '5d7f90fdf7d211a1b7fb633e',
        nid: 968,
        name: 'Denver Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Stainless Steel with Sandstone finish and Denver Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/sandstone/denver-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/sandstone/denver-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/sandstone/denver-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.56
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.56
          }
        ]
      },
      {
        id: '5d7f90fe863ae14e95b37b91',
        nid: 998,
        name: 'Dune Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Stainless Steel with Sandstone finish and Dune Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/sandstone/dune-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/sandstone/dune-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/sandstone/dune-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.56
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.56
          }
        ]
      },
      {
        id: '5d7f90ff87250531565ca70a',
        nid: 1004,
        name: 'Kalahari Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Stainless Steel with Sandstone finish and Kalahari Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/sandstone/kalahari-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/sandstone/kalahari-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/sandstone/kalahari-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.56
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.56
          }
        ]
      },
    ],
  },
  {
    id: '5d7f91005514b5c86f493efe',
    nid: 156,
    name: 'Seastone',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/seastone.jpg',
    patterns: [
      {
        id: '5d7f9101322269f6d9a20033',
        nid: 5,
        name: 'No Pattern',
        fullName: 'Stainless Steel with Seastone finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/none.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/no-pattern-full.jpg',
        effectOverrides: [
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Bottom Highlight',
              nid: 536,
              id: '5d7f9b06f0b4637621c8bd2c'
            },
            opacity: 0.65
          },
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Shadows',
              nid: 538,
              id: '5d7f9b9524241d2305f5b0eb'
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f9102c03a36b48c3ddaa5',
        nid: 1413,
        name: 'City Lights Eco-Etch',
        fullName:
          'Stainless Steel with Seastone finish and City Lights Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/city-lights-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/city-lights-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/city-lights-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Bottom Highlight',
              nid: 536,
              id: '5d7f9b06f0b4637621c8bd2c'
            },
            opacity: 0.65
          },
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Shadows',
              nid: 538,
              id: '5d7f9b9524241d2305f5b0eb'
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f9103a402828f6945e625',
        nid: 8026,
        name: 'Glacier Eco-Etch',
        fullName:
          'Stainless Steel with Seastone finish and Glacier Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/glacier-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/glacier-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/glacier-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Bottom Highlight',
              nid: 536,
              id: '5d7f9b06f0b4637621c8bd2c'
            },
            opacity: 0.65
          },
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Shadows',
              nid: 538,
              id: '5d7f9b9524241d2305f5b0eb'
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f910475e82fb4bc8276b4',
        nid: 7994,
        name: 'Current Eco-Etch',
        fullName:
          'Stainless Steel with Seastone finish and Current Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/current-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/current-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/current-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Bottom Highlight',
              nid: 536,
              id: '5d7f9b06f0b4637621c8bd2c'
            },
            opacity: 0.65
          },
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Shadows',
              nid: 538,
              id: '5d7f9b9524241d2305f5b0eb'
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f9105f20ecce54d82bf8a',
        nid: 8009,
        name: 'Dash Eco-Etch',
        fullName:
          'Stainless Steel with Seastone finish and Dash Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/dash-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/dash-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/dash-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Bottom Highlight',
              nid: 536,
              id: '5d7f9b06f0b4637621c8bd2c'
            },
            opacity: 0.65
          },
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Shadows',
              nid: 538,
              id: '5d7f9b9524241d2305f5b0eb'
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f910657eeb0b1e12c618a',
        nid: 1496,
        name: 'Flicker Eco-Etch',
        fullName:
          'Stainless Steel with Seastone finish and Flicker Eco-Etch Pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/flicker-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/flicker-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/flicker-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Bottom Highlight',
              nid: 536,
              id: '5d7f9b06f0b4637621c8bd2c'
            },
            opacity: 0.65
          },
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Shadows',
              nid: 538,
              id: '5d7f9b9524241d2305f5b0eb'
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f9107497d6c18d55bca40',
        nid: 1497,
        name: 'Mica Eco-Etch',
        fullName:
          'Stainless Steel with Seastone finish and Mica Eco-Etch Pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/mica-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/mica-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/mica-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Bottom Highlight',
              nid: 536,
              id: '5d7f9b06f0b4637621c8bd2c'
            },
            opacity: 0.65
          },
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Shadows',
              nid: 538,
              id: '5d7f9b9524241d2305f5b0eb'
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f91089fbd2e682da2b4f8',
        nid: 1498,
        name: 'Reeds Eco-Etch',
        fullName:
          'Stainless Steel with Seastone finish and Reeds Eco-Etch Pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/reeds-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/reeds-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/reeds-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Bottom Highlight',
              nid: 536,
              id: '5d7f9b06f0b4637621c8bd2c'
            },
            opacity: 0.65
          },
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Shadows',
              nid: 538,
              id: '5d7f9b9524241d2305f5b0eb'
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f91091ecefb558c6ec6fc',
        nid: 1687,
        name: 'Seagrass Eco-Etch',
        fullName:
          'Stainless Steel with Seastone finish and Seagrass Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/seagrass-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/seagrass-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/seagrass-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Bottom Highlight',
              nid: 536,
              id: '5d7f9b06f0b4637621c8bd2c'
            },
            opacity: 0.65
          },
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Shadows',
              nid: 538,
              id: '5d7f9b9524241d2305f5b0eb'
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f910a38761f9d3174f483',
        nid: 1688,
        name: 'Sequence Eco-Etch',
        fullName:
          'Stainless Steel with Seastone finish and Sequence Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/sequence-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/sequence-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/sequence-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Bottom Highlight',
              nid: 536,
              id: '5d7f9b06f0b4637621c8bd2c'
            },
            opacity: 0.65
          },
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Shadows',
              nid: 538,
              id: '5d7f9b9524241d2305f5b0eb'
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f910b310e366c7fd97e71',
        nid: 1689,
        name: 'Silkworm Eco-Etch',
        fullName:
          'Stainless Steel with Seastone finish and Silkworm Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/silkworm-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/silkworm-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/silkworm-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Bottom Highlight',
              nid: 536,
              id: '5d7f9b06f0b4637621c8bd2c'
            },
            opacity: 0.65
          },
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Shadows',
              nid: 538,
              id: '5d7f9b9524241d2305f5b0eb'
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f910caf859feab8601c1a',
        nid: 1521,
        name: 'Stacks Eco-Etch',
        fullName:
          'Stainless Steel with Seastone finish and Stacks Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/stacks-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/stacks-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/stacks-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Bottom Highlight',
              nid: 536,
              id: '5d7f9b06f0b4637621c8bd2c'
            },
            opacity: 0.65
          },
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Shadows',
              nid: 538,
              id: '5d7f9b9524241d2305f5b0eb'
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f910df582a6275f6159a9',
        nid: 1523,
        name: 'Stripe Eco-Etch',
        fullName:
          'Stainless Steel with Seastone finish and Stripe Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/stripe-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/stripe-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/stripe-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Bottom Highlight',
              nid: 536,
              id: '5d7f9b06f0b4637621c8bd2c'
            },
            opacity: 0.65
          },
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Shadows',
              nid: 538,
              id: '5d7f9b9524241d2305f5b0eb'
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f910e8e68aed3c452a9f5',
        nid: 1690,
        name: 'Thatch Eco-Etch',
        fullName:
          'Stainless Steel with Seastone finish and Thatch Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/thatch-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/thatch-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/thatch-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Bottom Highlight',
              nid: 536,
              id: '5d7f9b06f0b4637621c8bd2c'
            },
            opacity: 0.65
          },
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Shadows',
              nid: 538,
              id: '5d7f9b9524241d2305f5b0eb'
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f910f52243263caf25c1f',
        nid: 8042,
        name: 'Trace Eco-Etch',
        fullName:
          'Stainless Steel with Seastone finish and Trace Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/trace-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/trace-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/trace-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Bottom Highlight',
              nid: 536,
              id: '5d7f9b06f0b4637621c8bd2c'
            },
            opacity: 0.65
          },
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Shadows',
              nid: 538,
              id: '5d7f9b9524241d2305f5b0eb'
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f9110df9d19394a4c66e5',
        nid: 1735,
        name: 'Carbon Fusion',
        fullName:
          'Stainless Steel with Seastone finish and Carbon Fusion pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/carbon-fusion.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/carbon-fusion-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/carbon-fusion-full.jpg',
        effectOverrides: [
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Bottom Highlight',
              nid: 536,
              id: '5d7f9b06f0b4637621c8bd2c'
            },
            opacity: 0.65
          },
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Shadows',
              nid: 538,
              id: '5d7f9b9524241d2305f5b0eb'
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f911171409219cf4fde2c',
        nid: 153,
        name: 'Cinch Fusion',
        fullName:
          'Stainless Steel with Seastone finish and Cinch Fusion pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/cinch-fusion.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/cinch-fusion-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/cinch-fusion-full.jpg',
        effectOverrides: [
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Bottom Highlight',
              nid: 536,
              id: '5d7f9b06f0b4637621c8bd2c'
            },
            opacity: 0.65
          },
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Shadows',
              nid: 538,
              id: '5d7f9b9524241d2305f5b0eb'
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f9112f8dee16b3a038e59',
        nid: 1759,
        name: 'Moraine Fusion',
        fullName:
          'Stainless Steel with Seastone finish and Moraine Fusion pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/moraine-fusion.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/moraine-fusion-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/moraine-fusion-full.jpg',
        effectOverrides: [
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Bottom Highlight',
              nid: 536,
              id: '5d7f9b06f0b4637621c8bd2c'
            },
            opacity: 0.65
          },
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Shadows',
              nid: 538,
              id: '5d7f9b9524241d2305f5b0eb'
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f9113416bc9bad8be4d4a',
        nid: 885,
        name: 'Champagne Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Stainless Steel with Seastone finish and Champagne Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/champagne-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/champagne-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/champagne-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Bottom Highlight',
              nid: 536,
              id: '5d7f9b06f0b4637621c8bd2c'
            },
            opacity: 0.65
          },
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Shadows',
              nid: 538,
              id: '5d7f9b9524241d2305f5b0eb'
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f9114c4350297320e08d9',
        nid: 910,
        name: 'Chardonnay Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Stainless Steel with Seastone finish and Chardonnay Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/chardonnay-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/chardonnay-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/chardonnay-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Bottom Highlight',
              nid: 536,
              id: '5d7f9b06f0b4637621c8bd2c'
            },
            opacity: 0.65
          },
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Shadows',
              nid: 538,
              id: '5d7f9b9524241d2305f5b0eb'
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f9115fdc0da622db3fa8a',
        nid: 938,
        name: 'Dallas Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Stainless Steel with Seastone finish and Dallas Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/dallas-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/dallas-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/dallas-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Bottom Highlight',
              nid: 536,
              id: '5d7f9b06f0b4637621c8bd2c'
            },
            opacity: 0.65
          },
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Shadows',
              nid: 538,
              id: '5d7f9b9524241d2305f5b0eb'
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f911698c853455fb6b376',
        nid: 970,
        name: 'Denver Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Stainless Steel with Seastone finish and Denver Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/denver-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/denver-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/denver-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Bottom Highlight',
              nid: 536,
              id: '5d7f9b06f0b4637621c8bd2c'
            },
            opacity: 0.65
          },
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Shadows',
              nid: 538,
              id: '5d7f9b9524241d2305f5b0eb'
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f911736c027cdfeaac194',
        nid: 1000,
        name: 'Dune Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Stainless Steel with Seastone finish and Dune Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/dune-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/dune-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/dune-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Bottom Highlight',
              nid: 536,
              id: '5d7f9b06f0b4637621c8bd2c'
            },
            opacity: 0.65
          },
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Shadows',
              nid: 538,
              id: '5d7f9b9524241d2305f5b0eb'
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f91184ec582f5cc3b3456',
        nid: 1006,
        name: 'Kalahari Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Stainless Steel with Seastone finish and Kalahari Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/kalahari-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/kalahari-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/kalahari-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Bottom Highlight',
              nid: 536,
              id: '5d7f9b06f0b4637621c8bd2c'
            },
            opacity: 0.65
          },
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Shadows',
              nid: 538,
              id: '5d7f9b9524241d2305f5b0eb'
            },
            opacity: 0.65
          }
        ]
      },
    ],
  },
  {
    id: '5d7f9119fad81f88d3b212e1',
    nid: 376,
    name: 'Silk',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/silk.jpg',
    patterns: [
      {
        id: '5d7f911a65fe076bb4e19694',
        nid: 39837,
        name: 'No Pattern',
        fullName: 'Stainless Steel with Silk finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/none.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/no-pattern-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.2
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.2
          }
        ]
      },
      {
        id: '5d7f911b1f10722965696f0a',
        nid: 39848,
        name: 'City Lights Eco-Etch',
        fullName:
          'Stainless Steel with Silk finish and City Lights Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/city-lights-eco-etch-swatch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/city-lights-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/city-lights-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.2
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.2
          }
        ]
      },
      {
        id: '5d7f911c6d328d32911fb903',
        nid: 39849,
        name: 'Glacier Eco-Etch',
        fullName:
          'Stainless Steel with Silk finish and Glacier Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/city-lights-eco-etch-swatch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/city-lights-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/city-lights-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.2
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.2
          }
        ]
      },
      {
        id: '5d7f911d16112222bd1c1d0b',
        nid: 39850,
        name: 'Current Eco-Etch',
        fullName:
          'Stainless Steel with Silk finish and Current Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/current-eco-etch-swatch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/current-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/current-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.2
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.2
          }
        ]
      },
      {
        id: '5d7f911e0cfeb594ee15388f',
        nid: 39851,
        name: 'Dash Eco-Etch',
        fullName: 'Stainless Steel with Silk finish and Dash Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/dash-eco-etch-swatch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/dash-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/dash-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.2
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.2
          }
        ]
      },
      {
        id: '5d7f911fd0cf96dd75f74970',
        nid: 39853,
        name: 'Flicker Eco-Etch',
        fullName:
          'Stainless Steel with Silk finish and Flicker Eco-Etch Pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/dash-eco-etch-swatch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/dash-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/dash-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.2
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.2
          }
        ]
      },
      {
        id: '5d7f9120931e208ef1016178',
        nid: 39854,
        name: 'Mica Eco-Etch',
        fullName: 'Stainless Steel with Silk finish and Mica Eco-Etch Pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/mica-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/mica-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/mica-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.2
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.2
          }
        ]
      },
      {
        id: '5d7f9121b431ffa74ac4e46c',
        nid: 39857,
        name: 'Reeds Eco-Etch',
        fullName: 'Stainless Steel with Silk finish and Reeds Eco-Etch Pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/reeds-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/reeds-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/reeds-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.2
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.2
          }
        ]
      },
      {
        id: '5d7f9122da57f7604700c1ad',
        nid: 39858,
        name: 'Seagrass Eco-Etch',
        fullName:
          'Stainless Steel with Silk finish and Seagrass Eco-Etch Pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/seagrass-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/seagrass-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/seagrass-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.2
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.2
          }
        ]
      },
      {
        id: '5d7f9123489da5b02c6486d5',
        nid: 39859,
        name: 'Sequence Eco-Etch',
        fullName:
          'Stainless Steel with Silk finish and Sequence Eco-Etch Pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/sequence-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/sequence-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/sequence-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.2
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.2
          }
        ]
      },
      {
        id: '5d7f91248573786a68cf801a',
        nid: 39860,
        name: 'Silkworm Eco-Etch',
        fullName:
          'Stainless Steel with Silk finish and Silkworm Eco-Etch Pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/silkworm-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/silkworm-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/silkworm-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.2
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.2
          }
        ]
      },
      {
        id: '5d7f91254ce7d27974250abf',
        nid: 39861,
        name: 'Stacks Eco-Etch',
        fullName:
          'Stainless Steel with Silk finish and Stacks Eco-Etch Pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/stacks-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/stacks-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/stacks-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.2
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.2
          }
        ]
      },
      {
        id: '5d7f9126e7190bd54df4c43d',
        nid: 39862,
        name: 'Stripe Eco-Etch',
        fullName:
          'Stainless Steel with Silk finish and Stripe Eco-Etch Pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/stripe-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/stripe-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/stripe-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.2
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.2
          }
        ]
      },
      {
        id: '5d7f9127fdf65fc315eb6325',
        nid: 39863,
        name: 'Thatch Eco-Etch',
        fullName:
          'Stainless Steel with Silk finish and Thatch Eco-Etch Pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/thatch-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/thatch-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/thatch-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.2
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.2
          }
        ]
      },
      {
        id: '5d7f9128c32584f30b142bfe',
        nid: 39864,
        name: 'Trace Eco-Etch',
        fullName: 'Stainless Steel with Silk finish and Trace Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/trace-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/trace-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/trace-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.2
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.2
          }
        ]
      },
      {
        id: '5d7f912948babe2dd27c4801',
        nid: 39845,
        name: 'Carbon Fusion',
        fullName: 'Stainless Steel with Silk finish and Carbon Fusion pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/carbon-fusion.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/carbon-fusion-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/carbon-fusion-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.2
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.2
          }
        ]
      },
      {
        id: '5d7f912a402f7d66d8d87421',
        nid: 39846,
        name: 'Cinch Fusion',
        fullName: 'Stainless Steel with Silk finish and Cinch Fusion pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/cinch-fusion.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/cinch-fusion-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/cinch-fusion-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.2
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.2
          }
        ]
      },
      {
        id: '5d7f912b79cadf79c5a57ae2',
        nid: 39847,
        name: 'Moraine Fusion',
        fullName: 'Stainless Steel with Silk finish and Moraine Fusion pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/moraine-fusion.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/moraine-fusion-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/moraine-fusion-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.2
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.2
          }
        ]
      },
      {
        id: '5d7f912c431eefe95f2b4c3a',
        nid: 39838,
        name: 'Champagne Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Stainless Steel with Silk finish and Champagne Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/champagne-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/champagne-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/champagne-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.2
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.2
          }
        ]
      },
      {
        id: '5d7f912df6c50efd0a6f893f',
        nid: 39839,
        name: 'Chardonnay Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Stainless Steel with Silk finish and Chardonnay Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/chardonnay-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/chardonnay-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/chardonnay-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.2
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.2
          }
        ]
      },
      {
        id: '5d7f912ea8e964ffafe952bb',
        nid: 39840,
        name: 'Dallas Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Stainless Steel with Silk finish and Dallas Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/dallas-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/dallas-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/dallas-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.2
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.2
          }
        ]
      },
      {
        id: '5d7f912fefac2e6412648f29',
        nid: 39842,
        name: 'Denver Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Stainless Steel with Silk finish and Denver Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/denver-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/denver-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/denver-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.2
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.2
          }
        ]
      },
      {
        id: '5d7f91308fe5218f10c9735c',
        nid: 39843,
        name: 'Dune Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Stainless Steel with Silk finish and Dune Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/dune-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/dune-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/dune-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.2
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.2
          }
        ]
      },
      {
        id: '5d7f913127982f74de38ecca',
        nid: 39844,
        name: 'Kalahari Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Stainless Steel with Silk finish and Kalahari Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/kalahari-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/kalahari-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/kalahari-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.2
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.2
          }
        ]
      },
    ],
  },
  {
    id: '5d7f9132836d2b9af163fa0d',
    nid: 157,
    name: 'Linen',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/linen/linen.jpg',
    patterns: [
      {
        id: '5d7f91331fc6666591ace1d1',
        nid: 158,
        name: 'No Pattern',
        fullName: 'Stainless Steel with Linen finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/none.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/linen/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/linen/no-pattern-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f9134e342448fbdfdf18e',
        nid: 882,
        name: 'Champagne Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Stainless Steel with Linen finish and Champagne Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/linen/champagne-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/linen/champagne-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/linen/champagne-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f913538125db7e413d1c0',
        nid: 906,
        name: 'Chardonnay Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Stainless Steel with Linen finish and Chardonnay Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/linen/chardonnay-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/linen/chardonnay-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/linen/chardonnay-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f9136850edf51628d5353',
        nid: 906,
        name: 'Chardonnay Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Stainless Steel with Linen finish and Chardonnay Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/linen/chardonnay-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/linen/chardonnay-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/linen/chardonnay-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f9137bd63b77439306cea',
        nid: 932,
        name: 'Dallas Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Stainless Steel with Linen finish and Dallas Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/linen/dallas-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/linen/dallas-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/linen/dallas-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f9138f830d7b160f38587',
        nid: 964,
        name: 'Denver Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Stainless Steel with Linen finish and Denver Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/linen/denver-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/linen/denver-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/linen/denver-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f9139277e60d313489db0',
        nid: 950,
        name: 'Dune Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Stainless Steel with Linen finish and Dune Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/linen/dune-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/linen/dune-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/linen/dune-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f913af3344d14ea65b9d2',
        nid: 1001,
        name: 'Kalahari Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Stainless Steel with Linen finish and Kalahari Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/linen/kalahari-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/linen/kalahari-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/linen/kalahari-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.5
          }
        ]
      },
    ],
  },
  {
    id: '5d7f913b6e8f67e6a74917b5',
    nid: 140,
    name: 'Diamond',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/diamond/diamond.jpg',
    patterns: [
      {
        id: '5d7f913cee213f2a4e84628a',
        nid: 160,
        name: 'No Pattern',
        fullName: 'Stainless Steel with Diamond finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/none.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/diamond/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/diamond/no-pattern-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.6
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.6
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.6
          }
        ]
      },
      {
        id: '5d7f913d3b4392376d1e5d70',
        nid: 1048,
        name: 'Champagne Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Stainless Steel with Diamond finish and Champagne Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/diamond/champagne-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/diamond/champagne-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/diamond/champagne-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.6
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.6
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.6
          }
        ]
      },
      {
        id: '5d7f913e4328a24e9cbb3fad',
        nid: 1050,
        name: 'Chardonnay Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Stainless Steel with Diamond finish and Chardonnay Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/diamond/chardonnay-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/diamond/chardonnay-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/diamond/chardonnay-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.6
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.6
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.6
          }
        ]
      },
      {
        id: '5d7f913fdf62c4e46b95d90e',
        nid: 931,
        name: 'Dallas Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Stainless Steel with Diamond finish and Dallas Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/diamond/dallas-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/diamond/dallas-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/diamond/dallas-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.6
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.6
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.6
          }
        ]
      },
      {
        id: '5d7f9140054c4dc7f2911ddb',
        nid: 963,
        name: 'Denver Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Stainless Steel with Diamond finish and Denver Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/diamond/denver-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/diamond/denver-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/diamond/denver-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.6
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.6
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.6
          }
        ]
      },
      {
        id: '5d7f9141689f922683f893f3',
        nid: 1052,
        name: 'Dune Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Stainless Steel with Diamond finish and Dune Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/diamond/dune-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/diamond/dune-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/diamond/dune-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.6
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.6
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.6
          }
        ]
      },
      {
        id: '5d7f914269fd7c3a310355b9',
        nid: 1054,
        name: 'Kalahari Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Stainless Steel with Diamond finish and Kalahari Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/diamond/kalahari-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/diamond/kalahari-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/diamond/kalahari-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.6
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.6
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.6
          }
        ]
      },
    ],
  },
  {
    id: '5d7f91431dff4bdbfdbf6043',
    nid: 141,
    name: 'Mist',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mist/mist.jpg',
    patterns: [
      {
        id: '5d7f91448ef985855dce0261',
        nid: 161,
        name: 'No Pattern',
        fullName: 'Stainless Steel with Mist finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/none.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mist/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mist/no-pattern-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.7
          }
        ]
      },
      {
        id: '5d7f914564d1d9b4ae995c9e',
        nid: 883,
        name: 'Champagne Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Stainless Steel with Mist finish and Champagne Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mist/champagne-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mist/champagne-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mist/champagne-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.7
          }
        ]
      },
      {
        id: '5d7f9146d8009c0befc26f55',
        nid: 908,
        name: 'Chardonnay Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Stainless Steel with Mist finish and Chardonnay Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mist/chardonnay-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mist/chardonnay-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mist/chardonnay-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.7
          }
        ]
      },
      {
        id: '5d7f91470de98dfcff13925c',
        nid: 935,
        name: 'Dallas Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Stainless Steel with Mist finish and Dallas Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mist/dallas-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mist/dallas-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mist/dallas-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.7
          }
        ]
      },
      {
        id: '5d7f91481188c4809607a68b',
        nid: 967,
        name: 'Denver Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Stainless Steel with Mist finish and Denver Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mist/denver-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mist/denver-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mist/denver-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.7
          }
        ]
      },
      {
        id: '5d7f9149634054539d6d03d6',
        nid: 1003,
        name: 'Kalahari Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Stainless Steel with Mist finish and Kalahari Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mist/kalahari-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mist/kalahari-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mist/kalahari-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.7
          }
        ]
      },
    ],
  },
  {
    id: '5d7f914a0d7f8de7a418f307',
    nid: 143,
    name: 'Mirror',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/mirror.jpg',
    patterns: [
      {
        id: '5d7f914b531b5190f186646f',
        nid: 163,
        name: 'No Pattern',
        fullName: 'Stainless Steel with Mirror finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/none.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/no-pattern-full.jpg',
      },
      {
        id: '5d7f914c13ba0640bff4483d',
        nid: 14,
        name: 'City Lights Eco-Etch',
        fullName:
          'Stainless Steel with Mirror finish and City Lights Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/city-lights-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/city-lights-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/city-lights-eco-etch-full.jpg',
      },
      {
        id: '5d7f914d8017373b0fe0fed4',
        nid: 8024,
        name: 'Glacier Eco-Etch',
        fullName:
          'Stainless Steel with Mirror finish and Glacier Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/glacier-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/glacier-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/glacier-eco-etch-full.jpg',
      },
      {
        id: '5d7f914e74ce8961b9a555c0',
        nid: 7992,
        name: 'Current Eco-Etch',
        fullName:
          'Stainless Steel with Mirror finish and Current Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/current-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/current-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/current-eco-etch-full.jpg',
      },
      {
        id: '5d7f914fabea0a18c95bb784',
        nid: 8007,
        name: 'Dash Eco-Etch',
        fullName:
          'Stainless Steel with Mirror finish and Dash Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/dash-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/dash-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/dash-eco-etch-full.jpg',
      },
      {
        id: '5d7f91506aa4a34d0a8b55c9',
        nid: 1490,
        name: 'Flicker Eco-Etch',
        fullName:
          'Stainless Steel with Mirror finish and Flicker Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/flicker-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/flicker-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/flicker-eco-etch-full.jpg',
      },
      {
        id: '5d7f91512ba276ca06ae79c0',
        nid: 1491,
        name: 'Mica Eco-Etch',
        fullName:
          'Stainless Steel with Mirror finish and Mica Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/mica-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/mica-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/mica-eco-etch-full.jpg',
      },
      {
        id: '5d7f9152f2e7336522805a1f',
        nid: 1492,
        name: 'Reeds Eco-Etch',
        fullName:
          'Stainless Steel with Mirror finish and Reeds Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/reeds-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/reeds-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/reeds-eco-etch-full.jpg',
      },
      {
        id: '5d7f9153a4c24920f8932db4',
        nid: 1707,
        name: 'Seagrass Eco-Etch',
        fullName:
          'Stainless Steel with Mirror finish and Seagrass Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/seagrass-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/seagrass-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/seagrass-eco-etch-full.jpg',
      },
      {
        id: '5d7f915498b05f24656c0da7',
        nid: 1711,
        name: 'Sequence Eco-Etch',
        fullName:
          'Stainless Steel with Mirror finish and Sequence Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/sequence-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/sequence-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/sequence-eco-etch-full.jpg',
      },
      {
        id: '5d7f915523ca34364446ebdb',
        nid: 1713,
        name: 'Silkworm Eco-Etch',
        fullName:
          'Stainless Steel with Mirror finish and Silkworm Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/silkworm-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/silkworm-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/silkworm-eco-etch-full.jpg',
      },
      {
        id: '5d7f91567f703242d956771f',
        nid: 1715,
        name: 'Stacks Eco-Etch',
        fullName:
          'Stainless Steel with Mirror finish and Stacks Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/stacks-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/stacks-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/stacks-eco-etch-full.jpg',
      },
      {
        id: '5d7f91573f7dca5dd8b9b1e0',
        nid: 1717,
        name: 'Stripe Eco-Etch',
        fullName:
          'Stainless Steel with Mirror finish and Stripe Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/stripe-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/stripe-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/stripe-eco-etch-full.jpg',
      },
      {
        id: '5d7f9158cab9cf42c42bb4f8',
        nid: 1692,
        name: 'Thatch Eco-Etch',
        fullName:
          'Stainless Steel with Mirror finish and Thatch Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/thatch-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/thatch-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/thatch-eco-etch-full.jpg',
      },
      {
        id: '5d7f91596677fd744daa98e6',
        nid: 8040,
        name: 'Trace Eco-Etch',
        fullName:
          'Stainless Steel with Mirror finish and Trace Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/trace-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/trace-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/trace-eco-etch-full.jpg',
      },
      {
        id: '5d7f915a236ef306e17880c6',
        nid: 1733,
        name: 'Carbon Fusion',
        fullName:
          'Stainless Steel with Mirror finish and Carbon Fusion pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/carbon-fusion.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/carbon-fusion-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/carbon-fusion-full.jpg',
      },
      {
        id: '5d7f915b8647007d9b1f3d08',
        nid: 1724,
        name: 'Cinch Fusion',
        fullName: 'Stainless Steel with Mirror finish and Cinch Fusion pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/cinch-fusion.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/cinch-fusion-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/cinch-fusion-full.jpg',
      },
      {
        id: '5d7f915c522a632c9dfcdfa2',
        nid: 1757,
        name: 'Moraine Fusion',
        fullName:
          'Stainless Steel with Mirror finish and Moraine Fusion pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/moraine-fusion.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/moraine-fusion-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/moraine-fusion-full.jpg',
      },
      {
        id: '5d7f915d33f3ccc420e57623',
        nid: 489,
        name: 'Champagne Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Stainless Steel with Mirror finish and Champagne Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/champagne-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/champagne-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/champagne-impression-full.jpg',
      },
      {
        id: '5d7f915e34ffd6bcd440cd41',
        nid: 907,
        name: 'Chardonnay Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Stainless Steel with Mirror finish and Chardonnay Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/chardonnay-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/chardonnay-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/chardonnay-impression-full.jpg',
      },
      {
        id: '5d7f915f98eee56512104be9',
        nid: 934,
        name: 'Dallas Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Stainless Steel with Mirror finish and Dallas Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/dallas-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/dallas-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/dallas-impression-full.jpg',
      },
      {
        id: '5d7f9160c2201aae50d63352',
        nid: 966,
        name: 'Denver Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Stainless Steel with Mirror finish and Denver Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/denver-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/denver-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/denver-impression-full.jpg',
      },
      {
        id: '5d7f916199cff9cf52c9ccf1',
        nid: 996,
        name: 'Dune Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Stainless Steel with Mirror finish and Dune Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/dune-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/dune-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/dune-impression-full.jpg',
      },
      {
        id: '5d7f9162c6d9c1c4d01aa1c7',
        nid: 1002,
        name: 'Kalahari Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Stainless Steel with Mirror finish and Kalahari Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/kalahari-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/kalahari-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/kalahari-impression-full.jpg',
      },
    ],
  },
  {
    id: '5d7f9163dd6824ac7123d3bb',
    nid: 159,
    name: 'Satin',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/satin.jpg',
    patterns: [
      {
        id: '5d7f91647b0012ed208c2c08',
        nid: 3,
        name: 'No Pattern',
        fullName: 'Stainless Steel with Satin finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/none.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/no-pattern-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.3
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f9165b8134bc542ea500b',
        nid: 1412,
        name: 'City Lights Eco-Etch',
        fullName:
          'Stainless Steel with Satin finish and City Lights Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/city-lights-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/city-lights-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/city-lights-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.3
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f9166dfa85e86ad5eb185',
        nid: 8025,
        name: 'Glacier Eco-Etch',
        fullName:
          'Stainless Steel with Satin finish and Glacier Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/glacier-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/glacier-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/glacier-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.3
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f9167ca6eb1a79f6dd388',
        nid: 7993,
        name: 'Current Eco-Etch',
        fullName:
          'Stainless Steel with Satin finish and Current Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/current-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/current-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/current-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.3
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f9168a24c541a7ccfda8e',
        nid: 8008,
        name: 'Dash Eco-Etch',
        fullName: 'Stainless Steel with Satin finish and Dash Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/dash-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/dash-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/dash-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.3
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f91691195ce612bd7e61b',
        nid: 1493,
        name: 'Flicker Eco-Etch',
        fullName:
          'Stainless Steel with Satin finish and Flicker Eco-Etch Pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/flicker-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/flicker-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/flicker-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.3
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f916afbc828c932dc692d',
        nid: 1494,
        name: 'Mica Eco-Etch',
        fullName: 'Stainless Steel with Satin finish and Mica Eco-Etch Pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/mica-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/mica-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/mica-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.3
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f916b5d4ed0b3fd0ac3d6',
        nid: 1495,
        name: 'Reeds Eco-Etch',
        fullName:
          'Stainless Steel with Satin finish and Reeds Eco-Etch Pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/reeds-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/reeds-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/reeds-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.3
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f916c9d8659d4a15e6765',
        nid: 1708,
        name: 'Seagrass Eco-Etch',
        fullName:
          'Stainless Steel with Satin finish and Seagrass Eco-Etch Pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/seagrass-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/seagrass-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/seagrass-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.3
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f916d91e5f713d33e1a66',
        nid: 1712,
        name: 'Sequence Eco-Etch',
        fullName:
          'Stainless Steel with Satin finish and Sequence Eco-Etch Pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/sequence-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/sequence-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/sequence-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.3
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f916e7fa7e30186a02d95',
        nid: 1714,
        name: 'Silkworm Eco-Etch',
        fullName:
          'Stainless Steel with Satin finish and Silkworm Eco-Etch Pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/silkworm-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/silkworm-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/silkworm-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.3
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f916ff8eeb348e74f7d59',
        nid: 1716,
        name: 'Stacks Eco-Etch',
        fullName:
          'Stainless Steel with Satin finish and Stacks Eco-Etch Pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/stacks-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/stacks-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/stacks-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.3
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f91703a7a48ca4158acd1',
        nid: 1718,
        name: 'Stripe Eco-Etch',
        fullName:
          'Stainless Steel with Satin finish and Stripe Eco-Etch Pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/stripe-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/stripe-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/stripe-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.3
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f9171a8eb991e605ddbbb',
        nid: 1691,
        name: 'Thatch Eco-Etch',
        fullName:
          'Stainless Steel with Satin finish and Thatch Eco-Etch Pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/thatch-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/thatch-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/thatch-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.3
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f9172c0c0b701fe84b360',
        nid: 8041,
        name: 'Trace Eco-Etch',
        fullName:
          'Stainless Steel with Satin finish and Trace Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/trace-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/trace-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/trace-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.3
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f9173c974d02db35443a8',
        nid: 1734,
        name: 'Carbon Fusion',
        fullName: 'Stainless Steel with Satin finish and Carbon Fusion Pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/carbon-fusion.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/carbon-fusion-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/carbon-fusion-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.3
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f917422ff15bff863883e',
        nid: 1725,
        name: 'Cinch Fusion',
        fullName: 'Stainless Steel with Satin finish and Cinch Fusion Pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/cinch-fusion.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/cinch-fusion-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/cinch-fusion-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.3
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f91756ad3701bbf713ea8',
        nid: 1758,
        name: 'Moraine Fusion',
        fullName:
          'Stainless Steel with Satin finish and Moraine Fusion Pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/moraine-fusion.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/moraine-fusion-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/moraine-fusion-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.3
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f9176020771e636fe44b3',
        nid: 884,
        name: 'Champagne Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Stainless Steel with Satin finish and Champagne Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/champagne-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/champagne-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/champagne-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.3
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f91771ed5cd72a3a300cd',
        nid: 909,
        name: 'Chardonnay Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Stainless Steel with Satin finish and Chardonnay Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/chardonnay-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/chardonnay-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/chardonnay-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.3
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f9178be47b48d1a8c9355',
        nid: 937,
        name: 'Dallas Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Stainless Steel with Satin finish and Dallas Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/dallas-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/dallas-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/dallas-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.3
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f917987973bec51066d14',
        nid: 969,
        name: 'Denver Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Stainless Steel with Satin finish and Denver Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/denver-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/denver-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/denver-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.3
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f917a66a2be76700cde67',
        nid: 999,
        name: 'Dune Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Stainless Steel with Satin finish and Dune Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/dune-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/dune-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/dune-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.3
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f917b32510035e7d61837',
        nid: 1005,
        name: 'Kalahari Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Stainless Steel with Satin finish and Kalahari Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/kalahari-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/kalahari-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/kalahari-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.3
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
    ],
  },
]

export default StainlessSteel
