let CustomSurface = [
  {
    id: '5d7f8a0d97f06964196aec71',
    nid: 338,
    name: 'Custom Surface',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/custom-surface/custom-surface/custom-surface.jpg',
    surfaceLabel: "Pattern",
    patterns: [
      {
        id: '5d7f8a0e1c17874f709a8637',
        nid: 22058,
        name: 'No Pattern',
        fullName: 'Custom Surface',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/custom-surface/custom-surface/no-pattern.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/custom-surface/custom-surface/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/custom-surface/custom-surface/no-pattern-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.65
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.65
          }
        ]
      },
    ],
  },
]

export default CustomSurface
