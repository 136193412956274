let VivistonePearlOnyx = [
  {
    id: '5d7f98f4ef4e1e32e5656ef5',
    nid: 195,
    name: 'Slab A-1',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-a-1/slab-a-1.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f98f5ce6786611ff1872b',
        nid: 88,
        name: 'Pearlex',
        fullName:
          'ViviStone Pearl Onyx glass with slab A-1 interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-a-1/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-a-1/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-a-1/pearlex-full.jpg',
        finish: {
          id: '5d7f98f6212e46ecd340534e',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f98f742ba7382f54159fa',
        nid: 223,
        name: 'Standard',
        fullName:
          'ViviStone Pearl Onyx glass with slab A-1 interlayer and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-a-1/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-a-1/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-a-1/standard-full.jpg',
        finish: {
          id: '5d7f98f86e2493fd3decd9f2',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f98f9b5ac60f44c1e71b3',
    nid: 240,
    name: 'Slab A-2',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-a-2/slab-a-2.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f98fa784c18a24cf39110',
        nid: 2090,
        name: 'Pearlex',
        fullName:
          'ViviStone Pearl Onyx glass with slab A-2 interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-a-2/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-a-2/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-a-2/pearlex-full.jpg',
        finish: {
          id: '5d7f98fbe3d4588ae88952d3',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f98fc83382646f4432736',
        nid: 2091,
        name: 'Standard',
        fullName:
          'ViviStone Pearl Onyx glass with slab A-2 interlayer and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-a-2/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-a-2/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-a-2/standard-full.jpg',
        finish: {
          id: '5d7f98fd3f1654e399989576',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f98fe284dc166a4fe4a63',
    nid: 241,
    name: 'Slab A-3',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-a-3/slab-a-3.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f98ff1940393d0945bd9b',
        nid: 2092,
        name: 'Pearlex',
        fullName:
          'ViviStone Pearl Onyx glass with slab A-3 interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-a-3/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-a-3/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-a-3/pearlex-full.jpg',
        finish: {
          id: '5d7f9900f57dd595238ed67b',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f990141a57853148dc95c',
        nid: 2093,
        name: 'Standard',
        fullName:
          'ViviStone Pearl Onyx glass with slab A-3 interlayer and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-a-3/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-a-3/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-a-3/standard-full.jpg',
        finish: {
          id: '5d7f9902d5292786ee9b400c',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f99032693e1f3a75919c3',
    nid: 242,
    name: 'Slab A-4',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-a-4/slab-a-4.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f9904399f0ee9fa862f14',
        nid: 2094,
        name: 'Pearlex',
        fullName:
          'ViviStone Pearl Onyx glass with slab A-4 interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-a-4/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-a-4/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-a-4/pearlex-full.jpg',
        finish: {
          id: '5d7f990521aa38fde3d9e6d1',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f99066fd436f97d219c8d',
        nid: 2095,
        name: 'Standard',
        fullName:
          'ViviStone Pearl Onyx glass with slab A-4 interlayer and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-a-4/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-a-4/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-a-4/standard-full.jpg',
        finish: {
          id: '5d7f990724ef391267f608d5',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f9908c248443c9c09a9a6',
    nid: 244,
    name: 'Slab B-5',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-b-5/slab-b-5.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f990991d5d7e14b764967',
        nid: 2096,
        name: 'Pearlex',
        fullName:
          'ViviStone Pearl Onyx glass with slab B-5 interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-b-5/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-b-5/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-b-5/pearlex-full.jpg',
        finish: {
          id: '5d7f990afffad42101725679',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f990bcc397a7a5202644c',
        nid: 2097,
        name: 'Standard',
        fullName:
          'ViviStone Pearl Onyx glass with slab B-5 interlayer and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-b-5/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-b-5/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-b-5/standard-full.jpg',
        finish: {
          id: '5d7f990c20104f634b0b87fa',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f990d4ace251cab24c6bd',
    nid: 245,
    name: 'Slab B-6',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-b-6/slab-b-6.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f990edfe599a42bd01a2e',
        nid: 2098,
        name: 'Pearlex',
        fullName:
          'ViviStone Pearl Onyx glass with slab B-6 interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-b-6/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-b-6/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-b-6/pearlex-full.jpg',
        finish: {
          id: '5d7f990fccb799c1f20952bf',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f99103229b8e3821f8f4b',
        nid: 2099,
        name: 'Standard',
        fullName:
          'ViviStone Pearl Onyx glass with slab B-6 interlayer and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-b-6/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-b-6/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-b-6/standard-full.jpg',
        finish: {
          id: '5d7f9911b6cf397d3ab76d19',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f9912ecb7615c1c8c63a2',
    nid: 252,
    name: 'Slab B-7',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-b-7/slab-b-7.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f99137b8edb96c9e6c9a9',
        nid: 2100,
        name: 'Pearlex',
        fullName:
          'ViviStone Pearl Onyx glass with slab B-7 interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-b-7/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-b-7/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-b-7/pearlex-full.jpg',
        finish: {
          id: '5d7f99148a778083054950d9',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f99152eaf647479b7a876',
        nid: 2101,
        name: 'Standard',
        fullName:
          'ViviStone Pearl Onyx glass with slab B-7 interlayer and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-b-7/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-b-7/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-b-7/standard-full.jpg',
        finish: {
          id: '5d7f9916e34a1331bb7b660b',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f9917552b349bd841fc19',
    nid: 253,
    name: 'Slab B-8',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-b-8/slab-b-8.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f9918ef0ffd8fde9b2ca1',
        nid: 2102,
        name: 'Pearlex',
        fullName:
          'ViviStone Pearl Onyx glass with slab B-8 interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-b-8/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-b-8/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-b-8/pearlex-full.jpg',
        finish: {
          id: '5d7f99199ac6e48c327ef7cb',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f991a02d8192ce7b96e08',
        nid: 2103,
        name: 'Standard',
        fullName:
          'ViviStone Pearl Onyx glass with slab B-8 interlayer and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-b-8/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-b-8/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/slab-b-8/standard-full.jpg',
        finish: {
          id: '5d7f991bff75ff693354292b',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
]

export default VivistonePearlOnyx
