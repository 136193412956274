let Bermanglass = [
  {
    id: '5d7f7ff86b9b0746f773cf63',
    nid: 354,
    name: 'BermanGlass Levels',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bermanglass/bermanglass-levels/bermanglass-levels.jpg',
    surfaceLabel: "Pattern",
    patterns: [
      {
        id: '5d7f80030e46cd86474e6fdc',
        nid: 29837,
        name: 'Corduroy + Caprice',
        fullName:
          'BermanGlass Levels Kiln Cast Glass in Corduroy + Caprice design',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bermanglass/bermanglass-levels/corduroy-caprice.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bermanglass/bermanglass-levels/corduroy-caprice-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bermanglass/bermanglass-levels/corduroy-caprice-full.jpg',
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.4,
        captureOnly: true,
        effectOverrides: [
          {
            effect: {
              name: 'BermanGlass, Environment Lighting',
              nid: 29849,
              id: '5d7f9b331c770e8ad1061a44'
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8018de817d0c91f0e6eb',
        nid: 29838,
        name: 'Corduroy + Shimmer',
        fullName:
          'BermanGlass Levels Kiln Cast Glass in Corduroy + Shimmer design',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bermanglass/bermanglass-levels/corduroy-shimmer.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bermanglass/bermanglass-levels/corduroy-shimmer-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bermanglass/bermanglass-levels/corduroy-shimmer-full.jpg',
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.15,
        whiteLight: true,
        captureOnly: true,
        effectOverrides: [
          {
            effect: {
              name: 'VividGlass, Pearlex Finish, Shadows',
              nid: 1649,
              id: '5d7f9ba1d01bc684f68f0db6'
            },
            opacity: 0.1
          }
        ]
      },
      {
        id: '5d7f80238b742e086c69b795',
        nid: 29870,
        name: 'Hikaru + Obsidian',
        fullName:
          'BermanGlass Levels Kiln Cast Glass in Hikaru + Obsidian design',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bermanglass/bermanglass-levels/hikaru-obsidian.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bermanglass/bermanglass-levels/hikaru-obsidian-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bermanglass/bermanglass-levels/hikaru-obsidian-full.jpg',
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.3,
        captureOnly: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b2ba6fb16c17e0f76d0',
              nid: 1646,
              title: 'VividGlass, Pearlex Finish, Environment Lighting',
            },
            opacity: 0.15
          }
        ]
      },
      {
        id: '5d7f802b2ac233b2085e3968',
        nid: 29871,
        name: 'Hikaru + Shadow',
        fullName:
          'BermanGlass Levels Kiln Cast Glass in Hikaru + Shadow design',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bermanglass/bermanglass-levels/hikaru-shadow.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bermanglass/bermanglass-levels/hikaru-shadow-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bermanglass/bermanglass-levels/hikaru-shadow-full.jpg',
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.1,
        whiteLight: true,
        captureOnly: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9ba1d01bc684f68f0db6',
              nid: 1649,
              title: 'VividGlass, Pearlex Finish, Shadows',
            },
            opacity: 0.1
          }
        ]
      },
      {
        id: '5d7f802b2ac233b2085e3968',
        nid: 29872,
        name: 'Hikaru + Taupe',
        fullName: 'BermanGlass Levels Kiln Cast Glass in Hikaru + Taupe design',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bermanglass/bermanglass-levels/hikaru-taupe.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bermanglass/bermanglass-levels/hikaru-taupe-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bermanglass/bermanglass-levels/hikaru-taupe-full.jpg',
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        whiteLight: true,
        captureOnly: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9ba1d01bc684f68f0db6',
              nid: 1649,
              title: 'VividGlass, Pearlex Finish, Shadows',
            },
            opacity: 0.15
          }
        ]
      },
      {
        id: '5d7f803fb4a6f5c0a83cd711',
        nid: 29873,
        name: "Nuv'eau + Lagoon",
        fullName:
          "BermanGlass Levels Kiln Cast Glass in Nuv'eau + Lagoon design",
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bermanglass/bermanglass-levels/nuveau-lagoon.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bermanglass/bermanglass-levels/nuveau-lagoon-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bermanglass/bermanglass-levels/nuveau-lagoon-full.jpg',
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.35,
        captureOnly: true,
      },
      {
        id: '5d7f804be39aaefd4ee8dea7',
        nid: 29874,
        name: "Nuv'eau + Taupe",
        fullName:
          "BermanGlass Levels Kiln Cast Glass in Nuv'eau + Taupe design",
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bermanglass/bermanglass-levels/nuveau-taupe.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bermanglass/bermanglass-levels/nuveau-taupe-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bermanglass/bermanglass-levels/nuveau-taupe-full.jpg',
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.15,
        whiteLight: true,
        captureOnly: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9ba1d01bc684f68f0db6',
              nid: 1649,
              title: 'VividGlass, Pearlex Finish, Shadows',
            },
            opacity: 0.15
          }
        ]
      },
    ],
  },
]

export default Bermanglass
