import React from 'react'
import styles from './surfaces-search.module.css'
import SearchRow from './search-row'
import SearchPreview from './search-preview'
import LightplaneSelections from './lightplane-selections'
import CeramilocSelections from './ceramiloc-selections'
import CustomText from './custom-text'
import { orderBy } from 'lodash'

class MaterialSearch extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeMaterialGroup: {},
      activeMaterial: {},
      activeFinish: {},
      patterns: [],
      activePattern: {},
      lightplaneActive: false,
      ceramilocActive: false,
      customText: '',
    }
  }

  componentDidMount() {
    if (this.props.materialGroup.materials) {
      if (this.props.materialGroup.materials[0].subMaterials) {
        if (this.props.materialGroup.materials[0].subMaterials[0].surfaces) {
          let activeMaterial = this.props.material
          if (!activeMaterial) {
            activeMaterial = this.props.materialGroup.materials[0]
          }
          let subMaterials = activeMaterial.subMaterials.filter(
            (submaterial) => {
              return !submaterial.disabled
            }
          )
          subMaterials = orderBy(subMaterials, 'name', 'asc')
          subMaterials = orderBy(subMaterials, 'sort', 'asc')
          activeMaterial.subMaterials = subMaterials
          let finish = subMaterials[0]
          let patterns = this.filterPatterns(finish.surfaces)
          let pattern = patterns[0]
          if (pattern && !pattern?.finish) {
            pattern.finish = {
              id: finish._id,
              name: finish.name,
              nid: finish.nid,
            }
          }

          finish.id = finish._id

          let lightplaneActive = this.hasLightPlane()
          let ceramilocActive = this.hasCeramiloc()
          let customText = this.getCustomText()
          this.setState({
            activeMaterialGroup: this.props.materialGroup,
            activeMaterial: activeMaterial,
            activeFinish: finish,
            patterns: patterns,
            activePattern: pattern,
            lightplaneActive: lightplaneActive,
            ceramilocActive: ceramilocActive,
            customText: customText,
          })
        }
      }
    }
  }

  hasLightPlane = () => {
    let lightplane = false
    if (this.props.activePanel) {
      this.props.activePanel.panelCoords.forEach((coord, i) => {
        if (coord.lightplane && coord.active) {
          lightplane = true
        }
      })
    }
    return lightplane
  }

  hasCeramiloc = () => {
    let ceramiloc = false
    if (this.props.activePanel) {
      this.props.activePanel.panelCoords.forEach((coord, i) => {
        if (coord.ceramiloc && coord.active) {
          ceramiloc = true
        }
      })
    }
    return ceramiloc
  }

  getCustomText = () => {
    let customText = ''
    if (this.props.activePanel) {
      this.props.activePanel.panelCoords.forEach((coord, i) => {
        if (coord.customText && coord.active) {
          customText = coord.customText
        }
      })
    }
    return customText
  }

  filterPatterns = (patterns) => {
    if (this.props.activePanel.patternsExcluded) {
      patterns = patterns?.filter(
        function (item) {
          if (item.pattern) {
            return (
              !this.props.activePanel.patternsExcluded.includes(
                item.pattern.nid
              ) && !item.disabled
            )
          } else {
            return (
              !this.props.activePanel.patternsExcluded.includes(item.nid) &&
              !item.disabled
            )
          }
        }.bind(this)
      )
    }

    let returnPatterns = orderBy(patterns, 'name', 'asc')
    returnPatterns = orderBy(returnPatterns, 'sort', 'asc')

    let noPatternIndex = returnPatterns.findIndex(function (element) {
      return element.name === 'No Pattern'
    })
    if (noPatternIndex > 0) {
      var noPattern = returnPatterns.splice(noPatternIndex, 1)
      returnPatterns.unshift(noPattern[0])
    }
    return returnPatterns
  }

  onMaterialSelect = (material) => {
    if (material.subMaterials) {
      if (material.subMaterials[0].surfaces[0]) {
        let subMaterials = material.subMaterials
        subMaterials = orderBy(subMaterials, 'name', 'asc')
        subMaterials = orderBy(subMaterials, 'sort', 'asc')
        let subMaterial = subMaterials[0]
        let surfaces = this.filterPatterns(subMaterial.surfaces)
        let surface = surfaces[0]
        // if(!surface.finish){
        //   surface.finish = {
        //     id: subMaterial._id,
        //     nid: subMaterial.nid,
        //     name: subMaterial.name
        //   }
        // }
        this.setState({
          activeMaterial: material,
          activeFinish: subMaterial,
          activePattern: surface,
          patterns: surfaces,
        })
      }
    }
  }

  onFinishSelect = (finish) => {
    if (finish.surfaces) {
      let surfaces = this.filterPatterns(finish.surfaces)
      let surface = surfaces[0]
      if (!surface.finish) {
        surface.finish = {
          id: finish._id,
          nid: finish.nid,
          name: finish.name,
        }
      }
      this.setState({
        activeFinish: finish,
        activePattern: surface,
        patterns: surfaces,
      })
    }
  }

  onPatternSelect = (surface) => {
    if (!surface.finish) {
      surface.finish = {
        id: this.state.activeFinish._id,
        name: this.state.activeFinish.name,
        nid: this.state.activeFinish.nid,
      }
    }
    this.setState({
      activePattern: surface,
    })
  }

  onCloseButton = () => {
    const { onCloseButton } = this.props
    onCloseButton()
  }

  applyLightPlane = (lightplane) => {
    this.setState({
      lightplaneActive: lightplane,
      ceramilocActive: false,
    })
  }

  applyCeramiloc = (ceramiloc) => {
    this.setState({
      ceramilocActive: ceramiloc,
      lightplaneActive: false,
    })
  }

  updateCustomText = (customText) => {
    this.setState({
      customText: customText,
    })
  }

  applySurface = (surface) => {
    const { applySurface } = this.props
    applySurface(
      surface,
      this.state.lightplaneActive,
      this.state.ceramilocActive,
      this.state.customText
    )
  }

  shouldRotateThumb = () => {
    var shouldRotate = false
    if (this.props.activePanel) {
      if (this.props.activePanel.panelCoords) {
        this.props.activePanel.panelCoords.forEach(function (coords) {
          if (coords.lsbdrs[0].rotateSurface) {
            shouldRotate = true
            return shouldRotate
          }
        })
      }
    }
    return shouldRotate
  }

  render() {
    let rotate = this.shouldRotateThumb()
    let materials = (
      <SearchRow
        objects={this.props.materialGroup.materials}
        activeObject={this.state.activeMaterial}
        objectType="Material"
        rotate={rotate}
        onClick={this.onMaterialSelect}
      />
    )
    let finishes = ''
    if (this.state.activeMaterial.name) {
      let objectType = 'Finish'
      if (this.state.activeMaterial.subMaterialLabel) {
        objectType = this.state.activeMaterial.subMaterialLabel
      }
      let subMaterials = orderBy(this.state.activeMaterial.subMaterials, 'name', 'asc')
      subMaterials = orderBy(subMaterials, 'sort', 'asc')
      finishes = (
        <SearchRow
          hideSearchRow={this.props.materialGroup.hideSearchRow}
          objects={subMaterials}
          activeObject={this.state.activeFinish}
          objectType={objectType}
          rotate={rotate}
          onClick={this.onFinishSelect}
        />
      )
    }
    let patterns = ''
    let preview = ''
    if (this.state.activePattern?.name) {
      let objectType = 'Pattern'
      if (this.state.activeFinish.surfaceLabel) {
        objectType = this.state.activeFinish.surfaceLabel
      }
      patterns = (
        <SearchRow
          hideSearchRow={this.state.activeFinish.hideSearchRow}
          objects={this.state.patterns}
          activeObject={this.state.activePattern}
          objectType={objectType}
          rotate={rotate}
          primaryPanel={true}
          onClick={this.onPatternSelect}
        />
      )
      preview = (
        <SearchPreview
          object={this.state.activePattern}
          applySurface={this.applySurface}
          canFavorite={true}
          activePanel={this.props.activePanel}
          updateCustomText={this.updateCustomText}
          customText={this.state.customText}
        />
      )
    }
    let lightplaneSelections = null
    if (
      this.state.activePattern.lightplaneCompatible &&
      !this.props.configuration.hideLightPlaneDialog
    ) {
      lightplaneSelections = (
        <LightplaneSelections
          activeObject={this.state.activePattern}
          applyLightPlane={this.applyLightPlane}
          lightplaneActive={this.state.lightplaneActive}
          activePanel={this.props.activePanel}
          rotate={rotate}
        />
      )
    }
    let ceramilocSelections = null
    if (this.state.activePattern.ceramilocCompatible) {
      ceramilocSelections = (
        <CeramilocSelections
          activeObject={this.state.activePattern}
          applyCeramiloc={this.applyCeramiloc}
          ceramilocActive={this.state.ceramilocActive}
          activePanel={this.props.activePanel}
          rotate={rotate}
        />
      )
    }
    let customText = null
    if (this.state.activePattern.isCustomSurface) {
      customText = (
        <CustomText
          customText={this.state.customText}
          updateCustomText={this.updateCustomText}
        />
      )
    }
    return (
      <div className={styles['material__search__submaterials']}>
        <button
          title="Close"
          className={styles['materal__search__preview__close']}
          onClick={this.onCloseButton}
        >
          ×
        </button>
        {preview}
        {materials}
        {finishes}
        {patterns}
        {lightplaneSelections}
        {ceramilocSelections}
        {customText}
      </div>
    )
  }
}

export default MaterialSearch
