let VivigraphixGraphica = [
  {
    id: '5d7f929b73389f57fcaf5706',
    nid: 122,
    name: 'Cairo',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/cairo/cairo.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f929c3e5893dde91168aa',
        nid: 123,
        name: 'Pearlex',
        fullName:
          'ViviGraphix Graphica glass with Cairo interlayer in White and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/cairo/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/cairo/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/cairo/pearlex-full.jpg',
        finish: {
          id: '5d7f929dc3339db6dbf3aa58',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.1,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9ba1d01bc684f68f0db6',
              nid: 1649,
              title: 'VividGlass, Pearlex Finish, Shadows',
            },
            opacity: 0.15
          },
        ]
      },
      {
        id: '5d7f929eb6d0eb9e8b827913',
        nid: 84,
        name: 'Standard',
        fullName:
          'ViviGraphix Graphica glass with Cairo interlayer in White and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/cairo/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/cairo/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/cairo/standard-full.jpg',
        finish: {
          id: '5d7f929f5de6ad521cd8a9a1',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.1,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.24
          },
        ]
      },
    ],
  },
  {
    id: '5d7f92a08c3cde74d2ee1986',
    nid: 238,
    name: 'Cairo Slate Blue',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/cairo-slate-blue/cairo-slate-blue.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f92a16b78b5a70822bb75',
        nid: 147,
        name: 'Pearlex',
        fullName:
          'ViviGraphix Graphica glass with Cairo interlayer in Slate Blue and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/cairo-slate-blue/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/cairo-slate-blue/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/cairo-slate-blue/pearlex-full.jpg',
        finish: {
          id: '5d7f92a2bb233d55b0f5b237',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f92a3c7ae02474d0608dc',
        nid: 146,
        name: 'Standard',
        fullName:
          'ViviGraphix Graphica glass with Cairo interlayer in Slate Blue and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/cairo-slate-blue/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/cairo-slate-blue/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/cairo-slate-blue/standard-full.jpg',
        finish: {
          id: '5d7f92a488e5bf38499db23a',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f92a525d801703fc87912',
    nid: 239,
    name: 'Cairo Taupe',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/cairo-taupe/cairo-taupe.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f92a62efeb419e5ec5ce9',
        nid: 148,
        name: 'Pearlex',
        fullName:
          'ViviGraphix Graphica glass with Cairo interlayer in Taupe and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/cairo-taupe/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/cairo-taupe/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/cairo-taupe/pearlex-full.jpg',
        finish: {
          id: '5d7f92a7eb8795d0177f62f8',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f92a83cb291f856c91734',
        nid: 149,
        name: 'Standard',
        fullName:
          'ViviGraphix Graphica glass with Cairo interlayer in Taupe and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/cairo-taupe/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/cairo-taupe/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/cairo-taupe/standard-full.jpg',
        finish: {
          id: '5d7f92a9b50e71cc09169d4d',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f92aaf1fd46e59aebbe08',
    nid: 123,
    name: 'Centene',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/centene/centene.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f92ab4305544f486ba690',
        nid: 125,
        name: 'Pearlex',
        fullName:
          'ViviGraphix Graphica glass with Centene interlayer in White and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/centene/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/centene/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/centene/pearlex-full.jpg',
        finish: {
          id: '5d7f92acb21ac78f2d4aafa9',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.1,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9ba1d01bc684f68f0db6',
              nid: 1649,
              title: 'VividGlass, Pearlex Finish, Shadows',
            },
            opacity: 0.15
          },
        ]
      },
      {
        id: '5d7f92aded85e3daa308bee0',
        nid: 124,
        name: 'Standard',
        fullName:
          'ViviGraphix Graphica glass with Centene interlayer in White and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/centene/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/centene/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/centene/standard-full.jpg',
        finish: {
          id: '5d7f92ae5c46771c0a78578f',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.1,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.24
          },
        ]
      },
    ],
  },
  {
    id: '5d7f92af93e4616381a2c1dd',
    nid: 276,
    name: 'Current',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/current/current.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f92b010ac6b6853bb56ff',
        nid: 5999,
        name: 'Pearlex',
        fullName:
          'ViviGraphix Graphica glass with Current interlayer in White and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/current/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/current/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/current/pearlex-full.jpg',
        finish: {
          id: '5d7f92b1117f6d4319609d03',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.1,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9ba1d01bc684f68f0db6',
              nid: 1649,
              title: 'VividGlass, Pearlex Finish, Shadows',
            },
            opacity: 0.15
          },
        ]
      },
      {
        id: '5d7f92b24a45d0100baffc11',
        nid: 5995,
        name: 'Standard',
        fullName:
          'ViviGraphix Graphica glass with Current interlayer in White and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/current/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/current/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/current/standard-full.jpg',
        finish: {
          id: '5d7f92b365b9d3de29684933',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.1,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.24
          },
        ]
      },
    ],
  },
  {
    id: '5d7f92b48fd9abeec84a387c',
    nid: 125,
    name: 'Dot Medium 45',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/dot-medium-45/dot-medium-45.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f92b552a6fce0d83290cc',
        nid: 1519,
        name: 'Pearlex',
        fullName:
          'ViviGraphix Graphica glass with Dot Medium 45 interlayer in White and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/dot-medium-45/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/dot-medium-45/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/dot-medium-45/pearlex-full.jpg',
        finish: {
          id: '5d7f92b6a3a15ddc2cf5063d',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.1,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9ba1d01bc684f68f0db6',
              nid: 1649,
              title: 'VividGlass, Pearlex Finish, Shadows',
            },
            opacity: 0.15
          },
        ]
      },
      {
        id: '5d7f92b7672b1faf5601f30a',
        nid: 1520,
        name: 'Standard',
        fullName:
          'ViviGraphix Graphica glass with Dot Medium 45 interlayer in White and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/dot-medium-45/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/dot-medium-45/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/dot-medium-45/standard-full.jpg',
        finish: {
          id: '5d7f92b877fc19778ee58dcc',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.1,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.24
          },
        ]
      },
    ],
  },
  {
    id: '5d7f92b9e56fd6919341853a',
    nid: 277,
    name: 'Drift',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/drift/drift.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f92bafcf2dcbd66b5e691',
        nid: 6000,
        name: 'Pearlex',
        fullName:
          'ViviGraphix Graphica glass with Drift interlayer in White and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/drift/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/drift/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/drift/pearlex-full.jpg',
        finish: {
          id: '5d7f92bb8fed6394aa96a736',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.1,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9ba1d01bc684f68f0db6',
              nid: 1649,
              title: 'VividGlass, Pearlex Finish, Shadows',
            },
            opacity: 0.15
          },
        ]
      },
      {
        id: '5d7f92bcff650c9fe0bc89db',
        nid: 5996,
        name: 'Standard',
        fullName:
          'ViviGraphix Graphica glass with Drift interlayer in White and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/drift/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/drift/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/drift/standard-full.jpg',
        finish: {
          id: '5d7f92bdef198b79194c3712',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.1,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.24
          },
        ]
      },
    ],
  },
  {
    id: '5d7f92be65efacfe57a7e7e7',
    nid: 278,
    name: 'Frost',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/frost/frost.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f92bfa40ba99c6ff6afa7',
        nid: 6001,
        name: 'Pearlex',
        fullName:
          'ViviGraphix Graphica glass with Frost interlayer in White and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/frost/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/frost/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/frost/pearlex-full.jpg',
        finish: {
          id: '5d7f92c0022a9b89fe0ce749',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.1,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9ba1d01bc684f68f0db6',
              nid: 1649,
              title: 'VividGlass, Pearlex Finish, Shadows',
            },
            opacity: 0.15
          },
        ]
      },
      {
        id: '5d7f92c11bb64017e2b07b73',
        nid: 5997,
        name: 'Standard',
        fullName:
          'ViviGraphix Graphica glass with Frost interlayer in White and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/frost/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/frost/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/frost/standard-full.jpg',
        finish: {
          id: '5d7f92c2508b67ff8dfa1d37',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.1,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.24
          },
        ]
      },
    ],
  },
  {
    id: '5d7f92c3ca1135da91bac332',
    nid: 279,
    name: 'Glacier',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/glacier/glacier.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f92c4d081a5b98deede0e',
        nid: 6002,
        name: 'Pearlex',
        fullName:
          'ViviGraphix Graphica glass with Glacier interlayer in White and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/glacier/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/glacier/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/glacier/pearlex-full.jpg',
        finish: {
          id: '5d7f92c54fca58d966ed4373',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.1,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9ba1d01bc684f68f0db6',
              nid: 1649,
              title: 'VividGlass, Pearlex Finish, Shadows',
            },
            opacity: 0.15
          },
        ]
      },
      {
        id: '5d7f92c6877d2a8a51659ba8',
        nid: 5998,
        name: 'Standard',
        fullName:
          'ViviGraphix Graphica glass with Glacier interlayer in White and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/glacier/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/glacier/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/glacier/standard-full.jpg',
        finish: {
          id: '5d7f92c7cd10d3ba9ac87337',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.1,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.24
          },
        ]
      },
    ],
  },
  {
    id: '5d7f92c8b2a49415d231c037',
    nid: 131,
    name: 'Seagrass',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/seagrass/seagrass.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f92c9130927c227e84f77',
        nid: 141,
        name: 'Pearlex',
        fullName:
          'ViviGraphix Graphica glass with Seagrass interlayer in White and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/seagrass/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/seagrass/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/seagrass/pearlex-full.jpg',
        finish: {
          id: '5d7f92ca4c76037476ad5a9c',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.1,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9ba1d01bc684f68f0db6',
              nid: 1649,
              title: 'VividGlass, Pearlex Finish, Shadows',
            },
            opacity: 0.15
          },
        ]
      },
      {
        id: '5d7f92cb96bf2b328e5c0b66',
        nid: 140,
        name: 'Standard',
        fullName:
          'ViviGraphix Graphica glass with Seagrass interlayer in White and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/seagrass/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/seagrass/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/seagrass/standard-full.jpg',
        finish: {
          id: '5d7f92cc0c19e47097e0eedc',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.1,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.24
          },
        ]
      },
    ],
  },
  {
    id: '5d7f92cde6a749f7af011728',
    nid: 132,
    name: 'Silkworm',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/silkworm/silkworm.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f92ced48ae2227358102c',
        nid: 143,
        name: 'Pearlex',
        fullName:
          'ViviGraphix Graphica glass with Silkworm interlayer in White and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/silkworm/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/silkworm/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/silkworm/pearlex-full.jpg',
        finish: {
          id: '5d7f92cf34581c7cf1729880',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.1,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9ba1d01bc684f68f0db6',
              nid: 1649,
              title: 'VividGlass, Pearlex Finish, Shadows',
            },
            opacity: 0.15
          },
        ]
      },
      {
        id: '5d7f92d02d8b7f59ec44c638',
        nid: 142,
        name: 'Standard',
        fullName:
          'ViviGraphix Graphica glass with Silkworm interlayer in White and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/silkworm/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/silkworm/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/silkworm/standard-full.jpg',
        finish: {
          id: '5d7f92d19c20cf78db5b112b',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.1,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.24
          },
        ]
      },
    ],
  },
  {
    id: '5d7f92d24fb359fc866ef6e6',
    nid: 133,
    name: 'Vertical 116',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/vertical-116/vertical-116.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f92d3cbba52b97930122a',
        nid: 145,
        name: 'Pearlex',
        fullName:
          'ViviGraphix Graphica glass with Vertical 116 interlayer in White and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/vertical-116/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/vertical-116/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/vertical-116/pearlex-full.jpg',
        finish: {
          id: '5d7f92d4d89a34e13e0159b9',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.1,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9ba1d01bc684f68f0db6',
              nid: 1649,
              title: 'VividGlass, Pearlex Finish, Shadows',
            },
            opacity: 0.15
          },
        ]
      },
      {
        id: '5d7f92d5cdfdc9999327ffc1',
        nid: 144,
        name: 'Standard',
        fullName:
          'ViviGraphix Graphica glass with Vertical 116 interlayer in White and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/vertical-116/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/vertical-116/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/vertical-116/standard-full.jpg',
        finish: {
          id: '5d7f92d6876bf46cdd9fc882',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.1,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.24
          },
        ]
      },
    ],
  },
]

export default VivigraphixGraphica
