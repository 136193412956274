import React from 'react'
import styles from './surfaces-search.module.css'

class PanelOptions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  onClick = value => {
    const { onClickOption } = this.props
    onClickOption(value)
  }

  render(){
    let centerStyle = ' '
    let buttonClassName = styles['material__search__submaterial']
    let activeOption = this.props.activePanel.panelOptions.find(option=>option.id === this.props.activeOption)
    if(!activeOption && this.props.activePanel.panelOptions.length){
      activeOption = this.props.activePanel.panelOptions[0]
    } 
    return (
      <div className={styles['search__group']}>
        <h3 className={styles['material__search__title']}><span>{this.props.activePanel.shortName} Options: </span>{activeOption?.name}</h3>
        <div className={styles['search__group__items'] + centerStyle}>
            <div>
            {this.props.activePanel.panelOptions.map(option=>{
                let activeClass = option.id === this.props.activeOption ? buttonClassName + ' ' + styles['material__search__active'] : buttonClassName
                return (<button key={option.id} className={activeClass} onClick={e=>{this.onClick(option.id)}}>
                    <img src={option.swatch?.publicURL} alt={option.name} title={option.name} />
                </button>)
            })}
            </div>
        </div>
      </div>
    )
  }

}

export default PanelOptions
