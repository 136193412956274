let VivistoneWhiteOnyx = [
  {
    id: '5d7f9907f27f8c45c4e82c7f',
    nid: 195,
    name: 'Slab A-1',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-white-onyx/slab-a-1/slab-a-1.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f990869d0b6dcd7c5575e',
        nid: 4856,
        name: 'Pearlex',
        fullName:
          'ViviStone White Onyx glass with slab A-1 interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-white-onyx/slab-a-1/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-white-onyx/slab-a-1/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-white-onyx/slab-a-1/pearlex-full.jpg',
        finish: {
          id: '5d7f990925b591c6741c5161',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9ba1d01bc684f68f0db6',
              nid: 1649,
              title: 'VividGlass, Pearlex Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
      {
        id: '5d7f990a1fb78972742e4dd3',
        nid: 4860,
        name: 'Standard',
        fullName:
          'ViviStone White Onyx glass with slab A-1 interlayer and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-white-onyx/slab-a-1/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-white-onyx/slab-a-1/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-white-onyx/slab-a-1/standard-full.jpg',
        finish: {
          id: '5d7f990bdf321b862fbd951a',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.1,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.24
          },
        ]
      },
    ],
  },
  {
    id: '5d7f990c1744445654342e36',
    nid: 240,
    name: 'Slab A-2',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-white-onyx/slab-a-2/slab-a-2.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f990d35a9fa9a0c031954',
        nid: 4857,
        name: 'Pearlex',
        fullName:
          'ViviStone White Onyx glass with slab A-2 interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-white-onyx/slab-a-2/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-white-onyx/slab-a-2/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-white-onyx/slab-a-2/pearlex-full.jpg',
        finish: {
          id: '5d7f990e4297234d1b2b123a',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9ba1d01bc684f68f0db6',
              nid: 1649,
              title: 'VividGlass, Pearlex Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
      {
        id: '5d7f990f3919ea729d583ff9',
        nid: 4861,
        name: 'Standard',
        fullName:
          'ViviStone White Onyx glass with slab A-2 interlayer and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-white-onyx/slab-a-2/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-white-onyx/slab-a-2/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-white-onyx/slab-a-2/standard-full.jpg',
        finish: {
          id: '5d7f99107211565353fba62b',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.24
          },
        ]
      },
    ],
  },
  {
    id: '5d7f99119f649f97a1cfa15a',
    nid: 241,
    name: 'Slab A-3',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-white-onyx/slab-a-3/slab-a-3.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f9912cdde9a476ef8dc84',
        nid: 4858,
        name: 'Pearlex',
        fullName:
          'ViviStone White Onyx glass with slab A-3 interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-white-onyx/slab-a-3/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-white-onyx/slab-a-3/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-white-onyx/slab-a-3/pearlex-full.jpg',
        finish: {
          id: '5d7f99130128d39342c0aaf9',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9ba1d01bc684f68f0db6',
              nid: 1649,
              title: 'VividGlass, Pearlex Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
      {
        id: '5d7f99141d8823dff7caae77',
        nid: 4862,
        name: 'Standard',
        fullName:
          'ViviStone White Onyx glass with slab A-3 interlayer and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-white-onyx/slab-a-3/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-white-onyx/slab-a-3/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-white-onyx/slab-a-3/standard-full.jpg',
        finish: {
          id: '5d7f99157422a8c7ccbd8438',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.24
          },
        ]
      },
    ],
  },
  {
    id: '5d7f9916b355b7dddca81d82',
    nid: 242,
    name: 'Slab A-4',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-white-onyx/slab-a-4/slab-a-4.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f9917f41fa358af9e519c',
        nid: 4859,
        name: 'Pearlex',
        fullName:
          'ViviStone White Onyx glass with slab A-4 interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-white-onyx/slab-a-4/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-white-onyx/slab-a-4/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-white-onyx/slab-a-4/pearlex-full.jpg',
        finish: {
          id: '5d7f991871692382b055c246',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9ba1d01bc684f68f0db6',
              nid: 1649,
              title: 'VividGlass, Pearlex Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
      {
        id: '5d7f9919986bf0a5f1e4f301',
        nid: 4863,
        name: 'Standard',
        fullName:
          'ViviStone White Onyx glass with slab A-4 interlayer and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-white-onyx/slab-a-4/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-white-onyx/slab-a-4/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-white-onyx/slab-a-4/standard-full.jpg',
        finish: {
          id: '5d7f991aec4addacf5d87897',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.24
          },
        ]
      },
    ],
  },
]

export default VivistoneWhiteOnyx
