import React from 'react'
import styles from '../PanelSelections/panel-selections.module.css'
import searchStyles from '../SurfacesSearch/surfaces-search.module.css'
import CeilingSearchPanel from './CeilingSearchPanel'

class CeilingFloorSearch extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      configuration: {}
    }
  }

  componentDidMount() {
    this.setState({
      configuration: this.props.configuration,
    }, function(){

    })
  }

  render(){

    return (
      <div>
        <div className={styles['info__panel']}>
          {this.state.configuration.name} | CEILING SELECTION: Ceilings may be specified with one of several lighting configurations. Material options include Stainless Steel and Fused Metal in finishes to complement the wall panels. Begin by selecting a lighting configuration below.
        </div>
        <div className={searchStyles['material__search__submaterials']}>
        <CeilingSearchPanel
          applyLighting={this.props.applyLighting}
          applyMaterial={this.props.applyMaterial}
          applyFinish={this.props.applyFinish}
          applyFloor={this.props.applyFloor}
          configuration={this.props.configuration}
          applyLightTemperature={this.props.applyLightTemperature}
        />
        </div>
      </div>
    )
  }

}

export default CeilingFloorSearch
