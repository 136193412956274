let VivichromeChromis = [
  {
    id: '5d7f91865b86a05db5c05d77',
    nid: 209,
    name: 'Cava',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/cava/cava.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f9187c9d58018e8664941',
        nid: 185,
        name: 'Opalex',
        fullName:
          'ViviChrome Chromis glass with Cava interlayer and Opalex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/cava/opalex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/cava/opalex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/cava/opalex-full.jpg',
        finish: {
          id: '5d7f918816d4b8404449e18b',
          nid: 165,
          name: 'Opalex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.1,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b8f9047f602ce8ec337',
              nid: 1650,
              title: 'VividGlass, Opalex Finish, Shadows',
            },
            opacity: 0.15
          }
        ]
      },
      {
        id: '5d7f91895cbc2bc806ca34f2',
        nid: 186,
        name: 'Pearlex',
        fullName:
          'ViviChrome Chromis glass with Cava interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/cava/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/cava/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/cava/pearlex-full.jpg',
        finish: {
          id: '5d7f918a77d717eea76e5e9d',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: false,
        lightplaneOpacity: 0.1,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9ba1d01bc684f68f0db6',
              nid: 1649,
              title: 'VividGlass, Pearlex Finish, Shadows',
            },
            opacity: 0.15
          }
        ]
      },
      {
        id: '5d7f918bc0ceac30427561a4',
        nid: 187,
        name: 'Standard',
        fullName:
          'ViviChrome Chromis glass with Cava interlayer and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/cava/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/cava/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/cava/standard-full.jpg',
        finish: {
          id: '5d7f918c863ca1d5f55741e7',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: false,
        lightplaneOpacity: 0.1,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.15
          }
        ]
      },
    ],
  },
  {
    id: '5d7f918d36629167113fb768',
    nid: 208,
    name: 'Ice Grey',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/ice-grey/ice-grey.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f918e49e9ebc35bccba0c',
        nid: 191,
        name: 'Opalex',
        fullName:
          'ViviChrome Chromis glass with Ice Grey interlayer and Opalex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/ice-grey/opalex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/ice-grey/opalex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/ice-grey/opalex-full.jpg',
        finish: {
          id: '5d7f918816d4b8404449e18b',
          nid: 165,
          name: 'Opalex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b8f9047f602ce8ec337',
              nid: 1650,
              title: 'VividGlass, Opalex Finish, Shadows',
            },
            opacity: 0.18
          }
        ]
      },
      {
        id: '5d7f9190687b33d581c77648',
        nid: 190,
        name: 'Pearlex',
        fullName:
          'ViviChrome Chromis glass with Ice Grey interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/ice-grey/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/ice-grey/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/ice-grey/pearlex-full.jpg',
        finish: {
          id: '5d7f918a77d717eea76e5e9d',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: false,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9ba1d01bc684f68f0db6',
              nid: 1649,
              title: 'VividGlass, Pearlex Finish, Shadows',
            },
            opacity: 0.18
          }
        ]
      },
      {
        id: '5d7f9192af13bb12e9bd4021',
        nid: 192,
        name: 'Standard',
        fullName:
          'ViviChrome Chromis glass with Ice Grey interlayer and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/ice-grey/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/ice-grey/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/ice-grey/standard-full.jpg',
        finish: {
          id: '5d7f91934ffbd2b5b047f4e0',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: false,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.28
          }
        ]
      },
    ],
  },
  {
    id: '5d7f91948f7e547a7f5438a0',
    nid: 207,
    name: 'Mirror Mist',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/mirror-mist/mirror-mist.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f919599d4200dd5fd9b3d',
        nid: 195,
        name: 'Opalex',
        fullName:
          'ViviChrome Chromis glass with Mirror Mist interlayer and Opalex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/mirror-mist/opalex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/mirror-mist/opalex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/mirror-mist/opalex-full.jpg',
        finish: {
          id: '5d7f918816d4b8404449e18b',
          nid: 165,
          name: 'Opalex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.1,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.24
          }
        ]
      },
    ],
  },
  {
    id: '5d7f91972bc62108f8f81653',
    nid: 206,
    name: 'Persimmon',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/persimmon/persimmon.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f9198493e330452b601e3',
        nid: 197,
        name: 'Opalex',
        fullName:
          'ViviChrome Chromis glass with Persimmon interlayer and Opalex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/persimmon/opalex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/persimmon/opalex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/persimmon/opalex-full.jpg',
        finish: {
          id: '5d7f918816d4b8404449e18b',
          nid: 165,
          name: 'Opalex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b8f9047f602ce8ec337',
              nid: 1650,
              title: 'VividGlass, Opalex Finish, Shadows',
            },
            opacity: 0.18
          }
        ]
      },
      {
        id: '5d7f919acae5ff8396a0e897',
        nid: 196,
        name: 'Pearlex',
        fullName:
          'ViviChrome Chromis glass with Persimmon interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/persimmon/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/persimmon/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/persimmon/pearlex-full.jpg',
        finish: {
          id: '5d7f918a77d717eea76e5e9d',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: false,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9ba1d01bc684f68f0db6',
              nid: 1649,
              title: 'VividGlass, Pearlex Finish, Shadows',
            },
            opacity: 0.18
          }
        ]
      },
      {
        id: '5d7f919cca81296244d375fc',
        nid: 198,
        name: 'Standard',
        fullName:
          'ViviChrome Chromis glass with Persimmon interlayer and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/persimmon/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/persimmon/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/persimmon/standard-full.jpg',
        finish: {
          id: '5d7f919d1a0b88a7a495d36f',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: false,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.28
          }
        ]
      },
    ],
  },
  {
    id: '5d7f919e0dd4c2d32510df32',
    nid: 205,
    name: 'Plum',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/plum/plum.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f919fa1e36fe365b65fc1',
        nid: 200,
        name: 'Opalex',
        fullName:
          'ViviChrome Chromis glass with Plum interlayer and Opalex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/plum/opalex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/plum/opalex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/plum/opalex-full.jpg',
        finish: {
          id: '5d7f918816d4b8404449e18b',
          nid: 165,
          name: 'Opalex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.3,
      },
      {
        id: '5d7f91a1f6b5eb34c4220e6d',
        nid: 199,
        name: 'Pearlex',
        fullName:
          'ViviChrome Chromis glass with Plum interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/plum/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/plum/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/plum/pearlex-full.jpg',
        finish: {
          id: '5d7f918a77d717eea76e5e9d',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: false,
        lightplaneOpacity: 0.3,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b2ba6fb16c17e0f76d0',
              nid: 1646,
              title: 'VividGlass, Pearlex Finish, Environment Lighting',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f91a3462bd8a70c301bf0',
        nid: 201,
        name: 'Standard',
        fullName:
          'ViviChrome Chromis glass with Plum interlayer and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/plum/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/plum/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/plum/standard-full.jpg',
        finish: {
          id: '5d7f91a4569b7c4e792d0fe5',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: false,
        lightplaneOpacity: 0.3,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b2532c18c975f0cffcd',
              nid: 1645,
              title: 'VividGlass, Standard Finish, Environment Lighting',
            },
            opacity: 0.5
          }
        ]
      },
    ],
  },
  {
    id: '5d7f91a5232c0c73dc62aa19',
    nid: 204,
    name: 'Seaglass',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/seaglass/seaglass.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f91a63ecfd0dcb607e021',
        nid: 203,
        name: 'Opalex',
        fullName:
          'ViviChrome Chromis glass with Seaglass interlayer and Opalex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/seaglass/opalex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/seaglass/opalex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/seaglass/opalex-full.jpg',
        finish: {
          id: '5d7f918816d4b8404449e18b',
          nid: 165,
          name: 'Opalex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b8f9047f602ce8ec337',
              nid: 1650,
              title: 'VividGlass, Opalex Finish, Shadows',
            },
            opacity: 0.18
          }
        ]
      },
      {
        id: '5d7f91a823649abf9a034d49',
        nid: 202,
        name: 'Pearlex',
        fullName:
          'ViviChrome Chromis glass with Seaglass interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/seaglass/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/seaglass/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/seaglass/pearlex-full.jpg',
        finish: {
          id: '5d7f918a77d717eea76e5e9d',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: false,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b8f9047f602ce8ec337',
              nid: 1650,
              title: 'VividGlass, Opalex Finish, Shadows',
            },
            opacity: 0.18
          }
        ]
      },
      {
        id: '5d7f91aa17d01fb1870ca0b8',
        nid: 204,
        name: 'Standard',
        fullName:
          'ViviChrome Chromis glass with Seaglass interlayer and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/seaglass/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/seaglass/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/seaglass/standard-full.jpg',
        finish: {
          id: '5d7f91ab5e4ce716f824de5a',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: false,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.28
          }
        ]
      },
    ],
  },
  {
    id: '5d7f91ac70d4334a2d487371',
    nid: 202,
    name: 'Taupe',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/taupe/taupe.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f91adb7d04d583a1fe91d',
        nid: 193,
        name: 'Opalex',
        fullName:
          'ViviChrome Chromis glass with Taupe interlayer and Opalex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/taupe/opalex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/taupe/opalex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/taupe/opalex-full.jpg',
        finish: {
          id: '5d7f918816d4b8404449e18b',
          nid: 165,
          name: 'Opalex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b8f9047f602ce8ec337',
              nid: 1650,
              title: 'VividGlass, Opalex Finish, Shadows',
            },
            opacity: 0.18
          }
        ]
      },
      {
        id: '5d7f91af0e830aaa14144ed4',
        nid: 86,
        name: 'Pearlex',
        fullName:
          'ViviChrome Chromis glass with Taupe interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/taupe/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/taupe/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/taupe/pearlex-full.jpg',
        finish: {
          id: '5d7f918a77d717eea76e5e9d',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: false,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9ba1d01bc684f68f0db6',
              nid: 1649,
              title: 'VividGlass, Pearlex Finish, Shadows',
            },
            opacity: 0.18
          }
        ]
      },
      {
        id: '5d7f91b1c75b416da6d238b9',
        nid: 194,
        name: 'Standard',
        fullName:
          'ViviChrome Chromis glass with Taupe interlayer and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/taupe/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/taupe/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/taupe/standard-full.jpg',
        finish: {
          id: '5d7f91b2e2e1abdbf71bb05c',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: false,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.28
          }
        ]
      },
    ],
  },
  {
    id: '5d7f91b3440f660451eb5626',
    nid: 201,
    name: 'White',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/white/white.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f91b4aa8029a71e9a877c',
        nid: 206,
        name: 'Opalex',
        fullName:
          'ViviChrome Chromis glass with White interlayer and Opalex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/white/opalex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/white/opalex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/white/opalex-full.jpg',
        finish: {
          id: '5d7f918816d4b8404449e18b',
          nid: 165,
          name: 'Opalex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.1,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b8f9047f602ce8ec337',
              nid: 1650,
              title: 'VividGlass, Opalex Finish, Shadows',
            },
            opacity: 0.15
          }
        ]
      },
      {
        id: '5d7f91b62311988c26400fe6',
        nid: 205,
        name: 'Pearlex',
        fullName:
          'ViviChrome Chromis glass with White interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/white/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/white/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/white/pearlex-full.jpg',
        finish: {
          id: '5d7f918a77d717eea76e5e9d',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: false,
        lightplaneOpacity: 0.1,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9ba1d01bc684f68f0db6',
              nid: 1649,
              title: 'VividGlass, Pearlex Finish, Shadows',
            },
            opacity: 0.15
          }
        ]
      },
      {
        id: '5d7f91b81ba7b68fd2ec52ee',
        nid: 207,
        name: 'Standard',
        fullName:
          'ViviChrome Chromis glass with White interlayer and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/white/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/white/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/white/standard-full.jpg',
        finish: {
          id: '5d7f91b987ab47f9757e7333',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: false,
        lightplaneOpacity: 0.1,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.24
          }
        ]
      },
    ],
  },
]

export default VivichromeChromis
