let LinqWovenMetal = [
  {
    id: '5d7f8dad13b75fd159b2c63d',
    nid: 317,
    name: 'Stainless Steel With Natural Patina',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/linq-woven-metal/stainless-steel-with-natural-patina/stainless-steel-with-natural-patina.jpg',
    surfaceLabel: "Pattern",
    patterns: [
      {
        id: '5d7f8dae332864100ae79c16',
        nid: 17805,
        name: 'Echo',
        fullName:
          'Echo CrossLinq pattern in Stainless Steel with Natural Patina',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/linq-woven-metal/stainless-steel-with-natural-patina/echo.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/linq-woven-metal/stainless-steel-with-natural-patina/echo-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/linq-woven-metal/stainless-steel-with-natural-patina/echo-full.jpg',
        captureOnly: true,
      },
      {
        id: '5d7f8daf78afb5da885eedcd',
        nid: 17807,
        name: 'Merge',
        fullName:
          'Merge CrossLinq pattern in Stainless Steel with Natural Patina',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/linq-woven-metal/stainless-steel-with-natural-patina/merge.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/linq-woven-metal/stainless-steel-with-natural-patina/merge-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/linq-woven-metal/stainless-steel-with-natural-patina/merge-full.jpg',
        captureOnly: true,
      },
      {
        id: '5d7f8db086dc02e381f575fd',
        nid: 17808,
        name: 'Rhythm',
        fullName:
          'Rhythm CrossLinq pattern in Stainless Steel with Natural Patina',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/linq-woven-metal/stainless-steel-with-natural-patina/rhythm.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/linq-woven-metal/stainless-steel-with-natural-patina/rhythm-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/linq-woven-metal/stainless-steel-with-natural-patina/rhythm-full.jpg',
        captureOnly: true,
      },
      {
        id: '5d7f8db1bd71a98cf50b4c83',
        nid: 17809,
        name: 'Slope',
        fullName:
          'Slope CrossLinq pattern in Stainless Steel with Natural Patina',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/linq-woven-metal/stainless-steel-with-natural-patina/slope.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/linq-woven-metal/stainless-steel-with-natural-patina/slope-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/linq-woven-metal/stainless-steel-with-natural-patina/slope-full.jpg',
        captureOnly: true,
        effectOverrides: [
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Bottom Highlight',
              nid: 536,
              id: '5d7f9b06f0b4637621c8bd2c'
            },
            opacity: 0.3
          },
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Environment Lighting',
              nid: 537,
              id: '5d7f9b00ace8d40248d90743'
            },
            opacity: 0.7
          },
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Shadows',
              nid: 538,
              id: '5d7f9b9524241d2305f5b0eb'
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8db2df83f1e67bc22839',
        nid: 17810,
        name: 'Sum',
        fullName:
          'Sum CrossLinq pattern in Stainless Steel with Natural Patina',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/linq-woven-metal/stainless-steel-with-natural-patina/sum.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/linq-woven-metal/stainless-steel-with-natural-patina/sum-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/linq-woven-metal/stainless-steel-with-natural-patina/sum-full.jpg',
        captureOnly: true,
        effectOverrides: [
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Bottom Highlight',
              nid: 536,
              id: '5d7f9b06f0b4637621c8bd2c'
            },
            opacity: 0.3
          },
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Environment Lighting',
              nid: 537,
              id: '5d7f9b00ace8d40248d90743'
            },
            opacity: 0.7
          },
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Shadows',
              nid: 538,
              id: '5d7f9b9524241d2305f5b0eb'
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8db369a276d434c242c6',
        nid: 17812,
        name: 'Wave',
        fullName:
          'Wave CrossLinq pattern in Stainless Steel with Natural Patina',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/linq-woven-metal/stainless-steel-with-natural-patina/wave.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/linq-woven-metal/stainless-steel-with-natural-patina/wave-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/linq-woven-metal/stainless-steel-with-natural-patina/wave-full.jpg',
        captureOnly: true,
        effectOverrides: [
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Bottom Highlight',
              nid: 536,
              id: '5d7f9b06f0b4637621c8bd2c'
            },
            opacity: 0.3
          },
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Environment Lighting',
              nid: 537,
              id: '5d7f9b00ace8d40248d90743'
            },
            opacity: 0.7
          },
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Shadows',
              nid: 538,
              id: '5d7f9b9524241d2305f5b0eb'
            },
            opacity: 0.3
          }
        ]
      },
    ],
  },
  {
    id: '5d7f8db48d958b7dc1e5ddc9',
    nid: 318,
    name: 'Stainless Steel With Dark Patina',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/linq-woven-metal/stainless-steel-with-dark-patina/stainless-steel-with-dark-patina.jpg',
    surfaceLabel: "Pattern",
    patterns: [
      {
        id: '5d7f8db57f0cbe86d433591a',
        nid: 17817,
        name: 'Merge',
        fullName: 'Merge CrossLinq pattern in Stainless Steel with Dark Patina',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/linq-woven-metal/stainless-steel-with-dark-patina/merge.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/linq-woven-metal/stainless-steel-with-dark-patina/merge-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/linq-woven-metal/stainless-steel-with-dark-patina/merge-full.jpg',
        captureOnly: true,
        effectOverrides: [
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Bottom Highlight',
              nid: 536,
              id: '5d7f9b06f0b4637621c8bd2c'
            },
            opacity: 0.08
          },
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Environment Lighting',
              nid: 537,
              id: '5d7f9b00ace8d40248d90743'
            },
            opacity: 0.7
          },
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Shadows',
              nid: 538,
              id: '5d7f9b9524241d2305f5b0eb'
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8db67db13d35f093309c',
        nid: 17816,
        name: 'Sum',
        fullName: 'Sum CrossLinq pattern in Stainless Steel with Dark Patina',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/linq-woven-metal/stainless-steel-with-dark-patina/sum.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/linq-woven-metal/stainless-steel-with-dark-patina/sum-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/linq-woven-metal/stainless-steel-with-dark-patina/sum-full.jpg',
        captureOnly: true,
        effectOverrides: [
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Bottom Highlight',
              nid: 536,
              id: '5d7f9b06f0b4637621c8bd2c'
            },
            opacity: 0.08
          },
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Environment Lighting',
              nid: 537,
              id: '5d7f9b00ace8d40248d90743'
            },
            opacity: 0.7
          },
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Shadows',
              nid: 538,
              id: '5d7f9b9524241d2305f5b0eb'
            },
            opacity: 0.3
          }
        ]
      },
    ],
  },
  {
    id: '5d7f8db7144bb14baf106fb2',
    nid: 323,
    name: 'Brass with Natural Patina',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/linq-woven-metal/brass-with-natural-patina/brass-with-natural-patina.jpg',
    surfaceLabel: "Pattern",
    patterns: [
      {
        id: '5d7f8db83c5c9827cb226bcc',
        nid: 17813,
        name: 'Merge',
        fullName: 'Merge CrossLinq pattern in Brass with Natural Patina',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/linq-woven-metal/brass-with-natural-patina/merge.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/linq-woven-metal/brass-with-natural-patina/merge-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/linq-woven-metal/brass-with-natural-patina/merge-full.jpg',
        captureOnly: true,
        effectOverrides: [
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Bottom Highlight',
              nid: 536,
              id: '5d7f9b06f0b4637621c8bd2c'
            },
            opacity: 0.08
          },
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Environment Lighting',
              nid: 537,
              id: '5d7f9b00ace8d40248d90743'
            },
            opacity: 0.7
          },
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Shadows',
              nid: 538,
              id: '5d7f9b9524241d2305f5b0eb'
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8db9770d6f3e2c6aa355',
        nid: 17814,
        name: 'Sum',
        fullName: 'Sum CrossLinq pattern in Brass with Natural Patina',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/linq-woven-metal/brass-with-natural-patina/sum.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/linq-woven-metal/brass-with-natural-patina/sum-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/linq-woven-metal/brass-with-natural-patina/sum-full.jpg',
        captureOnly: true,
        effectOverrides: [
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Bottom Highlight',
              nid: 536,
              id: '5d7f9b06f0b4637621c8bd2c'
            },
            opacity: 0.08
          },
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Environment Lighting',
              nid: 537,
              id: '5d7f9b00ace8d40248d90743'
            },
            opacity: 0.7
          },
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Shadows',
              nid: 538,
              id: '5d7f9b9524241d2305f5b0eb'
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8dbaa53b218e7945d100',
        nid: 17815,
        name: 'Wave',
        fullName: 'Wave CrossLinq pattern in Brass with Natural Patina',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/linq-woven-metal/brass-with-natural-patina/wave.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/linq-woven-metal/brass-with-natural-patina/wave-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/linq-woven-metal/brass-with-natural-patina/wave-full.jpg',
        captureOnly: true,
        effectOverrides: [
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Bottom Highlight',
              nid: 536,
              id: '5d7f9b06f0b4637621c8bd2c'
            },
            opacity: 0.08
          },
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Environment Lighting',
              nid: 537,
              id: '5d7f9b00ace8d40248d90743'
            },
            opacity: 0.7
          },
          {
            effect: {
              name: 'Stainless Steel & Fused Metal Shadows',
              nid: 538,
              id: '5d7f9b9524241d2305f5b0eb'
            },
            opacity: 0.3
          }
        ]
      },
    ],
  },
]

export default LinqWovenMetal
