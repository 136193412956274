import React from 'react'
import styles from '../SurfacesSearch/surfaces-search.module.css'
import materials from '../../../../objects/surfaces/data/materials-secondary'
import MaterialSearch from './material-search'
import FrameStyleSelection from './frame-style-selection'

class FramesSearch extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      materials: materials,
      activeMaterialGroup: {},
      activeMaterial: {}
    }
  }

  componentDidMount() {

  }

  onClickItem = (material) => {
    this.setState({
      activeMaterial: material,
      showMaterialSearch: true
    })
    this.props.resetMaterialSearch(true)
  }

  onCloseButton = () => {

    this.props.resetMaterialSearch(false)
  }

  applySurface = surface => {
    const { applySurface } = this.props
    applySurface(surface)
  }

  checkVerticalHorizontalLED = () => {
    if((this.props.activePanel.name.toLowerCase().includes('vertical') || this.props.activePanel.name.toLowerCase().includes('horizontal'))){
      if(this.props.configuration.framePanels && this.props.activePanel.parent){
        let framePanel = this.props.configuration.framePanels.find(panel => panel.id === this.props.activePanel.parent)
        if(framePanel?.surface?.name){
          let surfaceName = framePanel?.surface?.name ? framePanel.surface.name : ""
          if(surfaceName?.includes('LED')){
            return true
          }
        }
      }
    }
    return false
  }

  render(){
    let items = null
    let horizontalVerticalLED = this.checkVerticalHorizontalLED()
    let options = null
    if(this.props.activePanel.options){
      options = this.props.activePanel.options.filter(option=>{
        return !(option.name.includes('LED') && horizontalVerticalLED)
      })
      if(options){
        items = options.map(function(item, key){
          if(item.name.includes('LED') && horizontalVerticalLED){
            return null
          }
          return (
            <FrameItem
              material={item}
              key={key}
              onClick={this.onClickItem}
            />
          )
        }.bind(this))
      }
    }

    let searchPanelContent = <div className={styles['search__panel']}><div className={styles['material__group']}>
    {items}</div></div>
    let frameStyleButtons = null
    if(this.props.activePanel.showFrameStyle){
      frameStyleButtons = <FrameStyleSelection
      configuration={this.props.configuration}
      applyPanelFrameType={this.props.applyPanelFrameType}
      frameType={this.props.frameType}
       />
    }
    if(this.props.showMaterialSearch){
      searchPanelContent = <div><MaterialSearch
      configuration={this.props.configuration}
      options={options}
      material={this.state.activeMaterial}
      onCloseButton={this.onCloseButton}
      applySurface={this.applySurface}
      />
      {frameStyleButtons}
      </div>
    }
    return (
      <div>
        <div className={styles['search__tab__singular']}>
          <div className={styles['title__mixed']}>
          Materials
          </div>
          <div className={styles['small__title']}>
          {this.props.shortName}
          </div>
        </div>
        {searchPanelContent}
      </div>
    )
  }

}

class FrameItem extends React.Component {
  constructor(props){
    super(props)
    this.state={

    }
  }
  componentDidMount(){

  }
  onClick = () => {
    const { onClick } = this.props
    onClick(this.props.material)
  }
  render(){
    return(
      <button className={styles['material__selection']} key={this.props.material.id} onClick={this.onClick}>
        <img src={this.props.material.swatchImage.publicURL} alt={this.props.material.name} title={this.props.material.name} />
      </button>
    )
  }
}

export default FramesSearch
