import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './config-select.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class ConfigFamily extends Component {
  static propTypes = {
    activeConfig: PropTypes.string,
    id: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired
  }

  onClick = () => {
    const { id, onClick } = this.props
    onClick(id)
  }

  onChangeOpen = (opening) => {
    this.props.changeOpening(opening)
  }

  onClickConfig = (configId) => {
    this.props.onClickConfig(this.props.id, configId)
  }

  onClickPreset = (presetId) =>{
    var preset = this.props.presets.find(function(preset, i){
      return presetId === preset._id
    })
    this.props.onConfigPreset(preset)
  }

  render(){
    const {
      onClick,
      onChangeOpen,
      props: { id, name, image },
    } = this
    let opening = 'Front'
    if(this.props.design.additionalDetails){
      opening = this.props.design.additionalDetails.openingOption
    }
    let className = styles['config__item']
    let activeCheck = ''

    if (this.props.isActive) {
      className += ' ' + styles['config__active']
      activeCheck = <div className={styles['config__active__icon']}><FontAwesomeIcon icon="check-square" className={styles['config__active__icon__svg']}/></div>
      let frontActive = <FontAwesomeIcon icon="check-square" className={styles['config__active__icon__svg']}/>
      let frontRearActive = <div className={styles['opening__unchecked']} />
      if(opening === 'Front and Rear'){
        frontActive = <div className={styles['opening__unchecked']} />
        frontRearActive = <FontAwesomeIcon icon="check-square" className={styles['config__active__icon__svg']}/>
      }
      let configs = null
      let configGrid = null

      if(this.props.configurations.length > 0){

        configs = this.props.configurations.sort(function(a, b){
          let comparison = 0;
          if(a.name > b.name){
            comparison = 1
          }else if(a.name < b.name){
            comparison = -1
          }
          return comparison
        }).map(function(config, i){
          let configImage = ''
          if(config.configThumbnailImage){
            configImage = config.configThumbnailImage.publicURL
          }
          let configStyle = styles['config__preset']
          if(config._id === this.props.activeConfig){
            configStyle += ' ' + styles['config__preset__selected']
          }
          return <button key={i} className={configStyle}
            onClick={(e) => this.onClickConfig(config._id)}>
            <h3 style={{margin:'0px'}}>{config.name}</h3>
            <img alt={config.name} src={configImage} />
          </button>
        }.bind(this))

        let rowStart = parseInt(this.props.index / 3) + 2
        let arrowPosClass = ''
        if(this.props.index % 3 === 0){
          arrowPosClass = styles['arrow__left']
        }
        if(this.props.index %3 === 2){
          arrowPosClass = styles['arrow__right']
        }
        configGrid = <div className={styles['config__presets'] + ' ' + arrowPosClass} style={{
          gridRow: rowStart + ' / ' + rowStart
        }}>
          <div className={styles['presets__inner']}>
          {configs}
          </div>
        </div>

        className += ' ' + styles['has__presets']
      }
      return (
        <>
        <div key={id} className={className}>
          <div className={styles['button__inner']}>
            <div className={styles['config__title']}>{name}<br/>OPENING OPTIONS</div>
            <button onClick={(e) => onChangeOpen('Front')}>
            {frontActive}
            <span>Front</span>
            </button>
            <button onClick={(e) => onChangeOpen('Front and Rear')}>
            {frontRearActive}
            <span>Front & Rear</span>
            </button>
          </div>
        </div>
        {configGrid}
        </>
      )
    }else{
      return (
        <div key={id} className={className}>
          <button className={styles['config__button']} onClick={onClick}>
            <div className={styles['button__inner']}>
              <div className={styles['config__title']}>{name}</div>
              <img src={image} alt={name} style={{
                height: '70px'
              }} />
              {activeCheck}
            </div>
          </button>
        </div>
      )
    }
  }
}

export default ConfigFamily
