import React from 'react'
import styles from './panel-legends.module.css'
import Legend from './legend'

class PanelLegends extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activePanel: {},
    }
  }

  onClick = (panel, subPanel) => {
    const { onPanelChange } = this.props
    onPanelChange(panel)
  }

  render() {
    let primaryPanelLegends = ''
    let secondaryPanelLegends = ''
    let framePanelLegends = ''
    let dualOpen = false
    if (this.props.configuration.additionalDetails) {
      if (
        this.props.configuration.additionalDetails.openingOption ===
        'Front and Rear'
      ) {
        dualOpen = true
      }
    }
    if (this.props.configuration.primaryPanels) {
      primaryPanelLegends = this.props.configuration.primaryPanels.map(
        function (panel, key) {
          let legends = panel.panelCoords.map(
            function (item, key) {
              if (item.legend) {
                if (item.legend.hideLabel) {
                  return null
                } else {
                  if (dualOpen) {
                    if (item.dualOpenHide || panel.dualOpenHide) {
                      return null
                    } else {
                      return (
                        <Legend
                          item={item}
                          panel={panel}
                          activePanel={this.props.activePanel}
                          key={item.name}
                          dualOpen={dualOpen}
                          onClick={this.onClick}
                        />
                      )
                    }
                  } else {
                    return (
                      <Legend
                        item={item}
                        panel={panel}
                        activePanel={this.props.activePanel}
                        key={item.name}
                        onClick={this.onClick}
                      />
                    )
                  }
                }
              }
            }.bind(this)
          )
          return legends
        }.bind(this)
      )
      secondaryPanelLegends = this.props.configuration.secondaryPanels.map(
        function (panel, key) {
          let legends = panel.panelCoords.map(
            function (item, key) {
              if (item.legend) {
                if (item.legend.hideLabel) {
                  return null
                } else {
                  if (dualOpen) {
                    if (item.dualOpenHide || panel.dualOpenHide) {
                      return null
                    } else {
                      return (
                        <Legend
                          item={item}
                          panel={panel}
                          activePanel={this.props.activePanel}
                          key={item.name}
                          dualOpen={dualOpen}
                          onClick={this.onClick}
                        />
                      )
                    }
                  } else {
                    return (
                      <Legend
                        item={item}
                        panel={panel}
                        activePanel={this.props.activePanel}
                        key={item.name}
                        onClick={this.onClick}
                      />
                    )
                  }
                }
              }
            }.bind(this)
          )
          return legends
        }.bind(this)
      )
      framePanelLegends = this.props.framePanels.map(
        function (panel, key) {
          let legends = []
          if (panel.legends) {
            legends = panel.legends.map(
              function (item, key) {
                if (dualOpen && item.dualOpenHide) {
                  return null
                } else {
                  return (
                    <Legend
                      item={item}
                      panel={panel}
                      dualOpen={dualOpen}
                      activePanel={this.props.activePanel}
                      key={item.name}
                      onClick={this.onClick}
                    />
                  )
                }
              }.bind(this)
            )
          } else if (panel.type === 'panel-group') {
            legends = panel.panels.map(
              function (panel, key) {
                return panel.legends.map(
                  function (item, key) {
                    if (dualOpen && item.dualOpenHide) {
                      return null
                    } else {
                      return (
                        <Legend
                          item={item}
                          panel={panel}
                          dualOpen={dualOpen}
                          activePanel={this.props.activePanel}
                          key={item.name}
                          onClick={this.onClick}
                        />
                      )
                    }
                  }.bind(this)
                )
              }.bind(this)
            )
          }
          return legends
        }.bind(this)
      )
    }
    return (
      <div className={styles['panel__legends']}>
        {primaryPanelLegends}
        {secondaryPanelLegends}
        {framePanelLegends}
      </div>
    )
  }
}

export default PanelLegends
