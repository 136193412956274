import React from 'react'
import styles from '../SurfacesSearch/surfaces-search.module.css'

class HandrailPreview extends React.Component {
  render(){
    let className = styles['material__search__preview']
    let previewStyle = null
    let previewStyle2 = null
    let description = this.props.configuration.handrail.name.toUpperCase() + ' HANDRAIL: '
    if(this.props.configuration.handrail.selectedMaterial){
      description += 'Rail in ' + this.props.configuration.handrail.selectedMaterial.fullName
      previewStyle = {
        backgroundImage: 'url('+this.props.configuration.handrail.selectedMaterial.preview+')'
      }
      description += ' with ' + this.props.configuration.handrail.selectedStandoff.name
    }else{
      if(this.props.configuration.handrail.fullName){
        description += this.props.configuration.handrail.fullName
        description += ' with ' + this.props.configuration.handrail.selectedStandoff.name
      }else{
        description += ' ' + this.props.configuration.handrail.selectedStandoff.name
      }
    }

    description += ' in ' + this.props.configuration.handrail.selectedStandoff.selectedMaterial.fullName
    previewStyle2 = {
      backgroundImage: 'url('+this.props.configuration.handrail.selectedStandoff.selectedMaterial.preview+')'
    }
    return(
      <div className={className}>
        <div className={styles['material__search__preview_img']} style={previewStyle}>
        </div>
        <div className={styles['material__search__preview_img'] + ' ' + styles['material__search__preview_img_second']} style={previewStyle2}>
        </div>
        <div className={styles['search__preview_text']}>
          {description}
        </div>
      </div>
    )
  }
}

export default HandrailPreview
