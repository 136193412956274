import React from 'react'
import styles from '../SurfacesSearch/surfaces-search.module.css'
import { configHasWhiteLight } from '../../../utils/effects-layers'

class CeilingPreview extends React.Component {
  render(){
    let className = styles['material__search__preview']
    let previewStyle = null
    let previewStyle2 = null
    let description = 'ELEVATOR CEILING '
    let whiteLight = false
    if(configHasWhiteLight(this.props.configuration)){
      whiteLight = true
    }
    if(this.props.configuration.lighting){
      description += ' with ' + this.props.configuration.lighting.name
      if(whiteLight){
        previewStyle2 = {
          backgroundImage: 'url('+this.props.configuration.lighting.previewAlt+')'
        }
      }else{
        previewStyle2 = {
          backgroundImage: 'url('+this.props.configuration.lighting.preview+')'
        }
      }
    }
    if(this.props.configuration.lightTemperature){
      description += ' shown at ' + this.props.configuration.lightTemperature.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'K'
    }
    if(this.props.configuration.ceiling){
      description += ' in ' + this.props.configuration.ceiling.finish.fullName
      previewStyle = {
        backgroundImage: 'url('+this.props.configuration.ceiling.finish.preview+')'
      }
    }

    return(
      <div className={className}>
        <div className={styles['material__search__preview_img']} style={previewStyle}>
        </div>
        <div className={styles['material__search__preview_img'] + ' ' + styles['material__search__preview_img_second']} style={previewStyle2}>
        </div>
        <div className={styles['search__preview_text']}>
          {description}
        </div>
      </div>
    )
  }
}

export default CeilingPreview
