import React from 'react'
import ConfigSelect from '../ConfigSelect'
import styles from './configurations.module.css'
import ConfigPresets from '../ConfigSelect/config-presets'

const LEVELrText = 'LEVELr pairs a frameless panel aesthetic with refined trim and LED options. Multiple configurations, each defined by wall panel shape and layout, make it easy to create distinctive elevator interiors. Choose one of the configurations below and give it a name at right (optional).'

const LEVELeText = 'LEVELe pairs aluminum-framed panels with an interlocking grid system. Multiple configurations, each defined by wall panel shape and layout, make it easy to create distinctive elevator interiors. Choose one of the configurations below and give it a name at right (optional).'

const LEVELcText = 'LEVELc offers versatile design and material options in two basic configurations that make it easy to create distinctive, durable elevator interiors that are simple to install. Choose one of the configurations below and give it a name at right (optional).'

const OEMText = 'OEM presets help streamline the design and material selection process. Multiple configurations, each defined by wall panel shape and layout, make it easy to create distinctive elevator interiors. Choose one of the configurations below and give it a name at right (optional).'

class Configurations extends React.Component {
  constructor(props) {
    super(props)
    let activeTab = 0
    this.state = {
      description: LEVELrText,
      openDesignTips: false,
      activeTab: activeTab,
    }
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (this.props.design !== prevProps.design) {
      let activeTab = 0
      let tabDescription = OEMText
      let activeConfig = this.props.design?.configuration?.id
      if (this.props.design?.configuration?._id) {
        activeConfig = this.props.design?.configuration?._id
      }
      if (activeConfig && this.props.data.allConfiguration.nodes.length) {
        let configSelected = this.props.data.allConfiguration.nodes.find(
          (config) => {
            return config._id === activeConfig
          }
        )
        if (configSelected) {
          let configFamily = configSelected.name.substr(
            0,
            configSelected.name.indexOf('-')
          )
          switch (configFamily) {
            case 'LEVELr':
              activeTab = 0
              tabDescription = LEVELrText
              break
            case 'LEVELe':
              activeTab = 1
              tabDescription = LEVELeText
              break
            case 'LEVELc':
              activeTab = 2
              tabDescription = LEVELcText
              break
          }
          this.setState({ activeTab: activeTab, description: tabDescription })
        }
      }
    }
  }

  onConfigChange = (configFamily, config) => {
    this.props.onChange(config)
  }

  onTabClick = (tabNumber) => {
    let tabDescription = OEMText
    switch (tabNumber) {
      case 0:
        tabDescription = LEVELrText
        break
      case 1:
        tabDescription = LEVELeText
        break
      case 2:
        tabDescription = LEVELcText
        break
      case 3:
        tabDescription = OEMText
    }
    this.setState({
      activeTab: tabNumber,
      description: tabDescription
    })
  }

  render() {
    let showPresets = false
    if (this.props.userInfo) {
      if (
        this.props.userInfo.email.toLowerCase().includes('@forms-surfaces.com')
      ) {
        showPresets = true
      }
    }
    return (
      <div>
        <div className={styles['info__panel']}>
          <p>{this.state.description}</p>
        </div>
        <div className={styles['tabs']}>
          <button
            className={
              styles['config__tab'] +
              ' ' +
              (this.state.activeTab === 0 ? styles['active'] : '')
            }
            key="0"
            onClick={() => this.onTabClick(0)}
          >
            LEVELr
          </button>
          <button
            className={
              styles['config__tab'] +
              ' ' +
              (this.state.activeTab === 1 ? styles['active'] : '')
            }
            key="1"
            onClick={() => this.onTabClick(1)}
          >
            LEVELe
          </button>
          <button
            className={
              styles['config__tab'] +
              ' ' +
              (this.state.activeTab === 2 ? styles['active'] : '')
            }
            key="2"
            onClick={() => this.onTabClick(2)}
          >
            LEVELc
          </button>
          {showPresets && (
            <button
              className={
                styles['config__tab'] +
                ' ' +
                (this.state.activeTab === 3 ? styles['active'] : '')
              }
              key="3"
              onClick={() => this.onTabClick(3)}
            >
              OEM Presets
            </button>
          )}
        </div>
        <div
          className={
            styles['config__panel'] +
            ' ' +
            (this.state.activeTab === 0 ? styles['active'] : '')
          }
        >
          <ConfigSelect
            onChange={this.onConfigChange}
            userInfo={this.props.userInfo}
            onConfigPreset={this.props.onConfigPreset}
            changeOpening={this.props.changeOpening}
            design={this.props.design}
            data={this.props.data}
            familyName="LEVELr"
          />
        </div>
        <div
          className={
            styles['config__panel'] +
            ' ' +
            (this.state.activeTab === 1 ? styles['active'] : '')
          }
        >
          <ConfigSelect
            onChange={this.onConfigChange}
            userInfo={this.props.userInfo}
            onConfigPreset={this.props.onConfigPreset}
            changeOpening={this.props.changeOpening}
            design={this.props.design}
            data={this.props.data}
            familyName="LEVELe"
          />
        </div>
        <div
          className={
            styles['config__panel'] +
            ' ' +
            (this.state.activeTab === 2 ? styles['active'] : '')
          }
        >
          <ConfigSelect
            onChange={this.onConfigChange}
            userInfo={this.props.userInfo}
            onConfigPreset={this.props.onConfigPreset}
            changeOpening={this.props.changeOpening}
            design={this.props.design}
            data={this.props.data}
            familyName="LEVELc"
          />
        </div>
        {showPresets && (
          <div
            className={
              styles['config__panel'] +
              ' ' +
              (this.state.activeTab === 3 ? styles['active'] : '')
            }
          >
            <ConfigPresets
              presets={this.props.data.allDesignPreset.nodes}
              configurationFamilies={
                this.props.data.allConfigurationFamily.nodes
              }
              onConfigPreset={this.props.onConfigPreset}
              changeOpening={this.props.changeOpening}
              design={this.props.design}
            />
          </div>
        )}
      </div>
    )
  }
}

export default Configurations
