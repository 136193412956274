import React from 'react'
import styles from '../SurfacesSearch/surfaces-search.module.css'
import {
  configurationCaptureOnly,
} from '../../../utils/design'

class FrameStyleSelection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      catpureOnly: false
    }
  }

  componentDidMount() {
    let captureOnly = configurationCaptureOnly(this.props.configuration)
    this.setState({
      captureOnly: captureOnly
    }, function(){
      if(captureOnly && this.props.frameType !== 'Capture'){
        this.props.applyPanelFrameType('Capture')
      }
    }.bind(this))
  }

  onClickItem(frameStyle){
    if(this.state.captureOnly){
      if(frameStyle !== 'Minimal'){
        this.props.applyPanelFrameType(frameStyle)
      }
    }else{
      this.props.applyPanelFrameType(frameStyle)
    }
  }

  render(){
    let minimalFrame = ''
    let captureFrame = ' ' + styles['active']
    if(this.props.frameType){
      if(this.props.frameType === 'Minimal'){
        minimalFrame = ' ' + styles['active']
        captureFrame = ''
      }
    }
    let disableMinimal = ''
    if(this.state.captureOnly){
      disableMinimal = ' ' + styles['disabled']
    }
    return (
      <div className={styles['search__group']}>
        <h3 className={styles['material__search__title']}><span>Frame Style: </span>{this.props.configuration.panelFrameType}</h3>
        <div className={styles['panel__frames']}>
            <button disabled={this.state.captureOnly} className={ styles['minimal__panel__frame'] + ' fadeHover ' + styles['panel__frame__selection'] + minimalFrame + disableMinimal } rel={'Minimal'} onClick={() => this.onClickItem('Minimal')}><span style={{display: 'none'}}>Minimal</span></button>

            <button className={ styles['capture__panel__frame'] + ' fadeHover ' + styles['panel__frame__selection'] + captureFrame } rel={'Capture'} onClick={() => this.onClickItem('Capture')}><span style={{display: 'none'}}>Capture</span></button>
        </div>
        <div className={styles['frame__style__notes']}>
        NOTE: Capture panel frames will be used by default with VividGlass in LightPlane configuration, BermanGlass, Linq, and some Bonded Metal or Bonded Quartz.
        </div>
      </div>
    )
  }

}

export default FrameStyleSelection
