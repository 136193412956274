import React from 'react'
import styles from './panel-selections.module.css'

class PanelItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  onClick = () => {
    const { panel, onClick, panelType, index } = this.props
    panel.type = panelType
    panel.index = index
    onClick(panel)
  }

  render(){
    let className = styles['panel__selection'] + ' fadeHover'
    if(this.props.activePanel){
      if(this.props.panel.id === this.props.activePanel.id){
        className += ' ' + styles['panel__active']
      }
      if(this.props.panel.applied){
        className += ' ' + styles['panel__applied']
      }
    }
    return (
      <button className={className} key={this.props.label} onClick={this.onClick}>
        {this.props.panel.label}
      </button>
    )
  }

}

export default PanelItem
