let VivispectraElements = [
  {
    id: '5d7f93a0d13e2de4067cf84f',
    nid: 181,
    name: 'Acero',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/acero/acero.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f93a1b40ad11fa2a6997e',
        nid: 234,
        name: 'Pearlex',
        fullName:
          'ViviSpectra Elements glass with Acero interlayer and Pearlex finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/acero/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/acero/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/acero/pearlex-full.jpg',
        finish: {
          id: '5d7f93a243c3155e1eba9c07',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.1,
        whiteLight: true,
      },
      {
        id: '5d7f93a3bcc5b48ee9195785',
        nid: 235,
        name: 'Standard',
        fullName:
          'ViviSpectra Elements glass with Acero interlayer and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/acero/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/acero/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/acero/standard-full.jpg',
        finish: {
          id: '5d7f93a4d13316414f1a2079',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.1,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f93a5464a2c0e57dc7622',
    nid: 235,
    name: 'Arctic',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/arctic/arctic.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f93a6365493ab74f33286',
        nid: 1512,
        name: 'Pearlex',
        fullName:
          'ViviSpectra Elements glass with Arctic interlayer and Pearlex finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/arctic/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/arctic/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/arctic/pearlex-full.jpg',
        finish: {
          id: '5d7f93a70c03d06b709aeaef',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.1,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9ba1d01bc684f68f0db6',
              nid: 1649,
              title: 'VividGlass, Pearlex Finish, Shadows',
            },
            opacity: 0.15
          },
        ]
      },
      {
        id: '5d7f93a80f5f1c2e06279fcb',
        nid: 1513,
        name: 'Standard',
        fullName:
          'ViviSpectra Elements glass with Arctic interlayer and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/arctic/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/arctic/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/arctic/standard-full.jpg',
        finish: {
          id: '5d7f93a99c53098e756750bc',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.1,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.24
          },
        ]
      },
    ],
  },
  {
    id: '5d7f93aaa77473a32a3baf7f',
    nid: 182,
    name: 'Black Fig',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/black-fig/black-fig.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f93ab1ed7b8ec47f41203',
        nid: 239,
        name: 'Pearlex',
        fullName:
          'ViviSpectra Elements glass with Black Fig interlayer and Pearlex finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/black-fig/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/black-fig/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/black-fig/pearlex-full.jpg',
        finish: {
          id: '5d7f93aca5a079887949ec0a',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.4,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b2ba6fb16c17e0f76d0',
              nid: 1646,
              title: 'VividGlass, Pearlex Finish, Environment Lighting',
            },
            opacity: 0.25
          },
        ]
      },
      {
        id: '5d7f93add3c153b9310b41be',
        nid: 240,
        name: 'Standard',
        fullName:
          'ViviSpectra Elements glass with Black Fig interlayer and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/black-fig/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/black-fig/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/black-fig/standard-full.jpg',
        finish: {
          id: '5d7f93ae7000de97bfc9046e',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.4,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b2532c18c975f0cffcd',
              nid: 1645,
              title: 'VividGlass, Standard Finish, Environment Lighting',
            },
            opacity: 0.25
          }
        ]
      },
    ],
  },
  {
    id: '5d7f93afdc3089fd7684a0b9',
    nid: 345,
    name: 'Black Marble',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/black-marble/black-marble.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f93b0dbb1da082033702d',
        nid: 25923,
        name: 'Pearlex',
        fullName:
          'ViviSpectra Elements glass with Black Marble interlayer and Pearlex finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/black-marble/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/black-marble/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/black-marble/pearlex-full.jpg',
        finish: {
          id: '5d7f93b1e1264e9c3e66611c',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.4,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b2ba6fb16c17e0f76d0',
              nid: 1646,
              title: 'VividGlass, Pearlex Finish, Environment Lighting',
            },
            opacity: 0.25
          },
        ]
      },
      {
        id: '5d7f93b22a52f157aa6d08e7',
        nid: 25924,
        name: 'Standard',
        fullName:
          'ViviSpectra Elements glass with Black Marble interlayer and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/black-marble/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/black-marble/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/black-marble/standard-full.jpg',
        finish: {
          id: '5d7f93b37c841fa41f3d392a',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.4,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b2532c18c975f0cffcd',
              nid: 1645,
              title: 'VividGlass, Standard Finish, Environment Lighting',
            },
            opacity: 0.25
          }
        ]
      },
    ],
  },
  {
    id: '5d7f93b4fc34ded3a9033689',
    nid: 193,
    name: 'Highlight',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/highlight/highlight.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f93b5d1b332e9971a4fd7',
        nid: 1518,
        name: 'Pearlex',
        fullName:
          'ViviSpectra Elements glass with Highlight interlayer and Pearlex finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/highlight/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/highlight/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/highlight/pearlex-full.jpg',
        finish: {
          id: '5d7f93b6dad8ba07007bbbeb',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f93b7b289e1d773343053',
        nid: 591,
        name: 'Standard',
        fullName:
          'ViviSpectra Elements glass with Highlight interlayer and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/highlight/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/highlight/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/highlight/standard-full.jpg',
        finish: {
          id: '5d7f93b8f503afd575aca95f',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f93b9817aa33e466e7f49',
    nid: 346,
    name: 'Marea',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/marea/marea.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f93ba2bd51c105de3619b',
        nid: 25926,
        name: 'Pearlex',
        fullName:
          'ViviSpectra Elements glass with Marea interlayer and Pearlex finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/marea/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/marea/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/marea/pearlex-full.jpg',
        finish: {
          id: '5d7f93bbab15da054b41c10b',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.1,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9ba1d01bc684f68f0db6',
              nid: 1649,
              title: 'VividGlass, Pearlex Finish, Shadows',
            },
            opacity: 0.18
          },
        ]
      },
      {
        id: '5d7f93bce0db486c7bc08d53',
        nid: 25927,
        name: 'Standard',
        fullName:
          'ViviSpectra Elements glass with Marea interlayer and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/marea/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/marea/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/marea/standard-full.jpg',
        finish: {
          id: '5d7f93bd2272623d6f530f92',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.1,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.24
          },
        ]
      },
    ],
  },
  {
    id: '5d7f93be8951e3577780c07e',
    nid: 349,
    name: 'Raya',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/raya/raya.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f93bf034f53a82d2b6be5',
        nid: 25921,
        name: 'Pearlex',
        fullName:
          'ViviSpectra Elements glass with Raya interlayer and Pearlex finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/raya/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/raya/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/raya/pearlex-full.jpg',
        finish: {
          id: '5d7f93c0a21694d760653560',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        whiteLight: true,
      },
      {
        id: '5d7f93c105f83d5fd93d5c1e',
        nid: 25922,
        name: 'Standard',
        fullName:
          'ViviSpectra Elements glass with Raya interlayer and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/raya/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/raya/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/raya/standard-full.jpg',
        finish: {
          id: '5d7f93c242f117a665bcb869',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f93c32426b229084c1055',
    nid: 184,
    name: 'Strata',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/strata/strata.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f93c452d0e81ccefa86bb',
        nid: 232,
        name: 'Pearlex',
        fullName:
          'ViviSpectra Elements glass with Strata interlayer and Pearlex finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/strata/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/strata/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/strata/pearlex-full.jpg',
        finish: {
          id: '5d7f93c5c989b14079b9e785',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.1,
        whiteLight: true,
      },
      {
        id: '5d7f93c645e8c2254a953802',
        nid: 233,
        name: 'Standard',
        fullName:
          'ViviSpectra Elements glass with Strata interlayer and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/strata/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/strata/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/strata/standard-full.jpg',
        finish: {
          id: '5d7f93c72969d7b4092151e5',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.1,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f93c890325d3157565b42',
    nid: 185,
    name: 'Tali',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/tali/tali.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f93c98dda447443cf6e4a',
        nid: 237,
        name: 'Pearlex',
        fullName:
          'ViviSpectra Elements glass with Tali interlayer and Pearlex finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/tali/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/tali/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/tali/pearlex-full.jpg',
        finish: {
          id: '5d7f93cae85dfe767f4ef082',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.3,
      },
      {
        id: '5d7f93cb867b7c0746c33827',
        nid: 238,
        name: 'Standard',
        fullName:
          'ViviSpectra Elements glass with Tali interlayer and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/tali/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/tali/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/tali/standard-full.jpg',
        finish: {
          id: '5d7f93cc75d4b5d1aeed0043',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.3,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f93cd0d4d39e2662addc0',
    nid: 348,
    name: 'Tandarra',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/tandarra/tandarra.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f93cec6418e51c7487bcc',
        nid: 25933,
        name: 'Pearlex',
        fullName:
          'ViviSpectra Elements glass with Tandarra interlayer and Pearlex finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/tandarra/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/tandarra/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/tandarra/pearlex-full.jpg',
        finish: {
          id: '5d7f93cf3a7ffb705e2c79b4',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f93d000f8d591877532a3',
        nid: 25932,
        name: 'Standard',
        fullName:
          'ViviSpectra Elements glass with Tandarra interlayer and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/tandarra/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/tandarra/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/tandarra/standard-full.jpg',
        finish: {
          id: '5d7f93d1ca12ecee44898d62',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f93d22e53bca59ad6ee7f',
    nid: 236,
    name: 'Timber',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/timber/timber.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f93d3ef2e4e5f27f33a32',
        nid: 1514,
        name: 'Pearlex',
        fullName:
          'ViviSpectra Elements glass with Timber interlayer and Pearlex finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/timber/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/timber/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/timber/pearlex-full.jpg',
        finish: {
          id: '5d7f93d4768c84103fa62e8a',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.1,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9ba1d01bc684f68f0db6',
              nid: 1649,
              title: 'VividGlass, Pearlex Finish, Shadows',
            },
            opacity: 0.25
          },
        ]
      },
      {
        id: '5d7f93d555d756304d8afc02',
        nid: 1515,
        name: 'Standard',
        fullName:
          'ViviSpectra Elements glass with Timber interlayer and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/timber/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/timber/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/timber/standard-full.jpg',
        finish: {
          id: '5d7f93d6b8691dfecfd8df56',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.1,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.4
          },
        ]
      },
    ],
  },
  {
    id: '5d7f93d7bb2b6a1e02b74170',
    nid: 347,
    name: 'Umbra',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/umbra/umbra.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f93d8911dff1fdb6df4b9',
        nid: 25928,
        name: 'Pearlex',
        fullName:
          'ViviSpectra Elements glass with Umbra interlayer and Pearlex finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/umbra/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/umbra/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/umbra/pearlex-full.jpg',
        finish: {
          id: '5d7f93d9906778a09fcb096a',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.1,
        whiteLight: true,
      },
      {
        id: '5d7f93dac120e05e537cfb20',
        nid: 25929,
        name: 'Standard',
        fullName:
          'ViviSpectra Elements glass with Umbra interlayer and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/umbra/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/umbra/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/umbra/standard-full.jpg',
        finish: {
          id: '5d7f93dbb8f5a87f8dce8d8b',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.1,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f93dc9db3e9bd2becdfbf',
    nid: 344,
    name: 'White Marble',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/white-marble/white-marble.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f93ddb80bdf08aba70750',
        nid: 25930,
        name: 'Pearlex',
        fullName:
          'ViviSpectra Elements glass with White Marble interlayer and Pearlex finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/white-marble/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/white-marble/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/white-marble/pearlex-full.jpg',
        finish: {
          id: '5d7f93dedddd6e9adc45f9f5',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.1,
        whiteLight: true,
      },
      {
        id: '5d7f93df0d2d0a29e891806e',
        nid: 25931,
        name: 'Standard',
        fullName:
          'ViviSpectra Elements glass with White Marble interlayer and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/white-marble/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/white-marble/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/white-marble/standard-full.jpg',
        finish: {
          id: '5d7f93e075a023f201c412f7',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.1,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f93e1cd6db51416c4d2ca',
    nid: 255,
    name: 'Yucca Horizontal',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/yucca-horizontal/yucca-horizontal.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f93e25be54d91ec723408',
        nid: 2568,
        name: 'Pearlex',
        fullName:
          'ViviSpectra Elements glass with Yucca interlayer and Pearlex finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/yucca-horizontal/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/yucca-horizontal/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/yucca-horizontal/pearlex-full.jpg',
        finish: {
          id: '5d7f93e382599a38296a2cf2',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f93e43066ade6e1e7fbe4',
        nid: 2560,
        name: 'Standard',
        fullName:
          'ViviSpectra Elements glass with Yucca interlayer and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/yucca-horizontal/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/yucca-horizontal/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/yucca-horizontal/standard-full.jpg',
        finish: {
          id: '5d7f93e55564b2f8b121ebe7',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f93e634fec92040a43097',
    nid: 186,
    name: 'Yucca Vertical',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/yucca-vertical/yucca-vertical.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f93e7d981a6bd8057fdbf',
        nid: 243,
        name: 'Pearlex',
        fullName:
          'ViviSpectra Elements glass with Yucca interlayer and Pearlex finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/yucca-vertical/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/yucca-vertical/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/yucca-vertical/pearlex-full.jpg',
        finish: {
          id: '5d7f93e82ff5f04c10cf9589',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f93e9a779d92a4bd08c5d',
        nid: 244,
        name: 'Standard',
        fullName:
          'ViviSpectra Elements glass with Yucca interlayer and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/yucca-vertical/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/yucca-vertical/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/yucca-vertical/standard-full.jpg',
        finish: {
          id: '5d7f93ea3197eff761ad7bbf',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f93ebe87bc9c889978942',
    nid: 256,
    name: 'Yucca Blue Horizontal',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/yucca-blue-horizontal/yucca-blue-horizontal.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f93ecced93bc21bff4dbe',
        nid: 2572,
        name: 'Pearlex',
        fullName:
          'ViviSpectra Elements glass with Yucca Blue interlayer and Pearlex finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/yucca-blue-horizontal/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/yucca-blue-horizontal/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/yucca-blue-horizontal/pearlex-full.jpg',
        finish: {
          id: '5d7f93ed25b430056ea440ee',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f93ee90faaa51c65c0b0e',
        nid: 2571,
        name: 'Standard',
        fullName:
          'ViviSpectra Elements glass with Yucca Blue interlayer and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/yucca-blue-horizontal/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/yucca-blue-horizontal/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/yucca-blue-horizontal/standard-full.jpg',
        finish: {
          id: '5d7f93ef9a331936f8653324',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f93f0a5cb282595336725',
    nid: 187,
    name: 'Yucca Blue Vertical',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/yucca-blue-vertical/yucca-blue-vertical.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f93f1a76f7d2ccb5dc861',
        nid: 245,
        name: 'Pearlex',
        fullName:
          'ViviSpectra Elements glass with Yucca Blue interlayer and Pearlex finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/yucca-blue-vertical/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/yucca-blue-vertical/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/yucca-blue-vertical/pearlex-full.jpg',
        finish: {
          id: '5d7f93f28b4a64edc58d3ac1',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f93f3081728de71a4595f',
        nid: 246,
        name: 'Standard',
        fullName:
          'ViviSpectra Elements glass with Yucca Blue interlayer and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/yucca-blue-vertical/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/yucca-blue-vertical/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/yucca-blue-vertical/standard-full.jpg',
        finish: {
          id: '5d7f93f45805560754194514',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f93f548fc8ea0281d4cb7',
    nid: 257,
    name: 'Yucca Smoke Horizontal',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/yucca-smoke-horizontal/yucca-smoke-horizontal.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f93f65e174c11cd5e7467',
        nid: 2576,
        name: 'Pearlex',
        fullName:
          'ViviSpectra Elements glass with Yucca Smoke interlayer and Pearlex finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/yucca-smoke-horizontal/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/yucca-smoke-horizontal/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/yucca-smoke-horizontal/pearlex-full.jpg',
        finish: {
          id: '5d7f93f737229a369aaa1e14',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f93f87feac2988ae57983',
        nid: 2575,
        name: 'Standard',
        fullName:
          'ViviSpectra Elements glass with Yucca Smoke interlayer and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/yucca-smoke-horizontal/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/yucca-smoke-horizontal/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/yucca-smoke-horizontal/standard-full.jpg',
        finish: {
          id: '5d7f93f955a61e364daf1def',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f93fada2e3102cc9cb178',
    nid: 234,
    name: 'Yucca Smoke Vertical',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/yucca-smoke-vertical/yucca-smoke-vertical.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f93fb066fee7709d8d928',
        nid: 1510,
        name: 'Pearlex',
        fullName:
          'ViviSpectra Elements glass with Yucca Smoke interlayer and Pearlex finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/yucca-smoke-vertical/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/yucca-smoke-vertical/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/yucca-smoke-vertical/pearlex-full.jpg',
        finish: {
          id: '5d7f93fc5694f8899e297e29',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f93fd78f20f25c219a505',
        nid: 1511,
        name: 'Standard',
        fullName:
          'ViviSpectra Elements glass with Yucca Smoke interlayer and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/yucca-smoke-vertical/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/yucca-smoke-vertical/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/yucca-smoke-vertical/standard-full.jpg',
        finish: {
          id: '5d7f93febeb45280fb220fe8',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
]

export default VivispectraElements
