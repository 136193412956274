let VivistrataLayers = [
  {
    id: '5d7f991692e95ecf2cf1d297',
    nid: 290,
    name: 'Canopy',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistrata-layers/canopy/canopy.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f991767bafd5ba4316a69',
        nid: 14533,
        name: 'Standard',
        fullName:
          'ViviStrata Layers glass with Canopy pattern in White and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistrata-layers/canopy/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistrata-layers/canopy/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistrata-layers/canopy/standard-full.jpg',
        finish: {
          id: '5d7f99181120931951c2583c',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f99197ff5bd2ce3e614a1',
    nid: 291,
    name: 'Glide',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistrata-layers/glide/glide.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f991a47b3d0caa01c693d',
        nid: 14534,
        name: 'Standard',
        fullName:
          'ViviStrata Layers glass with Glide pattern in White and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistrata-layers/glide/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistrata-layers/glide/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistrata-layers/glide/standard-full.jpg',
        finish: {
          id: '5d7f991b4110e155a4beb820',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.24
          },
        ]
      },
    ],
  },
  {
    id: '5d7f991ce356fca36730a105',
    nid: 292,
    name: 'Glint',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistrata-layers/glint/glint.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f991db6b69c2b79680552',
        nid: 14535,
        name: 'Standard',
        fullName:
          'ViviStrata Layers glass with Glint pattern in White and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistrata-layers/glint/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistrata-layers/glint/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistrata-layers/glint/standard-full.jpg',
        finish: {
          id: '5d7f991e265f6504c6548279',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.28
          },
        ]
      },
    ],
  },
  {
    id: '5d7f991f74285a9d90a67aa5',
    nid: 293,
    name: 'Hive',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistrata-layers/hive/hive.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f9920d788ad9317a14ce7',
        nid: 14536,
        name: 'Standard',
        fullName:
          'ViviStrata Layers glass with Hive pattern in White and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistrata-layers/hive/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistrata-layers/hive/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistrata-layers/hive/standard-full.jpg',
        finish: {
          id: '5d7f992175144a3c4c71787b',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.24
          },
        ]
      },
    ],
  },
  {
    id: '5d7f9922fa50dbf4c3e37648',
    nid: 294,
    name: 'Palisade',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistrata-layers/palisade/palisade.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f9923bc3fbd172007349f',
        nid: 14537,
        name: 'Standard',
        fullName:
          'ViviStrata Layers glass with Palisade pattern in White and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistrata-layers/palisade/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistrata-layers/palisade/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistrata-layers/palisade/standard-full.jpg',
        finish: {
          id: '5d7f992475d1194fbfd29a67',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f99251ae020dfdacc3cb0',
    nid: 295,
    name: 'Plot',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistrata-layers/plot/plot.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f9926bb4349dc4b854288',
        nid: 14538,
        name: 'Standard',
        fullName:
          'ViviStrata Layers glass with Plot pattern in White and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistrata-layers/plot/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistrata-layers/plot/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistrata-layers/plot/standard-full.jpg',
        finish: {
          id: '5d7f99272450090cb048428d',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.28
          },
        ]
      },
    ],
  },
  {
    id: '5d7f99285b3ca18e8e549764',
    nid: 297,
    name: 'Ripple',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistrata-layers/ripple/ripple.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f9929becb6bb8211ac62b',
        nid: 14539,
        name: 'Standard',
        fullName:
          'ViviStrata Layers glass with Ripple pattern in White and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistrata-layers/ripple/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistrata-layers/ripple/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistrata-layers/ripple/standard-full.jpg',
        finish: {
          id: '5d7f992afc1e35ed53e9e38e',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.28
          },
        ]
      },
    ],
  },
  {
    id: '5d7f992ba51d1c0dfa6aa5ca',
    nid: 298,
    name: 'Surge',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistrata-layers/surge/surge.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f992c3c62040477abc10b',
        nid: 14540,
        name: 'Standard',
        fullName:
          'ViviStrata Layers glass with Surge pattern in White and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistrata-layers/surge/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistrata-layers/surge/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistrata-layers/surge/standard-full.jpg',
        finish: {
          id: '5d7f992d273f5ad7eb97380e',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
]

export default VivistrataLayers
