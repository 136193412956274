import React from 'react'
import styles from '../PanelLegends/panel-legends.module.css'

class CeilingLegends extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activePanel: {}
    }
  }

  onClick = () => {
    const { panel, onClick } = this.props
    onClick(panel)
  }

  render(){
    const fullWidth = 900
    const fullHeight = 1250
    let ceilingLegends = ''

    if(this.props.configuration.ceilingLegends.length){
      ceilingLegends = this.props.configuration.ceilingLegends.map(function(panel, key){
        let className = styles['panel__legend']
        className += ' ' + styles['legend__active']
        let left = ((panel.left / fullWidth) * 100) + '%'
        let top = ((panel.top / fullHeight) * 100) + '%'
        let legendStyle = {
          left: left,
          top: top
        }
        return (
          <div className={className} key={panel.label} style={legendStyle}>
            {panel.label}
          </div>
        )
      })

    }
    return (
      <div className={styles['panel__legends']}>
        {ceilingLegends}
      </div>
    )
  }
}

export default CeilingLegends
