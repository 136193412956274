let MaterialsSecondary = [
  {
    id: '5d7f8dcc53bc307fecfbc77d',
    nid: 4,
    name: 'Stainless Steel',
    materialGroup: {
      id: '5e4d7c46f1b8fc0008d8b004',
      ref: '5e4d7c46f1b8fc0008d8b004',
      nid: 175,
      name: 'Stainless Steel & Fused Metal',
    },
    subMaterialLabel: "Finish",
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/stainless-steel.jpg',
    finishes: [
      {
        id: '5d7f8dce7b46e17ac0486dbe',
        nid: 157,
        name: 'No Pattern',
        fullName: 'Stainless Steel with Sandstone finish',
        finish: {
          id: '5d7f8dcf6783b79d530f5161',
          nid: 155,
          name: 'Sandstone',
        },
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/sandstone/sandstone.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/sandstone/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/sandstone/no-pattern-full.jpg',
      },
      {
        id: '5d7f8dd0336df3410328984e',
        nid: 5,
        name: 'No Pattern',
        fullName: 'Stainless Steel with Seastone finish',
        finish: {
          id: '5d7f8dd1d7aabdf6a949cbd1',
          nid: 156,
          name: 'Seastone',
        },
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/seastone.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/seastone/no-pattern-full.jpg',
      },
      {
        id: '5d7f8dd29239f880863b241e',
        nid: 39837,
        name: 'No Pattern',
        fullName: 'Stainless Steel with Silk finish',
        finish: {
          id: '5d7f8dd33732e452585b2ca4',
          nid: 376,
          name: 'Silk',
        },
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/silk.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/silk/no-pattern-full.jpg',
      },
      {
        id: '5d7f8dd4dad2483c3a632a24',
        nid: 158,
        name: 'No Pattern',
        fullName: 'Stainless Steel with Linen finish',
        finish: {
          id: '5d7f8dd57667fad7ef09d16c',
          nid: 157,
          name: 'Linen',
        },
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/linen/linen.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/linen/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/linen/no-pattern-full.jpg',
      },
      {
        id: '5d7f8dd62c9d0ce65f5e0b63',
        nid: 160,
        name: 'No Pattern',
        fullName: 'Stainless Steel with Diamond finish',
        finish: {
          id: '5d7f8dd7dc437ff4e7d92364',
          nid: 140,
          name: 'Diamond',
        },
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/diamond/diamond.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/diamond/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/diamond/no-pattern-full.jpg',
      },
      {
        id: '5d7f8dd83eac3489d9b2e0ae',
        nid: 161,
        name: 'No Pattern',
        fullName: 'Stainless Steel with Mist finish',
        finish: {
          id: '5d7f8dd9458b66e053b88440',
          nid: 141,
          name: 'Mist',
        },
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mist/mist.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mist/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mist/no-pattern-full.jpg',
      },
      {
        id: '5d7f8ddae7142e2b39ae2d34',
        nid: 163,
        name: 'No Pattern',
        fullName: 'Stainless Steel with Mirror finish',
        finish: {
          id: '5d7f8ddb2e4e45346a1443f2',
          nid: 143,
          name: 'Mirror',
        },
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/mirror.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/mirror/no-pattern-full.jpg',
      },
      {
        id: '5d7f8ddcb5d06a427160bf9d',
        nid: 3,
        name: 'No Pattern',
        fullName: 'Stainless Steel with Satin finish',
        finish: {
          id: '5d7f8ddd69d014bcaec40665',
          nid: 159,
          name: 'Satin',
        },
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/satin.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/satin/no-pattern-full.jpg',
      },
    ],
  },
  {
    id: '5d7f8ddeb9f958f359f1b5ab',
    nid: 88,
    name: 'Fused Bronze',
    materialGroup: {
      id: '5e4d7c46f1b8fc0008d8b004',
      ref: '5e4d7c46f1b8fc0008d8b004',
      nid: 175,
      name: 'Stainless Steel & Fused Metal',
    },
    subMaterialLabel: "Finish",
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-bronze/fused-bronze.jpg',
    finishes: [
      {
        id: '5d7f8de04f9f399198a0969c',
        nid: 165,
        name: 'No Pattern',
        fullName: 'Fused Bronze with Sandstone finish',
        finish: {
          id: '5d7f8de1e008f9bcdecef60d',
          nid: 155,
          name: 'Sandstone',
        },
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-bronze/sandstone/sandstone.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-bronze/sandstone/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-bronze/sandstone/no-pattern-full.jpg',
      },
      {
        id: '5d7f8de2c221311cba4168c9',
        nid: 167,
        name: 'No Pattern',
        fullName: 'Fused Bronze with Seastone finish',
        finish: {
          id: '5d7f8de34b9c1e8ac1221949',
          nid: 156,
          name: 'Seastone',
        },
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-bronze/seastone/seastone.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-bronze/seastone/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-bronze/seastone/no-pattern-full.jpg',
      },
      {
        id: '5d7f8de4f3158f363bee557b',
        nid: 753,
        name: 'No Pattern',
        fullName: 'Fused Bronze with Linen finish',
        finish: {
          id: '5d7f8de55522c1c48f9a4e75',
          nid: 157,
          name: 'Linen',
        },
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-bronze/linen/linen.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-bronze/linen/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-bronze/linen/no-pattern-full.jpg',
      },
      {
        id: '5d7f8de6dce6e61a6733ea25',
        nid: 754,
        name: 'No Pattern',
        fullName: 'Fused Bronze with Diamond finish',
        finish: {
          id: '5d7f8de7730cc59094123487',
          nid: 140,
          name: 'Diamond',
        },
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-bronze/diamond/diamond.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-bronze/diamond/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-bronze/diamond/no-pattern-full.jpg',
      },
      {
        id: '5d7f8de8b7bf3e1c90b52948',
        nid: 94,
        name: 'No Pattern',
        fullName: 'Fused Bronze with Mirror finish',
        finish: {
          id: '5d7f8de90a3efab2aecbc847',
          nid: 143,
          name: 'Mirror',
        },
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-bronze/mirror/mirror.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-bronze/mirror/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-bronze/mirror/no-pattern-full.jpg',
      },
      {
        id: '5d7f8deaa368751656e2fce8',
        nid: 166,
        name: 'No Pattern',
        fullName: 'Fused Bronze with Satin finish',
        finish: {
          id: '5d7f8debc95a5b0fb0f059ed',
          nid: 159,
          name: 'Satin',
        },
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-bronze/satin/satin.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-bronze/satin/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-bronze/satin/no-pattern-full.jpg',
      },
    ],
  },
  {
    id: '5d7f8dec867e5939b2b08948',
    nid: 89,
    name: 'Fused Graphite',
    materialGroup: {
      id: '5e4d7c46f1b8fc0008d8b004',
      ref: '5e4d7c46f1b8fc0008d8b004',
      nid: 175,
      name: 'Stainless Steel & Fused Metal',
    },
    subMaterialLabel: "Finish",
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/fused-graphite.jpg',
    finishes: [
      {
        id: '5d7f8deeeb30c083b2f4d66f',
        nid: 170,
        name: 'No Pattern',
        fullName: 'Fused Graphite with Sandstone finish',
        finish: {
          id: '5d7f8def587c979d972a52cf',
          nid: 155,
          name: 'Sandstone',
        },
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/sandstone/sandstone.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/sandstone/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/sandstone/no-pattern-full.jpg',
      },
      {
        id: '5d7f8df0fb4adc363ec0a5be',
        nid: 172,
        name: 'No Pattern',
        fullName: 'Fused Graphite with Seastone finish',
        finish: {
          id: '5d7f8df1d72b72ef232e16f2',
          nid: 156,
          name: 'Seastone',
        },
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/seastone.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/no-pattern-full.jpg',
      },
      {
        id: '5d7f8df237a79672d01636f2',
        nid: 168,
        name: 'No Pattern',
        fullName: 'Fused Graphite with Linen finish',
        finish: {
          id: '5d7f8df315c5185837420cd3',
          nid: 157,
          name: 'Linen',
        },
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/linen/linen.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/linen/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/linen/no-pattern-full.jpg',
      },
      {
        id: '5d7f8df4654c3a3be66f1e69',
        nid: 755,
        name: 'No Pattern',
        fullName: 'Fused Graphite with Diamond finish',
        finish: {
          id: '5d7f8df5116edb184aaa7efd',
          nid: 140,
          name: 'Diamond',
        },
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/diamond/diamond.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/diamond/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/diamond/no-pattern-full.jpg',
      },
      {
        id: '5d7f8df6f6c28c6614cb070d',
        nid: 169,
        name: 'No Pattern',
        fullName: 'Fused Graphite with Mirror finish',
        finish: {
          id: '5d7f8df78c097d0e893c4675',
          nid: 143,
          name: 'Mirror',
        },
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/mirror.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/no-pattern-full.jpg',
      },
      {
        id: '5d7f8df84e197c0c497225d1',
        nid: 171,
        name: 'No Pattern',
        fullName: 'Fused Graphite with Satin finish',
        finish: {
          id: '5d7f8df92c6c64e270f111e9',
          nid: 159,
          name: 'Satin',
        },
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/satin.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/no-pattern-full.jpg',
      },
    ],
  },
  {
    id: '5d7f8dfa1874c6e754b0a3e9',
    nid: 284,
    name: 'Fused Nickel Bronze',
    materialGroup: {
      id: '5e4d7c46f1b8fc0008d8b004',
      ref: '5e4d7c46f1b8fc0008d8b004',
      nid: 175,
      name: 'Stainless Steel & Fused Metal',
    },
    subMaterialLabel: "Finish",
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/fused-nickel-bronze.jpg',
    finishes: [
      {
        id: '5d7f8dfc3d9510e0649a7983',
        nid: 8491,
        name: 'No Pattern',
        fullName: 'Fused Nickel Bronze with Sandstone finish',
        finish: {
          id: '5d7f8dfdae37fd5ce38170c7',
          nid: 155,
          name: 'Sandstone',
        },
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/sandstone/sandstone.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/sandstone/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/sandstone/no-pattern-full.jpg',
      },
      {
        id: '5d7f8dfe9f8f25a7f1cd67ee',
        nid: 8493,
        name: 'No Pattern',
        fullName: 'Fused Nickel Bronze with Seastone finish',
        finish: {
          id: '5d7f8dff7f8e5546207d60e3',
          nid: 156,
          name: 'Seastone',
        },
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/seastone.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/no-pattern-full.jpg',
      },
      {
        id: '5d7f8e006bd90bb1777f74ed',
        nid: 8489,
        name: 'No Pattern',
        fullName: 'Fused Nickel Bronze with Linen finish ',
        finish: {
          id: '5d7f8e01ac0b16bbb2a54dbb',
          nid: 157,
          name: 'Linen',
        },
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/linen/linen.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/linen/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/linen/no-pattern-full.jpg',
      },
      {
        id: '5d7f8e025bf396dc8cdcda55',
        nid: 8488,
        name: 'No Pattern',
        fullName: 'Fused Nickel Bronze with Diamond finish ',
        finish: {
          id: '5d7f8e03a64f96a3e4c59694',
          nid: 140,
          name: 'Diamond',
        },
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/diamond/diamond.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/diamond/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/diamond/no-pattern-full.jpg',
      },
      {
        id: '5d7f8e04c8321cd0b58d148e',
        nid: 8490,
        name: 'No Pattern',
        fullName: 'Fused Nickel Bronze with Mirror finish',
        finish: {
          id: '5d7f8e052d4f5aeeea116d44',
          nid: 143,
          name: 'Mirror',
        },
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/mirror.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/no-pattern-full.jpg',
      },
      {
        id: '5d7f8e06b7738845c22deb21',
        nid: 8492,
        name: 'No Pattern',
        fullName: 'Fused Nickel Bronze with Satin finish',
        finish: {
          id: '5d7f8e07bbdc2eee5ceac5cc',
          nid: 159,
          name: 'Satin',
        },
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/satin.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/no-pattern-full.jpg',
      },
    ],
  },
  {
    id: '5e29bf1b78fd831a1ef22e41',
    nid: 90,
    name: 'Fused Nickel Silver',
    materialGroup: {
      id: '5e4d7c46f1b8fc0008d8b004',
      ref: '5e4d7c46f1b8fc0008d8b004',
      nid: 175,
      name: 'Stainless Steel & Fused Metal',
    },
    subMaterialLabel: "Finish",
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/fused-nickel-silver.jpg',
    finishes: [
      {
        id: '5d7f8e09ce7ef5979c71c2e6',
        nid: 92,
        name: 'No Pattern',
        fullName: 'Fused Nickel Silver with Sandstone finish',
        finish: {
          id: '5d7f8e0ad441e6dd844a7821',
          nid: 155,
          name: 'Sandstone',
        },
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/sandstone/sandstone.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/sandstone/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/sandstone/no-pattern-full.jpg',
      },
      {
        id: '5d7f8e0b58b013d2112b8845',
        nid: 601,
        name: 'No Pattern',
        fullName: 'Fused Nickel Silver with Seastone finish',
        finish: {
          id: '5d7f8e0cc5303797609869a5',
          nid: 156,
          name: 'Seastone',
        },
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/seastone.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/no-pattern-full.jpg',
      },
      {
        id: '5d7f8e0d28be525ea9b839f4',
        nid: 90,
        name: 'No Pattern',
        fullName: 'Fused Nickel Silver with Linen finish',
        finish: {
          id: '5d7f8e0e561db028701c1bf4',
          nid: 157,
          name: 'Linen',
        },
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/linen/linen.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/linen/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/linen/no-pattern-full.jpg',
      },
      {
        id: '5d7f8e0fee083699c456b94c',
        nid: 758,
        name: 'No Pattern',
        fullName: 'Fused Nickel Silver with Diamond finish',
        finish: {
          id: '5d7f8e106f138eb5d61f4250',
          nid: 140,
          name: 'Diamond',
        },
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/diamond/diamond.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/diamond/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/diamond/no-pattern-full.jpg',
      },
      {
        id: '5d7f8e118f4407fb64ba2f82',
        nid: 91,
        name: 'No Pattern',
        fullName: 'Fused Nickel Silver with Mirror finish',
        finish: {
          id: '5d7f8e126888d3febb6b21eb',
          nid: 143,
          name: 'Mirror',
        },
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/mirror.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/no-pattern-full.jpg',
      },
      {
        id: '5d7f8e13d0a57780f157bd14',
        nid: 93,
        name: 'No Pattern',
        fullName: 'Fused Nickel Silver with Satin finish',
        finish: {
          id: '5d7f8e14ff9b34105a92f9a1',
          nid: 159,
          name: 'Satin',
        },
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/satin.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/no-pattern-full.jpg',
      },
    ],
  },
  {
    id: '5d7f8e155395acfe50f891d0',
    nid: 91,
    name: 'Fused White Gold',
    materialGroup: {
      id: '5e4d7c46f1b8fc0008d8b004',
      ref: '5e4d7c46f1b8fc0008d8b004',
      nid: 175,
      name: 'Stainless Steel & Fused Metal',
    },
    subMaterialLabel: "Finish",
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/fused-white-gold.jpg',
    finishes: [
      {
        id: '5d7f8e175179bed02a8d7419',
        nid: 175,
        name: 'No Pattern',
        fullName: 'Fused White Gold with Sandstone finish',
        finish: {
          id: '5d7f8e18cf76f527f9eedf98',
          nid: 155,
          name: 'Sandstone',
        },
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/sandstone/sandstone.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/sandstone/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/sandstone/no-pattern-full.jpg',
      },
      {
        id: '5d7f8e19d71826e5b6884a3d',
        nid: 61,
        name: 'No Pattern',
        fullName: 'Fused White Gold with Seastone finish',
        finish: {
          id: '5d7f8e1a81bc6feb6694d664',
          nid: 156,
          name: 'Seastone',
        },
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/seastone.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/no-pattern-full.jpg',
      },
      {
        id: '5d7f8e1b16fd815f6c3ff6db',
        nid: 173,
        name: 'No Pattern',
        fullName: 'Fused White Gold with Linen finish',
        finish: {
          id: '5d7f8e1cf67cca03b54868c2',
          nid: 157,
          name: 'Linen',
        },
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/linen/linen.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/linen/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/linen/no-pattern-full.jpg',
      },
      {
        id: '5d7f8e1d8b4ef6e4091dfeb9',
        nid: 759,
        name: 'No Pattern',
        fullName: 'Fused White Gold with Diamond finish',
        finish: {
          id: '5d7f8e1ea46415c2f4be95a3',
          nid: 140,
          name: 'Diamond',
        },
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/diamond/diamond.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/diamond/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/diamond/no-pattern-full.jpg',
      },
      {
        id: '5d7f8e1f21eb0a4cb41378f3',
        nid: 174,
        name: 'No Pattern',
        fullName: 'Fused White Gold with Mirror finish',
        finish: {
          id: '5d7f8e209104e392b70516eb',
          nid: 143,
          name: 'Mirror',
        },
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/mirror.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/no-pattern-full.jpg',
      },
      {
        id: '5d7f8e21b5c13b2459aef37b',
        nid: 176,
        name: 'No Pattern',
        fullName: 'Fused White Gold with Satin finish',
        finish: {
          id: '5d7f8e224556f067e3e2e44a',
          nid: 159,
          name: 'Satin',
        },
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/satin.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/no-pattern-full.jpg',
      },
    ],
  },
]

export default MaterialsSecondary
