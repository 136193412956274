let VivistoneCreamOnyx = [
  {
    id: '5d7f95bab1f2ab65f3532eef',
    nid: 195,
    name: 'Slab A-1',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-a-1/slab-a-1.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f95bb83d2f45d2b4b6890',
        nid: 228,
        name: 'Pearlex',
        fullName:
          'ViviStone Cream Onyx glass with slab A-1 interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-a-1/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-a-1/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-a-1/pearlex-full.jpg',
        finish: {
          id: '5d7f95bc1d1ec0160db54b6a',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f95bd3681a451f64faee6',
        nid: 229,
        name: 'Standard',
        fullName:
          'ViviStone Cream Onyx glass with slab A-1 interlayer and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-a-1/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-a-1/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-a-1/standard-full.jpg',
        finish: {
          id: '5d7f95be3418ccd990641197',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f95bf9759cdf661670b1d',
    nid: 240,
    name: 'Slab A-2',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-a-2/slab-a-2.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f95c07f6db4e2c32537cc',
        nid: 2025,
        name: 'Pearlex',
        fullName:
          'ViviStone Cream Onyx glass with slab A-2 interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-a-2/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-a-2/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-a-2/pearlex-full.jpg',
        finish: {
          id: '5d7f95c170ee6cf730833e4f',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f95c2b6de5b9c436f4507',
        nid: 2026,
        name: 'Standard',
        fullName:
          'ViviStone Cream Onyx glass with slab A-2 interlayer and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-a-2/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-a-2/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-a-2/standard-full.jpg',
        finish: {
          id: '5d7f95c33ddd2212b9429512',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f95c4204f659f429c2665',
    nid: 241,
    name: 'Slab A-3',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-a-3/slab-a-3.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f95c5cee2a94277eb06d5',
        nid: 2027,
        name: 'Pearlex',
        fullName:
          'ViviStone Cream Onyx glass with slab A-3 interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-a-3/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-a-3/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-a-3/pearlex-full.jpg',
        finish: {
          id: '5d7f95c6da9c2f871ec64a27',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f95c7dc51a5ad87f6889f',
        nid: 2028,
        name: 'Standard',
        fullName:
          'ViviStone Cream Onyx glass with slab A-3 interlayer and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-a-3/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-a-3/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-a-3/standard-full.jpg',
        finish: {
          id: '5d7f95c8bbe6e229fbca9161',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f95c9c5b6d4b931dad89d',
    nid: 243,
    name: 'Slab B-4',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-b-4/slab-b-4.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f95ca252c21f56399b00b',
        nid: 2029,
        name: 'Pearlex',
        fullName:
          'ViviStone Cream Onyx glass with slab B-4 interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-b-4/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-b-4/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-b-4/pearlex-full.jpg',
        finish: {
          id: '5d7f95cbcce843b3afda75fd',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f95cc3e176f09215a31c8',
        nid: 2030,
        name: 'Standard',
        fullName:
          'ViviStone Cream Onyx glass with slab B-4 interlayer and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-b-4/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-b-4/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-b-4/standard-full.jpg',
        finish: {
          id: '5d7f95cd148d74ad750c8229',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f95cedc3c82fb1f192e34',
    nid: 244,
    name: 'Slab B-5',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-b-5/slab-b-5.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f95cfd0f616f3ca44841d',
        nid: 2031,
        name: 'Pearlex',
        fullName:
          'ViviStone Cream Onyx glass with slab B-5 interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-b-5/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-b-5/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-b-5/pearlex-full.jpg',
        finish: {
          id: '5d7f95d0b7a0f8126ad4f0f3',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: true,
      },
      {
        id: '5d7f95d1b6318a70c51b559e',
        nid: 2032,
        name: 'Standard',
        fullName:
          'ViviStone Cream Onyx glass with slab B-5 interlayer and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-b-5/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-b-5/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-b-5/standard-full.jpg',
        finish: {
          id: '5d7f95d261f5aa704fa8bdfa',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f95d3ff18880ecd6ea514',
    nid: 245,
    name: 'Slab B-6',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-b-6/slab-b-6.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f95d4ef3f2cf9f3247a9a',
        nid: 2033,
        name: 'Pearlex',
        fullName:
          'ViviStone Cream Onyx glass with slab B-6 interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-b-6/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-b-6/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-b-6/pearlex-full.jpg',
        finish: {
          id: '5d7f95d5b50535b3dee4d899',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f95d6673dddd388d37521',
        nid: 2034,
        name: 'Standard',
        fullName:
          'ViviStone Cream Onyx glass with slab B-6 interlayer and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-b-6/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-b-6/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-b-6/standard-full.jpg',
        finish: {
          id: '5d7f95d737ffe55c21e340ac',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f95d8bffcff9701e48cbe',
    nid: 246,
    name: 'Slab C-7',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-c-7/slab-c-7.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f95d956a75cca345f299a',
        nid: 2035,
        name: 'Pearlex',
        fullName:
          'ViviStone Cream Onyx glass with slab C-7 interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-c-7/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-c-7/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-c-7/pearlex-full.jpg',
        finish: {
          id: '5d7f95da3a37759a64b2aac6',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f95dbb38fb5980231ea5a',
        nid: 2036,
        name: 'Standard',
        fullName:
          'ViviStone Cream Onyx glass with slab C-7 interlayer and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-c-7/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-c-7/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-c-7/standard-full.jpg',
        finish: {
          id: '5d7f95dc8db658edcf97df0c',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f95dd52d55cd5f787dbe2',
    nid: 247,
    name: 'Slab C-8',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-c-8/slab-c-8.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f95de4396c93465808c87',
        nid: 2037,
        name: 'Pearlex',
        fullName:
          'ViviStone Cream Onyx glass with slab C-8 interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-c-8/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-c-8/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-c-8/pearlex-full.jpg',
        finish: {
          id: '5d7f95df2598438a7a061a24',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f95e0893e85c8ad54b1ed',
        nid: 2038,
        name: 'Standard',
        fullName:
          'ViviStone Cream Onyx glass with slab C-8 interlayer and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-c-8/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-c-8/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-c-8/standard-full.jpg',
        finish: {
          id: '5d7f95e14b957d95c3be9627',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f95e223ae552cdbfa073e',
    nid: 248,
    name: 'Slab C-9',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-c-9/slab-c-9.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f95e3c41eb44774377452',
        nid: 2039,
        name: 'Pearlex',
        fullName:
          'ViviStone Cream Onyx glass with slab C-9 interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-c-9/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-c-9/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-c-9/pearlex-full.jpg',
        finish: {
          id: '5d7f95e46e5994ac32293250',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f95e5111ea7aa366a4e80',
        nid: 2040,
        name: 'Standard',
        fullName:
          'ViviStone Cream Onyx glass with slab C-9 interlayer and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-c-9/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-c-9/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-c-9/standard-full.jpg',
        finish: {
          id: '5d7f95e6df3c7b02e863ba24',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f95e735bd2830046dd49a',
    nid: 249,
    name: 'Slab D-10',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-d-10/slab-d-10.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f95e81b09587bf4ac38f9',
        nid: 2041,
        name: 'Pearlex',
        fullName:
          'ViviStone Cream Onyx glass with slab D-10 interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-d-10/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-d-10/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-d-10/pearlex-full.jpg',
        finish: {
          id: '5d7f95e9d4814c14f328e024',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f95eaa9a3ab1a93a07618',
        nid: 2042,
        name: 'Standard',
        fullName:
          'ViviStone Cream Onyx glass with slab D-10 interlayer and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-d-10/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-d-10/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-d-10/standard-full.jpg',
        finish: {
          id: '5d7f95eb52c5edeed9a443c0',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f95ec8426adf787ee02b5',
    nid: 250,
    name: 'Slab D-11',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-d-11/slab-d-11.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f95edee2f2e09bf1e072e',
        nid: 2043,
        name: 'Pearlex',
        fullName:
          'ViviStone Cream Onyx glass with slab D-11 interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-d-11/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-d-11/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-d-11/pearlex-full.jpg',
        finish: {
          id: '5d7f95ee5daf6eedd392d65b',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f95efb232036a0002262d',
        nid: 2044,
        name: 'Standard',
        fullName:
          'ViviStone Cream Onyx glass with slab D-11 interlayer and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-d-11/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-d-11/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-d-11/standard-full.jpg',
        finish: {
          id: '5d7f95f08c674af340f53153',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f95f12de529ecc3368293',
    nid: 251,
    name: 'Slab D-12',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-d-12/slab-d-12.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f95f265ea747d7ccb60b1',
        nid: 2046,
        name: 'Pearlex',
        fullName:
          'ViviStone Cream Onyx glass with slab D-12 interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-d-12/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-d-12/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-d-12/pearlex-full.jpg',
        finish: {
          id: '5d7f95f37100fd8f1ab9bcf4',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f95f4d390a2d8fd5e43af',
        nid: 2047,
        name: 'Standard',
        fullName:
          'ViviStone Cream Onyx glass with slab D-12 interlayer and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-d-12/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-d-12/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/slab-d-12/standard-full.jpg',
        finish: {
          id: '5d7f95f5e58cfdf7f800e47d',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
]

export default VivistoneCreamOnyx
