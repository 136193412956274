import React from 'react'
import styles from './surfaces-search.module.css'
import SearchRow from './search-row'
import SearchPreview from './search-preview'
import LightplaneSelections from './lightplane-selections'
import CeramilocSelections from './ceramiloc-selections'
import {findSurface} from '../../../utils/design'
import { getFavorites } from '../../../utils/favorites'

class MyFavoriteSearch extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeFavorite: null,
      activeFavoriteGroup: [],
      activeMaterial: {},
      activeFinish: {},
      activePattern: {},
      lightplaneActive: false,
      ceramilocActive: false
    }
  }

  componentDidMount() {
    this.getTheFavorites()
      let lightplaneActive = this.hasLightPlane()
      let ceramilocActive = this.hasCeramiloc()
      this.setState({
        activeFavorite: this.props.activeFavorite,
        lightplaneActive: lightplaneActive,
        ceramilocActive: ceramilocActive,
      })
  }

  getTheFavorites() {
    getFavorites().then((favorites)=>{
      for(var i = 0; i < favorites.length; i++){
        favorites[i] = findSurface(favorites[i].referenceId, this.props.data.allMaterialType.nodes)
      }
      this.setState({
        activeFavoriteGroup: favorites
      })
    })
    .catch((err)=>{
      console.log(err)
    })
  }

  hasLightPlane(){
    let lightplane = false
    if(this.props.activePanel){
      this.props.activePanel.panelCoords.forEach((coord, i) => {
        if(coord.lightplane && coord.active){
          lightplane = true
        }
      })
    }
    return lightplane
  }


  hasCeramiloc(){
    let ceramiloc = false
    if(this.props.activePanel){
      this.props.activePanel.panelCoords.forEach((coord, i) => {
        if(coord.ceramiloc && coord.active){
          ceramiloc = true
        }
      })
    }
    return ceramiloc
  }

  applyLightPlane = (lightplane) => {
    this.setState({
      lightplaneActive: lightplane,
      ceramilocActive: false
    })
  }

  applyCeramiloc = (ceramiloc) => {
    this.setState({
      ceramilocActive: ceramiloc,
      lightplaneActive: false,
    })
  }

  onMaterialSelect = material => {
    if(material.finishes){
      if(material.finishes[0].patterns[0]){
        let finish = material.finishes[0]
        let pattern = material.finishes[0].patterns[0]
        if(!pattern.finish){
          pattern.finish = {
            id: finish._id,
            name: finish.name,
            nid: finish.nid
          }
        }
        this.setState({
          activeMaterial: material,
          activeFinish: finish,
          activePattern: pattern
        })
      }
    }
  }

  onFinishSelect = finish => {
    if(finish.patterns){
      let pattern = finish.patterns[0]
      if(!pattern.finish){
        pattern.finish = {
          id: finish._id,
          name: finish.name,
          nid: finish.nid
        }
      }
      this.setState({
        activeFinish: finish,
        activePattern: finish.patterns[0]
      })
    }
  }

  onPatternSelect = pattern => {
    var surface = findSurface(pattern._id, this.props.data.allMaterialType.nodes)
    this.setState({
      activeFavorite: surface
    })
  }

  onCloseButton = () => {
    const { onCloseButton } = this.props
    onCloseButton()
  }

  applySurface = surface => {
    const { applySurface } = this.props
    applySurface(surface, this.state.lightplaneActive, this.state.ceramilocActive)
  }

  shouldRotateThumb = () => {
    var shouldRotate = false
    if(this.props.activePanel){
      if(this.props.activePanel.panelCoords){
        this.props.activePanel.panelCoords.forEach(function(coords){
          if(coords.lsbdrs[0].rotateSurface){
            shouldRotate = true
            return shouldRotate
          }
        })
      }
    }
    return shouldRotate
  }

  render(){
    let favorites = ''
    let preview = ''
    let surfaces = []
    let groupName = ''
    let rotate = this.shouldRotateThumb()
    if(this.state.activeFavoriteGroup){
      surfaces = this.state.activeFavoriteGroup
      groupName = 'My Favorites'
    }

    let lightplaneSelections = null
    let ceramilocSelections = null
    if(this.state.activeFavorite){
      favorites = <SearchRow
        objects={surfaces}
        usePreview={true}
        activeObject={this.state.activeFavorite}
        objectType={groupName}
        onClick={this.onPatternSelect}
      />
      preview = <SearchPreview
        object={this.state.activeFavorite}
        activePanel={this.props.activePanel}
        applySurface={this.applySurface}
      />

      if(this.state.activeFavorite.lightplaneCompatible){
        lightplaneSelections = <LightplaneSelections
          activeObject={this.state.activeFavorite}
          applyLightPlane={this.applyLightPlane}
          lightplaneActive={this.state.lightplaneActive}
          activePanel={this.props.activePanel}
          rotate={rotate}
        />
      }

      if(this.state.activeFavorite.ceramilocCompatible){
        ceramilocSelections = <CeramilocSelections
          activeObject={this.state.activeFavorite}
          applyCeramiloc={this.applyCeramiloc}
          ceramilocActive={this.state.ceramilocActive}
          activePanel={this.props.activePanel}
          rotate={rotate}
        />
      }
    }
    return (
      <div className={styles['material__search__submaterials']}>
        <button title="Close" className={styles['materal__search__preview__close']} onClick={this.onCloseButton}>×</button>
        {preview}
        {favorites}
        {lightplaneSelections}
        {ceramilocSelections}
      </div>
    )
  }
}

export default MyFavoriteSearch
