let FusedNickelBronze = [
  {
    id: '5d7f8cf6aaee1ecb5e4046ef',
    nid: 155,
    name: 'Sandstone',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/sandstone/sandstone.jpg',
    surfaceLabel: "Pattern",
    patterns: [
      {
        id: '5d7f8cf72d586b4e280f2227',
        nid: 8491,
        name: 'No Pattern',
        fullName: 'Fused Nickel Bronze with Sandstone finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/none.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/sandstone/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/sandstone/no-pattern-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.14
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.35
          }
        ]
      },
      {
        id: '5d7f8cf85207436b758839b1',
        nid: 8497,
        name: 'Champagne Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Bronze with Sandstone finish and Champagne Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/sandstone/champagne-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/sandstone/champagne-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/sandstone/champagne-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.14
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.35
          }
        ]
      },
      {
        id: '5d7f8cf940b887d3a524baa5',
        nid: 8503,
        name: 'Chardonnay Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Bronze with Sandstone finish and Chardonnay Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/sandstone/chardonnay-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/sandstone/chardonnay-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/sandstone/chardonnay-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.14
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.35
          }
        ]
      },
      {
        id: '5d7f8cfa0f5e63d0e2d54d06',
        nid: 8509,
        name: 'Dallas Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Bronze with Sandstone finish and Dallas Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/sandstone/dallas-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/sandstone/dallas-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/sandstone/dallas-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.14
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.35
          }
        ]
      },
      {
        id: '5d7f8cfb329cadd4ffbdbcfe',
        nid: 8515,
        name: 'Denver Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Bronze with Sandstone finish and Denver Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/sandstone/denver-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/sandstone/denver-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/sandstone/denver-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.14
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.35
          }
        ]
      },
      {
        id: '5d7f8cfc11874d21ed49cdcd',
        nid: 8521,
        name: 'Dune Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Bronze with Sandstone finish and Dune Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/sandstone/dune-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/sandstone/dune-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/sandstone/dune-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.14
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.35
          }
        ]
      },
      {
        id: '5d7f8cfdac9d9dc68ad94c39',
        nid: 8527,
        name: 'Kalahari Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Bronze with Sandstone finish and Kalahari Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/sandstone/kalahari-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/sandstone/kalahari-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/sandstone/kalahari-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.14
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.35
          }
        ]
      },
    ],
  },
  {
    id: '5d7f8cfe2bb47748e63ed7ef',
    nid: 156,
    name: 'Seastone',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/seastone.jpg',
    surfaceLabel: "Pattern",
    patterns: [
      {
        id: '5d7f8cffab209c69343777e8',
        nid: 8493,
        name: 'No Pattern',
        fullName: 'Fused Nickel Bronze with Seastone finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/none.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/no-pattern-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.41
          }
        ]
      },
      {
        id: '5d7f8d006d3b6b3d333bf6fd',
        nid: 8543,
        name: 'City Lights Eco-Etch',
        fullName:
          'Fused Nickel Bronze with Seastone finish and City Lights Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/city-lights-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/city-lights-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/city-lights-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.41
          }
        ]
      },
      {
        id: '5d7f8d013cfed5d2c433f205',
        nid: 8555,
        name: 'Glacier Eco-Etch',
        fullName:
          'Fused Nickel Bronze with Seastone finish and Glacier Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/glacier-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/glacier-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/glacier-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.41
          }
        ]
      },
      {
        id: '5d7f8d0244110750a4e7e74e',
        nid: 8546,
        name: 'Current Eco-Etch',
        fullName:
          'Fused Nickel Bronze with Seastone finish and Current Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/current-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/current-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/current-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.41
          }
        ]
      },
      {
        id: '5d7f8d03695b1575a98d083f',
        nid: 8549,
        name: 'Dash Eco-Etch',
        fullName:
          'Fused Nickel Bronze with Seastone finish and Dash Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/dash-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/dash-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/dash-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.41
          }
        ]
      },
      {
        id: '5d7f8d04c2a7c3d4e5b969d3',
        nid: 8552,
        name: 'Flicker Eco-Etch',
        fullName:
          'Fused Nickel Bronze with Seastone finish and Flicker Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/flicker-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/flicker-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/flicker-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.41
          }
        ]
      },
      {
        id: '5d7f8d05d8b5c4e8a286ac5e',
        nid: 8558,
        name: 'Mica Eco-Etch',
        fullName:
          'Fused Nickel Bronze with Seastone finish and Mica Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/mica-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/mica-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/mica-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.41
          }
        ]
      },
      {
        id: '5d7f8d06803cc16216e523f2',
        nid: 8561,
        name: 'Reeds Eco-Etch',
        fullName:
          'Fused Nickel Bronze with Seastone finish and Reeds Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/reeds-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/reeds-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/reeds-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.41
          }
        ]
      },
      {
        id: '5d7f8d070de44b702f06a08a',
        nid: 8564,
        name: 'Seagrass Eco-Etch',
        fullName:
          'Fused Nickel Bronze with Seastone finish and Seagrass Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/seagrass-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/seagrass-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/seagrass-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.41
          }
        ]
      },
      {
        id: '5d7f8d08ce9642097f7a912d',
        nid: 8567,
        name: 'Sequence Eco-Etch',
        fullName:
          'Fused Nickel Bronze with Seastone finish and Sequence Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/sequence-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/sequence-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/sequence-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.41
          }
        ]
      },
      {
        id: '5d7f8d09fe0e681212a08e3f',
        nid: 8570,
        name: 'Silkworm Eco-Etch',
        fullName:
          'Fused Nickel Bronze with Seastone finish and Silkworm Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/silkworm-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/silkworm-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/silkworm-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.41
          }
        ]
      },
      {
        id: '5d7f8d0af72fc73d0a8a1951',
        nid: 8573,
        name: 'Stacks Eco-Etch',
        fullName:
          'Fused Nickel Bronze with Seastone finish and Stacks Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/stacks-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/stacks-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/stacks-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.41
          }
        ]
      },
      {
        id: '5d7f8d0b8b2c572f4583c11a',
        nid: 8687,
        name: 'Stripe Eco-Etch',
        fullName:
          'Fused Nickel Bronze with Seastone finish and Stripe Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/stripe-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/stripe-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/stripe-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.41
          }
        ]
      },
      {
        id: '5d7f8d0cd594dd7ba5a54448',
        nid: 8703,
        name: 'Thatch Eco-Etch',
        fullName:
          'Fused Nickel Bronze with Seastone finish and Thatch Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/thatch-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/thatch-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/thatch-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.41
          }
        ]
      },
      {
        id: '5d7f8d0dd42c3a2ef1ea3361',
        nid: 8578,
        name: 'Trace Eco-Etch',
        fullName:
          'Fused Nickel Bronze with Seastone finish and Trace Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/trace-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/trace-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/trace-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.41
          }
        ]
      },
      {
        id: '5d7f8d0e9289a65bb9a7c53b',
        nid: 8532,
        name: 'Carbon Fusion',
        fullName:
          'Fused Nickel Bronze with Seastone finish and Carbon Fusion pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/carbon-fusion.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/carbon-fusion-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/carbon-fusion-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.41
          }
        ]
      },
      {
        id: '5d7f8d0fba3cf990ac6e1d44',
        nid: 8535,
        name: 'Cinch Fusion',
        fullName:
          'Fused Nickel Bronze with Seastone finish and Cinch Fusion pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/cinch-fusion.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/cinch-fusion-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/cinch-fusion-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.41
          }
        ]
      },
      {
        id: '5d7f8d10818dc9c6d3cd6140',
        nid: 8540,
        name: 'Moraine Fusion',
        fullName:
          'Fused Nickel Bronze with Seastone finish and Moraine Fusion pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/moraine-fusion.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/moraine-fusion-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/moraine-fusion-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.41
          }
        ]
      },
      {
        id: '5d7f8d113e8b4725fee9cb13',
        nid: 8499,
        name: 'Champagne Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Bronze with Seastone finish and Champagne Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/champagne-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/champagne-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/champagne-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.41
          }
        ]
      },
      {
        id: '5d7f8d1236042f112577d52a',
        nid: 8505,
        name: 'Chardonnay Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Bronze with Seastone finish and Chardonnay Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/chardonnay-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/chardonnay-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/chardonnay-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.41
          }
        ]
      },
      {
        id: '5d7f8d13ae2fcbef20f77f37',
        nid: 8511,
        name: 'Dallas Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Bronze with Seastone finish and Dallas Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/dallas-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/dallas-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/dallas-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.41
          }
        ]
      },
      {
        id: '5d7f8d14d914105aecbfc3d5',
        nid: 8517,
        name: 'Denver Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Bronze with Seastone finish and Denver Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/denver-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/denver-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/denver-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.41
          }
        ]
      },
      {
        id: '5d7f8d1583019f121ef24e91',
        nid: 8523,
        name: 'Dune Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Bronze with Seastone finish and Dune Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/dune-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/dune-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/dune-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.41
          }
        ]
      },
      {
        id: '5d7f8d1681b527bc07a47a40',
        nid: 8529,
        name: 'Kalahari Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Bronze with Seastone finish and Kalahari Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/kalahari-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/kalahari-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/seastone/kalahari-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.41
          }
        ]
      },
    ],
  },
  {
    id: '5d7f8d1739a7c53aa2a19913',
    nid: 157,
    name: 'Linen',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/linen/linen.jpg',
    surfaceLabel: "Pattern",
    patterns: [
      {
        id: '5d7f8d18232b1bc088bf3849',
        nid: 8489,
        name: 'No Pattern',
        fullName: 'Fused Nickel Bronze with Linen finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/none.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/linen/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/linen/no-pattern-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.13
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.13
          }
        ]
      },
      {
        id: '5d7f8d19ca5d79e1f64fc6fe',
        nid: 8495,
        name: 'Champagne Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Bronze with Linen finish and Champagne Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/linen/champagne-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/linen/champagne-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/linen/champagne-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.13
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.13
          }
        ]
      },
      {
        id: '5d7f8d1a9f10969b06a29f3a',
        nid: 8501,
        name: 'Chardonnay Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Bronze with Linen finish and Chardonnay Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/linen/chardonnay-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/linen/chardonnay-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/linen/chardonnay-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.13
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.13
          }
        ]
      },
      {
        id: '5d7f8d1bac6d22fbf17f0909',
        nid: 8507,
        name: 'Dallas Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Bronze with Linen finish and Dallas Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/linen/dallas-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/linen/dallas-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/linen/dallas-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.13
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.13
          }
        ]
      },
      {
        id: '5d7f8d1c0cd25670aac03b90',
        nid: 8513,
        name: 'Denver Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Bronze with Linen finish and Denver Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/linen/denver-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/linen/denver-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/linen/denver-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.13
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.13
          }
        ]
      },
      {
        id: '5d7f8d1d05350b096c57f8cc',
        nid: 8519,
        name: 'Dune Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Bronze with Linen finish and Dune Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/linen/dune-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/linen/dune-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/linen/dune-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.13
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.13
          }
        ]
      },
      {
        id: '5d7f8d1e435b0bb42b76cb5c',
        nid: 8525,
        name: 'Kalahari Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Bronze with Linen finish and Kalahari Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/linen/kalahari-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/linen/kalahari-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/linen/kalahari-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.13
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.13
          }
        ]
      },
    ],
  },
  {
    id: '5d7f8d1f0b771ebfcd42fc06',
    nid: 140,
    name: 'Diamond',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/diamond/diamond.jpg',
    surfaceLabel: "Pattern",
    patterns: [
      {
        id: '5d7f8d20f1f311107eef2cb0',
        nid: 8488,
        name: 'No Pattern',
        fullName: 'Fused Nickel Bronze with Diamond finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/none.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/diamond/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/diamond/no-pattern-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.14
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.35
          }
        ]
      },
      {
        id: '5d7f8d2147ae9624df786b00',
        nid: 8494,
        name: 'Champagne Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Bronze with Diamond finish and Champagne Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/diamond/champagne-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/diamond/champagne-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/diamond/champagne-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.14
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.35
          }
        ]
      },
      {
        id: '5d7f8d22f44348237dfd9387',
        nid: 8500,
        name: 'Chardonnay Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Bronze with Diamond finish and Chardonnay Impression pattern ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/diamond/chardonnay-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/diamond/chardonnay-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/diamond/chardonnay-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.14
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.35
          }
        ]
      },
      {
        id: '5d7f8d23c882625499b206b9',
        nid: 8506,
        name: 'Dallas Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Bronze with Diamond finish and Dallas Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/diamond/dallas-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/diamond/dallas-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/diamond/dallas-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.14
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.35
          }
        ]
      },
      {
        id: '5d7f8d24f3734e0ccf7eec4b',
        nid: 8512,
        name: 'Denver Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Bronze with Diamond finish and Denver Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/diamond/denver-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/diamond/denver-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/diamond/denver-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.14
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.35
          }
        ]
      },
      {
        id: '5d7f8d254406d143a7e632a3',
        nid: 8518,
        name: 'Dune Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Bronze with Diamond finish and Dune Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/diamond/dune-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/diamond/dune-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/diamond/dune-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.14
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.35
          }
        ]
      },
      {
        id: '5d7f8d26c60c8b8b263c7cff',
        nid: 8524,
        name: 'Kalahari Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Bronze with Diamond finish and Kalahari Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/diamond/kalahari-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/diamond/kalahari-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/diamond/kalahari-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.14
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.35
          }
        ]
      },
    ],
  },
  {
    id: '5d7f8d272728044a4ef626c7',
    nid: 143,
    name: 'Mirror',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/mirror.jpg',
    surfaceLabel: "Pattern",
    patterns: [
      {
        id: '5d7f8d28171b99c43a1cae1d',
        nid: 8490,
        name: 'No Pattern',
        fullName: 'Fused Nickel Bronze with Mirror finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/none.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/no-pattern-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.25
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.25
          }
        ]
      },
      {
        id: '5d7f8d2967b196210042a4fc',
        nid: 8541,
        name: 'City Lights Eco-Etch',
        fullName:
          'Fused Nickel Bronze with Mirror finish and City Lights Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/city-lights-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/city-lights-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/city-lights-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.25
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.25
          }
        ]
      },
      {
        id: '5d7f8d2adff18752c6d2536a',
        nid: 8553,
        name: 'Glacier Eco-Etch',
        fullName:
          'Fused Nickel Bronze with Mirror finish and Glacier Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/glacier-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/glacier-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/glacier-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.25
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.25
          }
        ]
      },
      {
        id: '5d7f8d2b3695527cc4fb0b0f',
        nid: 8544,
        name: 'Current Eco-Etch',
        fullName:
          'Fused Nickel Bronze with Mirror finish and Current Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/current-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/current-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/current-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.25
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.25
          }
        ]
      },
      {
        id: '5d7f8d2c0aa9aac3909a4935',
        nid: 8547,
        name: 'Dash Eco-Etch',
        fullName:
          'Fused Nickel Bronze with Mirror finish and Dash Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/dash-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/dash-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/dash-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.25
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.25
          }
        ]
      },
      {
        id: '5d7f8d2d5b79ee657a2908c0',
        nid: 8550,
        name: 'Flicker Eco-Etch',
        fullName:
          'Fused Nickel Bronze with Mirror finish and Flicker Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/flicker-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/flicker-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/flicker-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.25
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.25
          }
        ]
      },
      {
        id: '5d7f8d2e766738e5eb66bd86',
        nid: 8556,
        name: 'Mica Eco-Etch',
        fullName:
          'Fused Nickel Bronze with Mirror finish and Mica Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/mica-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/mica-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/mica-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.25
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.25
          }
        ]
      },
      {
        id: '5d7f8d2f5a13e99d702d572a',
        nid: 8559,
        name: 'Reeds Eco-Etch',
        fullName:
          'Fused Nickel Bronze with Mirror finish and Reeds Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/reeds-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/reeds-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/reeds-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.25
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.25
          }
        ]
      },
      {
        id: '5d7f8d308c27d4feac806b41',
        nid: 8562,
        name: 'Seagrass Eco-Etch',
        fullName:
          'Fused Nickel Bronze with Mirror finish and Seagrass Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/seagrass-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/seagrass-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/seagrass-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.25
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.25
          }
        ]
      },
      {
        id: '5d7f8d3179cda64956052237',
        nid: 8565,
        name: 'Sequence Eco-Etch',
        fullName:
          'Fused Nickel Bronze with Mirror finish and Sequence Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/sequence-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/sequence-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/sequence-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.25
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.25
          }
        ]
      },
      {
        id: '5d7f8d3272e1011d8c3184a7',
        nid: 8568,
        name: 'Silkworm Eco-Etch',
        fullName:
          'Fused Nickel Bronze with Mirror finish and Silkworm Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/silkworm-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/silkworm-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/silkworm-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.25
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.25
          }
        ]
      },
      {
        id: '5d7f8d333c08950b477e27fa',
        nid: 8571,
        name: 'Stacks Eco-Etch',
        fullName:
          'Fused Nickel Bronze with Mirror finish and Stacks Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/stacks-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/stacks-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/stacks-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.25
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.25
          }
        ]
      },
      {
        id: '5d7f8d3414e58da9018d70c0',
        nid: 8685,
        name: 'Stripe Eco-Etch',
        fullName:
          'Fused Nickel Bronze with Mirror finish and Stripe Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/stripe-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/stripe-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/stripe-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.25
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.25
          }
        ]
      },
      {
        id: '5d7f8d35ce16d8627160038a',
        nid: 8701,
        name: 'Thatch Eco-Etch',
        fullName:
          'Fused Nickel Bronze with Mirror finish and Thatch Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/thatch-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/thatch-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/thatch-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.25
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.25
          }
        ]
      },
      {
        id: '5d7f8d360db46dc2439f1efb',
        nid: 8576,
        name: 'Trace Eco-Etch',
        fullName:
          'Fused Nickel Bronze with Mirror finish and Trace Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/trace-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/trace-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/trace-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.25
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.25
          }
        ]
      },
      {
        id: '5d7f8d375c8c48d105788084',
        nid: 8530,
        name: 'Carbon Fusion',
        fullName:
          'Fused Nickel Bronze with Mirror finish and Carbon Fusion pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/carbon-fusion.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/carbon-fusion-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/carbon-fusion-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.25
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.25
          }
        ]
      },
      {
        id: '5d7f8d38428191a9352f1cae',
        nid: 8533,
        name: 'Cinch Fusion',
        fullName:
          'Fused Nickel Bronze with Mirror finish and Cinch Fusion pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/cinch-fusion.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/cinch-fusion-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/cinch-fusion-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.25
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.25
          }
        ]
      },
      {
        id: '5d7f8d39f21d13edff0e9630',
        nid: 8538,
        name: 'Moraine Fusion',
        fullName:
          'Fused Nickel Bronze with Mirror finish and Moraine Fusion pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/moraine-fusion.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/moraine-fusion-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/moraine-fusion-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.25
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.25
          }
        ]
      },
      {
        id: '5d7f8d3ae517755d11168073',
        nid: 8496,
        name: 'Champagne Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Bronze with Mirror finish and Champagne Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/champagne-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/champagne-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/champagne-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.25
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.25
          }
        ]
      },
      {
        id: '5d7f8d3be551d9317f3046e9',
        nid: 8502,
        name: 'Chardonnay Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Bronze with Mirror finish and Chardonnay Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/chardonnay-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/chardonnay-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/chardonnay-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.25
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.25
          }
        ]
      },
      {
        id: '5d7f8d3c593402fb0ab91693',
        nid: 8508,
        name: 'Dallas Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Bronze with Mirror finish and Dallas Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/dallas-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/dallas-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/dallas-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.25
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.25
          }
        ]
      },
      {
        id: '5d7f8d3db7aa1f83a668a837',
        nid: 8514,
        name: 'Denver Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Bronze with Mirror finish and Denver Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/denver-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/denver-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/denver-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.25
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.25
          }
        ]
      },
      {
        id: '5d7f8d3e5f88b615d42de057',
        nid: 8520,
        name: 'Dune Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Bronze with Mirror finish and Dune Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/dune-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/dune-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/dune-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.25
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.25
          }
        ]
      },
      {
        id: '5d7f8d3f4b780a03ada14f5d',
        nid: 8526,
        name: 'Kalahari Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Bronze with Mirror finish and Kalahari Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/kalahari-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/kalahari-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/mirror/kalahari-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.25
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.25
          }
        ]
      },
    ],
  },
  {
    id: '5d7f8d401699daabca089a46',
    nid: 159,
    name: 'Satin',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/satin.jpg',
    surfaceLabel: "Pattern",
    patterns: [
      {
        id: '5d7f8d416cb0bc29d0305f48',
        nid: 8492,
        name: 'No Pattern',
        fullName: 'Fused Nickel Bronze with Satin finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/none.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/no-pattern-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8d4264374da200b7fa06',
        nid: 8542,
        name: 'City Lights Eco-Etch',
        fullName:
          'Fused Nickel Bronze with Satin finish and City Lights Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/city-lights-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/city-lights-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/city-lights-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8d438d5a43abf216b25a',
        nid: 8554,
        name: 'Glacier Eco-Etch',
        fullName:
          'Fused Nickel Bronze with Satin finish and Glacier Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/glacier-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/glacier-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/glacier-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8d446fa0c10df298b802',
        nid: 8545,
        name: 'Current Eco-Etch',
        fullName:
          'Fused Nickel Bronze with Satin finish and Current Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/current-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/current-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/current-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8d45080712c3cbc67136',
        nid: 8548,
        name: 'Dash Eco-Etch',
        fullName:
          'Fused Nickel Bronze with Satin finish and Dash Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/dash-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/dash-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/dash-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8d464348a10c37d09b6d',
        nid: 8551,
        name: 'Flicker Eco-Etch',
        fullName:
          'Fused Nickel Bronze with Satin finish and Flicker Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/flicker-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/flicker-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/flicker-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8d47c3433d217c1ec7ea',
        nid: 8557,
        name: 'Mica Eco-Etch',
        fullName:
          'Fused Nickel Bronze with Satin finish and Mica Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/mica-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/mica-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/mica-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8d48431bbc42ba75d7eb',
        nid: 8560,
        name: 'Reeds Eco-Etch',
        fullName:
          'Fused Nickel Bronze with Satin finish and Reeds Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/reeds-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/reeds-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/reeds-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8d496b71fc1ce7bda4c0',
        nid: 8563,
        name: 'Seagrass Eco-Etch',
        fullName:
          'Fused Nickel Bronze with Satin finish and Seagrass Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/seagrass-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/seagrass-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/seagrass-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8d4a5a027f7693f7ae1c',
        nid: 8566,
        name: 'Sequence Eco-Etch',
        fullName:
          'Fused Nickel Bronze with Satin finish and Sequence Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/sequence-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/sequence-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/sequence-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8d4b4b52ac39a74cf1a3',
        nid: 8569,
        name: 'Silkworm Eco-Etch',
        fullName:
          'Fused Nickel Bronze with Satin finish and Silkworm Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/silkworm-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/silkworm-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/silkworm-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8d4cbb8ca00e455860bb',
        nid: 8572,
        name: 'Stacks Eco-Etch',
        fullName:
          'Fused Nickel Bronze with Satin finish and Stacks Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/stacks-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/stacks-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/stacks-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8d4d25739f7b05b0b669',
        nid: 8686,
        name: 'Stripe Eco-Etch',
        fullName:
          'Fused Nickel Bronze with Satin finish and Stripe Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/stripe-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/stripe-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/stripe-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8d4e51e02a09789b274b',
        nid: 8702,
        name: 'Thatch Eco-Etch',
        fullName:
          'Fused Nickel Bronze with Satin finish and Thatch Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/thatch-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/thatch-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/thatch-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8d4fb838c23ad0a453cd',
        nid: 8577,
        name: 'Trace Eco-Etch',
        fullName:
          'Fused Nickel Bronze with Satin finish and Trace Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/trace-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/trace-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/trace-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8d503ac929cd877590f2',
        nid: 8531,
        name: 'Carbon Fusion',
        fullName:
          'Fused Nickel Bronze with Satin finish and Carbon Fusion pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/carbon-fusion.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/carbon-fusion-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/carbon-fusion-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8d5121ba307dc929185f',
        nid: 8534,
        name: 'Cinch Fusion',
        fullName:
          'Fused Nickel Bronze with Satin finish and Cinch Fusion pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/cinch-fusion.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/cinch-fusion-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/cinch-fusion-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8d5205290844a79b317c',
        nid: 8539,
        name: 'Moraine Fusion',
        fullName:
          'Fused Nickel Bronze with Satin finish and Moraine Fusion pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/moraine-fusion.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/moraine-fusion-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/moraine-fusion-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8d53d8e0a1bb41c2679a',
        nid: 8498,
        name: 'Champagne Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Bronze with Satin finish and Champagne Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/champagne-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/champagne-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/champagne-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8d549264c2ab2b5cbc5b',
        nid: 8504,
        name: 'Chardonnay Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Bronze with Satin finish and Chardonnay Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/chardonnay-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/chardonnay-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/chardonnay-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8d55c3c252899380e2ba',
        nid: 8510,
        name: 'Dallas Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Bronze with Satin finish and Dallas Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/dallas-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/dallas-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/dallas-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8d56aec911e5a6a6e3ab',
        nid: 8516,
        name: 'Denver Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Bronze with Satin finish and Denver Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/denver-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/denver-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/denver-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8d57bebc78ae104a726e',
        nid: 8522,
        name: 'Dune Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Bronze with Satin finish and Dune Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/dune-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/dune-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/dune-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8d58f40b61d9a3935591',
        nid: 8528,
        name: 'Kalahari Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Bronze with Satin finish and Kalahari Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/kalahari-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/kalahari-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/satin/kalahari-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
    ],
  },
]

export default FusedNickelBronze
