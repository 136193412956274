import React from 'react'
import styles from './surfaces-search.module.css'
import {findSurface} from '../../../utils/design'

class FavoriteItem extends React.Component {
  constructor(props){
    super(props)
    this.state={
      surface: null
    }
  }
  componentDidMount(){
    if(this.props.surface){
      if(this.props.surface.id){
        let surface = findSurface(this.props.surface.id, this.props.data.allMaterialType.nodes)
        this.setState({
          surface: surface
        })
      }
    }
  }
  onClick = () => {
    const { onClick } = this.props
    onClick(this.state.surface)
  }
  render(){
    let item = '<div></div>'
    if(this.state.surface){
      item = <button key={this.state.surface.id} onClick={this.onClick} className={styles['material__selection'] + ' fadeHover'}>
        <img src={this.state.surface.surfacePreview.publicURL} alt={this.state.surface.name} title={this.state.surface.fullName} className={styles['search__image']} />
      </button>
    }
    return(
      <div>{item}</div>
    )
  }
}

class FavoritesGroup extends React.Component {
  constructor(props){
    super(props)
    this.state={

    }
  }
  componentDidMount(){

  }
  onClick = (surface) => {
    const { onClick } = this.props
    for(var i=0; i<this.props.group.surfaces.count; i++){
      this.props.group.surfaces[i] = findSurface(this.props.group.surfaces[i].nid, this.props.data.allMaterialType.nodes)
    }
    onClick(surface,this.props.group)
  }
  render(){
    let items = this.props.group.surfaces.map(function(item, key){
      return(
        <FavoriteItem
          surface={item}
          onClick={this.onClick}
          key={key}
          data={this.props.data}
        />
      )
    }.bind(this))
    return(
      <div className={styles['material__group']}>
        <h3 className={styles['material__title']}>{this.props.group.name}</h3>
        {items}
      </div>
    )
  }
}

class ConfigFavorites extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeMaterial: {},
      activeFavoriteGroup: {},
      activePattern: {}
    }
  }

  componentDidMount() {
    if(this.props.material){
      this.setState({
        activeMaterial: this.props.material
      })
    }
  }

  onPatternSelect = (pattern, group) => {
    const { onClickFavorite } = this.props
    onClickFavorite(pattern, group)
  }

  onCloseButton = () => {
    const { onCloseButton } = this.props
    onCloseButton()
  }

  render(){
    let items = this.props.configuration.favoriteSurfaces.map(function(item, key){
      return (
        <FavoritesGroup
          group={item}
          onClick={this.onPatternSelect}
          key={key}
          data={this.props.data}
        />
      )
    }.bind(this))
    return (
      <div className={styles['search__panel']}>
        <p className={styles['search__panel__text']}>The palettes below represent some of our favorite material and finish combinations for {this.props.configuration.name} wall panels. Individual swatches can be mixed and matched across palettes.</p>
        {items}
      </div>
    )
  }
}

export default ConfigFavorites
