import React from 'react'
import styles from './design-render.module.css'
import PanelLegends from './PanelLegends'
import HandrailLegends from './HandrailLegends'
import CeilingLegends from './CeilingLegends'
import finalLegendsBg from './img/cab-dimensions.svg'
class Legends extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {}

  render() {
    let cabTitle = ''
    let legends = ''
    if (this.props.showText) {
      if (!this.props.configuration.name) {
        cabTitle = (
          <div className={styles['cab__title']}>
            <p>
              SELECT AN ELEVATOR INTERIOR
              <br />
              FROM THE CONFIGURATION
              <br />
              OPTIONS AT LEFT
            </p>
          </div>
        )
      } else {
        cabTitle = (
          <div className={styles['cab__title']}>
            <h2>{this.props.configuration.name}</h2>
          </div>
        )
      }
    }
    if (this.props.showLegends && this.props.activePanel) {
      legends = (
        <PanelLegends
          configuration={this.props.configuration}
          activePanel={this.props.activePanel}
          framePanels={this.props.framePanels}
          onPanelChange={this.props.onPanelChange}
        />
      )
    }
    if (this.props.showHandrailLegends) {
      legends = <HandrailLegends configuration={this.props.configuration} handrailConflicts={this.props.handrailConflicts} />
    }
    if (this.props.showCeilingLegends) {
      legends = <CeilingLegends configuration={this.props.configuration} />
    }
    if (this.props.showFinalLegends) {
      let legendStyle = {
        backgroundImage: 'url(' + finalLegendsBg + ')',
      }
      legends = <div className={styles['final__legends']} style={legendStyle} />
    }
    return (
      <div>
        {cabTitle}
        {legends}
      </div>
    )
  }
}

export default Legends
