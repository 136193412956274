import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Tab from './Tab'
import styles from './tabs.module.css'

let lastScrollY = 0
let ticking = false

class Tabs extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      activeTab: this.props.children[0].props.label,
      tabClass: 'design__tabs',
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  rightTab = React.createRef()

  handleScroll = () => {
    lastScrollY = window.scrollY

    if (!ticking) {
      window.requestAnimationFrame(() => {
        if (lastScrollY > 96) {
          this.rightTab.current.style.top = '40px'
          this.rightTab.current.style.position = 'fixed'
        } else {
          this.rightTab.current.style.top = '0px'
          this.rightTab.current.style.position = 'relative'
        }
        this.rightTab.current.style.minHeight = 625
        this.rightTab.current.style.maxWidth = 565
        ticking = false
      })

      ticking = true
    }
  }

  onClickTabItem = tab => {
    this.setState({ activeTab: tab })
    this.props.onTabChange(tab)
  }

  resetToFirstTab = (callback) => {
    this.setState({
      activeTab: 'CONFIGURATIONS'
    }, function(){
      this.props.onTabChange(this.state.activeTab)
      callback()
    })
  }

  onNextButtonClick = button => {
    let newTab = this.state.activeTab
    switch (this.state.activeTab) {
      case 'CONFIGURATIONS':
        newTab = 'WALL PANELS'
        break
      case 'WALL PANELS':
        newTab = 'HANDRAILS'
        break
      case 'HANDRAILS':
        newTab = 'CEILINGS'
        break
      case 'CEILINGS':
        newTab = 'REVIEW'
        break
      default:
        break
    }
    this.setState({ activeTab: newTab })
    this.props.onTabChange(newTab)
  }

  render() {
    const {
      onClickTabItem,
      props: { children },
      state: { activeTab },
    } = this

    let nextButtonActive = ''
    let nextButtonDisabled = true
    return (
      <div className={'row ' + styles['design__content']}>
        <div
          className={`${styles[`tabs`]} ${styles[`design__tabs`]} col-xs-12`}
        >
          <div className={styles['tabList']}>
            {children.map(child => {
              if (child.type === 'rightPanel') return undefined
              const {
                label,
                id,
                complete,
                overrideImage,
                completeCenterCheck,
                active,
              } = child.props

              if (activeTab === label && complete) {
                nextButtonActive = ' ' + styles['next__button__active']
                nextButtonDisabled = false
              }

              return (
                <Tab
                  activeTab={activeTab}
                  key={label}
                  label={label}
                  id={id}
                  complete={complete}
                  overrideImage={overrideImage}
                  completeCenterCheck={completeCenterCheck}
                  active={active}
                  onClick={onClickTabItem}
                />
              )
            })}
          </div>
        </div>
        <div className="col-xs-12">
          <div className={'row'}>
            <div className="col-xs-5">
              <div className={styles['tabContent']}>
                {children.map(child => {
                  if (child.props.label !== activeTab) return undefined
                  return child.props.children
                })}
              </div>
            </div>
            <div className="col-xs-7">
              <div ref={this.rightTab} className={styles['right__tab']}>
                {children.map(child => {
                  if (child.type !== 'rightPanel') return undefined
                  return child.props.children
                })}
                <button
                  className={styles['next__button'] + nextButtonActive + ' fadeHover'}
                  disabled={nextButtonDisabled}
                  onClick={this.onNextButtonClick}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Tabs
