import React from 'react'
import styles from './surfaces-search.module.css'

class CustomText extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      customText: this.props.customText
    }
  }

  updateCustomText = e => {
    this.setState({
      customText: e.target.value
    }, function(){
      this.props.updateCustomText(this.state.customText)
    })
  }

  render(){
    return (
      <div className={styles['search__group']}>
        <h3 className={styles['material__search__title']}><span>Reference: </span>Enter Reference for Material below</h3>
        <div className={styles['search__group__items']}>
          <input type="text"
          placeholder="Enter Reference for Custom Material"
          value={this.state.customText}
          onChange={e => this.updateCustomText(e)} />
        </div>
      </div>
    )
  }

}

export default CustomText
