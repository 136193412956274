import stainlessSteel from './stainless-steel'
import fusedBronze from './fused-bronze'
import fusedGraphite from './fused-graphite'
import fusedNickelBronze from './fused-nickel-bronze'
import fusedNickelSilver from './fused-nickel-silver'
import fusedWhiteGold from './fused-white-gold'
import bondedAluminum from './bonded-aluminum'
import bondedBronze from './bonded-bronze'
import bondedNickelSilver from './bonded-nickel-silver'
import bondedQuartz from './bonded-quartz'
import linqWovenMetal from './linq-woven-metal'
import elementalCarbon from './elemental-carbon'
import vivichromeChromis from './vivichrome-chromis'
import vivichromeScribe from './vivichrome-scribe'
import vivigraphixGraphica from './vivigraphix-graphica'
import vivispectraElements from './vivispectra-elements'
import vivistoneAbaloneOnyx from './vivistone-abalone-onyx'
import vivistoneCreamOnyx from './vivistone-cream-onyx'
import vivistoneHoneyOnyx from './vivistone-honey-onyx'
import vivistoneOpalOnyx from './vivistone-opal-onyx'
import vivistonePearlOnyx from './vivistone-pearl-onyx'
import vivistoneWhiteOnyx from './vivistone-white-onyx'
import vivistrataLayers from './vivistrata-layers'
import vivitelaMesh from './vivitela-mesh'
import vivitelaShibori from './vivitela-shibori'
import bermanglass from './bermanglass'
import woodVeneer from './wood-veneer'
import customSurface from './custom-surface'
import mirroredGlassClear from './mirrored-glass-clear'
import mirroredGlassBronze from './mirrored-glass-bronze'
import mirroredGlassSmoke from './mirrored-glass-smoke'

let Materials = [
  {
    id: '5d7f9027d68003417e4fd559',
    ref: '5e4d7c46f1b8fc0008d8b004',
    nid: 175,
    name: 'Stainless Steel & Fused Metal',
    subMaterials: [
      {
        id: '5d7f90281a2194339bde3c8c',
        nid: 4,
        name: 'Stainless Steel',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/stainless-steel/stainless-steel.jpg',
        subMaterialLabel: "Finish",
        finishes: stainlessSteel,
      },
      {
        id: '5d7f9029d071270ac9ad0a9f',
        nid: 88,
        name: 'Fused Bronze',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-bronze/fused-bronze.jpg',
        subMaterialLabel: "Finish",
        finishes: fusedBronze,
      },
      {
        id: '5d7f902a012161ab3303fe89',
        nid: 89,
        name: 'Fused Graphite',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/fused-graphite.jpg',
        subMaterialLabel: "Finish",
        finishes: fusedGraphite,
      },
      {
        id: '5d7f902befb987da5c2c802a',
        nid: 284,
        name: 'Fused Nickel Bronze',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-bronze/fused-nickel-bronze.jpg',
        subMaterialLabel: "Finish",
        finishes: fusedNickelBronze,
      },
      {
        id: '5e29bf1b78fd831a1ef22e41',
        nid: 90,
        name: 'Fused Nickel Silver',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/fused-nickel-silver.jpg',
        subMaterialLabel: "Finish",
        finishes: fusedNickelSilver,
      },
      {
        id: '5d7f902c7ff1facbb5bbfefa',
        nid: 91,
        name: 'Fused White Gold',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/fused-white-gold.jpg',
        subMaterialLabel: "Finish",
        finishes: fusedWhiteGold,
      },
    ],
  },
  {
    id: '5d7f902dcb4e41fcc4f6d2ec',
    ref: '5e4d7c46f1b8fc0008d8b005',
    nid: 176,
    name: 'Bonded Metal & Bonded Quartz',
    subMaterials: [
      {
        id: '5d7f902e285487973eddd512',
        nid: 97,
        name: 'Bonded Aluminum',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/bonded-aluminum.jpg',
        subMaterialLabel: "Finish",
        finishes: bondedAluminum,
      },
      {
        id: '5d7f902f481daf3a7b90b3f1',
        nid: 98,
        name: 'Bonded Bronze',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/bonded-bronze.jpg',
        subMaterialLabel: "Finish",
        finishes: bondedBronze,
      },
      {
        id: '5d7f90303fb1a78aed529ce7',
        nid: 99,
        name: 'Bonded Nickel Silver',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/bonded-nickel-silver.jpg',
        subMaterialLabel: "Finish",
        finishes: bondedNickelSilver,
      },
      {
        id: '5d7f9031faec11d36f0cfd4f',
        nid: 101,
        name: 'Bonded Quartz',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/bonded-quartz.jpg',
        subMaterialLabel: "Finish",
        finishes: bondedQuartz,
      },
    ],
  },
  {
    id: '5d7f90325500fff0d8c0fa6a',
    ref: '5e4d7c46f1b8fc0008d8b006',
    nid: 274,
    name: 'Linq Woven Metal',
    subMaterials: [
      {
        id: '5d7f90334d51ee2a60a320c1',
        nid: 335,
        name: 'Linq Woven Metal',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/linq-woven-metal/linq-woven-metal.jpg',
        subMaterialLabel: "Patina",
        finishes: linqWovenMetal,
      },
    ],
  },
  {
    id: '5d7f9034d94d73bbe612cf62',
    ref: '5e4d7c46f1b8fc0008d8b007',
    nid: 377,
    name: 'Elemental Metal',
    subMaterials: [
      {
        id: '5d7f90350c7d10bd95cb2cbb',
        nid: 380,
        name: 'Elemental Carbon',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/elemental-carbon/elemental-carbon.jpg',
        subMaterialLabel: "Finish",
        finishes: elementalCarbon,
      },
    ],
  },
  {
    id: '5d7f903686dfad043a4dac69',
    ref: '5e4d7c46f1b8fc0008d8b008',
    nid: 177,
    name: 'VividGlass',
    subMaterials: [
      {
        id: '5d7f9037a4af55f7bff9acea',
        nid: 108,
        name: 'ViviChrome Chromis',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-chromis/vivichrome-chromis.jpg',
        subMaterialLabel: "Interlayer",
        finishes: vivichromeChromis,
      },
      {
        id: '5d7f90380ce1323877f23322',
        nid: 109,
        name: 'ViviChrome Scribe',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-scribe/vivichrome-scribe.jpg',
        subMaterialLabel: "Interlayer",
        finishes: vivichromeScribe,
      },
      {
        id: '5d7f9039cfb6f4f1695efcae',
        nid: 107,
        name: 'ViviGraphix Graphica',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivigraphix-graphica/vivigraphix-graphica.jpg',
        subMaterialLabel: "Interlayer",
        finishes: vivigraphixGraphica,
      },
      {
        id: '5d7f903aba7a66830c463208',
        nid: 169,
        name: 'ViviSpectra Elements',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivispectra-elements/vivispectra-elements.jpg',
        subMaterialLabel: "Interlayer",
        finishes: vivispectraElements,
      },
      {
        id: '5d7f903bb7c7ce9510bfd0d1',
        nid: 115,
        name: 'ViviStone Abalone Onyx',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/vivistone-abalone-onyx.jpg',
        subMaterialLabel: "Interlayer",
        finishes: vivistoneAbaloneOnyx,
      },
      {
        id: '5d7f903cf375abb9aea74a96',
        nid: 116,
        name: 'ViviStone Cream Onyx',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-cream-onyx/vivistone-cream-onyx.jpg',
        subMaterialLabel: "Interlayer",
        finishes: vivistoneCreamOnyx,
      },
      {
        id: '5d7f903dd33839f6f7edf8ae',
        nid: 117,
        name: 'ViviStone Honey Onyx',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/vivistone-honey-onyx.jpg',
        subMaterialLabel: "Interlayer",
        finishes: vivistoneHoneyOnyx,
      },
      {
        id: '5d7f903e57bab7f8082c8ce3',
        nid: 118,
        name: 'ViviStone Opal Onyx',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-opal-onyx/vivistone-opal-onyx.jpg',
        subMaterialLabel: "Interlayer",
        finishes: vivistoneOpalOnyx,
      },
      {
        id: '5d7f903fc6efd9bd41204d20',
        nid: 119,
        name: 'ViviStone Pearl Onyx',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-pearl-onyx/vivistone-pearl-onyx.jpg',
        subMaterialLabel: "Interlayer",
        finishes: vivistonePearlOnyx,
      },
      {
        id: '5d7f90405ec1117e170ebae5',
        nid: 275,
        name: 'ViviStone White Onyx',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-white-onyx/vivistone-white-onyx.jpg',
        subMaterialLabel: "Interlayer",
        finishes: vivistoneWhiteOnyx,
      },
      {
        id: '5d7f904169ff6397a243f8e2',
        nid: 289,
        name: 'ViviStrata Layers',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistrata-layers/vivistrata-layers.jpg',
        subMaterialLabel: "Interlayer",
        finishes: vivistrataLayers,
      },
      {
        id: '5d7f904201a3428b42222188',
        nid: 370,
        name: 'ViviTela Mesh',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-mesh/vivitela-mesh.jpg',
        subMaterialLabel: "Interlayer",
        finishes: vivitelaMesh,
      },
      {
        id: '5e2b18e74f991540cfcf26f3',
        nid: 389,
        name: 'ViviTela Shibori',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-shibori/vivitela-shibori.jpg',
        subMaterialLabel: "Interlayer",
        finishes: vivitelaShibori,
      },
    ],
  },
  {
    id: '5d7f9043a78aaf4736aca778',
    ref: '5e4d7c46f1b8fc0008d8b009',
    nid: 351,
    name: 'BermanGlass',
    subMaterials: [
      {
        id: '5d7f9044c9c75723734c6c17',
        nid: 352,
        name: 'BermanGlass',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bermanglass/bermanglass.jpg',
        note: {
          message: 'BermanGlass Levels glass cannot be used on wall panels that require a through-hole for a handrail standoff. It can be used on wall panels that don\'t incorporate handrail standoffs.'
        },
        subMaterialLabel: "Type",
        finishes: bermanglass,
      },
    ],
  },
  {
    id: '5d7f9045009db6f58000fb07',
    ref: '5e4d7c46f1b8fc0008d8b00a',
    nid: 168,
    name: 'Wood Veneer',
    subMaterials: [
      {
        id: '5d7f9046f35b96018abb96b0',
        nid: 180,
        name: 'Wood Veneer',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/wood-veneer/wood-veneer.jpg',
        subMaterialLabel: "Type",
        finishes: woodVeneer,
      },
    ],
  },
  {
    id: '5d7f90472d7e9db5643f4d03',
    ref: '5e4d7c46f1b8fc0008d8b00b',
    nid: 336,
    name: 'Custom',
    subMaterials: [
      {
        id: '5d7f9048cc311dd0d46c86f4',
        nid: 339,
        name: 'Custom Surface',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/custom-surface/custom-surface.jpg',
        subMaterialLabel: "Finish",
        finishes: customSurface,
      },
      {
        id: '5d7f9049a2d9e52d51ddbea1',
        nid: 341,
        name: 'Mirrored Glass, Clear',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/mirrored-glass-clear/mirrored-glass-clear.jpg',
        subMaterialLabel: "Finish",
        finishes: mirroredGlassClear,
      },
      {
        id: '5d7f904a9e975659cd38dafb',
        nid: 342,
        name: 'Mirrored Glass, Bronze',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/mirrored-glass-bronze/mirrored-glass-bronze.jpg',
        subMaterialLabel: "Finish",
        finishes: mirroredGlassBronze,
      },
      {
        id: '5d7f904b85fa091a34645c29',
        nid: 343,
        name: 'Mirroed Glass, Smoke',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/mirrored-glass-smoke/mirrored-glass-smoke.jpg',
        subMaterialLabel: "Finish",
        finishes: mirroredGlassSmoke,
      },
    ],
  },
]

export default Materials
