let VivitelaMesh = [
  {
    id: '5d7f9925d58d2d9a9d483ccd',
    nid: 371,
    name: 'Framework',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-mesh/framework/framework.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f992629512168c9b1b363',
        nid: 36847,
        name: 'Standard',
        fullName:
          'ViviTela Mesh glass with Framework interlayer in White Gold and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-mesh/framework/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-mesh/framework/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-mesh/framework/standard-full.jpg',
        finish: {
          id: '5d7f9927e6d9fb4610cae4c2',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: false,
        lightplaneOpacity: 0.001,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.28
          },
        ]
      },
    ],
  },
  {
    id: '5d7f99283ff42dfeb9533cf6',
    nid: 372,
    name: 'Overlay',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-mesh/overlay/overlay.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f9929e0eede2ede0fc551',
        nid: 36848,
        name: 'Standard',
        fullName:
          'ViviTela Mesh glass with Overlay interlayer in Nickel Silver and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-mesh/overlay/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-mesh/overlay/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-mesh/overlay/standard-full.jpg',
        finish: {
          id: '5d7f992a6986db659d2a4231',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: false,
        lightplaneOpacity: 0.001,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.28
          },
        ]
      },
    ],
  },
  {
    id: '5d7f992b5879cc70d84814e4',
    nid: 373,
    name: 'Oxford',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-mesh/oxford/oxford.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f992cf96edc722cb0b4b5',
        nid: 36849,
        name: 'Pearlex',
        fullName:
          'ViviTela Mesh glass with Oxford interlayer in Nickel Bronze and Pearlex+ finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-mesh/oxford/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-mesh/oxford/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-mesh/oxford/pearlex-full.jpg',
        finish: {
          id: '5d7f992dd5fd49c33f47fc98',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: false,
        lightplaneOpacity: 0.001,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9ba1d01bc684f68f0db6',
              nid: 1649,
              title: 'VividGlass, Pearlex Finish, Shadows',
            },
            opacity: 0.15
          },
        ]
      },
    ],
  },
  {
    id: '5d7f992ec1e3d884047a1342',
    nid: 374,
    name: 'Pointed Twill',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-mesh/pointed-twill/pointed-twill.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f992fcd0b11b90747c433',
        nid: 36850,
        name: 'Pearlex',
        fullName:
          'ViviTela Mesh glass with Pointed Twill interlayer in Stainless Steel and Pearlex+ finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-mesh/pointed-twill/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-mesh/pointed-twill/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-mesh/pointed-twill/pearlex-full.jpg',
        finish: {
          id: '5d7f993090269c91bcdb1af7',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: false,
        lightplaneOpacity: 0.001,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9ba1d01bc684f68f0db6',
              nid: 1649,
              title: 'VividGlass, Pearlex Finish, Shadows',
            },
            opacity: 0.18
          },
        ]
      },
    ],
  },
  {
    id: '5d7f9931e3f3c6828d17e49d',
    nid: 375,
    name: 'Twill',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-mesh/twill/twill.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f99322db72c3cc715ef38',
        nid: 36851,
        name: 'Standard',
        fullName:
          'ViviTela Mesh glass with Twill interlayer in Bronze and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-mesh/twill/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-mesh/twill/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-mesh/twill/standard-full.jpg',
        finish: {
          id: '5d7f9933e72bec3e18806865',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: false,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.28
          },
        ]
      },
    ],
  },
]

export default VivitelaMesh
