import React from 'react'
import materials from '../../../../objects/ceilings/data/materials'
import lighting from '../../../../objects/lighting/data/lighting'
import floors from '../../../../objects/floors/data/floors'
import SearchRow from '../SurfacesSearch/search-row'
import CeilingPreview from './CeilingPreview'
import LightTemperature from './LightTemperature'
import styles from '../SurfacesSearch/surfaces-search.module.css'

class CeilingSearchPanel extends React.Component {

  render(){
    let ceilingConfiguration = null
    ceilingConfiguration = <SearchRow
      objects={lighting}
      activeObject={this.props.configuration.lighting}
      objectType='Ceiling Configuration'
      centerThumbs={true}
      onClick={this.props.applyLighting}
    />
    let lightTemperature = <LightTemperature
      configuration={this.props.configuration}
      value={this.props.configuration.lightTemperature}
      onClick={this.props.applyLightTemperature}
    />
    let material = null
    material = <SearchRow
     objects={materials}
     activeObject={this.props.configuration.ceiling.material}
     objectType='Material'
     onClick={this.props.applyMaterial}
    />
    let finish = null
    let hideSearchRow = false
    if(this.props.configuration.ceiling.material.hideSearchRow){
      hideSearchRow = true
    }
    finish = <SearchRow
     objects={this.props.configuration.ceiling.material.finishes}
     activeObject={this.props.configuration.ceiling.finish}
     objectType='Finish'
     hideSearchRow={hideSearchRow}
     onClick={this.props.applyFinish}
    />
    let floor = null
    floor = <SearchRow
     objects={floors}
     activeObject={this.props.configuration.floor}
     objectType='Flooring'
     onClick={this.props.applyFloor}
    />
    let preview = <CeilingPreview
      configuration={this.props.configuration}
    />
    let notesStyle = {
      padding: '20px',
      clear: 'both',
      float: 'none',
      textTransform: 'uppercase',
      fontWeight: 400
    }
    let notes = <div style={notesStyle}><p className={styles['search__panel__text']}>NOTE: FLOORING OPTIONS SHOWN ARE FOR VISUAL REFERENCE ONLY. FORMS+SURFACES DOES NOT PROVIDE FLOORING OF ANY KIND.</p></div>
    return (
      <div>
        {preview}
        {ceilingConfiguration}
        {lightTemperature}
        {material}
        {finish}
        {floor}
        {notes}
      </div>
    )
  }
}

export default CeilingSearchPanel
