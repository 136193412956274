import React, { Component } from 'react'
import Cookies from 'universal-cookie'
import styles from './featuretip.module.css'

class FeatureTip extends Component{
  constructor(props) {
    super(props)
    let showTip = true
    const cookies = new Cookies()
    if(cookies.get(this.props.cookieName) === 'hide'){
      showTip = false
    }
    this.state = {
      showTip: showTip
    }
  }

  endLoader = () => {
    this.setState({
      loading: false
    })
  }

  startLoader = () => {
    this.setState({
      loading: true
    })
  }

  closeTip = () => {
    this.setState({
      showTip: false
    })
  }

  closeForGood = (e) => {
    if(e.target.checked === true){
      const cookies = new Cookies()
      let expireDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1))
      cookies.set(this.props.cookieName, 'hide', { path: '/', expires: expireDate })
      this.setState({
        showTip: false
      })
    }
  }

  render(){
    let title = ''
    let message = ''
    let closeButton = ''
    let closeCheck = ''
    if(this.props.cookieName){
      closeCheck = <div className={styles['hideCheck']}>
      <input type="checkbox" id="hide-check" name="hide-check"
      onChange={(e) => this.closeForGood(e)}
      value="hide"/>
      <label htmlFor="hide-check">
       Don't show this again</label>
      </div>
    }
    if(this.props.closeButtonText){
      closeButton = <button
      onClick={this.closeTip}
      >{this.props.closeButtonText}</button>
    }
    if(this.props.message){
      message = <div className={styles['message']}>{this.props.message}</div>
    }
    if(this.props.title){
      title = <h2>{this.props.title}</h2>
    }
    let tip = null
    if(this.state.showTip){
      tip = <div className={this.props.bigBox ? styles['featureTipbigBox']: styles['featureTip']}>
      {title}
      {message}
      {closeButton}
      {closeCheck}
      </div>
    }
    return(
      <>
      {this.state.showTip &&
        <div className={this.props.bigBox ? styles['featureTipCloseBG'] : ''} >
          {this.props.bigBox && 
          <button className={styles['featureTipCloseButton']} onClick={this.closeTip}></button>
          }
        {tip}
        </div>
      }
      </>
    )
  }
}

export default FeatureTip
