let FusedGraphite = [
  {
    id: '5d7f8ac150364b9490b58f25',
    nid: 155,
    name: 'Sandstone',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/sandstone/sandstone.jpg',
    surfaceLabel: "Pattern",
    patterns: [
      {
        id: '5d7f8ac23c735c54b4eb42e2',
        nid: 170,
        name: 'No Pattern',
        fullName: 'Fused Graphite with Sandstone finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/none.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/sandstone/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/sandstone/no-pattern-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.56
          }
        ]
      },
      {
        id: '5d7f8ac31dd3a64ea3063b5f',
        nid: 893,
        name: 'Champagne Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Graphite with Sandstone finish and Champagne Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/sandstone/champagne-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/sandstone/champagne-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/sandstone/champagne-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.14
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.56
          }
        ]
      },
      {
        id: '5d7f8ac4b44fec8706a33bae',
        nid: 918,
        name: 'Chardonnay Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Graphite with Sandstone finish and Chardonnay Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/sandstone/chardonnay-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/sandstone/chardonnay-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/sandstone/chardonnay-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.14
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.56
          }
        ]
      },
      {
        id: '5d7f8ac5eeaf3d700c951451',
        nid: 948,
        name: 'Dallas Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Graphite with Sandstone finish and Dallas Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/sandstone/dallas-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/sandstone/dallas-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/sandstone/dallas-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.14
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.56
          }
        ]
      },
      {
        id: '5d7f8ac679d88abdf4fad025',
        nid: 980,
        name: 'Denver Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Graphite with Sandstone finish and Denver Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/sandstone/denver-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/sandstone/denver-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/sandstone/denver-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.14
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.56
          }
        ]
      },
      {
        id: '5d7f8ac7fdba8f80fe749847',
        nid: 1014,
        name: 'Dune Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Graphite with Sandstone finish and Dune Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/sandstone/dune-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/sandstone/dune-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/sandstone/dune-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.14
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.56
          }
        ]
      },
      {
        id: '5d7f8ac8da7366e09fd5fda4',
        nid: 1035,
        name: 'Kalahari Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Graphite with Sandstone finish and Kalahari Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/sandstone/kalahari-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/sandstone/kalahari-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/sandstone/kalahari-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.14
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.56
          }
        ]
      },
    ],
  },
  {
    id: '5d7f8ac96ffc9915aefb0c90',
    nid: 156,
    name: 'Seastone',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/seastone.jpg',
    surfaceLabel: "Pattern",
    patterns: [
      {
        id: '5d7f8acad2916b84c8103fda',
        nid: 172,
        name: 'No Pattern',
        fullName: 'Fused Graphite with Seastone finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/none.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/no-pattern-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f8acb18aa1b9727109857',
        nid: 1414,
        name: 'City Lights Eco-Etch',
        fullName:
          'Fused Graphite with Seastone finish and City Lights Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/city-lights-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/city-lights-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/city-lights-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.41
          }
        ]
      },
      {
        id: '5d7f8acc3127331373e8a5cd',
        nid: 8016,
        name: 'Glacier Eco-Etch',
        fullName:
          'Fused Graphite with Seastone finish and Glacier Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/glacier-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/glacier-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/glacier-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f8acde56221f59f657fad',
        nid: 7986,
        name: 'Current Eco-Etch',
        fullName:
          'Fused Graphite with Seastone finish and Current Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/current-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/current-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/current-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f8ace025f5b8ce5bbe4d4',
        nid: 8001,
        name: 'Dash Eco-Etch',
        fullName:
          'Fused Graphite with Seastone finish and Dash Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/dash-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/dash-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/dash-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f8acf814189e8c1f03c81',
        nid: 1918,
        name: 'Flicker Eco-Etch',
        fullName:
          'Fused Graphite with Seastone finish and Flicker Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/flicker-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/flicker-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/flicker-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f8ad0b75ccaf0ab2554a0',
        nid: 1930,
        name: 'Mica Eco-Etch',
        fullName:
          'Fused Graphite with Seastone finish and Mica Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/mica-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/mica-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/mica-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f8ad120ef3c2a605a9c4d',
        nid: 1941,
        name: 'Reeds Eco-Etch',
        fullName:
          'Fused Graphite with Seastone finish and Reeds Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/reeds-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/reeds-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/reeds-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f8ad20e21ca7fa55b98a0',
        nid: 2116,
        name: 'Seagrass Eco-Etch',
        fullName:
          'Fused Graphite with Seastone finish and Seagrass Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/seagrass-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/seagrass-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/seagrass-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f8ad3ca29989b29647c9b',
        nid: 2128,
        name: 'Sequence Eco-Etch',
        fullName:
          'Fused Graphite with Seastone finish and Sequence Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/sequence-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/sequence-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/sequence-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f8ad474b5fd24aa0f100f',
        nid: 2142,
        name: 'Silkworm Eco-Etch',
        fullName:
          'Fused Graphite with Seastone finish and Silkworm Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/silkworm-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/silkworm-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/silkworm-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f8ad5f9384331c8c675a8',
        nid: 2250,
        name: 'Stacks Eco-Etch',
        fullName:
          'Fused Graphite with Seastone finish and Stacks Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/stacks-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/stacks-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/stacks-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f8ad62aab9b272a4f8feb',
        nid: 8684,
        name: 'Stripe Eco-Etch',
        fullName:
          'Fused Graphite with Seastone finish and Stripe Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/stripe-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/stripe-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/stripe-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f8ad7f1ee60e3b3986c3f',
        nid: 8700,
        name: 'Thatch Eco-Etch',
        fullName:
          'Fused Graphite with Seastone finish and Thatch Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/thatch-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/thatch-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/thatch-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f8ad8c56fe6f1e732c7c5',
        nid: 8034,
        name: 'Trace Eco-Etch',
        fullName:
          'Fused Graphite with Seastone finish and Trace Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/trace-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/trace-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/trace-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f8ad991747392d15245db',
        nid: 1811,
        name: 'Carbon Fusion',
        fullName:
          'Fused Graphite with Seastone finish and Carbon Fusion pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/carbon-fusion.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/carbon-fusion-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/carbon-fusion-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f8ada9a340b931888c566',
        nid: 1823,
        name: 'Cinch Fusion',
        fullName:
          'Fused Graphite with Seastone finish and Cinch Fusion pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/cinch-fusion.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/cinch-fusion-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/cinch-fusion-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f8adba41ef4019531b78c',
        nid: 1873,
        name: 'Moraine Fusion',
        fullName:
          'Fused Graphite with Seastone finish and Moraine Fusion pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/moraine-fusion.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/moraine-fusion-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/moraine-fusion-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f8adc4a66ec29e0f6b642',
        nid: 895,
        name: 'Champagne Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Graphite with Seastone finish and Champagne Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/champagne-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/champagne-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/champagne-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f8addb0d24ae5c306aefc',
        nid: 920,
        name: 'Chardonnay Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Graphite with Seastone finish and Chardonnay Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/chardonnay-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/chardonnay-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/chardonnay-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f8adea30b5dcae7ca1b49',
        nid: 950,
        name: 'Dallas Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Graphite with Seastone finish and Dallas Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/dallas-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/dallas-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/dallas-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f8adf243ab7a39703f1c3',
        nid: 982,
        name: 'Denver Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Graphite with Seastone finish and Denver Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/denver-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/denver-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/denver-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f8ae0727cdfe6cb209c8a',
        nid: 1016,
        name: 'Dune Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Graphite with Seastone finish and Dune Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/dune-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/dune-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/dune-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f8ae125bd8cfbe2d15c46',
        nid: 1037,
        name: 'Kalahari Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Graphite with Seastone finish and Kalahari Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/kalahari-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/kalahari-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/seastone/kalahari-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.65
          }
        ]
      },
    ],
  },
  {
    id: '5d7f8ae2f60ddbeccc439e31',
    nid: 157,
    name: 'Linen',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/linen/linen.jpg',
    surfaceLabel: "Pattern",
    patterns: [
      {
        id: '5d7f8ae3baf3d1cb7309a95d',
        nid: 168,
        name: 'No Pattern',
        fullName: 'Fused Graphite with Linen finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/none.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/linen/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/linen/no-pattern-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.13
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8ae4d5ca4d97e42b9677',
        nid: 891,
        name: 'Champagne Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Graphite with Linen finish and Champagne Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/linen/champagne-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/linen/champagne-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/linen/champagne-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.13
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8ae59116968a1a981216',
        nid: 916,
        name: 'Chardonnay Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Graphite with Linen finish and Chardonnay Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/linen/chardonnay-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/linen/chardonnay-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/linen/chardonnay-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.13
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8ae69787852f1950d8a7',
        nid: 946,
        name: 'Dallas Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Graphite with Linen finish and Dallas Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/linen/dallas-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/linen/dallas-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/linen/dallas-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.13
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8ae7706098220b627aa6',
        nid: 978,
        name: 'Denver Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Graphite with Linen finish and Denver Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/linen/denver-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/linen/denver-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/linen/denver-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.13
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8ae87a0fccfe6ef8f79e',
        nid: 1012,
        name: 'Dune Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Graphite with Linen finish and Dune Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/linen/dune-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/linen/dune-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/linen/dune-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.13
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8ae93e0511fab94aa972',
        nid: 1033,
        name: 'Kalahari Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Graphite with Linen finish and Kalahari Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/linen/kalahari-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/linen/kalahari-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/linen/kalahari-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.13
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.5
          }
        ]
      },
    ],
  },
  {
    id: '5d7f8aeadc5647c1a8e70865',
    nid: 140,
    name: 'Diamond',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/diamond/diamond.jpg',
    surfaceLabel: "Pattern",
    patterns: [
      {
        id: '5d7f8aeb8791d4cb2ca155cd',
        nid: 755,
        name: 'No Pattern',
        fullName: 'Fused Graphite with Diamond finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/none.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/diamond/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/diamond/no-pattern-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.14
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.56
          }
        ]
      },
      {
        id: '5d7f8aec157d887d992f1877',
        nid: 1069,
        name: 'Champagne Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Graphite with Diamond finish and Champagne Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/diamond/champagne-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/diamond/champagne-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/diamond/champagne-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.14
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.56
          }
        ]
      },
      {
        id: '5d7f8aed4ddcba6a028e2b24',
        nid: 1065,
        name: 'Chardonnay Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Graphite with Diamond finish and Chardonnay Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/diamond/chardonnay-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/diamond/chardonnay-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/diamond/chardonnay-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.14
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.56
          }
        ]
      },
      {
        id: '5d7f8aee52ff536434701b23',
        nid: 945,
        name: 'Dallas Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Graphite with Diamond finish and Dallas Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/diamond/dallas-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/diamond/dallas-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/diamond/dallas-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.14
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.56
          }
        ]
      },
      {
        id: '5d7f8aef7a782a4804a1bca1',
        nid: 977,
        name: 'Denver Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Graphite with Diamond finish and Denver Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/diamond/denver-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/diamond/denver-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/diamond/denver-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.14
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.56
          }
        ]
      },
      {
        id: '5d7f8af0677d4faba1cfeff3',
        nid: 1061,
        name: 'Dune Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Graphite with Diamond finish and Dune Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/diamond/dune-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/diamond/dune-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/diamond/dune-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.14
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.56
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.8
          }
        ]
      },
      {
        id: '5d7f8af1aa0ebbda0f6f5925',
        nid: 1057,
        name: 'Kalahari Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Graphite with Diamond finish and Kalahari Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/diamond/kalahari-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/diamond/kalahari-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/diamond/kalahari-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.14
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.56
          }
        ]
      },
    ],
  },
  {
    id: '5d7f8af25f7cacfa1cc04ae7',
    nid: 143,
    name: 'Mirror',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/mirror.jpg',
    surfaceLabel: "Pattern",
    patterns: [
      {
        id: '5d7f8af3ea518ceb636f81c2',
        nid: 169,
        name: 'No Pattern',
        fullName: 'Fused Graphite with Mirror finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/none.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/no-pattern-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          },
        ]
      },
      {
        id: '5d7f8af4f55fe6c4291a1496',
        nid: 1485,
        name: 'City Lights Eco-Etch',
        fullName:
          'Fused Graphite with Mirror finish and City Lights Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/city-lights-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/city-lights-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/city-lights-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.5
          },
        ]
      },
      {
        id: '5d7f8af5d30d934b5ab4dbfe',
        nid: 8018,
        name: 'Glacier Eco-Etch',
        fullName:
          'Fused Graphite with Mirror finish and Glacier Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/glacier-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/glacier-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/glacier-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.5
          },
        ]
      },
      {
        id: '5d7f8af62beec01289ac76d1',
        nid: 7988,
        name: 'Current Eco-Etch',
        fullName:
          'Fused Graphite with Mirror finish and Current Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/current-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/current-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/current-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.5
          },
        ]
      },
      {
        id: '5d7f8af7dff5e431522d9a1e',
        nid: 8003,
        name: 'Dash Eco-Etch',
        fullName: 'Fused Graphite with Mirror finish and Dash Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/dash-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/dash-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/dash-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.5
          },
        ]
      },
      {
        id: '5d7f8af813223fdc18b1a8f5',
        nid: 1916,
        name: 'Flicker Eco-Etch',
        fullName:
          'Fused Graphite with Mirror finish and Flicker Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/flicker-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/flicker-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/flicker-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.5
          },
        ]
      },
      {
        id: '5d7f8af96a1be8f96036480e',
        nid: 1928,
        name: 'Mica Eco-Etch',
        fullName: 'Fused Graphite with Mirror finish and Mica Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/mica-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/mica-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/mica-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.5
          },
        ]
      },
      {
        id: '5d7f8afa8e463167786e27db',
        nid: 1939,
        name: 'Reeds Eco-Etch',
        fullName:
          'Fused Graphite with Mirror finish and Reeds Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/reeds-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/reeds-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/reeds-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.5
          },
        ]
      },
      {
        id: '5d7f8afb704b8e9521be4102',
        nid: 2114,
        name: 'Seagrass Eco-Etch',
        fullName:
          'Fused Graphite with Mirror finish and Seagrass Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/seagrass-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/seagrass-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/seagrass-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.5
          },
        ]
      },
      {
        id: '5d7f8afc2988261c2abfc6ba',
        nid: 2126,
        name: 'Sequence Eco-Etch',
        fullName:
          'Fused Graphite with Mirror finish and Sequence Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/sequence-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/sequence-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/sequence-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.5
          },
        ]
      },
      {
        id: '5d7f8afd967e28a4b5e2e2b5',
        nid: 2140,
        name: 'Silkworm Eco-Etch',
        fullName:
          'Fused Graphite with Mirror finish and Silkworm Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/silkworm-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/silkworm-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/silkworm-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.5
          },
        ]
      },
      {
        id: '5d7f8afe2d8163504188e3da',
        nid: 2248,
        name: 'Stacks Eco-Etch',
        fullName:
          'Fused Graphite with Mirror finish and Stacks Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/stacks-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/stacks-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/stacks-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.5
          },
        ]
      },
      {
        id: '5d7f8aff42565621d9d9cf58',
        nid: 8682,
        name: 'Stripe Eco-Etch',
        fullName:
          'Fused Graphite with Mirror finish and Stripe Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/stripe-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/stripe-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/stripe-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.5
          },
        ]
      },
      {
        id: '5d7f8b0027a308dd41d330c7',
        nid: 8698,
        name: 'Thatch Eco-Etch',
        fullName:
          'Fused Graphite with Mirror finish and Thatch Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/thatch-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/thatch-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/thatch-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.5
          },
        ]
      },
      {
        id: '5d7f8b016ade8c4df0f58baf',
        nid: 8036,
        name: 'Trace Eco-Etch',
        fullName:
          'Fused Graphite with Mirror finish and Trace Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/trace-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/trace-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/trace-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.5
          },
        ]
      },
      {
        id: '5d7f8b0273ee450d54e95542',
        nid: 1794,
        name: 'Carbon Fusion',
        fullName: 'Fused Graphite with Mirror finish and Carbon Fusion pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/carbon-fusion.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/carbon-fusion-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/carbon-fusion-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.5
          },
        ]
      },
      {
        id: '5d7f8b03fb7316199aea91ef',
        nid: 1821,
        name: 'Cinch Fusion',
        fullName: 'Fused Graphite with Mirror finish and Cinch Fusion pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/cinch-fusion.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/cinch-fusion-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/cinch-fusion-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.5
          },
        ]
      },
      {
        id: '5d7f8b04caf01720b9cf97a0',
        nid: 1865,
        name: 'Moraine Fusion',
        fullName:
          'Fused Graphite with Mirror finish and Moraine Fusion pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/moraine-fusion.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/moraine-fusion-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/moraine-fusion-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.5
          },
        ]
      },
      {
        id: '5d7f8b05f895a14482194e41',
        nid: 892,
        name: 'Champagne Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Graphite with Mirror finish and Champagne Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/champagne-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/champagne-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/champagne-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.5
          },
        ]
      },
      {
        id: '5d7f8b06101696221fbf5cff',
        nid: 917,
        name: 'Chardonnay Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Graphite with Mirror finish and Chardonnay Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/chardonnay-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/chardonnay-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/chardonnay-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.5
          },
        ]
      },
      {
        id: '5d7f8b078695c694701efd94',
        nid: 947,
        name: 'Dallas Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Graphite with Mirror finish and Dallas Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/dallas-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/dallas-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/dallas-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.5
          },
        ]
      },
      {
        id: '5d7f8b087dab892fe10d8306',
        nid: 979,
        name: 'Denver Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Graphite with Mirror finish and Denver Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/denver-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/denver-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/denver-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.5
          },
        ]
      },
      {
        id: '5d7f8b094b4abd6505193a9e',
        nid: 1013,
        name: 'Dune Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Graphite with Mirror finish and Dune Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/dune-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/dune-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/dune-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.5
          },
        ]
      },
      {
        id: '5d7f8b0a84ea887c21861fc0',
        nid: 1034,
        name: 'Kalahari Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Graphite with Mirror finish and Kalahari Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/kalahari-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/kalahari-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/mirror/kalahari-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f8b0ba2df2a76d6f8a426',
    nid: 159,
    name: 'Satin',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/satin.jpg',
    surfaceLabel: "Pattern",
    patterns: [
      {
        id: '5d7f8b0c2128371370da4a3c',
        nid: 171,
        name: 'No Pattern',
        fullName: 'Fused Graphite with Satin finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/none.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/no-pattern-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8b0d994c43ffd6f1cec9',
        nid: 1486,
        name: 'City Lights Eco-Etch',
        fullName:
          'Fused Graphite with Satin finish and City Lights Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/city-lights-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/city-lights-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/city-lights-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8b0e33b7493f6576aa17',
        nid: 8017,
        name: 'Glacier Eco-Etch',
        fullName:
          'Fused Graphite with Satin finish and Glacier Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/glacier-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/glacier-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/glacier-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8b0f76142f88c26c8490',
        nid: 7987,
        name: 'Current Eco-Etch',
        fullName:
          'Fused Graphite with Satin finish and Current Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/current-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/current-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/current-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8b102be1590d5ecba58f',
        nid: 8002,
        name: 'Dash Eco-Etch',
        fullName: 'Fused Graphite with Satin finish and Dash Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/dash-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/dash-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/dash-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8b11b605f9b0964e456d',
        nid: 1917,
        name: 'Flicker Eco-Etch',
        fullName:
          'Fused Graphite with Satin finish and Flicker Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/flicker-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/flicker-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/flicker-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8b12484ac28bd4c74789',
        nid: 1929,
        name: 'Mica Eco-Etch',
        fullName: 'Fused Graphite with Satin finish and Mica Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/mica-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/mica-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/mica-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8b137a9de90e35b40e4e',
        nid: 1940,
        name: 'Reeds Eco-Etch',
        fullName: 'Fused Graphite with Satin finish and Reeds Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/reeds-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/reeds-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/reeds-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8b147176cf746325e0b9',
        nid: 2115,
        name: 'Seagrass Eco-Etch',
        fullName:
          'Fused Graphite with Satin finish and Seagrass Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/seagrass-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/seagrass-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/seagrass-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8b1532d1acee306e6256',
        nid: 2127,
        name: 'Sequence Eco-Etch',
        fullName:
          'Fused Graphite with Satin finish and Sequence Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/sequence-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/sequence-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/sequence-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8b166b69f998352386c3',
        nid: 2141,
        name: 'Silkworm Eco-Etch',
        fullName:
          'Fused Graphite with Satin finish and Silkworm Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/silkworm-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/silkworm-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/silkworm-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8b177691ff4a16dfa164',
        nid: 2249,
        name: 'Stacks Eco-Etch',
        fullName:
          'Fused Graphite with Satin finish and Stacks Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/stacks-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/stacks-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/stacks-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8b1870a44bcef4379fe8',
        nid: 8683,
        name: 'Stripe Eco-Etch',
        fullName:
          'Fused Graphite with Satin finish and Stripe Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/stripe-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/stripe-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/stripe-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8b19455695854c4362d6',
        nid: 8699,
        name: 'Thatch Eco-Etch',
        fullName:
          'Fused Graphite with Satin finish and Thatch Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/thatch-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/thatch-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/thatch-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8b1a2a52fb5ede3084d0',
        nid: 8035,
        name: 'Trace Eco-Etch',
        fullName: 'Fused Graphite with Satin finish and Trace Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/trace-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/trace-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/trace-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8b1b48e472af08ed573c',
        nid: 1798,
        name: 'Carbon Fusion',
        fullName: 'Fused Graphite with Satin finish and Carbon Fusion pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/carbon-fusion.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/carbon-fusion-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/carbon-fusion-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8b1c3ef887ac24e0e5bf',
        nid: 1822,
        name: 'Cinch Fusion',
        fullName: 'Fused Graphite with Satin finish and Cinch Fusion pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/cinch-fusion.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/cinch-fusion-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/cinch-fusion-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8b1dad3da27ffd743403',
        nid: 1869,
        name: 'Moraine Fusion',
        fullName: 'Fused Graphite with Satin finish and Moraine Fusion pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/moraine-fusion.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/moraine-fusion-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/moraine-fusion-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8b1ec615a957b16c04c1',
        nid: 894,
        name: 'Champagne Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Graphite with Satin finish and Champagne Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/champagne-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/champagne-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/champagne-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8b1f627857e44f9f2d29',
        nid: 919,
        name: 'Chardonnay Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Graphite with Satin finish and Chardonnay Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/chardonnay-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/chardonnay-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/chardonnay-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8b20ddf9ebe602b4fa66',
        nid: 949,
        name: 'Dallas Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Graphite with Satin finish and Dallas Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/dallas-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/dallas-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/dallas-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8b217271dc202833b643',
        nid: 981,
        name: 'Denver Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Graphite with Satin finish and Denver Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/denver-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/denver-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/denver-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8b22033eabce6ba3f728',
        nid: 1015,
        name: 'Dune Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Graphite with Satin finish and Dune Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/dune-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/dune-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/dune-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8b2305f934f4b43963ae',
        nid: 1036,
        name: 'Kalahari Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Graphite with Satin finish and Kalahari Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/kalahari-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/kalahari-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-graphite/satin/kalahari-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
    ],
  },
]

export default FusedGraphite
