import React from 'react'
import styles from '../PanelLegends/panel-legends.module.css'

class HandrailLegends extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activePanel: {},
    }
  }

  onClick = () => {
    const { panel, onClick } = this.props
    onClick(panel)
  }

  render() {
    const fullWidth = 900
    const fullHeight = 1250
    let handrailLegends = ''
    let crashrailLegends = ''
    let handrailConflicts = null
    if (this.props.configuration.handrailLegends.length) {
      handrailLegends = this.props.configuration.handrailLegends.map(function (
        panel,
        key
      ) {
        let className = styles['panel__legend']
        className += ' ' + styles['legend__active']
        let left = (panel.left / fullWidth) * 100 + '%'
        let top = (panel.top / fullHeight) * 100 + '%'
        if(this.props.configuration.additionalDetails.openingOption === "Front and Rear"){
          left = ((panel.left + 200) / fullWidth) * 100 + '%'
        }
        let legendStyle = {
          left: left,
          top: top,
        }
        return (
          <div className={className} key={panel.label} style={legendStyle}>
            {panel.label}
          </div>
        )
      }.bind(this))
    }

    if (this.props.configuration.crashRailLegends.length) {
      if (this.props.configuration.crashrail.id !== 'no-crashrail') {
        crashrailLegends = this.props.configuration.crashRailLegends.map(
          function (panel, key) {
            let className = styles['panel__legend']
            className += ' ' + styles['legend__active']
            let left = (panel.left / fullWidth) * 100 + '%'
            let top = (panel.top / fullHeight) * 100 + '%'
            let legendStyle = {
              left: left,
              top: top,
            }
            return (
              <div className={className} key={panel.label} style={legendStyle}>
                {panel.label}
              </div>
            )
          }
        )
      }
    }

    if (this.props.handrailConflicts.length) {
      let openingOption = this.props.configuration.additionalDetails.openingOption
      handrailConflicts = this.props.handrailConflicts.map(function (panel) {
        let className = styles['panel__legend']
        className += ' ' + styles['legend__error']
        let panelName = panel.name.substring(0,1);
        if(openingOption === "Front and Rear"){
          panelName += panel.legend.dualOpenLabel
        }else{
          panelName += panel.legend.label
        }
        let left = (panel.legend.left / fullWidth) * 100 + '%'
        let top = (panel.legend.top / fullHeight) * 100 + '%'
        let legendStyle = {
          left: left,
          top: top,
        }
        return (
          <div className={className} key={panel._id} style={legendStyle}>
            {panelName}
          </div>
        )
      })
    }

    return (
      <div className={styles['panel__legends']}>
        {handrailLegends}
        {crashrailLegends}
        {handrailConflicts}
      </div>
    )
  }
}

export default HandrailLegends
