let WoodVeneer = [
  {
    id: '5d7f993318e6cb6bd21459c1',
    nid: 258,
    name: 'Wood Veneer',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/wood-veneer/wood-veneer/wood-veneer.jpg',
    surfaceLabel: "Pattern",
    patterns: [
      {
        id: '5d7f9934ebaf4a2bfce53843',
        nid: 1162,
        name: 'American Cherry',
        fullName: 'American Cherry Wood Veneer',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/wood-veneer/wood-veneer/american-cherry.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/wood-veneer/wood-veneer/american-cherry-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/wood-veneer/wood-veneer/american-cherry-full.jpg',
      },
      {
        id: '5d7f99358305d6984bffa518',
        nid: 218,
        name: 'American Cherry with Dark Mahogany Stain',
        fullName: 'American Cherry with Dark Mahogany Stain Wood Veneer',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/wood-veneer/wood-veneer/american-cherry-with-dark-mahogany-stain.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/wood-veneer/wood-veneer/american-cherry-with-dark-mahogany-stain-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/wood-veneer/wood-veneer/american-cherry-with-dark-mahogany-stain-full.jpg',
      },
      {
        id: '5d7f9936fbb99aceae4b435f',
        nid: 219,
        name: 'American Walnut',
        fullName: 'American Walnut Wood Veneer ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/wood-veneer/wood-veneer/american-walnut.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/wood-veneer/wood-veneer/american-walnut-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/wood-veneer/wood-veneer/american-walnut-full.jpg',
      },
      {
        id: '5d7f99379fb504b487596b8e',
        nid: 221,
        name: 'Australian Lacewood',
        fullName: 'Australian Lacewood Wood Veneer',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/wood-veneer/wood-veneer/australian-lacewood.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/wood-veneer/wood-veneer/australian-lacewood-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/wood-veneer/wood-veneer/australian-lacewood-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9275da4a0ff366d8b5',
              nid: 1644,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Shadows',
            },
            opacity: 0.5
          },
        ]
      },
      {
        id: '5d7f9938302d9e3602eaf69f',
        nid: 156,
        name: 'Bamboo',
        fullName: 'Bamboo Wood Veneer ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/wood-veneer/wood-veneer/bamboo.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/wood-veneer/wood-veneer/bamboo-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/wood-veneer/wood-veneer/bamboo-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9af3df1d6cdcf96507a6',
              nid: 1643,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Environment Lighting',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9275da4a0ff366d8b5',
              nid: 1644,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Shadows',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9aebbfbc49bc1cc378d7',
              nid: 549,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Downlights',
            },
            opacity: 0.75
          },
        ]
      },
      {
        id: '5d7f9939080274cf625f6ded',
        nid: 222,
        name: 'Birdseye Maple',
        fullName: 'Birdseye Maple Wood Veneer',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/wood-veneer/wood-veneer/birdseye-maple.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/wood-veneer/wood-veneer/birdseye-maple-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/wood-veneer/wood-veneer/birdseye-maple-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9af3df1d6cdcf96507a6',
              nid: 1643,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Environment Lighting',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9275da4a0ff366d8b5',
              nid: 1644,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Shadows',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9aebbfbc49bc1cc378d7',
              nid: 549,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Downlights',
            },
            opacity: 0.75
          },
        ]
      },
      {
        id: '5d7f993a78cbfa94ff93b4af',
        nid: 1522,
        name: 'Walnut Burl',
        fullName: 'Walnut Burl Wood Veneer',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/wood-veneer/wood-veneer/walnut-burl.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/wood-veneer/wood-veneer/walnut-burl-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/wood-veneer/wood-veneer/walnut-burl-full.jpg',
      },
      {
        id: '5d7f993b4e351e37e834060b',
        nid: 60,
        name: 'Wenge',
        fullName: 'Wenge Wood Veneer',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/wood-veneer/wood-veneer/wenge.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/wood-veneer/wood-veneer/wenge-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/wood-veneer/wood-veneer/wenge-full.jpg',
      },
      {
        id: '5d7f993c781ba5d47fb120cf',
        nid: 220,
        name: 'White Birch',
        fullName: 'White Birch Wood Veneer',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/wood-veneer/wood-veneer/white-birch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/wood-veneer/wood-veneer/white-birch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/wood-veneer/wood-veneer/white-birch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9af3df1d6cdcf96507a6',
              nid: 1643,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Environment Lighting',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9275da4a0ff366d8b5',
              nid: 1644,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Shadows',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9aebbfbc49bc1cc378d7',
              nid: 549,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Downlights',
            },
            opacity: 0.75
          },
        ]
      },
    ],
  },
]

export default WoodVeneer
