import React from 'react'
import Layout from './Layout'
import Tabs from './DesignEdit/Tabs'
import Configurations from './DesignEdit/Configuration/Configurations'
import PanelSelections from './DesignEdit/PanelSelections'
import HandrailSearch from './DesignEdit/HandrailSearch'
import ReviewTab from './DesignEdit/ReviewTab'
import DesignRender from './DesignRender'
import DesignRenderPDF from './DesignRenderPDF'
import DesignRenderMulti from './DesignRenderMulti'
import Legends from './DesignRender/legends'
import HandrailLocations from '../../objects/handrails/data/locations'
import HandrailSeries from '../../objects/handrails/data/series'
import CrashRails from '../../objects/crash-rails/data/crash-rails'
import CrashRailLocations from '../../objects/crash-rails/data/locations'
import CeilingFloorSearch from './DesignEdit/CeilingFloorSearch'
import CeilingMaterials from '../../objects/ceilings/data/materials'
import Lighting from '../../objects/lighting/data/lighting'
import Floors from '../../objects/floors/data/floors'
import NameEdit from './DesignEdit/NameEdit'
import ImageView from './DesignRender/ImageView'
import DesignSubmit from './DesignSubmit'
import ErrorMessage from './ErrorMessage'
import { navigate } from '@reach/router'
import styles from './DesignRender/design-render.module.css'
import Lightbox from 'lightbox-react'
import IntroMessage from './DesignEdit/intro-message'
import cloneDeep from 'lodash/cloneDeep'
import FeatureTip from './FeatureTip'
import {
  arePanelsComplete,
  arePanelsApplied,
  areHandrailsComplete,
  isCeilingComplete,
  getDesign,
  updateDesign,
  constructDesign,
  designHasDisabledSurface,
  readyDesignForPost,
  buildRenderLayers,
  checkDesignForLightPlaneHandrailConflict
} from '../utils/design'
import { getProject } from '../utils/project'
import { getCurrentAuthUser } from '../utils/auth'
import { getUserInfo } from '../utils/user'
import { configShouldWhiteLight } from '../utils/effects-layers'
import { crossref } from '../utils/crossref'

class DesignEdit extends React.Component {
  constructor(props) {
    super(props)
    this.tabsElement = React.createRef()
    this.panelSelections = React.createRef()
    this.state = {
      design: {},
      framePanels: [],
      configurationIds: {
        _id: null,
        nid: null,
      },
      designName: '',
      projectId: null,
      designIndex: 0,
      renderLayers: {},
      renderShowText: true,
      renderShowLegends: false,
      renderFinalLegends: false,
      showViewDownloadButton: false,
      showMultiViewButton: false,
      activePanel: null,
      lightBoxOpen: false,
      pdfOpen: false,
      canvasImage: '',
      lsbwrsImage: '',
      submitDesignOpen: false,
      pdfFileData: '',
      step1Complete: false,
      step1OverrideImage: null,
      step2Complete: false,
      step3Complete: false,
      step4Complete: false,
      dualOpen: false,
      openWhiteLightWarning: false,
      newlyWhiteLight: false,
      showSwitchConfigWarning: false,
      newConfig: {},
      userInfo: null,
      error: '',
      disabledSurface: null,
      showDisabledSurfaceWarning: false,
      handrailConflicts: [],
      showHandrailConflictWarning: false
    }
    this.loadDesign()
  }

  componentDidMount() {
    window.map = this
  }

  loadDesign = () => {
    if (this.props.designid) {
      let design = null
      let userData = null
      let step1Complete = false
      let configThumbnail = null
      let activePanel = null
      let dualOpen = false
      let disabledSurface = null
      let showDisabledSurfaceWarning = false
      getUserInfo()
        .then(
          function (userDataData) {
            userData = userDataData
            return getDesign(this.props.designid)
          }.bind(this)
        )
        .then(function (designData) {
          design = designData
          return getCurrentAuthUser()
        })
        .then(
          function (authData) {
            let groups =
              authData.signInUserSession.accessToken.payload['cognito:groups']
            let redirectToProject = false
            if (groups) {
              if (!(groups.includes('admin') || groups.includes('eds-admin'))) {
                if (design.locked) {
                  redirectToProject = true
                }
              }
            } else {
              if (design.locked) {
                redirectToProject = true
              }
            }
            if (redirectToProject) {
              navigate('/project/' + design.project)
            }
            design = constructDesign(design, this.props.data)
            disabledSurface = designHasDisabledSurface(design)
            if (disabledSurface) {
              showDisabledSurfaceWarning = true
            }
            if (design.name) {
              step1Complete = true
              configThumbnail = design.configThumbnailImage.publicURL
            }
            if (design.primaryPanels) {
              activePanel = design.primaryPanels[0]
            }
            if (design.additionalDetails) {
              if (design.additionalDetails.openingOption === 'Front and Rear') {
                dualOpen = true
              }
            }
            if (this.props.watermark) {
              design.removeWatermark = false
            }
            return getProject(design.project)
          }.bind(this)
        )
        .then(
          function (project) {
            var designIndex = project.designs.findIndex(
              (element) => element.id === this.props.designid
            )
            let framePanels = []
            let previousElement = null
            let previousIndex = null
              
            if (design.framePanels) {
              design.framePanels.forEach((element, i) => {
                element.type = 'frame'
                if (previousElement?.label === element.label && element.label) {
                  if (previousElement.type === 'panel-group') {
                    element.parent = previousElement.id
                    previousElement.panels.push({ ...element })
                    previousElement.applied = (element.surface ? true : false)
                  } else {
                    element.parent = previousElement.id
                    previousElement.parent = previousElement.id
                    framePanels[previousIndex] = {
                      id: previousElement.id,
                      type: 'panel-group',
                      label: element.label,
                      panels: [{ ...previousElement }, { ...element }],
                      applied: (element.surface ? true : false)
                    }
                    previousElement = framePanels[previousIndex] 
                  }
                } else {
                  framePanels.push({ ...element })
                  previousElement = { ...element }
                  previousIndex = i
                }
              })
            }
            this.setState(
              {
                designName: design.name,
                projectId: design.project,
                designIndex: designIndex,
                design: design,
                framePanels: framePanels,
                step1Complete: step1Complete,
                step2Complete: arePanelsComplete(design),
                step3Complete: areHandrailsComplete(design),
                step4Complete: isCeilingComplete(design),
                activePanel: activePanel,
                dualOpen: dualOpen,
                step1OverrideImage: configThumbnail,
                userInfo: userData,
                disabledSurface: disabledSurface,
                showDisabledSurfaceWarning: showDisabledSurfaceWarning,
              },
              function () {
                let renderLayers = buildRenderLayers(this.state.design)
                this.setState({
                  renderLayers: renderLayers,
                })
              }.bind(this)
            )
          }.bind(this)
        )
        .catch(
          function (err) {
            this.setState({
              error: err,
            })
          }.bind(this)
        )
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.designid !== this.props.designid) {
      getDesign(this.props.designid)
        .then(
          function (design) {
            design = constructDesign(design, this.props.data)
            var step1Complete = false
            if (design.name) {
              step1Complete = true
            }
            this.setState(
              {
                designName: design.name,
                design: design,
                step1Complete: step1Complete,
                step2Complete: arePanelsComplete(design),
                step3Complete: areHandrailsComplete(design),
                step4Complete: isCeilingComplete(design),
                step1OverrideImage: design.configThumbnailImage.publicURL,
              },
              function () {
                let renderLayers = buildRenderLayers(this.state.design)
                this.setState({
                  renderLayers: renderLayers,
                })
              }.bind(this)
            )
          }.bind(this)
        )
        .catch(function (err) {
          console.log(err)
        })
      // this.loadDesign()
    }
  }

  crossref = (nid) => {
    return crossref(nid)
  }

  onConfigChange = (item) => {
    item.configuration = {
      _id: item._id,
      nid: item.nid,
    }
    if (arePanelsApplied(this.state.design)) {
      this.setState({
        newConfig: cloneDeep(item),
        showSwitchConfigWarning: true,
      })
    } else {
      this.changeConfig(item)
    }
  }

  onConfigPreset = (preset) => {
    this.setState(
      {
        loading: true,
      },
      function () {
        var newData = cloneDeep(preset)
        newData.owner = this.state.userInfo._id
        newData.project = this.state.projectId
        newData._id = this.props.designid
        newData.id = this.props.designid
        var design = constructDesign(newData, this.props.data)
        design.configuration = newData.configuration
        // design._id = newData.design?.id || newData.design._id
        design.additionalDetails = newData.additionalDetails
        let framePanels = []
        let previousElement = null
        let previousIndex = null
    
        if (design.framePanels) {
          design.framePanels.forEach((element, i) => {
            element.type = 'frame'
            if (previousElement?.label === element.label && element.label) {
              if (previousElement.type === 'panel-group') {
                element.parent = previousElement.id
                previousElement.panels.push({ ...element })
                previousElement.applied = (element.surface ? true : false)
              } else {
                element.parent = previousElement.id
                previousElement.parent = previousElement.id
                framePanels[previousIndex] = {
                  id: previousElement.id,
                  type: 'panel-group',
                  panels: [{ ...previousElement }, { ...element }],
                  applied: (element.surface ? true : false)
                }
              }
            } else {
              framePanels.push({ ...element })
            }
            previousElement = { ...element }
            previousIndex = i
          })
        }
        this.setState(
          {
            designName: newData.name,
            design: design,
            framePanels: framePanels,
            step1Complete: true,
            step2Complete: arePanelsComplete(design),
            step3Complete: areHandrailsComplete(design),
            step4Complete: isCeilingComplete(design),
          },
          function () {
            let renderLayers = buildRenderLayers(this.state.design)
            this.setState({
              renderLayers: renderLayers,
              loading: false,
            })
            this.saveDesign()
          }.bind(this)
        )
      }.bind(this)
    )
  }

  changeToNewConfig = () => {
    this.changeConfig(this.state.newConfig)
  }

  changeOpening = (opening) => {
    let design = this.props.data.allConfiguration.nodes.find((element) => {
      return element.id === this.state.design.id
    })
    if (!design.additionalDetails) {
      design.additionalDetails = {}
    }
    design.additionalDetails.openingOption = opening
    if (opening === 'Front') {
      this.onConfigChange(design)
    } else {
      design = { ...this.state.design }
      if (!design.additionalDetails) {
        design.additionalDetails = {}
      }
      if (design.handrail_location) {
        if (
          design.handrail_location.id === 'three-handrails-back-sides' ||
          design.handrail_location.id === 'one-handrail-back-wall'
        ) {
          design.handrail_location = HandrailLocations[2]
        }
      }
      if (design.crashrailLocation) {
        if (
          design.crashrailLocation.id === 'three-crashrails-back-sides' ||
          design.crashrailLocation.id === 'one-crashrail-back-wall'
        ) {
          design.crashrailLocation = CrashRailLocations[2]
        }
      }
      design.additionalDetails.openingOption = opening
      this.setState(
        {
          design: design,
        },
        function () {
          let renderLayers = buildRenderLayers(this.state.design)
          this.setState({
            renderLayers: renderLayers,
            dualOpen: true,
          })
          this.saveDesign()
        }.bind(this)
      )
    }
  }

  changeConfig = (item) => {
    if (!item.additionalDetails) {
      item.additionalDetails = {
        openingOption: 'Front',
      }
    }
    item.additionalDetails.quantity = 1
    let design = cloneDeep(item)
    if(item.nid >= 40060){
      design.lightTemperature = 5000
      design.panelFrameType = ''
    }
    design.designName = this.state.designName
    design.project = this.state.projectId
    design._id = this.props.designid
    let dualOpen = false
    if (design.additionalDetails) {
      if (design.additionalDetails.openingOption === 'Front and Rear') {
        dualOpen = true
      }
    }
    if (this.state.userInfo?.eds) {
      if (this.state.userInfo.eds.hideWatermark) {
        design.removeWatermark = true
        design.hideWatermark = true
      }
    }

    if(!this.props.watermark){
      design.removeWatermark = true
    }
    let framePanels = []
    let previousElement = null
    let previousIndex = null

    if (design.framePanels) {
      design.framePanels.forEach((element, i) => {
        element.type = 'frame'
        if (previousElement?.label === element.label && element.label) {
          if (previousElement.type === 'panel-group') {
            element.parent = previousElement.id
            previousElement.panels.push({ ...element })
            previousElement.applied = (element.surface ? true : false)
          } else {
            element.parent = previousElement.id
            previousElement.parent = previousElement.id
            framePanels[previousIndex] = {
              id: previousElement.id,
              type: 'panel-group',
              label: element.label,
              panels: [{ ...previousElement }, { ...element }],
              applied: (element.surface ? true : false)
            }
            previousElement = framePanels[previousIndex] 
          }
        } else {
          framePanels.push({ ...element })
          previousElement = { ...element }
          previousIndex = i
        }
      })
    }

    this.setState(
      {
        design: design,
        framePanels: framePanels,
        designName: design.name + ' #' + (this.state.designIndex + 1),
        activePanel: item.primaryPanels[0],
        showSwitchConfigWarning: false,
        dualOpen: dualOpen,
        step1Complete: true,
        step1OverrideImage: item.configThumbnailImage.publicURL,
        step2Complete: false,
        step3Complete: false,
        step4Complete: false,
      },
      function () {
        let renderLayers = buildRenderLayers(this.state.design)
        this.setState({
          renderLayers: renderLayers,
        })
        this.saveDesign()
      }.bind(this)
    )
  }

  onTabChange = (tab) => {
    switch (tab) {
      case 'CONFIGURATIONS':
        this.setState({
          renderShowText: true,
          renderShowLegends: false,
          renderShowHandrailLegends: false,
          renderShowCeilingLegends: false,
          renderFinalLegends: false,
          showViewDownloadButton: false,
          showMultiViewButton: false,
        })
        break
      case 'WALL PANELS':
        this.setState({
          renderShowText: false,
          renderShowLegends: true,
          renderShowHandrailLegends: false,
          renderShowCeilingLegends: false,
          renderFinalLegends: false,
          showViewDownloadButton: false,
          showMultiViewButton: true,
        })
        break
      case 'HANDRAILS':
        let design = { ...this.state.design }
        if (!this.state.design.handrail_location) {
          design.handrail_location = HandrailLocations[0]
          design.handrail = HandrailSeries[4]
          if (design.handrail.materials.length > 0) {
            design.handrail.selectedMaterial = design.handrail.materials[0]
          } else {
            design.handrail.selectedMaterial = null
          }
          design.handrail.selectedStandoff = design.handrail.standoffs[2]
          design.handrail.selectedStandoff.selectedMaterial =
            design.handrail.standoffs[2].materials[2]
          design.crashrail = CrashRails[0]
          design.crashrailLocation = CrashRailLocations[0]
          if (this.state.design.additionalDetails) {
            if (
              this.state.design.additionalDetails.openingOption ===
              'Front and Rear'
            ) {
              design.handrail_location = HandrailLocations[2]
              design.crashrailLocation = CrashRailLocations[2]
            }
          }
          this.setState(
            {
              design: design,
              step3Complete: areHandrailsComplete(design),
            },
            function () {
              this.saveDesign()
              let renderLayers = buildRenderLayers(this.state.design)
              this.setState({
                renderLayers: renderLayers,
              })
            }
          )
        }
        let conflicts = checkDesignForLightPlaneHandrailConflict(design)
        let showHandrailConflictWarning = false
        if(conflicts.length){
          showHandrailConflictWarning = true
        }
        this.setState({
          handrailConflicts: conflicts,
          showHandrailConflictWarning: showHandrailConflictWarning,
          renderShowText: false,
          renderShowLegends: false,
          renderShowHandrailLegends: true,
          renderShowCeilingLegends: false,
          renderFinalLegends: false,
          showViewDownloadButton: false,
          showMultiViewButton: true,
        })
        break
      case 'CEILINGS':
        if (!this.state.design.ceiling) {
          this.initCeilingTab(
            function () {
              this.setState({
                renderShowText: false,
                renderShowLegends: false,
                renderShowHandrailLegends: false,
                renderShowCeilingLegends: true,
                renderFinalLegends: false,
                showViewDownloadButton: false,
                showMultiViewButton: true,
              })
            }.bind(this)
          )
        } else {
          this.setState({
            renderShowText: false,
            renderShowLegends: false,
            renderShowHandrailLegends: false,
            renderShowCeilingLegends: true,
            renderFinalLegends: false,
            showViewDownloadButton: false,
            showMultiViewButton: true,
          })
        }
        break
      case 'REVIEW':
        this.setState({
          renderShowText: false,
          renderShowLegends: false,
          renderShowHandrailLegends: false,
          renderShowCeilingLegends: false,
          renderFinalLegends: true,
          showViewDownloadButton: true,
          showMultiViewButton: true,
        })
        break
      default:
        break
    }
  }

  onPanelChange = (panel) => {
    this.setState(
      {
        activePanel: panel,
      },
      function () {
        if(this.panelSelections.current){
          this.panelSelections.current.setPanelType(panel.type)
        }
      }
    )
  }

  selectSubPanels = (panel, flag) => {
    panel.panelCoords.forEach(function (item, index) {
      panel.panelCoords[index].active = flag
    })
    this.setState({
      activePanel: panel,
    })
  }

  applySurface = (
    surface,
    panel,
    lightplane = false,
    ceramiloc = false,
    customText = '',
    panelOption = '',
  ) => {
    let design = { ...this.state.design }
    var panelId = panel.id
    if (panel.type === 'primary') {
      let designPanel = design.primaryPanels.find((panel) => {
        return panel.id === panelId
      })
      designPanel.panelCoords.forEach(function (item, key) {
        if (designPanel.panelCoords[key].active) {
          designPanel.panelCoords[key].surface = surface
          designPanel.panelCoords[key].ceramiloc = ceramiloc
          designPanel.panelCoords[key].lightplane = lightplane
          designPanel.panelCoords[key].customText = customText
        }
      })
      designPanel.applied = true
    }
    if (panel.type === 'secondary') {
      let designPanel = design.secondaryPanels.find((panel) => {
        return panel.id === panelId
      })
      designPanel.panelCoords.forEach(function (item, key) {
        if (designPanel.panelCoords[key].active) {
          designPanel.panelCoords[key].surface = surface
          designPanel.panelCoords[key].ceramiloc = ceramiloc
          designPanel.panelCoords[key].lightplane = lightplane
          designPanel.panelCoords[key].customText = customText
        }
      })
      if(panelOption){
        designPanel.panelOption = panelOption
      }
      designPanel.applied = true
    }
    if (panel.type === 'frame') {
      let designPanel = design.framePanels.find((panel) => {
        return panel.id === panelId
      })
      designPanel.surface = surface
      designPanel.applied = true
    }
    let openWhiteLightWarning = false
    if (configShouldWhiteLight(design) && !this.state.newlyWhiteLight) {
      openWhiteLightWarning = true
    }
    let step2Complete = arePanelsComplete(design)
    this.setState(
      {
        design: design,
        step2Complete: step2Complete,
        openWhiteLightWarning: openWhiteLightWarning,
      },
      function () {
        let renderLayers = buildRenderLayers(this.state.design)
        this.setState({
          renderLayers: renderLayers,
        })
        this.saveDesign()
      }.bind(this)
    )
  }

  applyHandrailLocation = (location, callback) => {
    let design = { ...this.state.design }
    design.handrail_location = location
    let conflicts = checkDesignForLightPlaneHandrailConflict(design)
    let showHandrailConflictWarning = false
    if(conflicts.length){
      showHandrailConflictWarning = true
    }
    this.setState(
      {
        handrailConflicts: conflicts,
        design: design,
        showHandrailConflictWarning: showHandrailConflictWarning
      },
      function () {
        let renderLayers = buildRenderLayers(this.state.design)
        this.setState({
          renderLayers: renderLayers,
          step3Complete: areHandrailsComplete(design),
        })
        this.saveDesign()
        if (callback) {
          callback()
        }
      }.bind(this)
    )
  }

  applyHandrailSeries = (series, callback) => {
    let design = { ...this.state.design }
    design.handrail = series
    if (series.materials.length > 0) {
      design.handrail.selectedMaterial = series.materials[0]
    } else {
      design.handrail.selectedMaterial = null
    }
    design.handrail.selectedStandoff = series.standoffs[0]
    design.handrail.selectedStandoff.selectedMaterial =
      series.standoffs[0].materials[0]

    if(design.handrailsAllowed?.length > 0){
      let handrailAllowed = design.handrailsAllowed.find(handrail=>handrail.seriesId === series.nid)
      if(handrailAllowed?.locationsAllowed?.length > 0){
        let filteredLocations = HandrailLocations.filter(location => handrailAllowed.locationsAllowed.includes(location.id))
        if(filteredLocations?.length > 0){
          if(this.state.dualOpen){
            filteredLocations = filteredLocations.filter(location => location.id !== 'one-handrail-back-wall')
          }
          design.handrail_location = filteredLocations[0]
        }
      }
    }
    this.setState(
      {
        design: design,
        step3Complete: areHandrailsComplete(design),
      },
      function () {
        let renderLayers = buildRenderLayers(this.state.design)
        this.setState({
          renderLayers: renderLayers,
        })
        this.saveDesign()
        if (callback) {
          callback()
        }
      }.bind(this)
    )
  }

  applyHandrailMaterial = (material) => {
    let design = { ...this.state.design }
    design.handrail.selectedMaterial = material
    this.setState(
      {
        design: design,
        step3Complete: areHandrailsComplete(design),
      },
      function () {
        let renderLayers = buildRenderLayers(this.state.design)
        this.setState({
          renderLayers: renderLayers,
        })
        this.saveDesign()
      }.bind(this)
    )
  }

  applyStandoff = (standoff) => {
    let design = { ...this.state.design }
    design.handrail.selectedStandoff = standoff
    design.handrail.selectedStandoff.selectedMaterial = standoff.materials[0]
    this.setState(
      {
        design: design,
        step3Complete: areHandrailsComplete(design),
      },
      function () {
        let renderLayers = buildRenderLayers(this.state.design)
        this.setState({
          renderLayers: renderLayers,
        })
        this.saveDesign()
      }.bind(this)
    )
  }

  applyStandoffMaterial = (material) => {
    let design = { ...this.state.design }
    design.handrail.selectedStandoff.selectedMaterial = material
    this.setState(
      {
        design: design,
        step3Complete: areHandrailsComplete(design),
      },
      function () {
        let renderLayers = buildRenderLayers(this.state.design)
        this.setState({
          renderLayers: renderLayers,
        })
        this.saveDesign()
      }.bind(this)
    )
  }

  applyCrashRail = (crashRail, callback) => {
    let design = { ...this.state.design }
    design.crashrail = crashRail
    this.setState(
      {
        design: design,
      },
      function () {
        let renderLayers = buildRenderLayers(this.state.design)
        this.setState({
          renderLayers: renderLayers,
        })
        this.saveDesign()
        if (callback) {
          callback()
        }
      }.bind(this)
    )
  }

  applyCrashRailLocation = (location) => {
    let design = { ...this.state.design }
    design.crashrailLocation = location
    this.setState(
      {
        design: design,
      },
      function () {
        let renderLayers = buildRenderLayers(this.state.design)
        this.setState({
          renderLayers: renderLayers,
        })
        this.saveDesign()
      }.bind(this)
    )
  }

  applyLighting = (lighting) => {
    let design = { ...this.state.design }
    design.lighting = lighting
    this.setState(
      {
        design: design,
        step4Complete: isCeilingComplete(design),
      },
      function () {
        let renderLayers = buildRenderLayers(this.state.design)
        this.setState({
          renderLayers: renderLayers,
        })
        this.saveDesign()
      }.bind(this)
    )
  }

  applyLightTemperature = (value) => {
    let design = { ...this.state.design }
    design.lightTemperature = value
    this.setState(
      {
        design: design,
        step4Complete: isCeilingComplete(design),
      },
      function () {
        let renderLayers = buildRenderLayers(this.state.design)
        this.setState({
          renderLayers: renderLayers,
        })
        this.saveDesign()
      }.bind(this)
    )
  }

  applyCeilingMaterial = (material) => {
    let design = { ...this.state.design }
    design.ceiling.material = material
    design.ceiling.finish = material.finishes[0]
    this.setState(
      {
        design: design,
        step4Complete: isCeilingComplete(design),
      },
      function () {
        let renderLayers = buildRenderLayers(this.state.design)
        this.setState({
          renderLayers: renderLayers,
        })
        this.saveDesign()
      }.bind(this)
    )
  }

  applyCeilingFinish = (finish) => {
    let design = { ...this.state.design }
    design.ceiling.finish = finish
    this.setState(
      {
        design: design,
        step4Complete: isCeilingComplete(design),
      },
      function () {
        let renderLayers = buildRenderLayers(this.state.design)
        this.setState({
          renderLayers: renderLayers,
        })
        this.saveDesign()
      }.bind(this)
    )
  }

  applyFloor = (floor) => {
    let design = { ...this.state.design }
    design.floor = floor
    this.setState(
      {
        design: design,
      },
      function () {
        let renderLayers = buildRenderLayers(this.state.design)
        this.setState({
          renderLayers: renderLayers,
        })
        this.saveDesign()
      }.bind(this)
    )
  }

  initCeilingTab = (callback) => {
    let design = { ...this.state.design }
    design.lighting = Lighting[0]
    design.ceiling = {}
    design.ceiling.material = CeilingMaterials[0]
    design.ceiling.finish = CeilingMaterials[0].finishes[0]
    design.floor = Floors[8]
    this.setState(
      {
        design: design,
        step4Complete: isCeilingComplete(design),
      },
      function () {
        this.saveDesign()
        let renderLayers = buildRenderLayers(this.state.design)
        this.setState({
          renderLayers: renderLayers,
        })
        callback()
      }.bind(this)
    )
  }

  applyPanelFrameType = (type) => {
    let design = { ...this.state.design }
    design.panelFrameType = type
    this.setState(
      {
        design: design,
      },
      function () {
        this.saveDesign()
      }.bind(this)
    )
  }

  saveDesign = () => {
    var postDesign = readyDesignForPost(this.state.design, {
      id: this.props.designid,
      name: this.state.designName,
    })
    if (this.props.designid) {
      updateDesign(postDesign).catch((err) => {
        this.setState({
          error: err,
        })
      })
    }
  }

  updateDesignName = (name) => {
    this.setState(
      {
        designName: name,
      },
      function () {
        this.saveDesign()
      }.bind(this)
    )
  }

  handleDetailsFormUpdate = (additionalDetails) => {
    let design = { ...this.state.design }
    design.additionalDetails = additionalDetails
    this.setState(
      {
        design: design,
      },
      this.saveDesign()
    )
  }

  getCanvasImage = (imageUrl) => {
    if (this.state.canvasImage) {
      URL.revokeObjectURL(this.state.canvasImage)
    }

    this.setState({
      canvasImage: imageUrl,
    })
  }

  getLsbwrsImage = (imageUrl) => {
    if (this.state.lsbwrsImage) {
      URL.revokeObjectURL(this.state.lsbwrsImage)
    }
    this.setState({
      lsbwrsImage: imageUrl,
    })
  }

  downloadCanvasImage = () => {
    return this.state.canvasImage
  }

  lightboxCanvasPDF = () => {
    this.setState({
      pdfOpen: true,
    })
  }

  hasLightPlaneCompatible = () => {
    let returnVal = false
    this.state.design.primaryPanels.forEach(function (panel, panelIndex) {
      panel.panelCoords.forEach(function (item, subPanelIndex) {
        if (item.surface) {
          if (item.surface.lightplaneCompatible) {
            returnVal = true
          }
        }
      })
    })
    return returnVal
  }

  handleNavigate = (route, params) => {
    this.tabsElement.current.resetToFirstTab(
      function () {
        let designName = 'Elevator Design #' + this.state.designIndex + 1
        let newState = {
          configuration: {},
          configurationIds: {
            _id: null,
            nid: null,
          },
          designName: designName,
          projectId: null,
          designIndex: 0,
          renderLayers: {},
          renderShowText: true,
          renderShowLegends: false,
          renderFinalLegends: false,
          showViewDownloadButton: false,
          showMultiViewButton: false,
          activePanel: null,
          lightBoxOpen: false,
          pdfOpen: false,
          canvasImage: '',
          lsbwrsImage: '',
          submitDesignOpen: false,
          pdfFileData: '',
          step1Complete: false,
          step1OverrideImage: null,
          step2Complete: false,
          step3Complete: false,
          step4Complete: false,
          dualOpen: false,
          openWhiteLightWarning: false,
          newlyWhiteLight: false,
          showSwitchConfigWarning: false,
          newConfig: {},
          userInfo: null,
          error: '',
          disabledSurface: null,
          showDisabledSurfaceWarning: false,
        }
        this.setState(newState)
        navigate(route, params)
        this.loadDesign()
      }.bind(this)
    )
  }

  handleGetPdfData = (url) => {
    this.setState({
      pdfFileData: url,
    })
  }

  blobToFile = (theBlob, fileName) => {
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date()
    theBlob.name = fileName
    return theBlob
  }

  handleSubmitDesign = () => {
    this.setState({
      submitDesignOpen: true,
    })
  }

  render() {
    let viewDownloadButton = ''
    let advancedDownloadButton = ''
    let downloadButtonWrapper = ''
    let error = ''
    if (this.state.error) {
      error = (
        <ErrorMessage
          message={this.state.error.message}
          link="/profile"
          linkMessage="Go back to your profile"
        />
      )
    }
    if (this.state.showViewDownloadButton) {
      viewDownloadButton = (
        <button
          className={styles['design__download__button']}
          type="button"
          onClick={() => this.setState({ lightBoxOpen: true })}
        >
          <div>
            DOWNLOAD YOUR DESIGN
            <br />
            OR
            <br />
            SUBMIT YOUR DESIGN FOR ADVANCED DOWNLOAD
            <i className={styles['magnifier']}></i>
          </div>
        </button>
      )
      downloadButtonWrapper = (
        <div className={styles['design__download__button__wrapper']}>
          {viewDownloadButton}
          {advancedDownloadButton}
        </div>
      )
    }

    let rightPanel = (
      <div className="row">
        <NameEdit
          designName={this.state.designName}
          configuration={this.state.design}
          handleUpdate={this.updateDesignName}
        />
        <DesignRenderMulti
          configuration={this.state.renderLayers}
          reRender={true}
          standardImage={this.state.canvasImage}
          lsbwrsImage={this.state.lsbwrsImage}
          hideWatermark={!this.props.watermark}
          showMultiView={this.state.showMultiViewButton}
        />
        <div className="col-lg-12">
          <div className={styles['cab__wrapper']}>
            <Legends
              configuration={this.state.design}
              showText={this.state.renderShowText}
              showLegends={this.state.renderShowLegends}
              showHandrailLegends={this.state.renderShowHandrailLegends}
              showCeilingLegends={this.state.renderShowCeilingLegends}
              showFinalLegends={this.state.renderFinalLegends}
              framePanels={this.state.framePanels}
              activePanel={this.state.activePanel}
              onPanelChange={this.onPanelChange}
              handrailConflicts={this.state.handrailConflicts}
            />
            <DesignRender
              configuration={this.state.renderLayers}
              getCanvasImage={this.getCanvasImage}
              hideWatermark={!this.props.watermark}
            />
            {downloadButtonWrapper}
          </div>
        </div>
      </div>
    )
    let handrailSearch = null
    if (this.state.design.handrail) {
      handrailSearch = (
        <HandrailSearch
          configuration={this.state.design}
          applyHandrailLocation={this.applyHandrailLocation}
          applyHandrailSeries={this.applyHandrailSeries}
          applyHandrailMaterial={this.applyHandrailMaterial}
          applyStandoff={this.applyStandoff}
          applyStandoffMaterial={this.applyStandoffMaterial}
          applyCrashRail={this.applyCrashRail}
          applyCrashRailLocation={this.applyCrashRailLocation}
          dualOpen={this.state.dualOpen}
        />
      )
    }
    let ceilingFloorSearch = null
    ceilingFloorSearch = (
      <CeilingFloorSearch
        configuration={this.state.design}
        applyLighting={this.applyLighting}
        applyLightTemperature={this.applyLightTemperature}
        applyMaterial={this.applyCeilingMaterial}
        applyFinish={this.applyCeilingFinish}
        applyFloor={this.applyFloor}
      />
    )
    let reviewTab = (
      <ReviewTab
        configuration={this.state.design}
        openPDF={this.lightboxCanvasPDF}
        handleDetailsFormUpdate={this.handleDetailsFormUpdate}
        hasLightPlaneCompatible={this.hasLightPlaneCompatible}
        handleNavigate={this.handleNavigate}
        handleSubmitDesign={this.handleSubmitDesign}
      />
    )
    return (
      <>
        <Layout wrapperClass="design-page">
          {error}
          <Tabs
            className="designTabs"
            onTabChange={this.onTabChange}
            ref={this.tabsElement}
          >
            <div
              label="CONFIGURATIONS"
              id="configurations"
              complete={this.state.step1Complete}
              overrideImage={this.state.step1OverrideImage}
              active={true}
            >
              <Configurations
                data={this.props.data}
                userInfo={this.state.userInfo}
                changeOpening={this.changeOpening}
                design={this.state.design}
                onChange={this.onConfigChange}
                onConfigPreset={this.onConfigPreset}
              />
            </div>
            <div
              label="WALL PANELS"
              id="wall__panels"
              complete={this.state.step2Complete}
              active={this.state.step1Complete}
            >
              <PanelSelections
                configuration={this.state.design}
                framePanels={this.state.framePanels}
                frameType={this.state.design.panelFrameType}
                activePanel={this.state.activePanel}
                onChange={this.onPanelChange}
                selectSubPanels={this.selectSubPanels}
                applySurface={this.applySurface}
                applyPanelFrameType={this.applyPanelFrameType}
                ref={this.panelSelections}
                data={this.props.data}
              />
            </div>
            <div
              label="HANDRAILS"
              id="handrails"
              complete={this.state.step3Complete}
              completeCenterCheck={true}
              active={this.state.step2Complete}
            >
              {handrailSearch}
            </div>
            <div
              label="CEILINGS"
              id="ceilings"
              complete={this.state.step4Complete}
              completeCenterCheck={true}
              active={this.state.step3Complete}
            >
              {ceilingFloorSearch}
            </div>
            <div label="REVIEW" id="review" active={this.state.step4Complete}>
              {reviewTab}
            </div>
            <rightPanel>{rightPanel}</rightPanel>
          </Tabs>
          {this.state.lightBoxOpen && (
            <Lightbox
              reactModalStyle={{
                overlay: {
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                },
                content: {
                  color: 'lightsteelblue',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                },
                outer: {
                  color: '#ff0000',
                },
              }}
              mainSrc=<ImageView
                clickOutsideToClose={true}
                modal={true}
                downloadButton={true}
                downloadCanvasImage={this.downloadCanvasImage}
                downloadPDF={this.lightboxCanvasPDF}
                advancedDownload={this.handleSubmitDesign}
                closeLightbox={() => this.setState({ lightBoxOpen: false })}
                image={this.state.canvasImage}
              />
              onCloseRequest={() => this.setState({ lightBoxOpen: false })}
              enableZoom={false}
              clickOutsideToClose={true}
            />
          )}
          {this.state.pdfOpen && (
            <Lightbox
              mainSrc=<DesignRenderPDF
                userAttributes={this.state.userInfo}
                canvasRender={this.state.canvasImage}
                configuration={this.state.design}
                designName={this.state.designName}
                projectId={this.state.projectId}
                data={this.props.data}
                getPdfData={this.handleGetPdfData}
                hideWatermark={!this.props.watermark}
              />
              onCloseRequest={() => this.setState({ pdfOpen: false })}
              enableZoom={false}
              clickOutsideToClose={true}
            />
          )}
          {this.state.openWhiteLightWarning && (
            <Lightbox
              mainSrc=<div className={'lightboxWrapper'}>
                <div className={'deleteConfirm'}>
                  You have selected a LightPlane Panel with primarily white
                  glass. We have defaulted your ceiling to 5,000K to match our
                  standard LightPlane Panel color temperature. If you would like
                  to discuss a warmer color temperature for both the ceiling
                  lighting and LightPlane Panels, please contact your local
                  Territory Manager. You can contact us at{' '}
                  <a
                    href="mailto:sales@forms-surfaces.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    sales@forms-surfaces.com
                  </a>{' '}
                  or 800.451.0410.
                </div>
              </div>
              onCloseRequest={() =>
                this.setState(
                  { openWhiteLightWarning: false, newlyWhiteLight: true },
                  function () {
                    this.applyLightTemperature(5000)
                  }.bind(this)
                )
              }
              enableZoom={false}
              clickOutsideToClose={true}
            />
          )}
          {this.state.showSwitchConfigWarning && (
            <Lightbox
              mainSrc=<div className={'lightboxWrapper'}>
                <div className={'deleteConfirm'}>
                  <h2>WARNING</h2>
                  <p>
                    If you switch configurations while working within your
                    designated elevator interior, the material selections you've
                    already made will not be retained; you'll need to re-select
                    materials for your new design. Materials do not
                    automatically migrate from one configuration to another.
                  </p>
                  <p>
                    Click on PROCEED if you wish to select a new elevator
                    interior design. This operation cannot be undone. Click on
                    cancel if you wish to cancel.
                  </p>
                  <p>
                    Recommendation: Duplicate your current configuration if
                    you'd like to retain the same materials in other
                    configurations. You can then edit your duplicated
                    configuration to make changes.
                  </p>
                  <div className={'lightboxActions'}>
                    <button
                      onClick={() =>
                        this.setState({
                          showSwitchConfigWarning: false,
                        })
                      }
                    >
                      Cancel
                    </button>
                    <button onClick={this.changeToNewConfig}>Proceed</button>
                  </div>
                </div>
              </div>
              onCloseRequest={() =>
                this.setState({ showSwitchConfigWarning: false })
              }
              enableZoom={false}
              clickOutsideToClose={true}
            />
          )}
          {this.state.submitDesignOpen && (
            <Lightbox
              mainSrc=<DesignSubmit
                configuration={this.state.design}
                designName={this.state.designName}
                projectId={this.state.projectId}
                data={this.props.data}
                watermarkOnly={this.state.design.hidelsbwrs}
                onCloseButton={() => this.setState({ submitDesignOpen: false })}
              />
              onCloseRequest={() => this.setState({ submitDesignOpen: false })}
              enableZoom={false}
              clickOutsideToClose={true}
            />
          )}
          {this.state.showDisabledSurfaceWarning && (
            <Lightbox
              mainSrc=<div className={'lightboxWrapper'}>
                <div className={'deleteConfirm'}>
                  <h2>WARNING</h2>
                  <p>
                    Your design contains a surface or option that is
                    discontinued.
                    <strong>{this.state.disabledSurface.fullName}</strong> is no
                    longer available.
                  </p>
                  <p>Please check all of your selections before proceeding.</p>
                  <div className={'lightboxActions'}>
                    <button
                      onClick={() =>
                        this.setState({
                          showDisabledSurfaceWarning: false,
                        })
                      }
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
              onCloseRequest={() =>
                this.setState({ showDisabledSurfaceWarning: false })
              }
              enableZoom={false}
              clickOutsideToClose={true}
            />
          )}
          {this.state.showHandrailConflictWarning && (
            <Lightbox
            mainSrc=<div className={'lightboxWrapper'}>
              <div className={'deleteConfirm'}>
                <h2>WARNING</h2>
                <p>
                You have selected a Lightplane panel with a handrail. 
                We are not offering handrails on Lightplane panels. 
                Please replace the highlighted panels as a Non-Lightplane panel or remove the handrail from lightplane panels. 
                </p>
                <p>
                If you would like to discuss please contact your local Territory Manager. 
                You can contact us at <a href="mailto:sales@forms-surfaces.com">sales@forms-surfaces.com </a>or <a href="tel:1800451.410">800.451.0410</a>.
                </p>
                <div className={'lightboxActions'}>
                  <button
                    onClick={() =>
                      this.setState({
                        showHandrailConflictWarning: false,
                      })
                    }
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
            onCloseRequest={() =>
              this.setState({ showHandrailConflictWarning: false })
            }
            enableZoom={false}
            clickOutsideToClose={true}
          />
          )}
          <button
            style={{
              background: 'none',
              border: 'none',
              outline: 'none',
              textDecoration: 'underline',
              marginTop: '15px',
              marginLeft: '0px',
              paddingLeft: '0px',
              cursor: 'pointer',
            }}
            id="design-tips"
            onClick={(e) => {
              this.setState({
                openDesignTips: true,
              })
            }}
          >
            IMPORTANT DESIGN TIPS
          </button>
          {this.state.openDesignTips && (
            <Lightbox
              mainSrc=<div className={'lightboxWrapper'}>
                <div
                  style={{
                    width: '800px',
                    backgroundColor: '#ffffff',
                    margin: '0px auto',
                    padding: '25px',
                    position: 'relative',
                    fontSize: '0.8rem',
                    lineHeight: '1rem',
                  }}
                >
                  <strong>IMPORTANT DESIGN TIPS</strong>
                  <p>
                    The information below will help you make the most of the
                    Elevator Design Studio. Please help us create a better
                    resource by submitting additional questions or suggestions
                    to{' '}
                    <a href="mailto:eds@forms-surfaces.com">
                      eds@forms-surfaces.com
                    </a>
                    .
                  </p>
                  <ol>
                    <li>
                      <p>
                        <strong>
                          What’s the best way to navigate through the Elevator
                          Design Studio?
                        </strong>
                      </p>
                      <p>
                        Use the navigation tabs and prompts within the Elevator
                        Design Studio - they are designed to be visible,
                        intuitive and easy to use. Do not rely on your browser’s
                        forward and back arrows as they may compromise the
                        efficiency of the configuration process.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>
                          How similar will my actual elevator interior look
                          compared to the elevator interior built with Elevator
                          Design Studio?
                        </strong>
                      </p>
                      <p>
                        The elevator interiors created using the Elevator Design
                        Studio are intended as a visual guide. They are
                        renderings, not precise photographs. Because different
                        computers, monitors, and printers will render colors and
                        textures differently, actual surfaces and finishes will
                        vary slightly from those shown here.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>
                          The pattern on the swatch I chose is oriented
                          differently than it appears on the elevator panel. Can
                          I change the orientation of the pattern?
                        </strong>
                      </p>
                      <p>
                        Directional finishes and patterns are applied to
                        individual wall panels in the vertical/horizontal
                        orientation pre-determined for each elevator interior.
                        Many variations are currently beyond the capabilities of
                        the Elevator Design Studio. Please contact your F+S
                        Territory Manager for possible options. You can also
                        note your questions in the ‘Review’ section for your
                        project.
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>
                          Do I need to save my elevator interior as I configure
                          my design? If so, how?
                        </strong>
                      </p>
                      <p>
                        No, you do not need to save your design. Once you’ve
                        chosen a configuration, your design and material
                        selections will automatically be saved as you build your
                        elevator interior.
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
              onCloseRequest={() => this.setState({ openDesignTips: false })}
              enableZoom={false}
              clickOutsideToClose={true}
            />
          )}
        </Layout>
        <FeatureTip
          bigBox={true}
          title=""
          message=<IntroMessage />
          cookieName="new-3-view-options"
          closeButtonText="Okay"
        />
      </>
    )
  }
}

export default DesignEdit
