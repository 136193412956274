import React from 'react'
import styles from './panel-selections.module.css'

class FramePanels extends React.Component {
  constructor(props) {
    super(props)
  }

  onClick = (element) => {
    const { panel, onClick, panelType } = this.props
    element.type = panelType
    onClick(element)
  }

  render() {
    return (
      <>
        {this.props.panels.map((element, i) => {
          let className = styles['panel__selection'] + ' fadeHover'
          if (this.props.activePanel) {
            if (element.id === this.props.activePanel.id) {
              className += ' ' + styles['panel__active']
            }
            if (element?.type === 'panel-group') {
              element.panels.forEach((panel) => {
                if (panel.id === this.props.activePanel.id) {
                  className += ' ' + styles['panel__active']
                }
              })
            }
          }
          let designPanel = this.props.design.framePanels.find((item)=>{
            return item.id === element.id
          })
          if (element.applied || designPanel?.applied) {
            className += ' ' + styles['panel__applied']
          }
          if (element.dualOpenHide && this.props.dualOpen) {
            return null
          } else if (element?.type === 'panel-group') {
            let clickElement = element.panels.find(elem=>{
              return !(elem.dualOpenHide && this.props.dualOpen)
            })
            return (
              <button
                className={className}
                key={clickElement.id}
                onClick={(e) => this.onClick(clickElement)}
              >
                {clickElement.label}
              </button>
            )
          } else {
            return (
              <button
                className={className}
                key={element.id}
                onClick={(e) => this.onClick(element)}
              >
                {element.label}
              </button>
            )
          }
        })}
      </>
    )
  }
}

export default FramePanels
