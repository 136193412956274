import React from 'react'
import styles from './surfaces-search.module.css'
import { findSurface } from '../../../utils/design'
import { getFavorites } from '../../../utils/favorites'

class FavoriteItem extends React.Component {
  constructor(props){
    super(props)
    this.state={
      surface: null
    }
  }
  componentDidMount(){
    if(this.props.surface){
      if(this.props.surface.referenceId){
        let surface = findSurface(this.props.surface.referenceId, this.props.data.allMaterialType.nodes)
        this.setState({
          surface: surface
        })
      }
    }
  }
  onClick = () => {
    const { onClick } = this.props
    onClick(this.state.surface)
  }
  render(){
    let item = ''
    if(this.state.surface){
      item = <button key={this.state.surface.id} onClick={this.onClick} className={styles['material__selection'] + ' fadeHover'}>
        <img src={this.state.surface.surfacePreview.publicURL} alt={this.state.surface.fullName} title={this.state.surface.fullName} className={styles['search__image']} />
      </button>
    }
    return(
      <div>{item}</div>
    )
  }
}


class MyFavorites extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      favorites: [],
      activeMaterial: {},
      activeFavoriteGroup: {},
      activePattern: {}
    }
  }

  componentDidMount() {
    this.getTheFavorites()
  }

  getTheFavorites() {
    getFavorites().then((favorites)=>{
      this.setState({
        favorites: favorites
      })
    })
    .catch((err)=>{
      console.log(err)
    })
  }

  onPatternSelect = (pattern) => {
    const { onClickFavorite } = this.props
    onClickFavorite(pattern)
  }

  applySurface = surface => {
    const { applySurface } = this.props
    applySurface(surface)
  }

  onCloseButton = () => {
    const { onCloseButton } = this.props
    onCloseButton()
  }

  render(){
    let items = this.state.favorites.map(function(item, key){
      return(
        <FavoriteItem
          surface={item}
          onClick={this.onPatternSelect}
          key={key}
          data={this.props.data}
        />
      )
    }.bind(this))
    return (
      <div className={styles['search__panel']}>
        <p className={styles['search__panel__text']}>Save your favorite material and finish combinations to use again on future designs. Add swatches as you're working by using the star icon that appears on swatch close-up captions.</p>
        <div className={styles['search__my__favorites']}>
        {items}
        </div>
      </div>
    )
  }
}

export default MyFavorites
