import React from 'react'
import styles from '../panel-selections.module.css'

class SubPanel extends React.Component {

  componentDidMount() {

  }

  onClick = () => {
    const { index, active, setActiveSubPanel } = this.props
    setActiveSubPanel(index,!active)
  }

  render(){
    let className = styles['subpanel__selection'] + ' fadeHover'
    if(this.props.active){
    className += ' ' + styles['subpanel__active']
    }
    let style = {}
    if(this.props.subPanel.surface){
      let background = this.props.subPanel.surface.preview
      if(this.props.subPanel.surface.surfacePreview){
        background = this.props.subPanel.surface.surfacePreview.publicURL
      }
      style = { backgroundImage: `url(`+background+`)`, backgroundSize: 'cover', backgroundPosition: 'right center'}
    }
    let rotate = ''
    if(this.props.rotate){
      rotate = ' ' + styles['subpanel__image__rotate']
      if(this.props.subPanel.surface){
        if(this.props.subPanel.surface.dontRotate){
          rotate = ''
        }
      }
    }
    if(this.props.dualOpen){
      if(this.props.subPanel.dualOpenHide){
        return (null)
      }else{
        let label = this.props.label
        if(this.props.subPanel.legend.dualOpenLabel){
          label = this.props.subPanel.legend.dualOpenLabel
        }
        return (
          <button className={className} key={this.props.name} onClick={this.onClick} style={style}>
            <div className={styles['subpanel__image']+rotate} style={style} />
            <div className={styles['subpanel__label']}>{label}</div>
          </button>
        )
      }
    }else{
      return (
        <button className={className} key={this.props.name} onClick={this.onClick} style={style}>
          <div className={styles['subpanel__image']+rotate} style={style} />
          <div className={styles['subpanel__label']}>{this.props.label}</div>
        </button>
      )
    }
  }
}

export default SubPanel
