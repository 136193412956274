let VivistoneAbaloneOnyx = [
  {
    id: '5d7f94fa2332b358f2c57f79',
    nid: 195,
    name: 'Slab A-1',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-a-1/slab-a-1.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f94fbeef7b08841eae0ea',
        nid: 230,
        name: 'Pearlex',
        fullName:
          'ViviStone Abalone Onyx glass with slab A-1 interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-a-1/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-a-1/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-a-1/pearlex-full.jpg',
        finish: {
          id: '5d7f94fcae735591eeb3b3e0',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f94fd2df4fc42d05c3002',
        nid: 231,
        name: 'Standard',
        fullName:
          'ViviStone Abalone Onyx glass with slab A-1 interlayer and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-a-1/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-a-1/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-a-1/standard-full.jpg',
        finish: {
          id: '5d7f94fef687891e6a2b602c',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f94ff5cfac0d52e5d8c0c',
    nid: 240,
    name: 'Slab A-2',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-a-2/slab-a-2.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f9500ecce5a0dbd5cba35',
        nid: 2005,
        name: 'Pearlex',
        fullName:
          'ViviStone Abalone Onyx glass with slab A-2 interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-a-2/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-a-2/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-a-2/pearlex-full.jpg',
        finish: {
          id: '5d7f9501f198d64bf39a013c',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f95026b8a6c7cfefbd565',
        nid: 2006,
        name: 'Standard',
        fullName:
          'ViviStone Abalone Onyx glass with slab A-2 interlayer and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-a-2/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-a-2/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-a-2/standard-full.jpg',
        finish: {
          id: '5d7f950315c8373b815d7022',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f950402bb3171a11bd806',
    nid: 241,
    name: 'Slab A-3',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-a-3/slab-a-3.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f950531687e31953e02a3',
        nid: 2007,
        name: 'Pearlex',
        fullName:
          'ViviStone Abalone Onyx glass with slab A-3 interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-a-3/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-a-3/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-a-3/pearlex-full.jpg',
        finish: {
          id: '5d7f9506f0b63d6defca659f',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f950743fddab301797859',
        nid: 2010,
        name: 'Standard',
        fullName:
          'ViviStone Abalone Onyx glass with slab A-3 interlayer and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-a-3/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-a-3/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-a-3/standard-full.jpg',
        finish: {
          id: '5d7f95085f6a751a85468d87',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f95090479e82732fb6e1c',
    nid: 243,
    name: 'Slab B-4',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-b-4/slab-b-4.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f950a17a95f00dd5e17a7',
        nid: 2011,
        name: 'Pearlex',
        fullName:
          'ViviStone Abalone Onyx glass with slab B-4 interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-b-4/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-b-4/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-b-4/pearlex-full.jpg',
        finish: {
          id: '5d7f950bdc9b6a3cbdc393e3',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f950c2cd21de125464b8e',
        nid: 2012,
        name: 'Standard',
        fullName:
          'ViviStone Abalone Onyx glass with slab B-4 interlayer and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-b-4/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-b-4/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-b-4/standard-full.jpg',
        finish: {
          id: '5d7f950df8e9f39f4afd4879',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f950e93c6223e06a2e90b',
    nid: 244,
    name: 'Slab B-5',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-b-5/slab-b-5.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f950f1772da57b80ca0df',
        nid: 2013,
        name: 'Pearlex',
        fullName:
          'ViviStone Abalone Onyx glass with slab B-5 interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-b-5/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-b-5/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-b-5/pearlex-full.jpg',
        finish: {
          id: '5d7f951002402f89da069bcf',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f95111c3329f38af7e643',
        nid: 2014,
        name: 'Standard',
        fullName:
          'ViviStone Abalone Onyx glass with slab B-5 interlayer and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-b-5/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-b-5/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-b-5/standard-full.jpg',
        finish: {
          id: '5d7f951288437d69d9b116d7',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f951353dc3328e8cc15f8',
    nid: 245,
    name: 'Slab B-6',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-b-6/slab-b-6.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f9514ab474e987b466bfe',
        nid: 2015,
        name: 'Pearlex',
        fullName:
          'ViviStone Abalone Onyx glass with slab B-6 interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-b-6/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-b-6/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-b-6/pearlex-full.jpg',
        finish: {
          id: '5d7f9515ce0bb7fb527a83e8',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f95168237a592eb55a820',
        nid: 2016,
        name: 'Standard',
        fullName:
          'ViviStone Abalone Onyx glass with slab B-6 interlayer and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-b-6/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-b-6/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-b-6/standard-full.jpg',
        finish: {
          id: '5d7f9517f3670773fe8feefb',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f9518214e6c07ed6764d0',
    nid: 246,
    name: 'Slab C-7',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-c-7/slab-c-7.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f95192afaec620e4eb22e',
        nid: 2017,
        name: 'Pearlex',
        fullName:
          'ViviStone Abalone Onyx glass with slab C-7 interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-c-7/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-c-7/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-c-7/pearlex-full.jpg',
        finish: {
          id: '5d7f951a73873505b1640636',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f951b5aa373cdaf0aa0aa',
        nid: 2018,
        name: 'Standard',
        fullName:
          'ViviStone Abalone Onyx glass with slab C-7 interlayer and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-c-7/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-c-7/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-c-7/standard-full.jpg',
        finish: {
          id: '5d7f951c91bc180a4ce02346',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f951db695875c38d38829',
    nid: 247,
    name: 'Slab C-8',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-c-8/slab-c-8.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f951e166687fd03d67ead',
        nid: 2019,
        name: 'Pearlex',
        fullName:
          'ViviStone Abalone Onyx glass with slab C-8 interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-c-8/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-c-8/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-c-8/pearlex-full.jpg',
        finish: {
          id: '5d7f951f1189c38f37164549',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f9520802eef7a720755de',
        nid: 2020,
        name: 'Standard',
        fullName:
          'ViviStone Abalone Onyx glass with slab C-8 interlayer and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-c-8/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-c-8/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-c-8/standard-full.jpg',
        finish: {
          id: '5d7f9521a95600a3460c372c',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f952213103a7b9e70dfb8',
    nid: 248,
    name: 'Slab C-9',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-c-9/slab-c-9.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f9523cba713ad38a60a88',
        nid: 2021,
        name: 'Pearlex',
        fullName:
          'ViviStone Abalone Onyx glass with slab C-9 interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-c-9/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-c-9/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-c-9/pearlex-full.jpg',
        finish: {
          id: '5d7f95247aba01cc2f57e726',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f95251a7864a168601e4d',
        nid: 2022,
        name: 'Standard',
        fullName:
          'ViviStone Abalone Onyx glass with slab C-9 interlayer and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-c-9/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-c-9/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-abalone-onyx/slab-c-9/standard-full.jpg',
        finish: {
          id: '5d7f9526eb6340c00735c809',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
]

export default VivistoneAbaloneOnyx
