import React, { Component } from 'react'
import styles from './config-select.module.css'
import ConfigFamily from './config-family'
class ConfigSelect extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeConfigFamily: '-99',
      configPresets: [],
    }
  }

  componentDidMount() {}

  onClickItem = (id) => {
    if (id) {
      this.setState({ activeConfigFamily: id })
      let activeConfigFamily = this.props.data.allConfigurationFamily.nodes.find(
        (element) => {
          return element._id === id
        }
      )
      let activeConfig = this.props.data.allConfiguration.nodes.find(
        (element) => {
          return element._id === activeConfigFamily.configurations[0].id.id
        }
      )
      if (activeConfig && activeConfigFamily) {
        this.props.onChange(activeConfigFamily, activeConfig)
      }
    }
  }

  onClickConfig = (configFamilyId, configId) => {
    this.setState({
      activeConfigFamily: configFamilyId,
    })
    let activeConfigFamily = this.props.data.allConfigurationFamily.nodes.find(
      (element) => {
        return element._id === configFamilyId
      }
    )
    let activeConfig = this.props.data.allConfiguration.nodes.find(
      (element) => {
        return element._id === configId
      }
    )
    if (activeConfig && activeConfigFamily) {
      this.props.onChange(activeConfigFamily, activeConfig)
    }
  }

  render() {
    let activeConfig = this.props.design?.configuration?.id
    if (this.props.design?.configuration?._id) {
      activeConfig = this.props.design?.configuration?._id
    }
    let family = this.props.data.allConfigurationFamily.nodes.filter(
      (family) => {
        return family.name.includes(this.props.familyName)
      }
    )
    let items = family.map(
      function (item, key) {
        let configIds = item.configurations.map((ids) => {
          return ids.id.id
        })
        //filter configs to the family
        let configs = this.props.data.allConfiguration.nodes.filter(
          (configuration) => {
            return configIds.includes(configuration._id)
          }
        )
        configs = configs.sort((a, b) => {
          if (a.name < b.name) {
            return -1
          } else if (a.name > b.name) {
            return 1
          }
          return 0
        })
        let isActive = configIds.includes(activeConfig)
        return (
          <ConfigFamily
            id={item._id}
            isActive={isActive}
            index={key}
            key={item._id}
            onClick={this.onClickItem}
            onClickConfig={this.onClickConfig}
            activeConfig={activeConfig}
            name={item.name}
            image={item.thumbnailImage.publicURL}
            configurations={configs}
            design={this.props.design}
            changeOpening={this.props.changeOpening}
          />
        )
      }.bind(this)
    )
    return (
      <>
        <div className={styles['config__select']}>{items}</div>
      </>
    )
  }
}

export default ConfigSelect
