import React from 'react'
import styles from './surfaces-search.module.css'
import SearchRow from './search-row'
import SearchPreview from './search-preview'
import {findSurface} from '../../../utils/design'
import LightplaneSelections from './lightplane-selections'
import CeramilocSelections from './ceramiloc-selections'

class FavoriteSearch extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeFavorite: null,
      activeFavoriteGroup: props.favoriteGroup,
      activeMaterial: {},
      activeFinish: {},
      activePattern: {},
      lightplaneActive: false,
      ceramilocActive: false
    }
  }

  componentDidMount() {
    var favoriteGroup = this.props.favoriteGroup
    for(var i = 0; i < favoriteGroup.surfaces.length; i++){
      favoriteGroup.surfaces[i] = findSurface(favoriteGroup.surfaces[i].id, this.props.data.allMaterialType.nodes)
    }
    let lightplaneActive = this.hasLightPlane()
    let ceramilocActive = this.hasCeramiloc()
    this.setState({
      activeFavorite: this.props.activeFavorite,
      activeFavoriteGroup: this.props.favoriteGroup,
      lightplaneActive: lightplaneActive,
      ceramilocActive: ceramilocActive
    })
  }

  onMaterialSelect = material => {
    if(material.finishes){
      if(material.finishes[0].patterns[0]){
        let finish = material.finishes[0]
        let pattern = material.finishes[0].patterns[0]
        if(!pattern.finish){
          pattern.finish = {
            id: finish._id,
            name: finish.name,
            nid: finish.nid
          }
        }
        this.setState({
          activeMaterial: material,
          activeFinish: finish,
          activePattern: pattern
        })
      }
    }
  }

  onFinishSelect = finish => {
    if(finish.patterns){
      let pattern = finish.patterns[0]
      if(!pattern.finish){
        pattern.finish = {
          id: finish._id,
          name: finish.name,
          nid: finish.nid
        }
      }
      this.setState({
        activeFinish: finish,
        activePattern: finish.patterns[0]
      })
    }
  }

  onPatternSelect = pattern => {
    var surface = findSurface(pattern.id, this.props.data.allMaterialType.nodes)
    this.setState({
      activeFavorite: surface
    })
  }

  onCloseButton = () => {
    const { onCloseButton } = this.props
    onCloseButton()
  }

  applySurface = surface => {
    const { applySurface } = this.props
    applySurface(surface, this.state.lightplaneActive, this.state.ceramilocActive)
  }

  hasLightPlane = () => {
    let lightplane = false
    if(this.props.activePanel){
      this.props.activePanel.panelCoords.forEach((coord, i) => {
        if(coord.lightplane && coord.active){
          lightplane = true
        }
      })
    }
    return lightplane
  }


  hasCeramiloc = () => {
    let ceramiloc = false
    if(this.props.activePanel){
      this.props.activePanel.panelCoords.forEach((coord, i) => {
        if(coord.ceramiloc && coord.active){
          ceramiloc = true
        }
      })
    }
    return ceramiloc
  }

  shouldRotateThumb = () => {
    var shouldRotate = false
    if(this.props.activePanel){
      if(this.props.activePanel.panelCoords){
        this.props.activePanel.panelCoords.forEach(function(coords){
          if(coords.lsbdrs[0].rotateSurface){
            shouldRotate = true
            return shouldRotate
          }
        })
      }
    }
    return shouldRotate
  }

  applyLightPlane = (lightplane) => {
    this.setState({
      lightplaneActive: lightplane,
      ceramilocActive: false
    })
  }

  applyCeramiloc = (ceramiloc) => {
    this.setState({
      ceramilocActive: ceramiloc,
      lightplaneActive: false,
    })
  }

  render(){
    let favorites = ''
    let preview = ''
    let surfaces = []
    let groupName = ''
    let rotate = this.shouldRotateThumb()
    if(this.state.activeFavoriteGroup){
      surfaces = this.state.activeFavoriteGroup.surfaces
      groupName = this.state.activeFavoriteGroup.name
    }
    let lightplaneSelections = null
    let ceramilocSelections = null
    if(this.state.activeFavorite){
      favorites = <SearchRow
        objects={surfaces}
        activeObject={this.state.activeFavorite}
        objectType={groupName}
        onClick={this.onPatternSelect}
        usePreview={true}
      />
      preview = <SearchPreview
        object={this.state.activeFavorite}
        applySurface={this.applySurface}
      />

      if(this.state.activeFavorite.lightplaneCompatible){
        lightplaneSelections = <LightplaneSelections
          activeObject={this.state.activeFavorite}
          applyLightPlane={this.applyLightPlane}
          lightplaneActive={this.state.lightplaneActive}
          activePanel={this.props.activePanel}
          rotate={rotate}
        />
      }

      if(this.state.activeFavorite.ceramilocCompatible){
        ceramilocSelections = <CeramilocSelections
          activeObject={this.state.activeFavorite}
          applyCeramiloc={this.applyCeramiloc}
          ceramilocActive={this.state.ceramilocActive}
          activePanel={this.props.activePanel}
          rotate={rotate}
        />
      }
    }
    return (
      <div className={styles['material__search__submaterials']}>
        <button title="Close" className={styles['materal__search__preview__close']} onClick={this.onCloseButton}>×</button>
        {preview}
        {favorites}
        {lightplaneSelections}
        {ceramilocSelections}
      </div>
    )
  }
}

export default FavoriteSearch
