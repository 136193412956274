import React from 'react'
import styles from './surfaces-search.module.css'

class SearchItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {

  }

  onClick = () => {
    const { onClick } = this.props
    onClick(this.props.object)
  }

  render(){
    let className = styles['material__search__submaterial']
    if(this.props.object === this.props.activeObject){
      className += ' ' + styles['material__search__active']
    }
    let key = ''
    let image = ''
    let name = ''
    let rotate = ''
    if(this.props.object){
      image = this.props.object.swatch
      if(this.props.object.surfaceSwatch){
        image = this.props.object.surfaceSwatch.publicURL
      }
      if(this.props.object.subMaterialSwatch){
        image = this.props.object.subMaterialSwatch.publicURL
      }
      if(this.props.object.materialSwatch){
        image = this.props.object.materialSwatch.publicURL
      }
      if(this.props.object.swatchImage){
        image = this.props.object.swatchImage.publicURL
      }
      key = this.props.object.id
      name = this.props.object.name
      if(this.props.object.fullName){
        name=this.props.object.fullName
      }
    }
    if(this.props.usePreview){
      if(this.props.object){
        if(this.props.object.surfacePreview){
          image = this.props.object.surfacePreview.publicURL
        }else if(this.props.previewImage){
          image = this.props.object.previewImage.publicURL
        }else{
          image = this.props.object.preview
        }
      }
    }
    // don't rotate no pattern thumbs
    let noPattern = ''
    if(this.props.primaryPanel){
      if(this.props.rotate && (this.props.object.name !== 'No Pattern') && !this.props.object.dontRotate){
        rotate = ' ' + styles['rotate__item']
      }
      if(this.props.object.name === 'No Pattern'){
         noPattern = ' ' + styles['search__image__none']
      }
    }
    let favorite = null
    if(this.props.isFavorite){
      favorite = <i className="las la-star"></i>
    }
    return (
      <button disabled={this.props.disabled} className={className + ' fadeHover' + noPattern} key={key} onClick={this.onClick}>
        <img src={image} alt={name} title={name} className={styles['search__image'] + rotate} />
        {favorite}
      </button>
    )
  }

}

export default SearchItem
