let MirroredGlassClear = [
  {
    id: '5d7f90b98f22ac0667eae025',
    nid: 338,
    name: 'Custom Surface',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/mirrored-glass-clear/custom-surface/custom-surface.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f90bab56f5089eba82133',
        nid: 22695,
        name: 'Standard',
        fullName: 'Mirrored Glass, Clear',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/mirrored-glass-clear/custom-surface/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/mirrored-glass-clear/custom-surface/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/mirrored-glass-clear/custom-surface/standard-full.jpg',
        finish: {
          id: '5d7f90bbd1409c54b6a17933',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.24
          },
        ]
      },
    ],
  },
]

export default MirroredGlassClear
