import React from 'react'
import styles from './surfaces-search.module.css'

class LightplaneSelections extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  onClick = object => {
    const { onClick } = this.props
    onClick(object)
  }

  apply = (isLightplane) => {
    this.props.applyLightPlane(isLightplane)
  }

  render(){
    let searchItems = ''
    let centerStyle = ' '
    let noneClassName = styles['material__search__submaterial']
    let lightplaneClassName = styles['material__search__submaterial']
    let image = ''
    if(this.props.activeObject.surfaceSwatch){
      image = this.props.activeObject.surfaceSwatch.publicURL
    }
    let name = this.props.activeObject.fullName
    let rotate = ''
    let illuminationName = 'None'
    if(this.props.lightplaneActive){
      lightplaneClassName += ' ' + styles['material__search__active']
      illuminationName = 'Lightplane'
    }else{
      noneClassName += ' ' + styles['material__search__active']
    }
    if(this.props.rotate && !this.props.activeObject.dontRotate){
      rotate = ' ' + styles['rotate__item']
    }
    searchItems = <div>
      <button className={noneClassName + ' fadeHover ' + styles['search__image__none']} onClick={(e)=>{this.apply(false)}}>
      </button>
      <button className={lightplaneClassName + ' fadeHover ' + styles['lightplane']} onClick={(e)=>{this.apply(true)}}>
        <img src={image} alt={name} title={name} className={styles['search__image'] + rotate} />
      </button>
    </div>
    return (
      <div className={styles['search__group']}>
        <h3 className={styles['material__search__title']}><span>Panel Illumination: </span>{illuminationName}</h3>
        <div className={styles['search__group__items'] + centerStyle}>
          {searchItems}
        </div>
      </div>
    )
  }

}

export default LightplaneSelections
