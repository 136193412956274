import React from 'react'
import styles from './panel-selections.module.css'

class FrameSubPanels extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  onClick = (element) => {
    const { onClick, design } = this.props
    let panelElement = design?.framePanels.find((item) => {
      return item.id === element.id
    })
    if (panelElement) {
      onClick(panelElement)
    }
  }

  render() {
    let className = styles['subpanel__selection'] + ' fadeHover'
    if (this.props.active) {
      className += ' ' + styles['subpanel__active']
    }
    let panels = []
    if (this.props.activePanel?.parent) {
      let parent = this.props.framePanels.find((object) => {
        return object.id === this.props.activePanel.parent
      })
      if (parent) {
        panels = parent.panels
      }
    }

    return (
      <div className={styles['subpanel__selections']}>
        {panels.map((panel) => {
          let panelClass = className

          let framePanel = this.props.design.framePanels.find((item) => {
            return item.id === panel.id
          })
          if (framePanel.id === this.props.activePanel.id) {
            panelClass += ' ' + styles['subpanel__active']
          }
          framePanel.parent = this.props.activePanel.parent
          if ((this.props.dualOpen && framePanel.dualOpenHide)) {
            return null
          } else {
            return (
              <button
                className={panelClass}
                key={framePanel.name}
                onClick={(e) => this.onClick(framePanel)}
              >
                <div className={styles['subpanel__image']} />
                <div className={styles['subpanel__label']}>
                  {this.props.dualOpen
                    ? framePanel.legends[0]?.dualOpenLabel
                    : framePanel.legends[0]?.label}
                </div>
              </button>
            )
          }
        })}
      </div>
    )
  }
}

export default FrameSubPanels
