import React from 'react'
import { Link } from 'gatsby'
import {getProject} from '../../../utils/project'
import styles from './name-edit.module.css'

class NameEdit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showForm: false,
      name: '',
      projectName: '',
      designName: this.props.designName
    }
  }

  componentDidMount() {

  }

  componentDidUpdate(){
    if(this.props.configuration.project && !this.state.projectName){
      getProject(this.props.configuration.project)
      .then(function(project){
        this.setState({
          projectName: project.name,
          designName: this.props.designName
        })
      }.bind(this))
      .catch(function(err){
        console.log(err)
      })
    }
  }

  showForm = () =>{
    this.setState({
      showForm: true
    })
  }

  saveName = (name) => {
    this.setState({
      showForm: false
    }, function(){
      this.props.handleUpdate(this.state.designName)
    })
  }

  handleUpdate = (event) => {
    this.setState({
      designName: event.target.value
    })
  }

  render(){
    let textvisible = ''
    let formvisible = 'hidden'
    if(this.state.showForm){
      textvisible = 'hidden'
      formvisible = ''
    }
    let submitDisabled = true
    if(this.state.designName){
      submitDisabled = false
    }
    return (
      <div className={styles['name__edit']}>
        <div className={styles['project__name']}>
          <Link to={'/project/'+this.props.configuration.project}>{this.state.projectName}</Link>
        </div>
        <div className={styles['design__name'] + ' ' + styles[textvisible]}>
          <div className={styles['design__name_text']}>{this.props.designName}</div>
          <button className={styles['design__name__edit__button']}
            onClick={this.showForm}>(edit)</button>
        </div>
        <div className={styles['design__name__form'] + ' ' +
          styles[formvisible]}>
          <input type="text" value={this.state.designName} onChange={e => this.handleUpdate(e)} />
          <input className={styles['design__name__form__submit']}
          type="submit" value="Save" onClick={this.saveName}
          disabled={submitDisabled}
          />
        </div>
      </div>
    )
  }
}

export default NameEdit
