import React from 'react'
import styles from './surfaces-search.module.css'
import SearchItem from './search-item'
import { getFavorites } from '../../../utils/favorites'

class SearchRow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      favorites: []
    }
  }

  componentDidMount() {
    this.getTheFavorites()
  }

  getTheFavorites() {
    getFavorites().then((favorites)=>{
      this.setState({
        favorites: favorites
      })
    })
    .catch((err)=>{
      console.log(err)
    })
  }

  onClick = object => {
    const { onClick } = this.props
    onClick(object)
  }

  render(){
    let searchItems = ''
    let centerStyle = ' '
    let usePreview = this.props.usePreview
    let note = null
    if(this.props.objects){
      searchItems = this.props.objects.map(function(item, key){
        if(item){
          let isFavorite = false
          if(item._id){
            isFavorite = this.state.favorites.find(function(favorite){
              return favorite.referenceId === item._id
            })
          }
          let disabled = false
          if(item.dualOpenDisabled && this.props.dualOpen){
            disabled = true
          }
          if(item.disabled){
            disabled = true
          }
          if(this.props.disableAll){
            disabled = true
          }
          if(item.note){
            if(item.note.message){
              note = <div className={styles['search__item__note']}
                dangerouslySetInnerHTML={{
                  __html: item.note.message,
                }}
              />
            }
          }
          if(item.hidden || disabled){
            return(null)
          }else{
            return(
              <SearchItem
                disabled={disabled}
                object={item}
                activeObject={this.props.activeObject}
                onClick={this.onClick}
                usePreview={usePreview}
                rotate={this.props.rotate}
                key={key}
                primaryPanel={this.props.primaryPanel}
                isFavorite={isFavorite}
              />
            )
          }
        }
      }.bind(this))
    }
    if(this.props.centerThumbs){
      centerStyle += styles.center__thumbs
    }
    let searchHidden = ''
    if(this.props.hideSearchRow){
      searchHidden = ' hidden'
    }
    let activeName = this.props.activeObject?.name
    if(this.props.overrideName){
      activeName = this.props.overrideName
    }
    return (
      <div className={styles['search__group']+searchHidden}>
        <h3 className={styles['material__search__title']}><span>{this.props.objectType}: </span>{activeName}</h3>
        <div className={styles['search__group__items'] + centerStyle}>
          {searchItems}
          {note}
        </div>
      </div>
    )
  }

}

export default SearchRow
