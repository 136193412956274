import React from 'react'
import handrails from '../../../../objects/handrails/data/series'
import handrailLocations from '../../../../objects/handrails/data/locations'
import crashrails from '../../../../objects/crash-rails/data/crash-rails'
import crashrailLocations from '../../../../objects/crash-rails/data/locations'

import SearchRow from '../SurfacesSearch/search-row'
import HandrailPreview from './HandrailPreview'
import styles from '../SurfacesSearch/surfaces-search.module.css'

class HandrailSearchPanel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showText: false
    }
  }

  showText = (e) => {
    e.preventDefault()
    this.setState({
      showText: true
    })
  }

  render(){
    let locations = null
    let filteredHandrails = handrails
    let filteredLocations = handrailLocations

    if(this.props.configuration?.handrailsAllowed?.length > 0){
      let configurationHandrailsAllowed = this.props.configuration.handrailsAllowed
      if(this.props.dualOpen){
        configurationHandrailsAllowed = configurationHandrailsAllowed.filter(handrail=>{
          if(!handrail.locationsAllowed){
            return true
          }
          let locationsAllowed = handrail.locationsAllowed.join(',')
          if(locationsAllowed === 'one-handrail-back-wall,no-handrails'){
            return false
          }else{
            return true
          }
        })
      }
      let handrailsAllowed = configurationHandrailsAllowed.map(handrail=>handrail.seriesId)
      filteredHandrails = handrails.filter(handrail => handrailsAllowed.includes(handrail.nid) )
      if(this.props.configuration.handrail){
        let handrailAllowed = this.props.configuration.handrailsAllowed.find(handrail=>handrail.seriesId === this.props.configuration.handrail.nid)
        if(handrailAllowed?.locationsAllowed?.length > 0){
          filteredLocations = handrailLocations.filter(location => handrailAllowed.locationsAllowed.includes(location.id))
        }
      }
    }
    if(this.props.configuration.nid <= 40060){
      filteredHandrails = handrails.filter(handrail => handrail.nid !== 99)
    }
    locations = <SearchRow
      objects={filteredLocations}
      dualOpen={this.props.dualOpen}
      activeObject={this.props.configuration.handrail_location}
      objectType='Handrail Location'
      onClick={this.props.applyHandrailLocation}
    />
    let series = null
    series = <SearchRow
     objects={filteredHandrails}
     activeObject={this.props.configuration.handrail}
     objectType='Handrail Series'
     onClick={this.props.applyHandrailSeries}
    />
    let railMaterial = null
    if(this.props.configuration.handrail.materials.length > 0){
      railMaterial = <SearchRow
        objects={this.props.configuration.handrail.materials}
        activeObject={this.props.configuration.handrail.selectedMaterial}
        objectType='Rail Material & Finish'
        onClick={this.props.applyHandrailMaterial}
      />
    }
    let standoff = null
    standoff = <SearchRow
      objects={this.props.configuration.handrail.standoffs}
      activeObject={this.props.configuration.handrail.selectedStandoff}
      objectType='Standoff Design'
      onClick={this.props.applyStandoff}
    />
    let standoffMaterial = null
    standoffMaterial = <SearchRow
      objects={this.props.configuration.handrail.selectedStandoff.materials}
      activeObject={this.props.configuration.handrail.selectedStandoff.selectedMaterial}
      objectType='Standoff Material & Finish'
      onClick={this.props.applyStandoffMaterial}
    />
    let preview = <HandrailPreview
      configuration={this.props.configuration}
    />
    let crashRailSelect = null
    crashRailSelect = <SearchRow
      objects={crashrails}
      activeObject={this.props.configuration.crashrail}
      objectType='Crash Rail'
      onClick={this.props.applyCrashRail}
    />
    let crashRailLocationsSelect = null
    crashRailLocationsSelect = <SearchRow
      objects={crashrailLocations}
      dualOpen={this.props.dualOpen}
      activeObject={this.props.configuration.crashrailLocation}
      objectType='Crash Rail Location'
      disableAll={(this.props.configuration.crashrail.id === 'no-crashrail' ? true : false)}
      onClick={this.props.applyCrashRailLocation}
    />
    let notesStyle = {
      padding: '20px',
      clear: 'both',
      float: 'none',
      textTransform: 'uppercase',
      fontWeight: 400
    }
    let notes = <div style={notesStyle}><p className={styles['search__panel__text']}>For handrails that span multiple panels, Compass handrails with Cylinder or Bullet standoffs or Quadrant handrails with any standoff are recommended. For elevator door openings that are flush with the adjacent side wall(s), a Round handrail with radius bend ends is recommended. <a href="/" className={this.state.showText ? styles['hidden'] : ''} onClick={this.showText}>...</a><span className={this.state.showText ? '' : styles['hidden']}> When using VividGlass for a panel that incorporates a handrail, an escutcheon will be required at each standoff for most handrails; exceptions include Compass handrails with Cylinder or Bullet standoffs or Quadrant handrails with any standoff.</span></p></div>
    return (
      <div>
        {preview}
        {locations}
        {series}
        {railMaterial}
        {standoff}
        {standoffMaterial}
        {crashRailSelect}
        {crashRailLocationsSelect}
        {notes}
      </div>
    )
  }
}

export default HandrailSearchPanel
