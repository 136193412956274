import React from 'react'
import styles from './imageview.module.css'

class ImageView extends React.Component {
  componentDidMount() {

  }

  downloadImage = (el) => {
    el.preventDefault()
    if(this.props.downloadCanvasImage){
      var url = this.props.downloadCanvasImage()
      el.href = url

      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = 'elevator-design.jpg';
      a.click();
    }
  }

  downloadPDF = (el) =>{
    el.preventDefault()
    if(this.props.downloadPDF){
      this.props.downloadPDF()
      this.props.closeLightbox()
    }
  }

  advancedDownload = (el) => {
    el.preventDefault()
    if(this.props.advancedDownload){
      this.props.advancedDownload()
      this.props.closeLightbox()
    }
  }

  render() {
    return(
      <div className={this.props.modal ? styles['canvas__modal__wrap'] : ''} style={{justifyContent: 'center', height: '100%', display: 'flex'}}>
        <div className={styles['modal_inner']} style={{maxWidth: '500px', display: 'flex', background: 'transparent', justifyContent: 'center', alignItems: 'center'}}>
          <div style={{display: 'flex', flexDirection: 'row', gap: '1rem', padding: '1rem', backgroundColor: '#fff'}}>
            <img className={styles['canvas__image']} style={{width: '50%', height: 'auto', margin: '0px'}} src={this.props.image} alt="design" />
            <div className={styles['canvas_actions']} style={{display: 'flex', flexDirection: 'column', width: '100%', gap: '1rem', alignItems: 'center', justifyContent: 'center'}}>
              {this.props.downloadPDF ? <a href="/" alt="download overview PDF" onClick={(el) => this.downloadPDF(el)} className={styles['canvas__download']}>DOWNLOAD OVERVIEW PDF</a> : ''}
              {this.props.downloadButton ? <a href="/" alt="download design" download="eds-design.jpg" onClick={(el) => this.downloadImage(el)} className={styles['canvas__download']}>DOWNLOAD JPG</a> : ''}
              {this.props.advancedDownload ? <a href="/" alt="submit advanced download" onClick={(el) => this.advancedDownload(el)} className={styles['canvas__download']}>SUBMIT ADVANCED DOWNLOAD</a> : ''}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ImageView
