import React from 'react'
import styles from './surfaces-search.module.css'
import { findPrimarySurface } from '../../../utils/design'
import MaterialGroup from './material-group'
import MaterialSearch from './material-search'
import Tabs from './Tabs'
import ConfigFavorites from './config-favorites'
import FavoriteSearch from './favorite-search'
import MyFavorites from './my-favorites'
import MyFavoriteSearch from './my-favorite-search'
class SurfacesSearch extends React.Component {
  constructor(props) {
    super(props)
    this.materialsearchElement = React.createRef()
    this.tabsElement = React.createRef()
    this.state = {
      materials: this.props.data.allMaterialType.nodes,
      showMaterialSearch: false,
      activeMaterialGroup: {},
      activeMaterial: {},
      activeFavorite: {},
      materialsAllowed: null,
      materialsExcluded: [],
      showFavoriteSearch: false,
      activeFavoriteGroup: {},
      activeMyFavorite: {},
      showMyFavoriteSearch: false
    }
  }

  componentDidMount() {
    if(this.props.activePanel){
      this.setState({
        showMaterialSearch: this.props.showMaterialSearch,
        materialsAllowed: this.props.activePanel.materialsAllowed,
        materialsExcluded: this.props.activePanel.materialsExcluded
      })
    }
  }

  onClickItem = (materialGroup, subMaterial) => {
    this.setState({
      activeMaterialGroup: materialGroup,
      activeMaterial: subMaterial,
      showMaterialSearch: true,
    })
  }

  onClickFavorite = (surface, favoriteGroup) => {
    this.setState({
      activeFavorite: surface,
      activeFavoriteGroup: favoriteGroup,
      showFavoriteSearch: true,
    })
  }

  onClickMyFavorite = (surface) => {
    this.setState({
      activeMyFavorite: surface,
      showMyFavoriteSearch: true,
    })
  }

  onCloseButton = () => {
    this.setState({
      showMaterialSearch: false,
    })
  }

  onCloseFavorites = () => {
    this.setState({
      showFavoriteSearch: false,
    })
  }

  onCloseMyFavorites = () => {
    this.setState({
      showMyFavoriteSearch: false,
    })
  }

  resetActiveMaterial = () => {
    this.setState({
      activeMaterial: {},
      activeMaterialGroup: {},
      showMaterialSearch: false
    })
  }

  resetToFirstTab = () => {
    this.tabsElement.current.resetToFirstTab()
  }

  onPatternSelect = (pattern) => {
    var surface = findPrimarySurface(pattern._id, this.props.data.allMaterialType.nodes)
    this.setState({
      activeMaterialGroup: surface.materialGroup,
      activeMaterial: surface.material,
      showMaterialSearch: true,
    },function(){
      this.materialsearchElement.current.onMaterialSelect(surface.material)
      this.materialsearchElement.current.onFinishSelect(surface.finish)
      this.materialsearchElement.current.onPatternSelect(pattern)
    }.bind(this))
  }

  applySurface = (surface, lightplane=false, ceramiloc=false, customText='', activeOption='') => {
    const { applySurface } = this.props
    surface.material = {
      id: this.state.activeMaterial._id,
      nid: this.state.activeMaterial.nid,
      name: this.state.activeMaterial.name,
    }
    surface.materialGroup = {
      id: this.state.activeMaterialGroup._id,
      nid: this.state.activeMaterialGroup.nid,
      name: this.state.activeMaterialGroup.name,
    }
    applySurface(surface, lightplane, ceramiloc, customText, activeOption)
  }

  render() {
    let materialsNote = ''
    let items = this.state.materials.map(
      function(item, key) {
        if(this.props.activePanel){
          item.materials = item.materials.sort((a,b)=>{
            if(parseInt(a.sort) > parseInt(b.sort)) return 1
            if(parseInt(b.sort) > parseInt(a.sort)) return -1
            return 0})
          if(this.props.activePanel.materialsAllowed || this.props.activePanel.materialsExcluded){
            if(this.props.activePanel?.materialsExcluded?.length > 0 && 
              this.props.activePanel?.materialsExcluded?.includes(item.nid)){
                return null
            }
            if (
              this.props.activePanel.materialsAllowed.length > 0 &&
              !this.props.activePanel.materialsAllowed.includes(item.nid)
            ) {
              if (this.props.activePanel.materialsNote) {
                materialsNote = (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.props.activePanel.materialsNote,
                    }}
                  />
                )
              }
              return null
            } else {
              return (
                <MaterialGroup
                  activePanel={this.props.activePanel}
                  material={item}
                  key={item._id}
                  onClick={this.onClickItem}
                />
              )
            }
          }else{
            return (
              <MaterialGroup
                activePanel={this.props.activePanel}
                material={item}
                key={item._id}
                onClick={this.onClickItem}
              />
            )
          }
        }else{
          return null
        }
      }.bind(this)
    )
    let searchPanelContent = (
      <div className={styles['search__panel']}>
        <p className={styles['search__panel__text']}>
          First, choose a material by selecting a swatch from one of the
          material families below. You’ll refine your selection by choosing
          finishes and patterns on the next screen.
        </p>
        {items}
        <div className={styles['search__panel__text']}>{materialsNote}</div>
      </div>
    )
    if (this.state.showMaterialSearch) {
      searchPanelContent = (
        <MaterialSearch
          ref={this.materialsearchElement}
          configuration={this.props.configuration}
          materialGroup={this.state.activeMaterialGroup}
          material={this.state.activeMaterial}
          onCloseButton={this.onCloseButton}
          applySurface={this.applySurface}
          activePanel={this.props.activePanel}
        />
      )
    }
    let favoritesContent = <ConfigFavorites
      configuration={this.props.configuration}
      material={this.state.activeMaterial}
      onCloseButton={this.onCloseButton}
      favoriteGroup={this.state.activeFavoriteGroup}
      onClickFavorite={this.onClickFavorite}
      data={this.props.data}
    />
    if (this.state.showFavoriteSearch) {
      favoritesContent = (
        <FavoriteSearch
          favoriteGroup={this.state.activeFavoriteGroup}
          activeFavorite={this.state.activeFavorite}
          onCloseButton={this.onCloseFavorites}
          applySurface={this.applySurface}
          data={this.props.data}
        />
      )
    }
    let myFavoritesContent = <MyFavorites
        applySurface={this.applySurface}
        onClickFavorite={this.onClickMyFavorite}
        onCloseButton={this.onCloseMyFavorites}
        data={this.props.data}
      />
    if (this.state.showMyFavoriteSearch) {
      myFavoritesContent = (
        <MyFavoriteSearch
          favoriteGroup={this.state.activeFavoriteGroup}
          activeFavorite={this.state.activeMyFavorite}
          onCloseButton={this.onCloseMyFavorites}
          activePanel={this.props.activePanel}
          applySurface={this.applySurface}
          data={this.props.data}
        />
      )
    }
    return (
      <div>
        <Tabs ref={this.tabsElement} resetActiveMaterial={this.resetActiveMaterial}>
          <div label="Search Surfaces" icon="search">
            {searchPanelContent}
          </div>
          <div label="F+S Favorites" icon="fslogo" >
            {favoritesContent}
          </div>
          <div label="My Favorites" icon="star">
            {myFavoritesContent}
          </div>
        </Tabs>
      </div>
    )
  }
}

export default SurfacesSearch
