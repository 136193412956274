import React from 'react'
import styles from './panel-legends.module.css'

class Legend extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {

  }

  onClick = () => {
    const { onClick } = this.props
    this.props.item.active = true
    if(this.props.item.top){
      this.props.panel.legends.forEach(function(legend, index){
        this.props.panel.legends[index].active = true
      }.bind(this))
    }
    onClick(this.props.panel, this.props.item)
  }

  render(){
    const fullWidth = 900
    const fullHeight = 1250
    let className = styles['panel__legend']
    let labelText = this.props.panel.label
    if(this.props.activePanel){
      if(this.props.panel.id === this.props.activePanel.id){
        if(this.props.item.active){
          className += ' ' + styles['legend__active']
          if(this.props.item.legend){
            labelText = this.props.item.legend.label
          }
          if(this.props.dualOpen){
            if(this.props.item.legend?.dualOpenLabel){
              labelText = this.props.item.legend.dualOpenLabel
            }
          }
        }
        if(this.props.panel.type === 'frame'){
          className += ' ' + styles['legend__active']
          if(this.props.panel.legends[0].label){
            labelText = this.props.panel.legends[0].label
          }
          if(this.props.dualOpen){
            
            if(this.props.panel.legends[0].dualOpenLabel){
              labelText = this.props.panel.legends[0].dualOpenLabel
            }
          }
        }
      }
    }
    let left = ''
    let top = ''
    if(this.props.item.legend){
      left = ((this.props.item.legend.left / fullWidth) * 100) + '%'
      top = ((this.props.item.legend.top / fullHeight) * 100) + '%'
    }else{
      left = ((this.props.item.left / fullWidth) * 100) + '%'
      top = ((this.props.item.top / fullHeight) * 100) + '%'
    }
    let legendStyle = {
      left: left,
      top: top
    }
    return (
      <button className={className} style={legendStyle} onClick={this.onClick}>
        {labelText}
      </button>
    )
  }

}

export default Legend
