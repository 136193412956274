let BondedQuartz = [
  {
    id: '5d7f89e16dff582bdd994bc8',
    nid: 226,
    name: 'White',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/white/white.jpg',
    surfaceLabel: "Pattern",
    patterns: [
      {
        id: '5d7f89e2e4351cfcd68a0083',
        nid: 89,
        name: 'Carbon',
        fullName: 'Bonded Quartz, White with Carbon pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/white/carbon.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/white/carbon-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/white/carbon-full.jpg',
        captureOnly: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9275da4a0ff366d8b5',
              nid: 1644,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Shadows',
            },
            opacity: 0.25
          },
          {
            effect: {
              id: '5d7f9aebbfbc49bc1cc378d7',
              nid: 549,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Downlights',
            },
            opacity: 1
          },
          {
            effect: {
              id: '5d7f9af3df1d6cdcf96507a6',
              nid: 1643,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Environment Lighting',
            },
            opacity: 1
          }
        ]
      },
      {
        id: '5d7f89e3da038d3450e41674',
        nid: 514,
        name: 'Crinkle',
        fullName: 'Bonded Quartz, White with Crinkle pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/white/crinkle.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/white/crinkle-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/white/crinkle-full.jpg',
        captureOnly: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9aebbfbc49bc1cc378d7',
              nid: 549,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Downlights',
            },
            opacity: 1
          },
          {
            effect: {
              id: '5d7f9af3df1d6cdcf96507a6',
              nid: 1643,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Environment Lighting',
            },
            opacity: 1
          },
          {
            effect: {
              id: '5d7f9b9275da4a0ff366d8b5',
              nid: 1644,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Shadows',
            },
            opacity: 0.25
          }
        ]
      },
      {
        id: '5d7f89e461758fd10b1a09d2',
        nid: 13236,
        name: 'Current',
        fullName: 'Bonded Quartz, White with Current pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/white/current.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/white/current-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/white/current-full.jpg',
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9aebbfbc49bc1cc378d7',
              nid: 549,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Downlights',
            },
            opacity: 1
          },
          {
            effect: {
              id: '5d7f9af3df1d6cdcf96507a6',
              nid: 1643,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Environment Lighting',
            },
            opacity: 1
          },
          {
            effect: {
              id: '5d7f9b9275da4a0ff366d8b5',
              nid: 1644,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Shadows',
            },
            opacity: 0.25
          }
        ]
      },
      {
        id: '5d7f89e55563d0876a7296ae',
        nid: 13237,
        name: 'Dash',
        fullName: 'Bonded Quartz, White with Dash pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/white/dash.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/white/dash-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/white/dash-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9aebbfbc49bc1cc378d7',
              nid: 549,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Downlights',
            },
            opacity: 1
          },
          {
            effect: {
              id: '5d7f9af3df1d6cdcf96507a6',
              nid: 1643,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Environment Lighting',
            },
            opacity: 1
          },
          {
            effect: {
              id: '5d7f9b9275da4a0ff366d8b5',
              nid: 1644,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Shadows',
            },
            opacity: 0.25
          }
        ]
      },
      {
        id: '5d7f89e66cf66304b49fc48c',
        nid: 516,
        name: 'Dune',
        fullName: 'Bonded Quartz, White with Dune pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/white/dune.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/white/dune-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/white/dune-full.jpg',
        captureOnly: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9aebbfbc49bc1cc378d7',
              nid: 549,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Downlights',
            },
            opacity: 1
          },
          {
            effect: {
              id: '5d7f9af3df1d6cdcf96507a6',
              nid: 1643,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Environment Lighting',
            },
            opacity: 1
          },
          {
            effect: {
              id: '5d7f9b9275da4a0ff366d8b5',
              nid: 1644,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Shadows',
            },
            opacity: 0.25
          }
        ]
      },
      {
        id: '5d7f89e7ef1b7bce95d1982c',
        nid: 13238,
        name: 'Glint',
        fullName: 'Bonded Quartz, White with Glint pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/white/glint.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/white/glint-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/white/glint-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9aebbfbc49bc1cc378d7',
              nid: 549,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Downlights',
            },
            opacity: 1
          },
          {
            effect: {
              id: '5d7f9af3df1d6cdcf96507a6',
              nid: 1643,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Environment Lighting',
            },
            opacity: 1
          },
          {
            effect: {
              id: '5d7f9b9275da4a0ff366d8b5',
              nid: 1644,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Shadows',
            },
            opacity: 0.25
          }
        ]
      },
      {
        id: '5d7f89e87d04d4e42d427d82',
        nid: 352,
        name: 'Grass',
        fullName: 'Bonded Quartz, White with Grass pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/white/grass.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/white/grass-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/white/grass-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9aebbfbc49bc1cc378d7',
              nid: 549,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Downlights',
            },
            opacity: 1
          },
          {
            effect: {
              id: '5d7f9af3df1d6cdcf96507a6',
              nid: 1643,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Environment Lighting',
            },
            opacity: 1
          },
          {
            effect: {
              id: '5d7f9b9275da4a0ff366d8b5',
              nid: 1644,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Shadows',
            },
            opacity: 0.25
          }
        ]
      },
      {
        id: '5d7f89e9f5fbfabdd63d3d6b',
        nid: 1076,
        name: 'Kalahari',
        fullName: 'Bonded Quartz, White with Kalahari pattern ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/white/kalahari.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/white/kalahari-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/white/kalahari-full.jpg',
        captureOnly: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9aebbfbc49bc1cc378d7',
              nid: 549,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Downlights',
            },
            opacity: 1
          },
          {
            effect: {
              id: '5d7f9af3df1d6cdcf96507a6',
              nid: 1643,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Environment Lighting',
            },
            opacity: 1
          },
          {
            effect: {
              id: '5d7f9b9275da4a0ff366d8b5',
              nid: 1644,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Shadows',
            },
            opacity: 0.25
          }
        ]
      },
      {
        id: '5d7f89ea7f7d8fa3b48b7d44',
        nid: 1077,
        name: 'Loft',
        fullName: 'Bonded Quartz, White with Loft pattern ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/white/loft.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/white/loft-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/white/loft-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9aebbfbc49bc1cc378d7',
              nid: 549,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Downlights',
            },
            opacity: 1
          },
          {
            effect: {
              id: '5d7f9af3df1d6cdcf96507a6',
              nid: 1643,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Environment Lighting',
            },
            opacity: 1
          },
          {
            effect: {
              id: '5d7f9b9275da4a0ff366d8b5',
              nid: 1644,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Shadows',
            },
            opacity: 0.25
          }
        ]
      },
      {
        id: '5d7f89eb9c561894327de4d9',
        nid: 1078,
        name: 'Mara',
        fullName: 'Bonded Quartz, White with Mara pattern ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/white/mara.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/white/mara-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/white/mara-full.jpg',
        captureOnly: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9aebbfbc49bc1cc378d7',
              nid: 549,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Downlights',
            },
            opacity: 1
          },
          {
            effect: {
              id: '5d7f9af3df1d6cdcf96507a6',
              nid: 1643,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Environment Lighting',
            },
            opacity: 1
          },
          {
            effect: {
              id: '5d7f9b9275da4a0ff366d8b5',
              nid: 1644,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Shadows',
            },
            opacity: 0.25
          }
        ]
      },
      {
        id: '5d7f89ec2fedb6f278245c77',
        nid: 1079,
        name: 'Sahara',
        fullName: 'Bonded Quartz, White with Sahara pattern ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/white/sahara.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/white/sahara-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/white/sahara-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9aebbfbc49bc1cc378d7',
              nid: 549,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Downlights',
            },
            opacity: 1
          },
          {
            effect: {
              id: '5d7f9af3df1d6cdcf96507a6',
              nid: 1643,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Environment Lighting',
            },
            opacity: 1
          },
          {
            effect: {
              id: '5d7f9b9275da4a0ff366d8b5',
              nid: 1644,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Shadows',
            },
            opacity: 0.25
          }
        ]
      },
      {
        id: '5d7f89edd22746d2bced361e',
        nid: 13239,
        name: 'Tilt',
        fullName: 'Bonded Quartz, White with Tilt pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/white/tilt.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/white/tilt-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/white/tilt-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9aebbfbc49bc1cc378d7',
              nid: 549,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Downlights',
            },
            opacity: 1
          },
          {
            effect: {
              id: '5d7f9af3df1d6cdcf96507a6',
              nid: 1643,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Environment Lighting',
            },
            opacity: 1
          },
          {
            effect: {
              id: '5d7f9b9275da4a0ff366d8b5',
              nid: 1644,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Shadows',
            },
            opacity: 0.25
          }
        ]
      },
      {
        id: '5d7f89ee7a42d19162bf8135',
        nid: 1080,
        name: 'Trevia',
        fullName: 'Bonded Quartz, White with Trevia pattern ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/white/trevia.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/white/trevia-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/white/trevia-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9aebbfbc49bc1cc378d7',
              nid: 549,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Downlights',
            },
            opacity: 1
          },
          {
            effect: {
              id: '5d7f9af3df1d6cdcf96507a6',
              nid: 1643,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Environment Lighting',
            },
            opacity: 1
          },
          {
            effect: {
              id: '5d7f9b9275da4a0ff366d8b5',
              nid: 1644,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Shadows',
            },
            opacity: 0.25
          }
        ]
      },
      {
        id: '5d7f89ef60851319b97215e1',
        nid: 1081,
        name: 'Waterfall',
        fullName: 'Bonded Quartz, White with Waterfall pattern ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/white/waterfall.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/white/waterfall-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/white/waterfall-full.jpg',
        captureOnly: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9aebbfbc49bc1cc378d7',
              nid: 549,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Downlights',
            },
            opacity: 1
          },
          {
            effect: {
              id: '5d7f9af3df1d6cdcf96507a6',
              nid: 1643,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Environment Lighting',
            },
            opacity: 1
          },
          {
            effect: {
              id: '5d7f9b9275da4a0ff366d8b5',
              nid: 1644,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Shadows',
            },
            opacity: 0.25
          }
        ]
      },
    ],
  },
  {
    id: '5d7f89f0b042e90e28416243',
    nid: 189,
    name: 'Charcoal',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/charcoal/charcoal.jpg',
    surfaceLabel: "Pattern",
    patterns: [
      {
        id: '5d7f89f16e21bc7e18bb134c',
        nid: 1072,
        name: 'Carbon',
        fullName: 'Bonded Quartz, Charcoal with Carbon pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/charcoal/carbon.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/charcoal/carbon-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/charcoal/carbon-full.jpg',
        captureOnly: true,
      },
      {
        id: '5d7f89f283e7b71ed3665ba0',
        nid: 517,
        name: 'Crinkle',
        fullName: 'Bonded Quartz, Charcoal with Crinkle pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/charcoal/crinkle.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/charcoal/crinkle-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/charcoal/crinkle-full.jpg',
        captureOnly: true,
      },
      {
        id: '5d7f89f36a4eae4c40ec4491',
        nid: 13232,
        name: 'Current',
        fullName: 'Bonded Quartz, Charcoal with Current pattern ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/charcoal/current.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/charcoal/current-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/charcoal/current-full.jpg',
      },
      {
        id: '5d7f89f45dfe6dd7653a31ce',
        nid: 13233,
        name: 'Dash',
        fullName: 'Bonded Quartz, Charcoal with Dash pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/charcoal/dash.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/charcoal/dash-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/charcoal/dash-full.jpg',
      },
      {
        id: '5d7f89f5add9976ecb1cce9a',
        nid: 473,
        name: 'Dune',
        fullName: 'Bonded Quartz, Charcoal with Dune pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/charcoal/dune.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/charcoal/dune-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/charcoal/dune-full.jpg',
        captureOnly: true,
      },
      {
        id: '5d7f89f620b07f6364b73c6e',
        nid: 13234,
        name: 'Glint',
        fullName: 'Bonded Quartz, Charcoal with Glint pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/charcoal/glint.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/charcoal/glint-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/charcoal/glint-full.jpg',
      },
      {
        id: '5d7f89f74e1b733240885d8d',
        nid: 1073,
        name: 'Grass',
        fullName: 'Bonded Quartz, Charcoal with Grass pattern ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/charcoal/grass.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/charcoal/grass-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/charcoal/grass-full.jpg',
      },
      {
        id: '5d7f89f8fb5314e45b1f5673',
        nid: 1074,
        name: 'Loft',
        fullName: 'Bonded Quartz, Charcoal with Loft pattern ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/charcoal/loft.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/charcoal/loft-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/charcoal/loft-full.jpg',
      },
      {
        id: '5d7f89f90a351a6b3b51f0e9',
        nid: 13235,
        name: 'Tilt',
        fullName: 'Bonded Quartz, Charcoal with Tilt pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/charcoal/tilt.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/charcoal/tilt-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-quartz/charcoal/tilt-full.jpg',
      },
    ],
  },
]

export default BondedQuartz
