import React from 'react'
import styles from '../panel-selections.module.css'
import SubPanel from './sub-panel'

class SubPanels extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      refresh: false
    }
  }

  onClickSubPanel = (subPanel,active) => {
    this.props.activePanel.panelCoords[subPanel.index].active = active
  }

  selectall = (e) => {
    e.preventDefault()
    this.props.selectSubPanels(this.props.activePanel, true)
    this.setState({
      refresh: true
    })
  }

  selectnone = (e) => {
    e.preventDefault()
    this.props.selectSubPanels(this.props.activePanel, false)
    this.setState({
      refresh: true
    })
  }

  panelsSelected = () => {
    var selectCount = 0
    this.props.activePanel.panelCoords.forEach(function(coord){
      if(coord.active){
        selectCount++
      }
    })
    return selectCount
  }

  componentDidMount() {

  }

  render(){
    let primaryPanels = ''
    let subPanelText = ''
    let subPanelText2 = ''
    if(this.props.activePanel){
      if(this.props.activePanel.panelCoords){
        this.props.activePanel.panelCoords.sort(function(a, b){
          if(parseInt(a.name) > parseInt(b.name)) return 1
          if(parseInt(b.name) > parseInt(a.name)) return -1
          return 0
        })
        subPanelText = <h6 className={styles['subpanel__text']}>SUB-PANELS <span>({this.panelsSelected()} of {this.props.activePanel.panelCoords.length} sub-panels selected) <button className={styles['actionButton'] + ' fadeHover'} onClick={e => this.selectall(e)}>Select All</button> / <button className={styles['actionButton'] + ' fadeHover'} onClick={e => this.selectnone(e)}>None</button></span></h6>
        subPanelText2 = <p className={styles['subpanel__text2']}>Select one or more of the numbered sub-panels. You can select multiple sub-panels by clicking on the numbers above or from the rendering on right.</p>
        primaryPanels = this.props.activePanel.panelCoords.map(function(item, key){
          if(item.legend){
            if(!item.legend.hideLabel){
              return (
                <SubPanel
                  dualOpen={this.props.dualOpen}
                  subPanel={item}
                  name={item.name}
                  label={item.legend.label}
                  key={item.name}
                  index={key}
                  onClick={this.onClickSubPanel}
                  active={item.active}
                  rotate={this.props.rotate}
                  setActiveSubPanel={this.props.setActiveSubPanel}
                />
              )  
            }
          }
        }.bind(this))
      }
    }
    return (
      <div className={styles['subpanel__selections']}>
        {subPanelText}
        {primaryPanels}
        {subPanelText2}
      </div>
    )
  }

}

export default SubPanels
