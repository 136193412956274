let MirroredGlassBronze = [
  {
    id: '5d7f9097375ccb36885efa2b',
    nid: 338,
    name: 'Custom Surface',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/mirrored-glass-bronze/custom-surface/custom-surface.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f9098f1f3d39f826a299c',
        nid: 23329,
        name: 'Standard',
        fullName: 'Mirrored Glass, Bronze',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/mirrored-glass-bronze/custom-surface/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/mirrored-glass-bronze/custom-surface/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/mirrored-glass-bronze/custom-surface/standard-full.jpg',
        finish: {
          id: '5d7f9099c7390dd6a614c379',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.28
          },
        ]
      },
    ],
  },
]

export default MirroredGlassBronze
