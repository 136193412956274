let FusedWhiteGold = [
  {
    id: '5d7f8d709e89e5f0ad244622',
    nid: 155,
    name: 'Sandstone',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/sandstone/sandstone.jpg',
    surfaceLabel: "Pattern",
    patterns: [
      {
        id: '5d7f8d71596475e513231d20',
        nid: 175,
        name: 'No Pattern',
        fullName: 'Fused White Gold with Sandstone finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/none.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/sandstone/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/sandstone/no-pattern-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.28
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.28
          }
        ]
      },
      {
        id: '5d7f8d72cf1d52ef075471b2',
        nid: 903,
        name: 'Champagne Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused White Gold with Sandstone finish and Champagne Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/sandstone/champagne-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/sandstone/champagne-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/sandstone/champagne-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.28
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.28
          }
        ]
      },
      {
        id: '5d7f8d739e9e7ece1bf52343',
        nid: 928,
        name: 'Chardonnay Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused White Gold with Sandstone finish and Chardonnay Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/sandstone/chardonnay-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/sandstone/chardonnay-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/sandstone/chardonnay-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.28
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.28
          }
        ]
      },
      {
        id: '5d7f8d7499aee013a46df1f4',
        nid: 960,
        name: 'Dallas Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused White Gold with Sandstone finish and Dallas Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/sandstone/dallas-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/sandstone/dallas-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/sandstone/dallas-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.28
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.28
          }
        ]
      },
      {
        id: '5d7f8d755396afaa4b2665cd',
        nid: 992,
        name: 'Denver Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused White Gold with Sandstone finish and Denver Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/sandstone/denver-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/sandstone/denver-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/sandstone/denver-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.28
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.28
          }
        ]
      },
      {
        id: '5d7f8d76fb1e67b0c5fe48c2',
        nid: 1024,
        name: 'Dune Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused White Gold with Sandstone finish and Dune Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/sandstone/dune-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/sandstone/dune-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/sandstone/dune-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.28
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.28
          }
        ]
      },
      {
        id: '5d7f8d775e970cd522e0e93e',
        nid: 1045,
        name: 'Kalahari Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused White Gold with Sandstone finish and Kalahari Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/sandstone/kalahari-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/sandstone/kalahari-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/sandstone/kalahari-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.28
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.28
          }
        ]
      },
    ],
  },
  {
    id: '5d7f8d78d79a83016c45a0b8',
    nid: 156,
    name: 'Seastone',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/seastone.jpg',
    surfaceLabel: "Pattern",
    patterns: [
      {
        id: '5d7f8d79b9b5bb4b6254ed6b',
        nid: 61,
        name: 'No Pattern',
        fullName: 'Fused White Gold with Seastone finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/none.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/no-pattern-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.33
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.33
          }
        ]
      },
      {
        id: '5d7f8d7aeceb50818776fd45',
        nid: 1416,
        name: 'City Lights Eco-Etch',
        fullName:
          'Fused White Gold with Seastone finish and City Lights Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/city-lights-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/city-lights-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/city-lights-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.33
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.33
          }
        ]
      },
      {
        id: '5d7f8d7b5947641694e13dbd',
        nid: 8010,
        name: 'Glacier Eco-Etch',
        fullName:
          'Fused White Gold with Seastone finish and Glacier Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/glacier-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/glacier-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/glacier-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.33
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.33
          }
        ]
      },
      {
        id: '5d7f8d7c28ada137d10e43a6',
        nid: 7978,
        name: 'Current Eco-Etch',
        fullName:
          'Fused White Gold with Seastone finish and Current Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/current-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/current-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/current-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.33
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.33
          }
        ]
      },
      {
        id: '5d7f8d7d200582de44e8be9e',
        nid: 7995,
        name: 'Dash Eco-Etch',
        fullName:
          'Fused White Gold with Seastone finish and Dash Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/dash-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/dash-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/dash-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.33
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.33
          }
        ]
      },
      {
        id: '5d7f8d7e9fcc52a8b2c0f5a6',
        nid: 1923,
        name: 'Flicker Eco-Etch',
        fullName:
          'Fused White Gold with Seastone finish and Flicker Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/flicker-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/flicker-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/flicker-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.33
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.33
          }
        ]
      },
      {
        id: '5d7f8d7f8416dbe70ae412a2',
        nid: 1935,
        name: 'Mica Eco-Etch',
        fullName:
          'Fused White Gold with Seastone finish and Mica Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/mica-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/mica-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/mica-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.33
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.33
          }
        ]
      },
      {
        id: '5d7f8d808008cb69eb31ae22',
        nid: 1946,
        name: 'Reeds Eco-Etch',
        fullName:
          'Fused White Gold with Seastone finish and Reeds Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/reeds-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/reeds-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/reeds-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.33
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.33
          }
        ]
      },
      {
        id: '5d7f8d8101d50e7813ad3118',
        nid: 2122,
        name: 'Seagrass Eco-Etch',
        fullName:
          'Fused White Gold with Seastone finish and Seagrass Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/seagrass-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/seagrass-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/seagrass-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.33
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.33
          }
        ]
      },
      {
        id: '5d7f8d822b07c9c5ac55d4f6',
        nid: 2134,
        name: 'Sequence Eco-Etch',
        fullName:
          'Fused White Gold with Seastone finish and Sequence Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/sequence-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/sequence-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/sequence-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.33
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.33
          }
        ]
      },
      {
        id: '5d7f8d83467e49b001016ddb',
        nid: 2148,
        name: 'Silkworm Eco-Etch',
        fullName:
          'Fused White Gold with Seastone finish and Silkworm Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/silkworm-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/silkworm-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/silkworm-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.33
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.33
          }
        ]
      },
      {
        id: '5d7f8d8450653f4893950474',
        nid: 2255,
        name: 'Stacks Eco-Etch',
        fullName:
          'Fused White Gold with Seastone finish and Stacks Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/stacks-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/stacks-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/stacks-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.33
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.33
          }
        ]
      },
      {
        id: '5d7f8d8590e1cc10fc9a08ba',
        nid: 8692,
        name: 'Stripe Eco-Etch',
        fullName:
          'Fused White Gold with Seastone finish and Stripe Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/stripe-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/stripe-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/stripe-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.33
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.33
          }
        ]
      },
      {
        id: '5d7f8d863f5424209912db0e',
        nid: 8706,
        name: 'Thatch Eco-Etch',
        fullName:
          'Fused White Gold with Seastone finish and Thatch Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/thatch-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/thatch-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/thatch-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.33
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.33
          }
        ]
      },
      {
        id: '5d7f8d87965f7ca8aa3dab04',
        nid: 8028,
        name: 'Trace Eco-Etch',
        fullName:
          'Fused White Gold with Seastone finish and Trace Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/trace-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/trace-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/trace-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.33
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.33
          }
        ]
      },
      {
        id: '5d7f8d88a48437afe88060c7',
        nid: 1814,
        name: 'Carbon Fusion',
        fullName:
          'Fused White Gold with Seastone finish and Carbon Fusion pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/carbon-fusion.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/carbon-fusion-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/carbon-fusion-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.33
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.33
          }
        ]
      },
      {
        id: '5d7f8d8908312211f38bd2a0',
        nid: 1818,
        name: 'Cinch Fusion',
        fullName:
          'Fused White Gold with Seastone finish and Cinch Fusion pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/cinch-fusion.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/cinch-fusion-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/cinch-fusion-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.33
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.33
          }
        ]
      },
      {
        id: '5d7f8d8a236dd492fba039c5',
        nid: 1874,
        name: 'Moraine Fusion',
        fullName:
          'Fused White Gold with Seastone finish and Moraine Fusion pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/moraine-fusion.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/moraine-fusion-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/moraine-fusion-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.33
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.33
          }
        ]
      },
      {
        id: '5d7f8d8beb59ef3f0373e502',
        nid: 905,
        name: 'Champagne Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused White Gold with Seastone finish and Champagne Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/champagne-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/champagne-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/champagne-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.33
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.33
          }
        ]
      },
      {
        id: '5d7f8d8c68254e53353b2a9d',
        nid: 930,
        name: 'Chardonnay Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused White Gold with Seastone finish and Chardonnay Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/chardonnay-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/chardonnay-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/chardonnay-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.33
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.33
          }
        ]
      },
      {
        id: '5d7f8d8df222939ec07b2dd3',
        nid: 962,
        name: 'Dallas Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused White Gold with Seastone finish and Dallas Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/dallas-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/dallas-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/dallas-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.33
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.33
          }
        ]
      },
      {
        id: '5d7f8d8e0ab94bde2a8350a5',
        nid: 994,
        name: 'Denver Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused White Gold with Seastone finish and Denver Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/denver-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/denver-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/denver-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.33
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.33
          }
        ]
      },
      {
        id: '5d7f8d8fb6fe94a443bd5c93',
        nid: 1027,
        name: 'Dune Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused White Gold with Seastone finish and Dune Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/dune-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/dune-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/dune-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.33
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.33
          }
        ]
      },
      {
        id: '5d7f8d90f78854dfdc901e02',
        nid: 1047,
        name: 'Kalahari Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused White Gold with Seastone finish and Kalahari Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/kalahari-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/kalahari-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/seastone/kalahari-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.33
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.33
          }
        ]
      },
    ],
  },
  {
    id: '5d7f8d9163302b9157df3cb7',
    nid: 157,
    name: 'Linen',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/linen/linen.jpg',
    surfaceLabel: "Pattern",
    patterns: [
      {
        id: '5d7f8d9203f1d8cc668c5ef5',
        nid: 173,
        name: 'No Pattern',
        fullName: 'Fused White Gold with Linen finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/none.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/linen/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/linen/no-pattern-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.25
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.25
          }
        ]
      },
      {
        id: '5d7f8d932885511c178da098',
        nid: 901,
        name: 'Champagne Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused White Gold with Linen finish and Champagne Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/linen/champagne-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/linen/champagne-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/linen/champagne-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.25
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.25
          }
        ]
      },
      {
        id: '5d7f8d94aa8e404d1f157a01',
        nid: 926,
        name: 'Chardonnay Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused White Gold with Linen finish and Chardonnay Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/linen/chardonnay-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/linen/chardonnay-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/linen/chardonnay-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.25
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.25
          }
        ]
      },
      {
        id: '5d7f8d956233020141bffe73',
        nid: 958,
        name: 'Dallas Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused White Gold with Linen finish and Dallas Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/linen/dallas-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/linen/dallas-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/linen/dallas-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.25
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.25
          }
        ]
      },
      {
        id: '5d7f8d96075dc45dee88fa44',
        nid: 990,
        name: 'Denver Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused White Gold with Linen finish and Denver Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/linen/denver-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/linen/denver-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/linen/denver-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.25
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.25
          }
        ]
      },
      {
        id: '5d7f8d97e86f49ae63b85055',
        nid: 1022,
        name: 'Dune Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused White Gold with Linen finish and Dune Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/linen/dune-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/linen/dune-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/linen/dune-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.25
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.25
          }
        ]
      },
      {
        id: '5d7f8d9845d4e14dd3d8b8d0',
        nid: 1043,
        name: 'Kalahari Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused White Gold with Linen finish and Kalahari Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/linen/kalahari-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/linen/kalahari-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/linen/kalahari-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.25
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.25
          }
        ]
      },
    ],
  },
  {
    id: '5d7f8d99308b353f5f6b3873',
    nid: 140,
    name: 'Diamond',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/diamond/diamond.jpg',
    surfaceLabel: "Pattern",
    patterns: [
      {
        id: '5d7f8d9a773c4ecb40bbb93e',
        nid: 759,
        name: 'No Pattern',
        fullName: 'Fused White Gold with Diamond finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/none.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/diamond/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/diamond/no-pattern-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.28
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.28
          }
        ]
      },
      {
        id: '5d7f8d9b499aae732d794695',
        nid: 1071,
        name: 'Champagne Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused White Gold with Diamond finish and Champagne Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/diamond/champagne-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/diamond/champagne-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/diamond/champagne-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.28
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.28
          }
        ]
      },
      {
        id: '5d7f8d9c8f015dfca95ddffc',
        nid: 1067,
        name: 'Chardonnay Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused White Gold with Diamond finish and Chardonnay Impression pattern ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/diamond/chardonnay-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/diamond/chardonnay-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/diamond/chardonnay-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.28
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.28
          }
        ]
      },
      {
        id: '5d7f8d9d009c1f0bb661af0a',
        nid: 957,
        name: 'Dallas Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused White Gold with Diamond finish and Dallas Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/diamond/dallas-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/diamond/dallas-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/diamond/dallas-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.28
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.28
          }
        ]
      },
      {
        id: '5d7f8d9e786b387126e5a554',
        nid: 989,
        name: 'Denver Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused White Gold with Diamond finish and Denver Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/diamond/denver-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/diamond/denver-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/diamond/denver-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.28
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.28
          }
        ]
      },
      {
        id: '5d7f8d9f70da3a41c9a67e21',
        nid: 1063,
        name: 'Dune Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused White Gold with Diamond finish and Dune Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/diamond/dune-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/diamond/dune-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/diamond/dune-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.28
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.28
          }
        ]
      },
      {
        id: '5d7f8da0d0bdbb524f7506bf',
        nid: 1059,
        name: 'Kalahari Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused White Gold with Diamond finish and Kalahari Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/diamond/kalahari-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/diamond/kalahari-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/diamond/kalahari-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.28
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.28
          }
        ]
      },
    ],
  },
  {
    id: '5d7f8da1339b16a8660937d0',
    nid: 143,
    name: 'Mirror',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/mirror.jpg',
    surfaceLabel: "Pattern",
    patterns: [
      {
        id: '5d7f8da20195e7255c3e0c40',
        nid: 174,
        name: 'No Pattern',
        fullName: 'Fused White Gold with Mirror finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/none.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/no-pattern-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8da3bbb6dedb1c6e3aee',
        nid: 154,
        name: 'City Lights Eco-Etch',
        fullName:
          'Fused White Gold with Mirror finish and City Lights Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/city-lights-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/city-lights-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/city-lights-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8da433620946bf55ccac',
        nid: 8012,
        name: 'Glacier Eco-Etch',
        fullName:
          'Fused White Gold with Mirror finish and Glacier Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/glacier-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/glacier-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/glacier-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8da591e765b64e6f10f5',
        nid: 7982,
        name: 'Current Eco-Etch',
        fullName:
          'Fused White Gold with Mirror finish and Current Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/current-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/current-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/current-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8da6664797eea8b2469f',
        nid: 7997,
        name: 'Dash Eco-Etch',
        fullName:
          'Fused White Gold with Mirror finish and Dash Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/dash-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/dash-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/dash-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8da7e2e0458f9e29a361',
        nid: 1921,
        name: 'Flicker Eco-Etch',
        fullName:
          'Fused White Gold with Mirror finish and Flicker Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/flicker-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/flicker-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/flicker-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8da8632efa5414a3a3d6',
        nid: 1933,
        name: 'Mica Eco-Etch',
        fullName:
          'Fused White Gold with Mirror finish and Mica Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/mica-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/mica-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/mica-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8da92f6f2295ff57cb90',
        nid: 1944,
        name: 'Reeds Eco-Etch',
        fullName:
          'Fused White Gold with Mirror finish and Reeds Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/reeds-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/reeds-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/reeds-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8daac19c52bc5c1b22f8',
        nid: 2120,
        name: 'Seagrass Eco-Etch',
        fullName:
          'Fused White Gold with Mirror finish and Seagrass Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/seagrass-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/seagrass-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/seagrass-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8dab71ed89c60285ab65',
        nid: 2132,
        name: 'Sequence Eco-Etch',
        fullName:
          'Fused White Gold with Mirror finish and Sequence Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/sequence-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/sequence-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/sequence-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8dac3c9ce28790c1f73e',
        nid: 2146,
        name: 'Silkworm Eco-Etch',
        fullName:
          'Fused White Gold with Mirror finish and Silkworm Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/silkworm-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/silkworm-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/silkworm-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8dade41e1bc8bec9fbac',
        nid: 2253,
        name: 'Stacks Eco-Etch',
        fullName:
          'Fused White Gold with Mirror finish and Stacks Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/stacks-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/stacks-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/stacks-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8daef54953eb131b3d1e',
        nid: 8690,
        name: 'Stripe Eco-Etch',
        fullName:
          'Fused White Gold with Mirror finish and Stripe Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/stripe-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/stripe-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/stripe-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8daf9eef9059e7efc119',
        nid: 62,
        name: 'Thatch Eco-Etch',
        fullName:
          'Fused White Gold with Mirror finish and Thatch Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/thatch-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/thatch-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/thatch-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8db0e92dbfe84272c667',
        nid: 8030,
        name: 'Trace Eco-Etch',
        fullName:
          'Fused White Gold with Mirror finish and Trace Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/trace-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/trace-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/trace-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8db1c3566c2270d4671d',
        nid: 1796,
        name: 'Carbon Fusion',
        fullName:
          'Fused White Gold with Mirror finish and Carbon Fusion pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/carbon-fusion.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/carbon-fusion-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/carbon-fusion-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8db27bddbc9f1a67fbde',
        nid: 1816,
        name: 'Cinch Fusion',
        fullName:
          'Fused White Gold with Mirror finish and Cinch Fusion pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/cinch-fusion.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/cinch-fusion-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/cinch-fusion-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8db3c6222d27d1787836',
        nid: 1867,
        name: 'Moraine Fusion',
        fullName:
          'Fused White Gold with Mirror finish and Moraine Fusion pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/moraine-fusion.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/moraine-fusion-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/moraine-fusion-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8db4c0044629cc77323a',
        nid: 902,
        name: 'Champagne Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused White Gold with Mirror finish and Champagne Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/champagne-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/champagne-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/champagne-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8db551ca0ef0765cc03c',
        nid: 927,
        name: 'Chardonnay Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused White Gold with Mirror finish and Chardonnay Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/chardonnay-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/chardonnay-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/chardonnay-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8db60a34b8d8b4bc43a5',
        nid: 959,
        name: 'Dallas Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused White Gold with Mirror finish and Dallas Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/dallas-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/dallas-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/dallas-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8db792664846e654dbb1',
        nid: 991,
        name: 'Denver Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused White Gold with Mirror finish and Denver Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/denver-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/denver-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/denver-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8db83d018a307dbe4929',
        nid: 1023,
        name: 'Dune Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused White Gold with Mirror finish and Dune Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/dune-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/dune-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/dune-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8db9dffd7e48475a07f6',
        nid: 1044,
        name: 'Kalahari Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused White Gold with Mirror finish and Kalahari Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/kalahari-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/kalahari-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/mirror/kalahari-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.5
          }
        ]
      },
    ],
  },
  {
    id: '5d7f8dba120852569a5d3728',
    nid: 159,
    name: 'Satin',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/satin.jpg',
    surfaceLabel: "Pattern",
    patterns: [
      {
        id: '5d7f8dbbc3a0d973698f7418',
        nid: 176,
        name: 'No Pattern',
        fullName: 'Fused White Gold with Satin finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/none.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/no-pattern-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.15
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.15
          }
        ]
      },
      {
        id: '5d7f8dbc21ab311f3577cbbb',
        nid: 1489,
        name: 'City Lights Eco-Etch',
        fullName:
          'Fused White Gold with Satin finish and City Lights Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/city-lights-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/city-lights-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/city-lights-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.15
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.15
          }
        ]
      },
      {
        id: '5d7f8dbdac460a58ee6bca8a',
        nid: 8011,
        name: 'Glacier Eco-Etch',
        fullName:
          'Fused White Gold with Satin finish and Glacier Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/glacier-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/glacier-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/glacier-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.15
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.15
          }
        ]
      },
      {
        id: '5d7f8dbe1e3cf544c5ebf7f1',
        nid: 7979,
        name: 'Current Eco-Etch',
        fullName:
          'Fused White Gold with Satin finish and Current Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/current-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/current-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/current-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.15
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.15
          }
        ]
      },
      {
        id: '5d7f8dbf4e054eef23e4efa2',
        nid: 7996,
        name: 'Dash Eco-Etch',
        fullName:
          'Fused White Gold with Satin finish and Dash Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/dash-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/dash-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/dash-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.15
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.15
          }
        ]
      },
      {
        id: '5d7f8dc0ac178849892c773b',
        nid: 1922,
        name: 'Flicker Eco-Etch',
        fullName:
          'Fused White Gold with Satin finish and Flicker Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/flicker-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/flicker-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/flicker-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.15
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.15
          }
        ]
      },
      {
        id: '5d7f8dc138d9f5cf3eeb70ce',
        nid: 1934,
        name: 'Mica Eco-Etch',
        fullName:
          'Fused White Gold with Satin finish and Mica Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/mica-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/mica-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/mica-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.15
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.15
          }
        ]
      },
      {
        id: '5d7f8dc2e39d2cc2464b246f',
        nid: 1945,
        name: 'Reeds Eco-Etch',
        fullName:
          'Fused White Gold with Satin finish and Reeds Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/reeds-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/reeds-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/reeds-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.15
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.15
          }
        ]
      },
      {
        id: '5d7f8dc3d3d2e30c54de4c63',
        nid: 2121,
        name: 'Seagrass Eco-Etch',
        fullName:
          'Fused White Gold with Satin finish and Seagrass Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/seagrass-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/seagrass-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/seagrass-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.15
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.15
          }
        ]
      },
      {
        id: '5d7f8dc4c9a37ccbe56eb722',
        nid: 2133,
        name: 'Sequence Eco-Etch',
        fullName:
          'Fused White Gold with Satin finish and Sequence Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/sequence-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/sequence-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/sequence-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.15
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.15
          }
        ]
      },
      {
        id: '5d7f8dc5510e769596119da9',
        nid: 2147,
        name: 'Silkworm Eco-Etch',
        fullName:
          'Fused White Gold with Satin finish and Silkworm Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/silkworm-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/silkworm-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/silkworm-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.15
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.15
          }
        ]
      },
      {
        id: '5d7f8dc62b85ba083c8b2dc5',
        nid: 2254,
        name: 'Stacks Eco-Etch',
        fullName:
          'Fused White Gold with Satin finish and Stacks Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/stacks-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/stacks-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/stacks-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.15
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.15
          }
        ]
      },
      {
        id: '5d7f8dc7d684a8b7c62868fe',
        nid: 8691,
        name: 'Stripe Eco-Etch',
        fullName:
          'Fused White Gold with Satin finish and Stripe Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/stripe-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/stripe-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/stripe-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.15
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.15
          }
        ]
      },
      {
        id: '5d7f8dc8c29e17bd3dba865b',
        nid: 1693,
        name: 'Thatch Eco-Etch',
        fullName:
          'Fused White Gold with Satin finish and Thatch Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/thatch-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/thatch-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/thatch-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.15
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.15
          }
        ]
      },
      {
        id: '5d7f8dc9bca4992e1b018a5e',
        nid: 8029,
        name: 'Trace Eco-Etch',
        fullName:
          'Fused White Gold with Satin finish and Trace Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/trace-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/trace-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/trace-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.15
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.15
          }
        ]
      },
      {
        id: '5d7f8dca657fac1a3e33830d',
        nid: 1800,
        name: 'Carbon Fusion',
        fullName:
          'Fused White Gold with Satin finish and Carbon Fusion pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/carbon-fusion.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/carbon-fusion-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/carbon-fusion-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.15
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.15
          }
        ]
      },
      {
        id: '5d7f8dcb14ceebe55a70129c',
        nid: 1817,
        name: 'Cinch Fusion',
        fullName: 'Fused White Gold with Satin finish and Cinch Fusion pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/cinch-fusion.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/cinch-fusion-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/cinch-fusion-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.15
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.15
          }
        ]
      },
      {
        id: '5d7f8dccc2c6646ea0d55e17',
        nid: 1871,
        name: 'Moraine Fusion',
        fullName:
          'Fused White Gold with Satin finish and Moraine Fusion pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/moraine-fusion.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/moraine-fusion-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/moraine-fusion-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.15
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.15
          }
        ]
      },
      {
        id: '5d7f8dcdebe57c3d1a57c9ec',
        nid: 904,
        name: 'Champagne Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused White Gold with Satin finish and Champagne Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/champagne-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/champagne-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/champagne-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.15
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.15
          }
        ]
      },
      {
        id: '5d7f8dce42847d084770bff0',
        nid: 929,
        name: 'Chardonnay Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused White Gold with Satin finish and Chardonnay Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/chardonnay-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/chardonnay-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/chardonnay-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.15
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.15
          }
        ]
      },
      {
        id: '5d7f8dcfb83c174d1a814c1f',
        nid: 961,
        name: 'Dallas Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused White Gold with Satin finish and Dallas Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/dallas-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/dallas-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/dallas-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.15
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.15
          }
        ]
      },
      {
        id: '5d7f8dd0fa61525f0e3e733e',
        nid: 993,
        name: 'Denver Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused White Gold with Satin finish and Denver Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/denver-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/denver-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/denver-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.15
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.15
          }
        ]
      },
      {
        id: '5d7f8dd1c8fdc8573a66db02',
        nid: 1025,
        name: 'Dune Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused White Gold with Satin finish and Dune Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/dune-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/dune-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/dune-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.15
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.15
          }
        ]
      },
      {
        id: '5d7f8dd2b9a7fcab98d3da46',
        nid: 1046,
        name: 'Kalahari Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused White Gold with Satin finish and Kalahari Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/kalahari-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/kalahari-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-white-gold/satin/kalahari-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.15
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.15
          }
        ]
      },
    ],
  },
]

export default FusedWhiteGold
