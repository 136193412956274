let MirroredGlassSmoke = [
  {
    id: '5d7f90d5b6a704c336cafc01',
    nid: 338,
    name: 'Custom Surface',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/mirrored-glass-smoke/custom-surface/custom-surface.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f90d60990b918a3903b27',
        nid: 23330,
        name: 'Standard',
        fullName: 'Mirrored Glass, Smoke',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/mirrored-glass-smoke/custom-surface/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/mirrored-glass-smoke/custom-surface/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/mirrored-glass-smoke/custom-surface/standard-full.jpg',
        finish: {
          id: '5d7f90d7fef0ad472d3d92c0',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b2532c18c975f0cffcd',
              nid: 1645,
              title: 'VividGlass, Standard Finish, Environment Lighting',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
]

export default MirroredGlassSmoke
