let VivitelaShibori = [
  {
    id: '5e57c985e4be530448ed6ef9',
    nid: 383,
    name: 'Aurora 1',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-shibori/aurora-1/pearlex.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5e57c99f41f60d681eeac418',
        nid: 50575,
        name: 'Pearlex',
        fullName:
          'ViviTela Shibori glass with Aurora 1 interlayer and Pearlex finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-shibori/aurora-1/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-shibori/aurora-1/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-shibori/aurora-1/pearlex-full.jpg',
        finish: {
          id: '5d7f9223ae00c9aa1a5fbaa9',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        whiteLight: true,
        lightplaneOpacity: 0.1,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9ba1d01bc684f68f0db6',
              nid: 1649,
              title: 'VividGlass, Pearlex Finish, Shadows',
            },
            opacity: 0.15
          },
        ]
      },
      {
        id: '5e57c9ad2480610005bf1d86',
        nid: 50574,
        name: 'Standard',
        fullName:
          'ViviTela Shibori glass with Aurora 1 interlayer and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-shibori/aurora-1/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-shibori/aurora-1/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-shibori/aurora-1/standard-full.jpg',
        finish: {
          id: '5d7f9227bdfe4b1141432122',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        whiteLight: true,
        lightplaneOpacity: 0.1,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.24
          },
        ]
      },
    ],
  },
  {
    id: '5e57c9be076f1a57603dc280',
    nid: 384,
    name: 'Aurora 2',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-shibori/aurora-2/pearlex.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5e57c9cb34fc70bbb505266a',
        nid: 50577,
        name: 'Pearlex',
        fullName:
          'ViviTela Shibori glass with Aurora 2 interlayer and Pearlex finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-shibori/aurora-2/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-shibori/aurora-2/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-shibori/aurora-2/pearlex-full.jpg',
        finish: {
          id: '5d7f9223ae00c9aa1a5fbaa9',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        whiteLight: true,
        lightplaneOpacity: 0.1,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9ba1d01bc684f68f0db6',
              nid: 1649,
              title: 'VividGlass, Pearlex Finish, Shadows',
            },
            opacity: 0.15
          },
        ]
      },
      {
        id: '5e57c9d70885f5c8ec1132e0',
        nid: 50576,
        name: 'Standard',
        fullName:
          'ViviTela Shibori glass with Aurora 2 interlayer and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-shibori/aurora-2/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-shibori/aurora-2/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-shibori/aurora-2/standard-full.jpg',
        finish: {
          id: '5d7f9227bdfe4b1141432122',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        whiteLight: true,
        lightplaneOpacity: 0.1,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.24
          },
        ]
      },
    ],
  },
  {
    id: '5e57c9ea6ff01f586d8c65d7',
    nid: 385,
    name: 'Aurora 3',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-shibori/aurora-3/pearlex.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5e57c9f814dfbcd9e5937ccc',
        nid: 50579,
        name: 'Pearlex',
        fullName:
          'ViviTela Shibori glass with Aurora 3 interlayer and Pearlex finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-shibori/aurora-3/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-shibori/aurora-3/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-shibori/aurora-3/pearlex-full.jpg',
        finish: {
          id: '5d7f9223ae00c9aa1a5fbaa9',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        whiteLight: true,
        lightplaneOpacity: 0.1,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9ba1d01bc684f68f0db6',
              nid: 1649,
              title: 'VividGlass, Pearlex Finish, Shadows',
            },
            opacity: 0.15
          },
        ]
      },
      {
        id: '5e57ca08322e0e6f01f0438b',
        nid: 50578,
        name: 'Standard',
        fullName:
          'ViviTela Shibori glass with Aurora 3 interlayer and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-shibori/aurora-3/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-shibori/aurora-3/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-shibori/aurora-3/standard-full.jpg',
        finish: {
          id: '5d7f9227bdfe4b1141432122',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        whiteLight: true,
        lightplaneOpacity: 0.1,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.24
          },
        ]
      },
    ],
  },
  {
    id: '5e57ca15328e0858bcb58b76',
    nid: 386,
    name: 'Marina',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-shibori/marina/pearlex.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: 50581,
        name: 'Pearlex',
        fullName:
          'ViviTela Shibori glass with Marina interlayer and Pearlex finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-shibori/marina/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-shibori/marina/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-shibori/marina/pearlex-full.jpg',
        finish: {
          id: '5d7f9223ae00c9aa1a5fbaa9',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        whiteLight: true,
        lightplaneOpacity: 0.1,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9ba1d01bc684f68f0db6',
              nid: 1649,
              title: 'VividGlass, Pearlex Finish, Shadows',
            },
            opacity: 0.15
          },
        ]
      },
      {
        id: '5e57ca23807d127d6113496b',
        nid: 50580,
        name: 'Standard',
        fullName:
          'ViviTela Shibori glass with Marina interlayer and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-shibori/marina/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-shibori/marina/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-shibori/marina/standard-full.jpg',
        finish: {
          id: '5d7f9227bdfe4b1141432122',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        whiteLight: true,
        lightplaneOpacity: 0.1,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.24
          },
        ]
      },
    ],
  },
  {
    id: '5e57ca2fd37454894b17d0c5',
    nid: 387,
    name: 'Tidal',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-shibori/tidal/pearlex.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: 50583,
        name: 'Pearlex',
        fullName:
          'ViviTela Shibori glass with Tidal interlayer and Pearlex finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-shibori/tidal/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-shibori/tidal/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-shibori/tidal/pearlex-full.jpg',
        finish: {
          id: '5d7f9223ae00c9aa1a5fbaa9',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        whiteLight: true,
        lightplaneOpacity: 0.1,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9ba1d01bc684f68f0db6',
              nid: 1649,
              title: 'VividGlass, Pearlex Finish, Shadows',
            },
            opacity: 0.15
          },
        ]
      },
      {
        id: '5e57ca3d2372d5830ab98d51',
        nid: 50582,
        name: 'Standard',
        fullName:
          'ViviTela Shibori glass with Tidal interlayer and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-shibori/tidal/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-shibori/tidal/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-shibori/tidal/standard-full.jpg',
        finish: {
          id: '5d7f9227bdfe4b1141432122',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        whiteLight: true,
        lightplaneOpacity: 0.1,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.24
          },
        ]
      },
    ],
  },
  {
    id: '5e57ca4d944bff1239af589a',
    nid: 388,
    name: 'Willow',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-shibori/willow/pearlex.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5e57ca5c20cc20d11009ae30',
        nid: 50585,
        name: 'Pearlex',
        fullName:
          'ViviTela Shibori glass with Willow interlayer and Pearlex finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-shibori/willow/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-shibori/willow/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-shibori/willow/pearlex-full.jpg',
        finish: {
          id: '5d7f9223ae00c9aa1a5fbaa9',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        whiteLight: true,
        lightplaneOpacity: 0.1,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9ba1d01bc684f68f0db6',
              nid: 1649,
              title: 'VividGlass, Pearlex Finish, Shadows',
            },
            opacity: 0.15
          },
        ]
      },
      {
        id: '5e57ca6a568c45a885e9ea82',
        nid: 50584,
        name: 'Standard',
        fullName:
          'ViviTela Shibori glass with Willow interlayer and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-shibori/willow/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-shibori/willow/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivitela-shibori/willow/standard-full.jpg',
        finish: {
          id: '5d7f9227bdfe4b1141432122',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        whiteLight: true,
        lightplaneOpacity: 0.1,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.24
          },
        ]
      },
    ],
  },
]

export default VivitelaShibori
