import React from 'react'
import styles from '../SurfacesSearch/surfaces-search.module.css'

class MaterialGroupItem extends React.Component {
  constructor(props){
    super(props)
    this.state={

    }
  }
  componentDidMount(){

  }
  onClick = () => {
    const { onClick } = this.props
    onClick(this.props.materialGroup,this.props.subMaterial)
  }
  render(){
    if(this.props.subMaterial.disabled){
      return(null)
    }else{
      return(
        <button className={styles['material__selection'] + ' fadeHover'} key={this.props.subMaterial.id} onClick={this.onClick}>
          <img src={this.props.subMaterial.surfaceSwatch.publicURL} alt={this.props.subMaterial.fullName} title={this.props.subMaterial.fullName} />
        </button>
      )  
    }
  }
}

class MaterialGroup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {

  }

  onClick = () => {
    const { onClick } = this.props
    onClick(this.props.material)
  }

  render(){
    let items = this.props.material.surfaces.map(function(item, key){
      return (
        <MaterialGroupItem
          materialGroup={this.props.material}
          subMaterial={item}
          onClick={this.props.onClick}
          key={item.id}
        />
      )
    }.bind(this))
    return (
      <div className={styles['material__group']}>
        <h3 className={styles['material__title']}>{this.props.material.name}</h3>
        {items}
      </div>
    )
  }

}

export default MaterialGroup
