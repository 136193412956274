import React from 'react'
import styles from '../SurfacesSearch/surfaces-search.module.css'
import SearchRow from '../SurfacesSearch/search-row'
import SearchPreview from '../SurfacesSearch/search-preview'

class MaterialSearch extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeMaterial: {},
      options: []
    }
  }

  componentDidMount() {
    if(this.props.material){
      let activeMaterial = this.props.material
      this.setState({
        activeMaterial: activeMaterial,
        options: this.props.options
      })
    }
  }

  onMaterialSelect = material => {
    this.setState({
      activeMaterial: material
    })
  }

  onCloseButton = () => {
    const { onCloseButton } = this.props
    onCloseButton()
  }

  applySurface = surface => {
    const { applySurface } = this.props
    applySurface(surface)
  }

  render(){
    let materials = ''
    let finishes = ''
    let preview = ''
    if(this.state.activeMaterial.name){
      finishes = <SearchRow
        objects={this.state.options}
        activeObject={this.state.activeMaterial}
        objectType='Material'
        onClick={this.onMaterialSelect}
      />
      preview = <SearchPreview
        configuration={this.props?.configuration}
        object={this.state.activeMaterial}
        applySurface={this.applySurface}
      />
    }
    return (
      <div className={styles['material__search__submaterials']}>
        <button title="Close" className={styles['materal__search__preview__close']} onClick={this.onCloseButton}>×</button>
        {preview}
        {materials}
        {finishes}
      </div>
    )
  }

}

export default MaterialSearch
