import React from 'react'
import styles from './surfaces-search.module.css'

class MaterialGroupItem extends React.Component {
  constructor(props){
    super(props)
    this.state={

    }
  }
  componentDidMount(){

  }
  onClick = () => {
    const { onClick } = this.props
    onClick(this.props.materialGroup,this.props.subMaterial)
  }
  render(){
    let note = ''
    if(this.props.subMaterial.note){
      if(this.props.subMaterial.note.message){
        note = <div className={styles['material__note']}>
            {this.props.subMaterial.note.message}
          </div>
      }
    }
    let disabled = false
    let title = this.props.subMaterial.name
    if(this.props.subMaterial.disabled === true){
      disabled = true
      title += ': Disabled'
      return(null)
    }else{
      return(
        <>
        <div className={styles['material__selection']+' fadeHover'} key={this.props.subMaterial.id}>
          <button onClick={this.onClick} disabled={disabled}>
          <img src={this.props.subMaterial.materialSwatch.publicURL} alt={title} title={title} />
          </button>
        </div>
        {note}
        </>
      )
    }

  }
}

class MaterialGroup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {

  }

  onClick = () => {
    const { onClick } = this.props
    onClick(this.props.material)
  }

  render(){
    let items = this.props.material.materials.map(function(item, key){
      return (
        <MaterialGroupItem
          materialGroup={this.props.material}
          subMaterial={item}
          onClick={this.props.onClick}
          key={item._id}
        />
      )
    }.bind(this))
    if(this.props.material.disabled){
      return null
    }else{
      return (
        <div className={styles['material__group']}>
          <h3 className={styles['material__title']}>{this.props.material.name}</h3>
          {items}
        </div>
      )
    }
  }

}

export default MaterialGroup
