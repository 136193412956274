let BondedBronze = [
  {
    id: '5d7f897ae908b47279bb2c85',
    nid: 161,
    name: 'Dark',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/dark/dark.jpg',
    surfaceLabel: "Pattern",
    patterns: [
      {
        id: '5d7f897b08f22e4577029829',
        nid: 1147,
        name: 'Austin',
        fullName: 'Bonded Bronze with Dark Patina and Austin pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/dark/austin.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/dark/austin-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/dark/austin-full.jpg',
      },
      {
        id: '5d7f897cecdd187bbaeaeb3d',
        nid: 1148,
        name: 'Charleston',
        fullName: 'Bonded Bronze with Dark Patina and Charleston pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/dark/charleston.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/dark/charleston-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/dark/charleston-full.jpg',
      },
      {
        id: '5d7f897d11eae8e18336af4d',
        nid: 13222,
        name: 'Dash',
        fullName: 'Bonded Bronze with Dark Patina and Dash pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/dark/dash.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/dark/dash-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/dark/dash-full.jpg',
      },
      {
        id: '5d7f897e2fcf97219de9ec77',
        nid: 263,
        name: 'Delta',
        fullName: 'Bonded Bronze with Dark Patina and Delta pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/dark/delta.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/dark/delta-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/dark/delta-full.jpg',
      },
      {
        id: '5d7f897f38b1f96c1c55be69',
        nid: 13223,
        name: 'Glint',
        fullName: 'Bonded Bronze with Dark Patina and Glint pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/dark/glint.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/dark/glint-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/dark/glint-full.jpg',
      },
      {
        id: '5d7f8980cdb7b81d6d6209a8',
        nid: 1149,
        name: 'Grass',
        fullName: 'Bonded Bronze with Dark Patina and Grass pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/dark/grass.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/dark/grass-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/dark/grass-full.jpg',
      },
      {
        id: '5d7f8981c111d6a3b657781e',
        nid: 1150,
        name: 'Herringbone',
        fullName: 'Bonded Bronze with Dark Patina and Herringbone pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/dark/herringbone.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/dark/herringbone-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/dark/herringbone-full.jpg',
      },
      {
        id: '5d7f8982d8747e8f68fe13b0',
        nid: 1151,
        name: 'Loft',
        fullName: 'Bonded Bronze with Dark Patina and Loft pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/dark/loft.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/dark/loft-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/dark/loft-full.jpg',
      },
      {
        id: '5d7f89837ce8c4a53b542177',
        nid: 270,
        name: 'Rain',
        fullName: 'Bonded Bronze with Dark Patina and Rain pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/dark/rain.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/dark/rain-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/dark/rain-full.jpg',
      },
      {
        id: '5d7f898482e7a5487bf3dd1e',
        nid: 1152,
        name: 'Talus',
        fullName: 'Bonded Bronze with Dark Patina and Talus pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/dark/talus.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/dark/talus-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/dark/talus-full.jpg',
      },
      {
        id: '5d7f8985cb6473f533677d7f',
        nid: 13224,
        name: 'Tilt',
        fullName: 'Bonded Bronze with Dark Patina and Tilt pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/dark/tilt.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/dark/tilt-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/dark/tilt-full.jpg',
      },
      {
        id: '5d7f89866aba6cb65888d5e3',
        nid: 83,
        name: 'Trevia',
        fullName: 'Bonded Bronze with Dark Patina and Trevia pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/dark/trevia.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/dark/trevia-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/dark/trevia-full.jpg',
      },
      {
        id: '5d7f8987e4f2991ccf2ce472',
        nid: 1153,
        name: 'Vancouver',
        fullName: 'Bonded Bronze with Dark Patina and Vancouver pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/dark/vancouver.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/dark/vancouver-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/dark/vancouver-full.jpg',
      },
    ],
  },
  {
    id: '5d7f8988d8600049f2fe6902',
    nid: 160,
    name: 'Natural',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/natural.jpg',
    surfaceLabel: "Pattern",
    patterns: [
      {
        id: '5d7f8989f84d0c9fb66a9188',
        nid: 259,
        name: 'Austin',
        fullName: 'Bonded Bronze with Natural Patina and Austin pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/austin.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/austin-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/austin-full.jpg',
      },
      {
        id: '5d7f898ac984398556703f65',
        nid: 1107,
        name: 'Chardonnay',
        fullName: 'Bonded Bronze with Natural Patina and Chardonnay pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/chardonnay.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/chardonnay-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/chardonnay-full.jpg',
        captureOnly: true,
      },
      {
        id: '5d7f898b27ec16f169539c44',
        nid: 260,
        name: 'Champagne',
        fullName: 'Bonded Bronze with Natural Patina and Champagne pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/champagne.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/champagne-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/champagne-full.jpg',
        captureOnly: true,
      },
      {
        id: '5d7f898ca563f21089d77fb0',
        nid: 1108,
        name: 'Charleston',
        fullName: 'Bonded Bronze with Natural Patina and Charleston pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/charleston.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/charleston-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/charleston-full.jpg',
      },
      {
        id: '5d7f898d38eb266cbcd7b612',
        nid: 13219,
        name: 'Dash',
        fullName: 'Bonded Bronze with Natural Patina and Dash pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/dash.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/dash-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/dash-full.jpg',
      },
      {
        id: '5d7f898e91744f452d33c545',
        nid: 1109,
        name: 'Delta',
        fullName: 'Bonded Bronze with Natural Patina and Delta pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/delta.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/delta-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/delta-full.jpg',
      },
      {
        id: '5d7f898f73c73c5d6af2b49a',
        nid: 13220,
        name: 'Glint',
        fullName: 'Bonded Bronze with Natural Patina and Glint pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/glint.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/glint-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/glint-full.jpg',
      },
      {
        id: '5d7f89902357fa64ca856e68',
        nid: 265,
        name: 'Grass',
        fullName: 'Bonded Bronze with Natural Patina and Grass pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/grass.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/grass-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/grass-full.jpg',
      },
      {
        id: '5d7f8991d5258b5ea9f8df34',
        nid: 1110,
        name: 'Herringbone',
        fullName: 'Bonded Bronze with Natural Patina and Herringbone pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/herringbone.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/herringbone-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/herringbone-full.jpg',
      },
      {
        id: '5d7f8992e87c41132fb26a37',
        nid: 266,
        name: 'Kalahari',
        fullName: 'Bonded Bronze with Natural Patina and Kalahari pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/kalahari.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/kalahari-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/kalahari-full.jpg',
        captureOnly: true,
      },
      {
        id: '5d7f89938189a4852cee71bf',
        nid: 1111,
        name: 'Loft',
        fullName: 'Bonded Bronze with Natural Patina and Loft pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/loft.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/loft-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/loft-full.jpg',
      },
      {
        id: '5d7f89941a80e5c7ed7d4cef',
        nid: 1112,
        name: 'Mara',
        fullName: 'Bonded Bronze with Natural Patina and Mara pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/mara.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/mara-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/mara-full.jpg',
        captureOnly: true,
      },
      {
        id: '5d7f89958bf2e58b0bc22369',
        nid: 1113,
        name: 'Rain',
        fullName: 'Bonded Bronze with Natural Patina and Rain pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/rain.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/rain-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/rain-full.jpg',
      },
      {
        id: '5d7f8996dc794ce51fa8ce43',
        nid: 1114,
        name: 'Sahara',
        fullName: 'Bonded Bronze with Natural Patina and Sahara pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/sahara.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/sahara-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/sahara-full.jpg',
      },
      {
        id: '5d7f8997aada98bf72f94916',
        nid: 1115,
        name: 'Talus',
        fullName: 'Bonded Bronze with Natural Patina and Talus pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/talus.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/talus-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/talus-full.jpg',
      },
      {
        id: '5d7f8998c86e700ba921c7bf',
        nid: 1116,
        name: 'Terrane',
        fullName: 'Bonded Bronze with Natural Patina and Terrane pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/terrane.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/terrane-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/terrane-full.jpg',
      },
      {
        id: '5d7f89990b7f578bb5120801',
        nid: 13221,
        name: 'Tilt',
        fullName: 'Bonded Bronze with Natural Patina and Tilt pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/tilt.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/tilt-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/tilt-full.jpg',
      },
      {
        id: '5d7f899ab0f16474285eb7b3',
        nid: 1106,
        name: 'Trevia',
        fullName: 'Bonded Bronze with Natural Patina and Trevia pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/trevia.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/trevia-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/trevia-full.jpg',
      },
      {
        id: '5d7f899b082363d4d65debc5',
        nid: 13218,
        name: 'Undercurrent',
        fullName: 'Bonded Bronze with Natural Patina and Undercurrent pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/undercurrent.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/undercurrent-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/undercurrent-full.jpg',
      },
      {
        id: '5d7f899c946e7a48046b17c4',
        nid: 1117,
        name: 'Vancouver',
        fullName: 'Bonded Bronze with Natural Patina and Vancouver pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/vancouver.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/vancouver-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/vancouver-full.jpg',
      },
      {
        id: '5d7f899dda932062f65cf274',
        nid: 1118,
        name: 'Waterfall',
        fullName: 'Bonded Bronze with Natural Patina and Waterfall pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/waterfall.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/waterfall-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-bronze/natural/waterfall-full.jpg',
        captureOnly: true,
      },
    ],
  },
]

export default BondedBronze
