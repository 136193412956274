let VivichromeScribe = [
  {
    id: '5d7f92214a637b52b88b1a59',
    nid: 199,
    name: 'Blackboard',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-scribe/blackboard/blackboard.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f9222bf59d1280f224070',
        nid: 255,
        name: 'Pearlex',
        fullName:
          'ViviChrome Scribe glass, non-magnetic configuration with Blackboard interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-scribe/blackboard/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-scribe/blackboard/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-scribe/blackboard/pearlex-full.jpg',
        finish: {
          id: '5d7f9223ae00c9aa1a5fbaa9',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: false,
        lightplaneOpacity: 0.001,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b2ba6fb16c17e0f76d0',
              nid: 1646,
              title: 'VividGlass, Pearlex Finish, Environment Lighting',
            },
            opacity: 0.25
          }
        ]
      },
      {
        id: '5d7f92241df3168daab6448c',
        nid: 257,
        name: 'Pearlex',
        fullName:
          'ViviChrome Scribe glass, magnetic configuration with Blackboard interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-scribe/blackboard/pearlex-magnetic.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-scribe/blackboard/pearlex-magnetic-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-scribe/blackboard/pearlex-magnetic-full.jpg',
        finish: {
          id: '5d7f9225634407229e0962bb',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: false,
        lightplaneOpacity: 0.001,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b2ba6fb16c17e0f76d0',
              nid: 1646,
              title: 'VividGlass, Pearlex Finish, Environment Lighting',
            },
            opacity: 0.25
          }
        ]
      },
      {
        id: '5d7f9226606f8301e221af51',
        nid: 256,
        name: 'Standard',
        fullName:
          'ViviChrome Scribe glass, non-magnetic configuration with Blackboard interlayer and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-scribe/blackboard/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-scribe/blackboard/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-scribe/blackboard/standard-full.jpg',
        finish: {
          id: '5d7f9227bdfe4b1141432122',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: false,
        lightplaneOpacity: 0.001,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b2ba6fb16c17e0f76d0',
              nid: 1646,
              title: 'VividGlass, Pearlex Finish, Environment Lighting',
            },
            opacity: 0.25
          }
        ]
      },
      {
        id: '5d7f922873038201f8d7cf25',
        nid: 258,
        name: 'Standard',
        fullName:
          'ViviChrome Scribe glass, magnetic configuration with Blackboard interlayer and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-scribe/blackboard/standard-magnetic.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-scribe/blackboard/standard-magnetic-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-scribe/blackboard/standard-magnetic-full.jpg',
        finish: {
          id: '5d7f9229c16e4ca3cf7f432f',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: false,
        lightplaneOpacity: 0.001,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b2532c18c975f0cffcd',
              nid: 1645,
              title: 'VividGlass, Standard Finish, Environment Lighting',
            },
            opacity: 0.25
          }
        ]
      },
    ],
  },
  {
    id: '5d7f922abe770560c9df8b8d',
    nid: 200,
    name: 'Ultra White',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-scribe/ultra-white/ultra-white.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f922bc40eeedc579f294f',
        nid: 251,
        name: 'Pearlex',
        fullName:
          'ViviChrome Scribe glass, magnetic configuration with Ultra White interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-scribe/ultra-white/pearlex-magnetic.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-scribe/ultra-white/pearlex-magnetic-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-scribe/ultra-white/pearlex-magnetic-full.jpg',
        finish: {
          id: '5d7f922c8b1c0cffa7a37942',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: false,
        lightplaneOpacity: 0.001,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b358e884f0afb751013',
              nid: 29855,
              title: 'VividGlass, Pearlex finish, Downlights',
            },
            opacity: 1
          },
          {
            effect: {
              id: '5d7f9b2e5406ca100730c19a',
              nid: 543,
              title: 'VividGlass, Pearlex finish, Perimeter Lighting',
            },
            opacity: 1
          },
          {
            effect: {
              id: '5d7f9b2ba6fb16c17e0f76d0',
              nid: 1646,
              title: 'VividGlass, Pearlex Finish, Environment Lighting',
            },
            opacity: 1
          },
          {
            effect: {
              id: '5d7f9ba1d01bc684f68f0db6',
              nid: 1649,
              title: 'VividGlass, Pearlex Finish, Shadows',
            },
            opacity: 0.15
          }
        ]
      },
      {
        id: '5d7f922d0c8119853e177171',
        nid: 253,
        name: 'Pearlex',
        fullName:
          'ViviChrome Scribe glass, non-magnetic configuration with Ultra White interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-scribe/ultra-white/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-scribe/ultra-white/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-scribe/ultra-white/pearlex-full.jpg',
        finish: {
          id: '5d7f922ec6bf38e67c891cb5',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: false,
        lightplaneOpacity: 0.001,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b358e884f0afb751013',
              nid: 29855,
              title: 'VividGlass, Pearlex finish, Downlights',
            },
            opacity: 1
          },
          {
            effect: {
              id: '5d7f9b2e5406ca100730c19a',
              nid: 543,
              title: 'VividGlass, Pearlex finish, Perimeter Lighting',
            },
            opacity: 1
          },
          {
            effect: {
              id: '5d7f9b2ba6fb16c17e0f76d0',
              nid: 1646,
              title: 'VividGlass, Pearlex Finish, Environment Lighting',
            },
            opacity: 1
          },
          {
            effect: {
              id: '5d7f9ba1d01bc684f68f0db6',
              nid: 1649,
              title: 'VividGlass, Pearlex Finish, Shadows',
            },
            opacity: 0.15
          }
        ]
      },
      {
        id: '5d7f922f625a7f03eb293718',
        nid: 252,
        name: 'Standard',
        fullName:
          'ViviChrome Scribe glass, magnetic configuration with Ultra White interlayer and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-scribe/ultra-white/standard-magnetic.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-scribe/ultra-white/standard-magnetic-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-scribe/ultra-white/standard-magnetic-full.jpg',
        finish: {
          id: '5d7f9230b57c53e68ce69334',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: false,
        lightplaneOpacity: 0.001,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.24
          },
          {
            effect: {
              id: '5d7f9b2532c18c975f0cffcd',
              nid: 1645,
              title: 'VividGlass, Standard Finish, Environment Lighting',
            },
            opacity: 1
          },
        ]
      },
      {
        id: '5d7f9231e10f237674418771',
        nid: 254,
        name: 'Standard',
        fullName:
          'ViviChrome Scribe glass, non-magnetic configuration with Ultra White interlayer and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-scribe/ultra-white/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-scribe/ultra-white/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-scribe/ultra-white/standard-full.jpg',
        finish: {
          id: '5d7f92321a002767f3703a3a',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: false,
        lightplaneOpacity: 0.001,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.24
          },
        ]
      },
    ],
  },
  {
    id: '5d7f92334b554f88e2d6195c',
    nid: 201,
    name: 'White',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-scribe/white/white.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f92349e6a45cc388aa8de',
        nid: 247,
        name: 'Pearlex',
        fullName:
          'ViviChrome Scribe glass, magnetic configuration with White interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-scribe/white/pearlex-magnetic.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-scribe/white/pearlex-magnetic-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-scribe/white/pearlex-magnetic-full.jpg',
        finish: {
          id: '5d7f9235a827445a454e795c',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: false,
        lightplaneOpacity: 0.001,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9ba1d01bc684f68f0db6',
              nid: 1649,
              title: 'VividGlass, Pearlex Finish, Shadows',
            },
            opacity: 0.15
          },
        ]
      },
      {
        id: '5d7f9236765802007d8eecf7',
        nid: 249,
        name: 'Pearlex',
        fullName:
          'ViviChrome Scribe glass, non-magnetic configuration with White interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-scribe/white/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-scribe/white/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-scribe/white/pearlex-full.jpg',
        finish: {
          id: '5d7f923750f7d22b6183f407',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: false,
        lightplaneOpacity: 0.001,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9ba1d01bc684f68f0db6',
              nid: 1649,
              title: 'VividGlass, Pearlex Finish, Shadows',
            },
            opacity: 0.15
          },
        ]
      },
      {
        id: '5d7f9238278ec0e2a37c27a9',
        nid: 248,
        name: 'Standard',
        fullName:
          'ViviChrome Scribe glass, magnetic configuration with White interlayer and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-scribe/white/standard-magnetic.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-scribe/white/standard-magnetic-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-scribe/white/standard-magnetic-full.jpg',
        finish: {
          id: '5d7f923976865d48b9876957',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: false,
        lightplaneOpacity: 0.001,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.24
          },
        ]
      },
      {
        id: '5d7f923a24804c6fa0f09362',
        nid: 250,
        name: 'Standard',
        fullName:
          'ViviChrome Scribe glass, non-magnetic configuration with White interlayer and Standard finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-scribe/white/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-scribe/white/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivichrome-scribe/white/standard-full.jpg',
        finish: {
          id: '5d7f923bc15f306a7a3f1f89',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: false,
        lightplaneOpacity: 0.001,
        whiteLight: true,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.24
          },
        ]
      },
    ],
  },
]

export default VivichromeScribe
