import React from 'react'
import styles from '../SurfacesSearch/surfaces-search.module.css'
import { configShouldWhiteLight  } from '../../../utils/effects-layers'

class LightTemperature extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  onClick = value => {
    const { onClick } = this.props
    onClick(value)
  }

  render(){
    let disabled3k = false
    if(configShouldWhiteLight(this.props.configuration)){
      disabled3k = true
    }
    let className = styles['material__search__lighttemperature']
    let whiteLightClassName = className
    if(this.props.value === 5000){
      whiteLightClassName += ' ' + styles['material__search__active']
    }else{
      className += ' ' + styles['material__search__active']
    }
    return (
      <div className={styles['search__group']}>
        <h3 className={styles['material__search__title']}><span>Light Temperature: </span>{this.props.value ? this.props.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'K' : ''}</h3>
        <div className={styles['search__group__items']}>
          <button disabled={disabled3k} className={className + ' fadeHover'} onClick={(e)=>{this.onClick(3500)}}>
            3,200K
          </button>
          <button disabled={this.props.disabled} className={whiteLightClassName + ' fadeHover'} onClick={(e)=>{this.onClick(5000)}}>
            5,000K
          </button>
        </div>
      </div>
    )
  }

}

export default LightTemperature
