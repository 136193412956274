import React from 'react'
import styles from './panel-selections.module.css'
import PanelItem from './panel-item'
import SubPanels from './SubPanels'
import FrameSubPanels from './frame-subpanels'
import SurfacesSearch from '../SurfacesSearch'
import SurfacesSearchSecondary from '../SurfacesSearchSecondary'
import FramesSearch from '../FramesSearch'
import FramePanels from './frame-panels'

class PanelSelections extends React.Component {
  constructor(props) {
    super(props)
    this.surfacessearchElement = React.createRef()
    this.surfacessearchsecondaryElement = React.createRef()
    this.state = {
      configuration: {},
      dualOpen: false,
      searchType: 'primary',
      showMaterialSearch: false,
    }
  }

  componentDidMount() {
    let initPanel = { ...this.props.configuration.primaryPanels[0] }
    initPanel.type = 'primary'
    initPanel.index = 0
    initPanel.panelCoords.forEach(function (item, index) {
      if (!initPanel.applied) {
        initPanel.panelCoords[index].active = true
      }
    })
    let dualOpen = false
    if (this.props.configuration.additionalDetails) {
      if (
        this.props.configuration.additionalDetails.openingOption ===
        'Front and Rear'
      ) {
        dualOpen = true
      }
    }
    this.setState(
      {
        configuration: this.props.configuration,
        dualOpen: dualOpen,
      },
      function () {
        this.props.onChange(initPanel)
      }
    )
  }

  onClickItem = (panel) => {
    if (panel?.panelCoords) {
      panel.panelCoords.forEach(function (item, index) {
        if (!panel.applied) {
          panel.panelCoords[index].active = true
        }
      })
      this.setState({
        searchType: panel.type,
      })
      this.props.onChange(panel)
    } else if (panel?.type === 'panel-group') {
      this.setState({
        searchType: panel.panels[0].type,
        showMaterialSearch: false,
      })
      this.props.onChange(panel)
    } else {
      this.setState({
        searchType: panel?.type,
        showMaterialSearch: false,
      })
      this.props.onChange(panel)
    }
  }

  applySurface = (
    surface,
    lightplane = false,
    ceramiloc = false,
    customText = '',
    panelOption = '',
  ) => {
    const { applySurface } = this.props
    if (!surface.lightplaneCompatible) {
      lightplane = false
    }
    if (!surface.ceramilocCompatible) {
      ceramiloc = false
    }
    applySurface(
      surface,
      this.props.activePanel,
      lightplane,
      ceramiloc,
      customText,
      panelOption
    )
  }

  setActiveSubPanel = (subPanelIndex, active) => {
    let activePanel = { ...this.props.activePanel }
    activePanel.panelCoords[subPanelIndex].active = active
    this.props.onChange(activePanel)
    if (
      activePanel.panelCoords[subPanelIndex].surface &&
      activePanel.panelCoords[subPanelIndex].active
    ) {
      if (this.state.searchType === 'primary') {
        this.surfacessearchElement.current.resetToFirstTab()
        this.surfacessearchElement.current.onPatternSelect(
          activePanel.panelCoords[subPanelIndex].surface
        )
      } else {
        this.surfacessearchsecondaryElement.current.onPatternSelect(
          activePanel.panelCoords[subPanelIndex].surface
        )
      }
    }
  }

  resetMaterialSearch = (state) => {
    this.setState({
      showMaterialSearch: state,
    })
  }

  setPanelType = (searchType) => {
    this.setState({
      searchType: searchType,
      showMaterialSearch: false,
    })
  }

  shouldRotateThumb = () => {
    var shouldRotate = false
    if (this.props.activePanel) {
      if (this.props.activePanel.panelCoords) {
        this.props.activePanel.panelCoords.forEach(function (coords) {
          if (coords.lsbdrs[0].rotateSurface) {
            shouldRotate = true
            return shouldRotate
          }
        })
      }
    }
    return shouldRotate
  }

  render() {
    let primaryPanels = ''
    let secondaryPanels = ''
    let framePanels = ''
    let rotate = this.shouldRotateThumb()
    let itemKey = 0
    if (this.state.configuration.name) {
      primaryPanels = this.state.configuration.primaryPanels.map(
        function (item, key) {
          item.type = 'primary'
          itemKey++
          if (item.dualOpenHide && this.state.dualOpen) {
            return null
          } else {
            return (
              <PanelItem
                dualOpen={this.state.dualOpen}
                panel={item}
                index={itemKey}
                panelType="primary"
                activePanel={this.props.activePanel}
                key={itemKey}
                onClick={this.onClickItem}
              />
            )
          }
        }.bind(this)
      )
      secondaryPanels = this.state.configuration.secondaryPanels.map(
        function (item, key) {
          item.type = 'secondary'
          itemKey++
          if (item.dualOpenHide && this.state.dualOpen) {
            return null
          } else {
            return (
              <PanelItem
                dualOpen={this.state.dualOpen}
                panel={item}
                index={itemKey}
                panelType="secondary"
                activePanel={this.props.activePanel}
                key={itemKey}
                onClick={this.onClickItem}
              />
            )
          }
        }.bind(this)
      )
      framePanels = (
        <FramePanels
          design={this.props.configuration}
          panels={this.props.framePanels}
          dualOpen={this.state.dualOpen}
          panelType="frame"
          activePanel={this.props.activePanel}
          onClick={this.onClickItem}
        />
      )
    }
    let surfacesSearch = ''
    if (this.state.searchType === 'primary') {
      surfacesSearch = (
        <SurfacesSearch
          ref={this.surfacessearchElement}
          activePanel={this.props.activePanel}
          configuration={this.state.configuration}
          applySurface={this.applySurface}
          showMaterialSearch={this.state.showMaterialSearch}
          data={this.props.data}
        />
      )
    }
    let surfacesSearchSecondary = ''
    if (this.state.searchType === 'secondary') {
      surfacesSearchSecondary = (
        <SurfacesSearchSecondary
          ref={this.surfacessearchsecondaryElement}
          activePanel={this.props.activePanel}
          configuration={this.state.configuration}
          applySurface={this.applySurface}
          shortName={this.props.activePanel.shortName}
          showMaterialSearch={this.state.showMaterialSearch}
          data={this.props.data}
        />
      )
    }
    let framesSearch = ''
    if (this.state.searchType === 'frame') {
      framesSearch = (
        <FramesSearch
          activePanel={this.props.activePanel}
          configuration={this.state.configuration}
          materials={this.props.activePanel.options}
          shortName={this.props.activePanel.shortName}
          applySurface={this.applySurface}
          showMaterialSearch={this.state.showMaterialSearch}
          resetMaterialSearch={this.resetMaterialSearch}
          applyPanelFrameType={this.props.applyPanelFrameType}
          frameType={this.props.frameType}
        />
      )
    }
    return (
      <div>
        <div className={styles['info__panel']}>
          {this.state.configuration.name} | MATERIAL SELECTION: Click on a
          letter to choose a zone within the elevator interior. This will reveal
          the library of materials available for that zone. Click on a swatch to
          see a close-up view of a material, and to apply it to your highlighted
          zone.
        </div>
        <div className={styles['panel__selections']}>
          <div className={styles['panels__inner']}>
            {primaryPanels}
            {secondaryPanels}
            {framePanels}
            <SubPanels
              dualOpen={this.state.dualOpen}
              activePanel={this.props.activePanel}
              setActiveSubPanel={this.setActiveSubPanel}
              selectSubPanels={this.props.selectSubPanels}
              rotate={rotate}
            />
            <FrameSubPanels
              dualOpen={this.state.dualOpen}
              activePanel={this.props.activePanel}
              framePanels={this.props.framePanels}
              onClick={this.onClickItem}
              design={this.props.configuration}
            />
          </div>
          {surfacesSearch}
          {surfacesSearchSecondary}
          {framesSearch}
        </div>
      </div>
    )
  }
}

export default PanelSelections
