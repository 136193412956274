let ElementalCarbon = [
  {
    id: '5d7f8a1fa3775f7d53c69f17',
    nid: 381,
    name: 'Ice',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/elemental-carbon/ice/ice.jpg',
    surfaceLabel: "Pattern",
    patterns: [
      {
        id: '5d7f8a20a672063d102e6d9c',
        nid: 43265,
        name: 'No Pattern',
        fullName: 'Elemental Carbon with Ice finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/none.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/elemental-carbon/ice/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/elemental-carbon/ice/no-pattern-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.56
          },
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.14
          }
        ]
      },
    ],
  },
]

export default ElementalCarbon
