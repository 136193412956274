import React from 'react'
import styles from '../PanelSelections/panel-selections.module.css'
import searchStyles from '../SurfacesSearch/surfaces-search.module.css'
import HandrailSearchPanel from './HandrailSearchPanel'

class HandrailSearch extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      configuration: {}
    }
  }

  componentDidMount() {
    this.setState({
      configuration: this.props.configuration,
    }, function(){

    })
  }

  render(){

    return (
      <div>
        <div className={styles['info__panel']}>
          {this.state.configuration.name} | HANDRAIL SELECTION: Handrails are optional and may be specified for elevator back walls and/or side walls. Six standard series offer a wide range of design and material possibilities. Begin by selecting a series from the options below.
        </div>
        <div className={searchStyles['material__search__submaterials']}>
          <HandrailSearchPanel
            applyHandrailLocation={this.props.applyHandrailLocation}
            applyHandrailSeries={this.props.applyHandrailSeries}
            applyHandrailMaterial={this.props.applyHandrailMaterial}
            applyStandoff={this.props.applyStandoff}
            applyStandoffMaterial={this.props.applyStandoffMaterial}
            configuration={this.props.configuration}
            applyCrashRail={this.props.applyCrashRail}
            applyCrashRailLocation={this.props.applyCrashRailLocation}
            dualOpen={this.props.dualOpen}
          />
        </div>
      </div>
    )
  }

}

export default HandrailSearch
