import React, { Component } from 'react'
import styles from './config-select.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Preset extends Component {
  render() {
    return (
      <>
        <div className={this.props.className}>
          {this.props.activeConfigFamily !== this.props.family._id && (
            <button
              className={styles['config__button']}
              onClick={(e) => this.props.onClickFamily(this.props.family._id)}
            >
              <div className={styles['button__inner']}>
                <div className={styles['config__title']}>{this.props.family.name}</div>
                <img
                  src={this.props.family.thumbnailImage.publicURL}
                  alt={this.props.family.name}
                  style={{
                    height: '70px',
                  }}
                />
              </div>
            </button>
          )}
          {this.props.activeConfigFamily === this.props.family._id && (
            <div className={styles['button__inner']}>
              <div className={styles['config__title']}>
                {this.props.family.name}
                <br />
                OPENING OPTIONS
              </div>
              <button onClick={(e) => this.props.onChangeOpen('Front')}>
                {this.props.frontActive}
                <span>Front</span>
              </button>
              <button onClick={(e) => this.props.onChangeOpen('Front and Rear')}>
                {this.props.frontRearActive}
                <span>Front & Rear</span>
              </button>
            </div>
          )}
        </div>
        {this.props.presetsInner}
      </>
    )
  }
}
class ConfigPresets extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeConfigFamily: this.props.configurationFamilies[0]._id,
      activePreset: null,
    }
  }

  static propTypes = {}

  onClickPreset = (presetId) => {
    this.setState(
      {
        activePreset: presetId,
      },
      function () {
        var preset = this.props.presets.find(function (preset, i) {
          return presetId === preset._id
        })
        this.props.onConfigPreset(preset)
      }.bind(this)
    )
  }

  onClickFamily = (familyId) => {
    this.setState({
      activeConfigFamily: familyId,
    })
  }

  onChangeOpen = (opening) => {
    this.props.changeOpening(opening)
  }

  render() {
    return (
      <div className={styles['config__select']}>
        {this.props.configurationFamilies.map((family, i) => {
          if (family.presets.length) {
            let className = styles['config__item']
            let presetsInner = null
            let frontActive = null
            let frontRearActive = null
            let opening = 'Front'
            if (this.props.design.additionalDetails) {
              opening = this.props.design.additionalDetails.openingOption
            }
            if (this.state.activeConfigFamily === family._id) {
              className += ' ' + styles['config__active']
              frontActive = (
                <FontAwesomeIcon
                  icon="check-square"
                  className={styles['config__active__icon__svg']}
                />
              )
              frontRearActive = <div className={styles['opening__unchecked']} />
              if (opening === 'Front and Rear') {
                frontActive = <div className={styles['opening__unchecked']} />
                frontRearActive = (
                  <FontAwesomeIcon
                    icon="check-square"
                    className={styles['config__active__icon__svg']}
                  />
                )
              }
              let presets = family.presets.map((presetId, j) => {
                let preset = this.props.presets.find((item) => {
                  return item._id === presetId.id.id
                })
                let presetImage = ''
                if (preset.presetImageFile) {
                  presetImage = preset.presetImageFile.publicURL
                }
                let configStyle = styles['config__preset']
                if (preset._id === this.state.activePreset) {
                  configStyle += ' ' + styles['config__preset__selected']
                }
                return (
                  <button
                    key={i + ' ' + j}
                    className={configStyle}
                    onClick={(e) => this.onClickPreset(preset._id)}
                  >
                    <h3 style={{ margin: '0px' }}>{preset.name}</h3>
                    <img alt={preset.name} src={presetImage} />
                  </button>
                )
              })
              let rowStart = parseInt(i / 3) + 2
              let arrowPosClass = ''
              if (i % 3 === 0) {
                arrowPosClass = styles['arrow__left']
              }
              if (i % 3 === 2) {
                arrowPosClass = styles['arrow__right']
              }
              presetsInner = (
                <div
                  className={styles['config__presets'] + ' ' + arrowPosClass}
                  style={{
                    gridRow: rowStart + ' / ' + rowStart,
                  }}
                >
                  <div className={styles['presets__inner']}>{presets}</div>
                </div>
              )
            }

            return (
              <Preset 
              key={i}
              className={className}
              family={family}
              onClickFamily={this.onClickFamily}
              activeConfigFamily={this.state.activeConfigFamily}
              onChangeOpen={this.onChangeOpen}
              frontActive={frontActive}
              frontRearActive={frontRearActive}
              presetsInner={presetsInner}
              />
            )
          } else {
            return null
          }
        })}
      </div>
    )
  }
}

export default ConfigPresets
