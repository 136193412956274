import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './tabs.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Tab extends Component {
  static propTypes = {
    activeTab: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }

  onClick = () => {
    const { label, onClick, active } = this.props
    if(active){
      onClick(label)
    }
  }

  render() {
    const {
      onClick,
      props: { activeTab, label, id, complete, overrideImage, active },
    } = this

    let className = styles['tabListItem']
    // className += ' ' + styles[id]
    if (activeTab === label) {
      className += ' active ' + styles['tabListActive']
    }
    if(!active){
      className += ' ' + styles['tabListDisabled']
    }
    let spanStyle = styles['tabIcon'] + ' ' + styles[id]
    let completeCheck = null
    let overrideStyle = null
    if(complete){
      className += ' ' + styles['tabListComplete']
      let checkStyle = styles['complete__check']
      if(this.props.completeCenterCheck){
        checkStyle += ' ' + styles['complete__check__center']
      }
      completeCheck = <FontAwesomeIcon icon="check-circle" className={checkStyle}/>
    }
    if(overrideImage){
      overrideStyle = {
        backgroundImage: 'url('+overrideImage+')',
        backgroundSize: 'contain'
      }
    }

    return (
      <button className={className} onClick={onClick}>
        <span className={styles['tab__title']}>{label}</span>
        <span className={spanStyle} style={overrideStyle}></span>
        {completeCheck}
      </button>
    )
  }
}

export default Tab
