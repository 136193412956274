import React from 'react'
import panelStyles from '../PanelSelections/panel-selections.module.css'
import styles from './review-tab.module.css'
import AdditionalDetailsForm from './AdditionalDetailsForm'
import {
  createDesign
} from '../../../utils/design'
import {
  getProject
} from '../../../utils/project'
import { navigate } from '@reach/router'
class ReviewTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      configuration: {},
      expandDetailsForm: '',
    }
  }

  componentDidMount() {
    this.setState({
      configuration: this.props.configuration,
    }, function(){

    })
  }

  toggleExpandDetailsForm = (e) =>{
    e.preventDefault()
    let value = 'hidden'
    if(this.state.expandDetailsForm){
      value = ''
    }
    this.setState({
      expandDetailsForm: value
    })
  }


  handleCreateDesign = () => {
    getProject(this.state.configuration.project)
    .then(function(project){
      createDesign(this.state.configuration.project, project.designs.length + 1)
      .then(function(newDesign){

        this.props.handleNavigate('/design/'+newDesign._id, {designid: newDesign._id})
        // navigate('/design/'+newDesign._id)
      }.bind(this))
      .catch(function(err){
        console.log(err)
      })
    }.bind(this))
    .catch(function(err){
      console.log(err);
    })
  }

  handleGotoProject = () => {
    navigate('/project/'+this.state.configuration.project)
  }

  handleDetailsFormUpdate = (design) => {
    this.props.handleDetailsFormUpdate(design.additionalDetails)
  }

  render(){
    let additionalDetails = {
      quantity: 1
    }
    if(this.props.configuration.additionalDetails){
      additionalDetails = this.props.configuration.additionalDetails
    }
    return (
      <div>
        <div className={panelStyles['info__panel']}>
          {this.state.configuration.name} | DESIGN REVIEW: Your elevator design is complete. At right, you can see how it looks. Below, you can provide additional details required for the ‘Advanced Download’ feature. Finally, you can choose where to go from here: download a PDF of your design; begin a new design; or go to your ‘Projects’ folder.
        </div>
        <div className={styles['review__panel']}>
          <h3>+DIMENSIONS</h3>
          <p>Dimensions are not mandatory at this stage to complete your
design. However, you will need to provide them to request an
‘Advanced Download’. You can do this now or in the future.</p>
          <AdditionalDetailsForm
            formExpanded={this.state.expandDetailsForm}
            toggleExpand={this.toggleExpandDetailsForm}
            handleUpdate={ design => this.handleDetailsFormUpdate(design) }
            additionalDetails={additionalDetails}
            design={this.props.configuration}
          />
        </div>
        <div className={styles['review__panel']}>
          <h3>Wrap-Up</h3>
          <p>You’ve reached the end of the design process. Your selections have automatically been saved. From here, you can choose from the options below to: download a PDF that provides a visual recap and product details for your design; begin a new design; or go your Project page.</p>
          <div className={styles['panel__frames']}>
          </div>
          <button onClick={this.props.openPDF}
          className={styles['design__download'] + ' ' + styles['review__tab__action']}>
          <span>Download</span></button>
          <button
          onClick={this.handleCreateDesign}
          className={styles['design__add'] + ' ' + styles['review__tab__action']}>
          <span>New Elevator Interior</span>
          </button>
          <button
          onClick={this.handleGotoProject}
          className={styles['goto__project'] + ' ' + styles['review__tab__action']}>
          <span>Go To Project</span>
          </button>
        </div>
      </div>
    )
  }

}

export default ReviewTab
