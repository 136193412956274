let BondedNickelSilver = [
  {
    id: '5d7f899b22c8bbcad184b034',
    nid: 161,
    name: 'Dark',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/dark/dark.jpg',
    surfaceLabel: "Pattern",
    patterns: [
      {
        id: '5d7f899c954f0a318f559e96',
        nid: 1404,
        name: 'Austin',
        fullName: 'Bonded Nickel Silver with Dark Patina and Austin pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/dark/austin.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/dark/austin-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/dark/austin-full.jpg',
      },
      {
        id: '5d7f899df86d6f9726a1a35d',
        nid: 1405,
        name: 'Charleston',
        fullName:
          'Bonded Nickel Silver with Dark Patina and Charleston pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/dark/charleston.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/dark/charleston-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/dark/charleston-full.jpg',
      },
      {
        id: '5d7f899ec50db54eda6616ac',
        nid: 13229,
        name: 'Dash',
        fullName: 'Bonded Nickel Silver with Dark Patina and Dash pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/dark/dash.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/dark/dash-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/dark/dash-full.jpg',
      },
      {
        id: '5d7f899f6e81d5a0faaea8a6',
        nid: 1406,
        name: 'Delta',
        fullName: 'Bonded Nickel Silver with Dark Patina and Delta pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/dark/delta.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/dark/delta-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/dark/delta-full.jpg',
      },
      {
        id: '5d7f89a07e38577024f1a816',
        nid: 13230,
        name: 'Glint',
        fullName: 'Bonded Nickel Silver with Dark Patina and Glint pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/dark/glint.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/dark/glint-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/dark/glint-full.jpg',
      },
      {
        id: '5d7f89a1f676f2186c82f20c',
        nid: 1407,
        name: 'Grass',
        fullName: 'Bonded Nickel Silver with Dark Patina and Grass pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/dark/grass.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/dark/grass-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/dark/grass-full.jpg',
      },
      {
        id: '5d7f89a29b45519b6144baae',
        nid: 1408,
        name: 'Herringbone',
        fullName:
          'Bonded Nickel Silver with Dark Patina and Herringbone pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/dark/herringbone.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/dark/herringbone-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/dark/herringbone-full.jpg',
      },
      {
        id: '5d7f89a36df72f4232332056',
        nid: 1409,
        name: 'Loft',
        fullName: 'Bonded Nickel Silver with Dark Patina and Loft pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/dark/loft.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/dark/loft-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/dark/loft-full.jpg',
      },
      {
        id: '5d7f89a4937e2432ca2fe993',
        nid: 1410,
        name: 'Rain',
        fullName: 'Bonded Nickel Silver with Dark Patina and Rain pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/dark/rain.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/dark/rain-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/dark/rain-full.jpg',
      },
      {
        id: '5d7f89a5ad330f2d621d6c1f',
        nid: 752,
        name: 'Talus',
        fullName: 'Bonded Nickel Silver with Dark Patina and Talus pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/dark/talus.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/dark/talus-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/dark/talus-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9aebbfbc49bc1cc378d7',
              nid: 549,
              title: 'Bonded Metal, Bonded Quartz & Wood Veneer Downlights',
            },
            opacity: 0.5
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.25
          },
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.9
          }
        ]
      },
      {
        id: '5d7f89a6d0e7b5d45456ca1f',
        nid: 13231,
        name: 'Tilt',
        fullName: 'Bonded Nickel Silver with Dark Patina and Tilt pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/dark/tilt.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/dark/tilt-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/dark/tilt-full.jpg',
      },
      {
        id: '5d7f89a7d37ee91e1e96c17b',
        nid: 1411,
        name: 'Trevia',
        fullName: 'Bonded Nickel Silver with Dark Patina and Trevia pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/dark/trevia.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/dark/trevia-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/dark/trevia-full.jpg',
      },
      {
        id: '5d7f89a865563961f16a0681',
        nid: 273,
        name: 'Vancouver',
        fullName: 'Bonded Nickel Silver with Dark Patina and Vancouver pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/dark/vancouver.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/dark/vancouver-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/dark/vancouver-full.jpg',
      },
    ],
  },
  {
    id: '5d7f89a91194bf963dfa7026',
    nid: 160,
    name: 'Natural',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/natural.jpg',
    surfaceLabel: "Pattern",
    patterns: [
      {
        id: '5d7f89aace03babb255e5db9',
        nid: 1291,
        name: 'Austin',
        fullName: 'Bonded Nickel Silver with Natural Patina and Austin pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/austin.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/austin-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/austin-full.jpg',
      },
      {
        id: '5d7f89ab149663cc371f0337',
        nid: 262,
        name: 'Chardonnay',
        fullName:
          'Bonded Nickel Silver with Natural Patina and Chardonnay pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/chardonnay.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/chardonnay-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/chardonnay-full.jpg',
        captureOnly: true,
      },
      {
        id: '5d7f89ac6046b9f850b55d4a',
        nid: 1292,
        name: 'Champagne',
        fullName:
          'Bonded Nickel Silver with Natural Patina and Champagne pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/champagne.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/champagne-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/champagne-full.jpg',
        captureOnly: true,
      },
      {
        id: '5d7f89ad43fd0aced774d6e4',
        nid: 1296,
        name: 'Charleston',
        fullName:
          'Bonded Nickel Silver with Natural Patina and Charleston pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/charleston.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/charleston-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/charleston-full.jpg',
      },
      {
        id: '5d7f89aea405381df7c50deb',
        nid: 13226,
        name: 'Dash',
        fullName: 'Bonded Nickel Silver with Natural Patina and Dash pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/dash.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/dash-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/dash-full.jpg',
      },
      {
        id: '5d7f89afb94ce1022dd391df',
        nid: 1297,
        name: 'Delta',
        fullName: 'Bonded Nickel Silver with Natural Patina and Delta pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/delta.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/delta-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/delta-full.jpg',
      },
      {
        id: '5d7f89b0f260bdfe9b968e4b',
        nid: 13227,
        name: 'Glint',
        fullName: 'Bonded Nickel Silver with Natural Patina and Glint pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/glint.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/glint-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/glint-full.jpg',
      },
      {
        id: '5d7f89b1fbbace27dad0c7ee',
        nid: 1298,
        name: 'Grass',
        fullName: 'Bonded Nickel Silver with Natural Patina and Grass pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/grass.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/grass-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/grass-full.jpg',
      },
      {
        id: '5d7f89b2ad782a314546d4b1',
        nid: 1299,
        name: 'Herringbone',
        fullName:
          'Bonded Nickel Silver with Natural Patina and Herringbone pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/herringbone.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/herringbone-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/herringbone-full.jpg',
      },
      {
        id: '5d7f89b3e1adc9bc7efdbc12',
        nid: 1300,
        name: 'Kalahari',
        fullName:
          'Bonded Nickel Silver with Natural Patina and Kalahari pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/kalahari.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/kalahari-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/kalahari-full.jpg',
        captureOnly: true,
      },
      {
        id: '5d7f89b4bc1f8b8a773db79b',
        nid: 1301,
        name: 'Loft',
        fullName: 'Bonded Nickel Silver with Natural Patina and Loft pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/loft.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/loft-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/loft-full.jpg',
      },
      {
        id: '5d7f89b5d26545875a0f0005',
        nid: 1302,
        name: 'Mara',
        fullName: 'Bonded Nickel Silver with Natural Patina and Mara pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/mara.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/mara-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/mara-full.jpg',
        captureOnly: true,
      },
      {
        id: '5d7f89b699a5bb9388b26742',
        nid: 1303,
        name: 'Rain',
        fullName: 'Bonded Nickel Silver with Natural Patina and Rain pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/rain.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/rain-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/rain-full.jpg',
      },
      {
        id: '5d7f89b7af2f28d1be502cb1',
        nid: 1304,
        name: 'Sahara',
        fullName: 'Bonded Nickel Silver with Natural Patina and Sahara pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/sahara.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/sahara-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/sahara-full.jpg',
      },
      {
        id: '5d7f89b8609ab56d81abb1bf',
        nid: 1305,
        name: 'Talus',
        fullName: 'Bonded Nickel Silver with Natural Patina and Talus pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/talus.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/talus-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/talus-full.jpg',
      },
      {
        id: '5d7f89b92046e0ac87fd1e4a',
        nid: 1306,
        name: 'Terrane',
        fullName:
          'Bonded Nickel Silver with Natural Patina and Terrane pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/terrane.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/terrane-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/terrane-full.jpg',
      },
      {
        id: '5d7f89ba5e3c3fdac47e39e4',
        nid: 13228,
        name: 'Tilt',
        fullName: 'Bonded Nickel Silver with Natural Patina and Tilt pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/tilt.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/tilt-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/tilt-full.jpg',
      },
      {
        id: '5d7f89bbd975317f0d7c6d8a',
        nid: 1307,
        name: 'Trevia',
        fullName: 'Bonded Nickel Silver with Natural Patina and Trevia pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/trevia.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/trevia-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/trevia-full.jpg',
      },
      {
        id: '5d7f89bcd1d2720b809e6554',
        nid: 13225,
        name: 'Undercurrent',
        fullName:
          'Bonded Nickel Silver with Natural Patina and Undercurrent pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/undercurrent.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/undercurrent-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/undercurrent-full.jpg',
      },
      {
        id: '5d7f89bd16f5db80ec4e9a51',
        nid: 1308,
        name: 'Vancouver',
        fullName:
          'Bonded Nickel Silver with Natural Patina and Vancouver pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/vancouver.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/vancouver-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/vancouver-full.jpg',
      },
      {
        id: '5d7f89be3970c7404fe1638b',
        nid: 1309,
        name: 'Waterfall',
        fullName:
          'Bonded Nickel Silver with Natural Patina and Waterfall pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/waterfall.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/waterfall-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-nickel-silver/natural/waterfall-full.jpg',
        captureOnly: true,
      },
    ],
  },
]

export default BondedNickelSilver
