let FusedNickelSilver = [
  {
    id: '5d7f8d30fb55f5dc7545a703',
    nid: 155,
    name: 'Sandstone',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/sandstone/sandstone.jpg',
    surfaceLabel: "Pattern",
    patterns: [
      {
        id: '5d7f8d31163a3764da3a8e49',
        nid: 92,
        name: 'No Pattern',
        fullName: 'Fused Nickel Silver with Sandstone finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/none.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/sandstone/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/sandstone/no-pattern-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.14
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.56
          }
        ]
      },
      {
        id: '5d7f8d3271b760cf32902e6e',
        nid: 898,
        name: 'Champagne Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Silver with Sandstone finish and Champagne Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/sandstone/champagne-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/sandstone/champagne-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/sandstone/champagne-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.14
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.56
          }
        ]
      },
      {
        id: '5d7f8d33134a8d6c1a09e421',
        nid: 923,
        name: 'Chardonnay Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Silver with Sandstone finish and Chardonnay Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/sandstone/chardonnay-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/sandstone/chardonnay-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/sandstone/chardonnay-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.14
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.56
          }
        ]
      },
      {
        id: '5d7f8d34bab1900612368a13',
        nid: 954,
        name: 'Dallas Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Silver with Sandstone finish and Dallas Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/sandstone/dallas-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/sandstone/dallas-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/sandstone/dallas-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.14
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.56
          }
        ]
      },
      {
        id: '5d7f8d35d5fbfc736ed09662',
        nid: 986,
        name: 'Denver Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Silver with Sandstone finish and Denver Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/sandstone/denver-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/sandstone/denver-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/sandstone/denver-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.14
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.56
          }
        ]
      },
      {
        id: '5d7f8d368071d0a51d68d155',
        nid: 1019,
        name: 'Dune Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Silver with Sandstone finish and Dune Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/sandstone/dune-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/sandstone/dune-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/sandstone/dune-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.14
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.56
          }
        ]
      },
      {
        id: '5d7f8d37f0ca484e5580c7d3',
        nid: 1040,
        name: 'Kalahari Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Silver with Sandstone finish and Kalahari Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/sandstone/kalahari-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/sandstone/kalahari-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/sandstone/kalahari-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.14
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.56
          }
        ]
      },
    ],
  },
  {
    id: '5d7f8d38513a342c626df343',
    nid: 156,
    name: 'Seastone',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/seastone.jpg',
    surfaceLabel: "Pattern",
    patterns: [
      {
        id: '5d7f8d392d9627f0b29564cb',
        nid: 601,
        name: 'No Pattern',
        fullName: 'Fused Nickel Silver with Seastone finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/none.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/no-pattern-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f8d3ab9b21da75304650b',
        nid: 109,
        name: 'City Lights Eco-Etch',
        fullName:
          'Fused Nickel Silver with Seastone finish and City Lights Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/city-lights-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/city-lights-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/city-lights-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f8d3b13c210b097e06413',
        nid: 8013,
        name: 'Glacier Eco-Etch',
        fullName:
          'Fused Nickel Silver with Seastone finish and Glacier Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/glacier-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/glacier-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/glacier-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f8d3c8addb53a1a4c287d',
        nid: 7983,
        name: 'Current Eco-Etch',
        fullName:
          'Fused Nickel Silver with Seastone finish and Current Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/current-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/current-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/current-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f8d3d9b6c4dc697c2ecf1',
        nid: 7998,
        name: 'Dash Eco-Etch',
        fullName:
          'Fused Nickel Silver with Seastone finish and Dash Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/dash-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/dash-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/dash-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f8d3e72b9bde2eab18fc0',
        nid: 110,
        name: 'Flicker Eco-Etch',
        fullName:
          'Fused Nickel Silver with Seastone finish and Flicker Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/flicker-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/flicker-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/flicker-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f8d3f3540233a50630be7',
        nid: 111,
        name: 'Mica Eco-Etch',
        fullName:
          'Fused Nickel Silver with Seastone finish and Mica Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/mica-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/mica-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/mica-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f8d40181640f10743bce3',
        nid: 82,
        name: 'Reeds Eco-Etch',
        fullName:
          'Fused Nickel Silver with Seastone finish and Reeds Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/reeds-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/reeds-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/reeds-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f8d41a137be0b390186ee',
        nid: 2119,
        name: 'Seagrass Eco-Etch',
        fullName:
          'Fused Nickel Silver with Seastone finish and Seagrass Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/seagrass-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/seagrass-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/seagrass-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f8d42037adb9c4ab73b53',
        nid: 2131,
        name: 'Sequence Eco-Etch',
        fullName:
          'Fused Nickel Silver with Seastone finish and Sequence Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/sequence-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/sequence-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/sequence-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f8d4369472268b562fc7a',
        nid: 2145,
        name: 'Silkworm Eco-Etch',
        fullName:
          'Fused Nickel Silver with Seastone finish and Silkworm Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/silkworm-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/silkworm-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/silkworm-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f8d44b7ebb7a5f8500ad6',
        nid: 116,
        name: 'Stacks Eco-Etch',
        fullName:
          'Fused Nickel Silver with Seastone finish Stacks Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/stacks-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/stacks-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/stacks-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f8d45286dcf74247b63b8',
        nid: 117,
        name: 'Stripe Eco-Etch',
        fullName:
          'Fused Nickel Silver with Seastone finish and Stripe Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/stripe-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/stripe-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/stripe-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f8d46eb4afae092377695',
        nid: 8031,
        name: 'Trace Eco-Etch',
        fullName:
          'Fused Nickel Silver with Seastone finish and Trace Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/trace-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/trace-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/trace-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f8d4778b2e0a90316a1f0',
        nid: 1813,
        name: 'Carbon Fusion',
        fullName:
          'Fused Nickel Silver with Seastone finish and Carbon Fusion pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/carbon-fusion.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/carbon-fusion-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/carbon-fusion-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f8d489d364a3ab6072729',
        nid: 120,
        name: 'Cinch Fusion',
        fullName:
          'Fused Nickel Silver with Seastone finish and Cinch Fusion pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/cinch-fusion.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/cinch-fusion-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/cinch-fusion-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f8d49fad7d0d875de5cf5',
        nid: 122,
        name: 'Moraine Fusion',
        fullName:
          'Fused Nickel Silver with Seastone finish and Moraine Fusion pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/moraine-fusion.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/moraine-fusion-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/moraine-fusion-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f8d4ab005e43ce8b64e18',
        nid: 900,
        name: 'Champagne Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Silver with Seastone finish and Champagne Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/champagne-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/champagne-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/champagne-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f8d4bd99ea09ed9fcdc87',
        nid: 925,
        name: 'Chardonnay Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Silver with Seastone finish and Chardonnay Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/chardonnay-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/chardonnay-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/chardonnay-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f8d4cd466bddfb7e0d2fb',
        nid: 956,
        name: 'Dallas Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Silver with Seastone finish and Dallas Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/dallas-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/dallas-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/dallas-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f8d4d1b7e4dc75fa53b21',
        nid: 988,
        name: 'Denver Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Silver with Seastone finish and Denver Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/denver-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/denver-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/denver-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f8d4e9a568cb62b8ef9da',
        nid: 1021,
        name: 'Dune Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Silver with Seastone finish and Dune Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/dune-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/dune-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/dune-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.65
          }
        ]
      },
      {
        id: '5d7f8d4fe2e012e24dfd5bbb',
        nid: 1042,
        name: 'Kalahari Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Silver with Seastone finish and Kalahari Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/kalahari-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/kalahari-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/seastone/kalahari-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.16
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.65
          }
        ]
      },
    ],
  },
  {
    id: '5d7f8d5071c5528cfa3be67b',
    nid: 157,
    name: 'Linen',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/linen/linen.jpg',
    surfaceLabel: "Pattern",
    patterns: [
      {
        id: '5d7f8d5163804f33ec1184c5',
        nid: 90,
        name: 'No Pattern',
        fullName: 'Fused Nickel Silver with Linen finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/none.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/linen/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/linen/no-pattern-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.13
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8d527cbce9b3a08d850f',
        nid: 896,
        name: 'Champagne Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Silver with Linen finish and Champagne Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/linen/champagne-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/linen/champagne-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/linen/champagne-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.13
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8d5398573c74443bdd24',
        nid: 921,
        name: 'Chardonnay Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Silver with Linen finish and Chardonnay Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/linen/chardonnay-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/linen/chardonnay-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/linen/chardonnay-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.13
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8d54cb62fd95e38e84c1',
        nid: 952,
        name: 'Dallas Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Silver with Linen finish and Dallas Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/linen/dallas-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/linen/dallas-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/linen/dallas-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.13
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8d556db6a09efecf127a',
        nid: 984,
        name: 'Denver Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Silver with Linen finish and Denver Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/linen/denver-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/linen/denver-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/linen/denver-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.13
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8d56cd26c4659fbfaba8',
        nid: 1017,
        name: 'Dune Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Silver with Linen finish and Dune Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/linen/dune-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/linen/dune-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/linen/dune-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.13
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8d575dbfdb5963b9a95d',
        nid: 1038,
        name: 'Kalahari Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Silver with Linen finish and Kalahari Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/linen/kalahari-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/linen/kalahari-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/linen/kalahari-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.13
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.5
          }
        ]
      },
    ],
  },
  {
    id: '5d7f8d58466fb967de1cac6a',
    nid: 140,
    name: 'Diamond',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/diamond/diamond.jpg',
    surfaceLabel: "Pattern",
    patterns: [
      {
        id: '5d7f8d59ce2acb67b4244bc7',
        nid: 758,
        name: 'No Pattern',
        fullName: 'Fused Nickel Silver with Diamond finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/none.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/diamond/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/diamond/no-pattern-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.14
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.56
          }
        ]
      },
      {
        id: '5d7f8d5a4abc165ac4ca7b2a',
        nid: 1070,
        name: 'Champagne Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Silver with Diamond finish and Champagne Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/diamond/champagne-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/diamond/champagne-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/diamond/champagne-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.14
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.56
          }
        ]
      },
      {
        id: '5d7f8d5b6fa8acbf329ae580',
        nid: 1066,
        name: 'Chardonnay Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Silver with Diamond finish and Chardonnay Impression pattern ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/diamond/chardonnay-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/diamond/chardonnay-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/diamond/chardonnay-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.14
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.56
          }
        ]
      },
      {
        id: '5d7f8d5c7cd195469d4c0dee',
        nid: 951,
        name: 'Dallas Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Silver with Diamond finish and Dallas Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/diamond/dallas-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/diamond/dallas-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/diamond/dallas-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.14
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.56
          }
        ]
      },
      {
        id: '5d7f8d5d650ac204a57e4a73',
        nid: 983,
        name: 'Denver Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Silver with Diamond finish and Denver Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/diamond/denver-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/diamond/denver-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/diamond/denver-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.14
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.56
          }
        ]
      },
      {
        id: '5d7f8d5e3ffc79778bea62fb',
        nid: 1062,
        name: 'Dune Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Silver with Diamond finish and Dune Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/diamond/dune-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/diamond/dune-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/diamond/dune-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.14
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.56
          }
        ]
      },
      {
        id: '5d7f8d5f0919b3f3cd203983',
        nid: 1058,
        name: 'Kalahari Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Silver with Diamond finish and Kalahari Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/diamond/kalahari-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/diamond/kalahari-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/diamond/kalahari-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.14
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.8
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.56
          }
        ]
      },
    ],
  },
  {
    id: '5d7f8d600ddee435b2b89b16',
    nid: 143,
    name: 'Mirror',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/mirror.jpg',
    surfaceLabel: "Pattern",
    patterns: [
      {
        id: '5d7f8d61fd242ad6c23f0e0a',
        nid: 91,
        name: 'No Pattern',
        fullName: 'Fused Nickel Silver with Mirror finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/none.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/no-pattern-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8d628b62bcf078fe6359',
        nid: 1487,
        name: 'City Lights Eco-Etch',
        fullName:
          'Fused Nickel Silver with Mirror finish and City Lights Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/city-lights-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/city-lights-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/city-lights-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8d63766a06b955321673',
        nid: 8015,
        name: 'Glacier Eco-Etch',
        fullName:
          'Fused Nickel Silver with Mirror finish and Glacier Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/glacier-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/glacier-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/glacier-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8d64a39fcbea2a42a60f',
        nid: 7985,
        name: 'Current Eco-Etch',
        fullName:
          'Fused Nickel Silver with Mirror finish and Current Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/current-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/current-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/current-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8d65616ae041cdd303f8',
        nid: 8000,
        name: 'Dash Eco-Etch',
        fullName:
          'Fused Nickel Silver with Mirror finish and Dash Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/dash-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/dash-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/dash-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8d6615620245a1cb90ac',
        nid: 1919,
        name: 'Flicker Eco-Etch',
        fullName:
          'Fused Nickel Silver with Mirror finish and Flicker Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/flicker-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/flicker-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/flicker-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8d67487109cb657f5ef3',
        nid: 1931,
        name: 'Mica Eco-Etch',
        fullName:
          'Fused Nickel Silver with Mirror finish and Mica Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/mica-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/mica-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/mica-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8d6867011b636a17bbc9',
        nid: 1942,
        name: 'Reeds Eco-Etch',
        fullName:
          'Fused Nickel Silver with Mirror finish and Reeds Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/reeds-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/reeds-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/reeds-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8d695fb2bbdf029b65e3',
        nid: 2117,
        name: 'Seagrass Eco-Etch',
        fullName:
          'Fused Nickel Silver with Mirror finish and Seagrass Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/seagrass-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/seagrass-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/seagrass-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8d6a69cc1d0ef4f6c986',
        nid: 2129,
        name: 'Sequence Eco-Etch',
        fullName:
          'Fused Nickel Silver with Mirror finish and Sequence Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/sequence-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/sequence-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/sequence-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8d6b5cbd3fa2efc2ef1f',
        nid: 2143,
        name: 'Silkworm Eco-Etch',
        fullName:
          'Fused Nickel Silver with Mirror finish and Silkworm Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/silkworm-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/silkworm-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/silkworm-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8d6cf77762a228496919',
        nid: 2251,
        name: 'Stacks Eco-Etch',
        fullName:
          'Fused Nickel Silver with Mirror finish and Stacks Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/stacks-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/stacks-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/stacks-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8d6dc06d32bbb992b861',
        nid: 8688,
        name: 'Stripe Eco-Etch',
        fullName:
          'Fused Nickel Silver with Mirror finish and Stripe Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/stripe-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/stripe-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/stripe-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8d6e453dd7e7d136c137',
        nid: 8704,
        name: 'Thatch Eco-Etch',
        fullName:
          'Fused Nickel Silver with Mirror finish and Thatch Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/thatch-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/thatch-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/thatch-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8d6f4b8ba3f251bbe247',
        nid: 8033,
        name: 'Trace Eco-Etch',
        fullName:
          'Fused Nickel Silver with Mirror finish and Trace Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/trace-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/trace-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/trace-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8d705b47637522011b50',
        nid: 1795,
        name: 'Carbon Fusion',
        fullName:
          'Fused Nickel Silver with Mirror finish and Carbon Fusion pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/carbon-fusion.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/carbon-fusion-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/carbon-fusion-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8d71831ef80c53c56c09',
        nid: 1819,
        name: 'Cinch Fusion',
        fullName:
          'Fused Nickel Silver with Mirror finish and Cinch Fusion pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/cinch-fusion.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/cinch-fusion-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/cinch-fusion-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8d72d20d807cf3820548',
        nid: 1866,
        name: 'Moraine Fusion',
        fullName:
          'Fused Nickel Silver with Mirror finish and Moraine Fusion pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/moraine-fusion.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/moraine-fusion-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/moraine-fusion-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8d73b2f427e7d73aa522',
        nid: 897,
        name: 'Champagne Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Silver with Mirror finish and Champagne Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/champagne-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/champagne-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/champagne-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8d745c27498f12f3f9d4',
        nid: 922,
        name: 'Chardonnay Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Silver with Mirror finish and Chardonnay Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/chardonnay-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/chardonnay-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/chardonnay-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8d7584e93643137317f9',
        nid: 953,
        name: 'Dallas Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Silver with Mirror finish and Dallas Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/dallas-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/dallas-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/dallas-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8d760a026dba6d14b6c2',
        nid: 985,
        name: 'Denver Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Silver with Mirror finish and Denver Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/denver-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/denver-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/denver-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8d77c400da4ab156e787',
        nid: 1018,
        name: 'Dune Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Silver with Mirror finish and Dune Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/dune-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/dune-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/dune-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          }
        ]
      },
      {
        id: '5d7f8d78664fe99fa1e14ec6',
        nid: 1039,
        name: 'Kalahari Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Silver with Mirror finish and Kalahari Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/kalahari-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/kalahari-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/mirror/kalahari-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.5
          }
        ]
      },
    ],
  },
  {
    id: '5d7f8d79b528af5893991785',
    nid: 159,
    name: 'Satin',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/satin.jpg',
    surfaceLabel: "Pattern",
    patterns: [
      {
        id: '5d7f8d7ad5c071eade848c88',
        nid: 93,
        name: 'No Pattern',
        fullName: 'Fused Nickel Silver with Satin finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/none.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/no-pattern-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/no-pattern-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8d7b498f96641787aa64',
        nid: 1488,
        name: 'City Lights Eco-Etch',
        fullName:
          'Fused Nickel Silver with Satin finish and City Lights Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/city-lights-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/city-lights-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/city-lights-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8d7c0b91219ed28bf631',
        nid: 8014,
        name: 'Glacier Eco-Etch',
        fullName:
          'Fused Nickel Silver with Satin finish and Glacier Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/glacier-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/glacier-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/glacier-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8d7d85aca983eb9db25d',
        nid: 7984,
        name: 'Current Eco-Etch',
        fullName:
          'Fused Nickel Silver with Satin finish and Current Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/current-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/current-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/current-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8d7e273e721d6221d79f',
        nid: 7999,
        name: 'Dash Eco-Etch',
        fullName:
          'Fused Nickel Silver with Satin finish and Dash Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/dash-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/dash-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/dash-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8d7f40906fdc4421d659',
        nid: 1920,
        name: 'Flicker Eco-Etch',
        fullName:
          'Fused Nickel Silver with Satin finish and Flicker Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/flicker-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/flicker-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/flicker-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8d808a68f3069c3c39e4',
        nid: 1932,
        name: 'Mica Eco-Etch',
        fullName:
          'Fused Nickel Silver with Satin finish and Mica Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/mica-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/mica-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/mica-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8d811fe2217a19c9471c',
        nid: 1943,
        name: 'Reeds Eco-Etch',
        fullName:
          'Fused Nickel Silver with Satin finish and Reeds Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/reeds-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/reeds-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/reeds-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8d828e242fd50c3c3887',
        nid: 2118,
        name: 'Seagrass Eco-Etch',
        fullName:
          'Fused Nickel Silver with Satin finish and Seagrass Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/seagrass-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/seagrass-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/seagrass-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8d83a43032b807b8bb85',
        nid: 2130,
        name: 'Sequence Eco-Etch',
        fullName:
          'Fused Nickel Silver with Satin finish and Sequence Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/sequence-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/sequence-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/sequence-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8d845c59e42c5e74f2e3',
        nid: 2144,
        name: 'Silkworm Eco-Etch',
        fullName:
          'Fused Nickel Silver with Satin finish and Silkworm Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/silkworm-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/silkworm-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/silkworm-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8d8544d7f59912e65f0a',
        nid: 2252,
        name: 'Stacks Eco-Etch',
        fullName:
          'Fused Nickel Silver with Satin finish and Stacks Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/stacks-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/stacks-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/stacks-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8d869e6d8ce441679862',
        nid: 8689,
        name: 'Stripe Eco-Etch',
        fullName:
          'Fused Nickel Silver with Satin finish and Stripe Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/stripe-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/stripe-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/stripe-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8d871f2fa025587b5873',
        nid: 8705,
        name: 'Thatch Eco-Etch',
        fullName:
          'Fused Nickel Silver with Satin finish and Thatch Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/thatch-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/thatch-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/thatch-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8d88af42a022128e08c4',
        nid: 8032,
        name: 'Trace Eco-Etch',
        fullName:
          'Fused Nickel Silver with Satin finish and Trace Eco-Etch pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/trace-eco-etch.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/trace-eco-etch-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/trace-eco-etch-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8d891eca5771b6ac2468',
        nid: 1799,
        name: 'Carbon Fusion',
        fullName:
          'Fused Nickel Silver with Satin finish and Carbon Fusion pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/carbon-fusion.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/carbon-fusion-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/carbon-fusion-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8d8a52064e3a62107537',
        nid: 1820,
        name: 'Cinch Fusion',
        fullName:
          'Fused Nickel Silver with Satin finish and Cinch Fusion pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/cinch-fusion.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/cinch-fusion-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/cinch-fusion-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8d8b63dfe3247d80126a',
        nid: 1870,
        name: 'Moraine Fusion',
        fullName:
          'Fused Nickel Silver with Satin finish and Moraine Fusion pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/moraine-fusion.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/moraine-fusion-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/moraine-fusion-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8d8c0a92b69c113d0736',
        nid: 899,
        name: 'Champagne Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Silver with Satin finish and Champagne Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/champagne-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/champagne-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/champagne-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8d8d04b421b63e378d6d',
        nid: 924,
        name: 'Chardonnay Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Silver with Satin finish and Chardonnay Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/chardonnay-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/chardonnay-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/chardonnay-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8d8e6a24ea3d61c8b7d7',
        nid: 955,
        name: 'Dallas Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Silver with Satin finish and Dallas Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/dallas-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/dallas-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/dallas-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8d8f508a0db8276bec9d',
        nid: 987,
        name: 'Denver Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Silver with Satin finish and Denver Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/denver-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/denver-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/denver-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8d901a5e029775fca2cc',
        nid: 1020,
        name: 'Dune Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Silver with Satin finish and Dune Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/dune-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/dune-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/dune-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
      {
        id: '5d7f8d91af70e383dd1ee5ed',
        nid: 1041,
        name: 'Kalahari Impression',
        pattern: {
          id: '5e3ad017b79643a55da2d0d9',
          nid: 37,
          name: 'Impression'
        },
        fullName:
          'Fused Nickel Silver with Satin finish and Kalahari Impression pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/kalahari-impression.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/kalahari-impression-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/fused-nickel-silver/satin/kalahari-impression-full.jpg',
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b06f0b4637621c8bd2c',
              nid: 536,
              title: 'Stainless Steel & Fused Metal Bottom Highlight',
            },
            opacity: 0.08
          },
          {
            effect: {
              id: '5d7f9b00ace8d40248d90743',
              nid: 537,
              title: 'Stainless Steel & Fused Metal Environment Lighting',
            },
            opacity: 0.7
          },
          {
            effect: {
              id: '5d7f9b9524241d2305f5b0eb',
              nid: 538,
              title: 'Stainless Steel & Fused Metal Shadows',
            },
            opacity: 0.3
          }
        ]
      },
    ],
  },
]

export default FusedNickelSilver
