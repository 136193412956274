import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from '../surfaces-search.module.css'

class Tab extends Component {
  static propTypes = {
    activeTab: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }

  onClick = () => {
    const { label, onClick } = this.props
    onClick(label)
  }

  render() {
    const {
      onClick,
      props: { activeTab, label, icon },
    } = this

    let className = styles['search__tab']

    if (activeTab === label) {
      className += ' active ' + styles['search__tab__active']
    }

    let tabIcon = ''
    if(icon && icon !== 'fslogo'){
      tabIcon = <FontAwesomeIcon icon={icon} />
    }
    if(icon === 'fslogo'){
      tabIcon = <div className={styles['fslogo']} />
    }

    return (
      <button className={className} onClick={onClick}>
        {tabIcon}
        {label}
      </button>
    )
  }
}

export default Tab
