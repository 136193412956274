import React, { Component } from 'react'

class IntroMessage extends Component {
  render() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '1rem',
          backgroundColor: '#888',
          padding: '1rem',
        }}
      >
        <div style={{ width: '33%', backgroundColor: '#fff', padding: '1rem' }}>
          <p>LEVELr Elevator Interiors</p>
          <p>
          LEVELr Elevator Interiors are available in five primary configurations, each broadly defined by wall panel shape and layout. All offer additional variations with alternate handrail panel treatments. 
          </p>
          <p>Features: </p>
          <ul>
            <li>Frameless Panel Aesthetic</li>
            <li>Utilizes Majority of F+S Materials Library</li>
            <li>Allows Numerous Material Combinations</li>
            <li>Fin, Recessed or LED Reveals</li>
            <li>Flush, Cove or LED Cove Bases</li>
            <li>Fin-mounted LEVELr Handrail Option </li>
          </ul>
        </div>
        <div style={{ width: '33%', backgroundColor: '#fff', padding: '1rem' }}>
          <p>LEVELe Elevator Interiors</p>
          <p>
          LEVELe Elevator Interiors are available in eight primary configurations, each broadly defined by wall panel shape and layout. Five offer additional variations with alternate panel treatments.
          </p>
          <p>Features: </p>
          <ul>
            <li>Framed Panel Aesthetic</li>
            <li>Utilizes Full F+S Materials Library</li>
            <li>Allows Diverse Material Combinations</li>
            <li>Compatible with LightPlane Panels</li>
            <li>Coordinating Bases</li>
            <li>OEM Presets</li>
          </ul>
        </div>
        <div style={{ width: '33%', backgroundColor: '#fff', padding: '1rem' }}>
          <p>LEVELc Elevator Interiors</p>
          <p>
          LEVELc-2000 Elevator Interiors offer numerous ways to combine materials to create durable visually exciting elevator interiors. The panel design eliminates the need for separate bases.
          </p>
          <p>Features: </p>
          <ul>
            <li>Stile, Rail & Inset Design</li>
            <li>Utilizes Full F+S Materials Library</li>
            <li>Combines Multiple Materials per Panel</li>
            <li>Easy Installation</li>
          </ul>
          <hr />
          <p>
          LEVELc-1000 Elevator Interiors are ideal when maintainable aesthetics and resistance to abuse are primary concerns.
          </p>
          <p>Features: </p>
          <ul>
            <li>Economical, Full-panel Design</li>
            <li>Stainless Steel or Fused Metal</li>
            <li>Coordinating Bases</li>
            <li>Easy Installation</li>
          </ul>
        </div>
      </div>
    )
  }
}

export default IntroMessage
