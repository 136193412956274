import React from "react"
import styles from "./form.module.css"
import { Form, Field } from 'react-final-form'
import ShellCeilingHeight from '../../../Form/shell-ceiling-height'
import AutoSave from '../../../Form/AutoSave'

class AdditionalDetailsForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      renderLayers: {},
      lsbdrsImage: '',
      lsbdrsFile: '',
      pdfFileData: '',
      includePricing: false,
      errorMessage: '',
      fullQuote: false,
      userAttributes: {},
      proceeding: false
    }
  }

  save = async values => {
    Object.keys(values).forEach(key => {
      if(!this.props.design.additionalDetails){
        this.props.design.additionalDetails = {}
      }
      this.props.design.additionalDetails[key] = values[key]
    })
    await this.props.handleUpdate(this.props.design)
  }

  render() {
    return (
      <Form
        className={styles.form}
        onSubmit={this.save /* NOT USED, but required */}
        initialValues={{
          openingOption: this.props.design.additionalDetails ?
          this.props.design.additionalDetails.openingOption : '',
          quantity: this.props.design.additionalDetails ?
          this.props.design.additionalDetails.quantity : '1',
          jobType: this.props.design.additionalDetails ?
          this.props.design.additionalDetails.jobType : '',
          elevatorType: this.props.design.additionalDetails ?
          this.props.design.additionalDetails.elevatorType : '',
          shellMaterial: this.props.design.additionalDetails ?
          this.props.design.additionalDetails.shellMaterial : '',
          manufacturer: this.props.design.additionalDetails ?
          this.props.design.additionalDetails.manufacturer : '',
          capacity: this.props.design.additionalDetails ?
          this.props.design.additionalDetails.capacity : '',
          comments: this.props.design.additionalDetails ?
          this.props.design.additionalDetails.comments: ''
        }}
        subscription={{} /* No need to subscribe to anything */}
      >
        {() => (
          <div>
            <ShellCeilingHeight
              design={this.props.design}
              updateDesign={this.props.handleUpdate}
              singleColumn={true}
            />
            <AutoSave debounce={1000} save={this.save} />
            <div>
              <label
                htmlFor="field_quantity"
                className={styles[`small__title`] + ' ' + styles[`review__label`]}>
                + Quantity:</label>
              <Field
                component="input"
                className={styles[`form__input`]}
                type="number"
                name="quantity"
                min="1"
                id="field_quantity" />
            </div>
            <div>
              <Field id="field_comments" className={styles[`text__area`]}
                name="comments"
                component="textarea"
                placeholder="Use this field to share any additional comment on
                your elevator interior design. If you use a ‘Custom’ material
                in your design, please provide us with as much information as
                possible in order to streamline the quoting process.">
              </Field>
            </div>
            <button
            className={styles[`small__title`] + ' fadeHover ' +
              styles[`clickable`]}
              onClick={this.props.toggleExpand}>+
                { this.props.formExpanded ? 'More Details ' : 'Less Details '}
              <span>
                ({this.props.formExpanded ? 'click to enlarge' : 'click to collapse'})
              </span>
            </button>
            <div className={styles[this.props.formExpanded]}>
              <div>
                <label htmlFor="job_type_option"
                  className={styles[`small__title`] + ' ' + styles[`review__label`]}>
                  + Job Type: </label>
                <Field
                  component="select"
                  id="job_type_option"
                  name="jobType"
                  className={styles[`opening__option`]}>
                  <option value="">Select an Option</option>
                  <option value="New">New</option>
                  <option value="Modernization">Modernization</option>
                </Field>
              </div>
              <div>
                <label htmlFor="elevator_type_option"
                  className={styles[`small__title`] + ' ' + styles[`review__label`]}>
                  + Elevator Type: </label>
                <Field
                  component="select"
                  id="elevator_type_option"
                  name="elevatorType"
                  className={styles[`opening__option`]}>
                  <option value="">Select an Option</option>
                  <option value="Passenger">Passenger</option>
                  <option value="Service">Service</option>
                </Field>
              </div>
              <div>
                <label htmlFor="shell_material_option"
                  className={styles[`small__title`] + ' ' + styles[`review__label`]}>
                  + Cab Shell Material: </label>
                <Field
                  component="select"
                  id="shell_material_option"
                  name="shellMaterial"
                  className={styles[`opening__option`]}>
                  <option value="">Select an Option</option>
                  <option value="Steel">Steel</option>
                  <option value="Wood">Wood</option>
                </Field>
              </div>
              <div>
                <label
                  htmlFor="manufacturer_option"
                  className={styles[`small__title`] + ' ' + styles[`review__label`]}>
                  + Manufacturer: </label>
                <Field
                  component="select"
                  id="manufacturer_option"
                  name="manufacturer"
                  className={styles[`opening__option`]}>
                  <option value="">Select an Option</option>
                  <option value="Fujitec">Fujitec</option>
                  <option value="Kone">Kone</option>
                  <option value="Mitsubishi">Mitsubishi</option>
                  <option value="Otis">Otis</option>
                  <option value="Schindler">Schindler</option>
                  <option value="ThyssenKrupp">ThyssenKrupp</option>
                  <option value="Other">Other (specify)</option>
                </Field>
              </div>
            </div>
          </div>
        )}
      </Form>
    )
  }
}
export default(AdditionalDetailsForm)
