import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './surfaces-search.module.css'
import {
  createSurfaceFavorite,
  getFavorites,
  deleteSurfaceFavorite } from '../../../utils/favorites'

class SearchPreview extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      favorites: [],
      isFavorite: false,
      currentFavorite: null,
      customText: this.props.customText
    }
  }

  componentDidMount() {
    this.getTheFavorites()
  }

  getTheFavorites() {
    getFavorites().then((favorites)=>{
      this.setState({
        favorites: favorites
      }, function(){
        var isFavorite = this.isInFavorites()
        this.setState({
          isFavorite: isFavorite,
          currentFavorite: isFavorite
        })
      }.bind(this))
    })
    .catch((err)=>{
      console.log(err)
    })
  }

  isInFavorites() {
    return this.state.favorites.find(function(item){
      return item.referenceId === this.props.object.id
    }.bind(this))
  }

  shouldRotatePreview() {
    var shouldRotate = false
    if(this.props.activePanel){
      if(this.props.activePanel.panelCoords){
        this.props.activePanel.panelCoords.forEach(function(coords){
          if(coords.lsbdrs[0].rotateSurface){
            shouldRotate = true
            return shouldRotate
          }
        })
      }
    }
    return shouldRotate
  }

  hasLightPlane = () => {
    let lightplane = false
    if(this.props.activePanel){
      if(this.props.activePanel.panelCoords){
        this.props.activePanel.panelCoords.forEach((coord, i) => {
          if(coord.lightplane && coord.active){
            lightplane = true
          }
        })
      }
    }
    return lightplane
  }

  hasCeramiloc = () => {
    let ceramiloc = false
    if(this.props.activePanel){
      if(this.props.activePanel.panelCoords){
        this.props.activePanel.panelCoords.forEach((coord, i) => {
          if(coord.ceramiloc && coord.active){
            ceramiloc = true
          }
        })
      }
    }
    return ceramiloc
  }

  applySurface = () => {
    const { applySurface, object } = this.props
    applySurface(object)
  }

  updateCustomText = (event) => {
    this.setState({
      customText: event.target.value
    }, function(){
      this.props.updateCustomText(this.state.customText)
    })
  }

  favoriteSurface = () => {
    if(this.state.isFavorite){
      deleteSurfaceFavorite(this.state.currentFavorite._id).then(function(){
        this.setState({
          currentFavorite: null
        }, function(){
          this.getTheFavorites()
        })
      }.bind(this))
    }else{
      createSurfaceFavorite(this.props.object.id).then(function(favorite){
        this.setState({
          currentFavorite: favorite
        }, function(){
          this.getTheFavorites()
        })
      }.bind(this))
    }
  }

  render(){
    let className = styles['material__search__preview']
    let previewUrl = this.props.object.preview
    if(this.props.object.surfacePreview){
      previewUrl = this.props.object.surfacePreview.publicURL
    }
    if(this.props.object.previewImage){
      previewUrl = this.props.object.previewImage.publicURL
    }
    if(this.props.configuration?.lightTemperature === 5000 && this.props.object.alt?.previewImage?.publicURL){
      previewUrl = this.props.object.alt?.previewImage?.publicURL
    }
    let previewStyle = {
      backgroundImage: 'url('+previewUrl+')'
    }
    let favoriteStyle = ''
    if(this.state.isFavorite){
      favoriteStyle = styles['search__preview__favorited']
    }
    let favoriteButton = ''
    let textStyle = ''
    let rotateStyle = ''
    if(this.shouldRotatePreview() && ! this.props.object.dontRotate){
      rotateStyle = ' ' + styles['search__preview__rotated']
    }
    let lightplaneText = ''
    if(this.hasLightPlane() && this.props.object.lightplaneCompatible){
      lightplaneText = ' - LightPlane Configuration'
    }
    let coatingText = ''
    if(this.hasCeramiloc() && this.props.object.ceramilocCompatible){
      coatingText = ' and Ceramiloc+ Protective Treatment'
    }
    if(this.props.canFavorite){
      favoriteButton = <button
      className={styles['search__preview__favorite'] + ' ' + favoriteStyle}
      onClick={this.favoriteSurface}>
        <FontAwesomeIcon icon='star' className='fadeHover' />
      </button>
      textStyle = ' ' + styles['search__preview_text_favorite']
    }
    let activeOption = null
    
    if(this.props.activeOption && this.props.activePanel?.panelOptions?.length > 0){
      activeOption = this.props.activePanel?.panelOptions.find(option=>this.props.activeOption === option.id)
    }
    
    return (
      <div className={className} key={this.props.object.id}>
        <div className={styles['material__search__preview_img'] + rotateStyle} style={previewStyle}>
          {this.props.activeOption && this.props.activePanel?.panelOptions?.length > 0 && activeOption?.preview?.publicURL &&
          <img src={activeOption.preview.publicURL} alt={activeOption.name} title={activeOption.name} />
          }
        </div>
        <button className={styles['material__search__apply']} title='Apply this surface' onClick={this.applySurface}>Apply</button>
        <div className={styles['search__preview_text'] + textStyle}>
        {activeOption?.name &&
          activeOption.name + ' '
        }
        {!activeOption?.name.includes('LED') && activeOption?.name &&
          'in '
        }
        {activeOption?.name.includes('LED') && (parseInt(this.props.configuration.lightTemperature) === 3200) &&
            'shown at 3,200K in '
        }
        {activeOption?.name.includes('LED') && (parseInt(this.props.configuration.lightTemperature) === 5000) &&
            'shown at 5,000K in '
        }
        {this.props.object.fullName}{lightplaneText}{coatingText}
        </div>
        {favoriteButton}
      </div>
    )
  }

}

export default SearchPreview
