import axios from 'axios'
import { Auth } from 'aws-amplify'
const restPath = process.env.GATSBY_APIURL

export const createSurfaceFavorite = surfaceId => {
  return new Promise((resolve, reject) => {
    try {
      Auth.currentSession()
        .then(function(session) {
          var favorite = {
            favoriteType: 'surface',
            referenceId: surfaceId,
          }

          axios
            .post(restPath + '/favorites/create', favorite,
              {headers: {'Content-Type': 'application/json',
              'Authorization': session.idToken.jwtToken }})
            .then(function(favorite) {
              resolve(favorite.data)
            })
            .catch(function(err) {
              reject(err)
            })

        })
        .catch(function(err) {
          reject(err)
        })
    } catch (err) {
      reject(err)
    }
  })
}

export const deleteSurfaceFavorite = favoriteId => {
  return new Promise((resolve, reject) => {
    try {
      Auth.currentSession()
        .then(function(session) {
          axios
            .delete(restPath + '/favorites/'+favoriteId,
              {headers: {'Content-Type': 'application/json',
              'Authorization': session.idToken.jwtToken }})
            .then(function(favorite) {
              resolve(favorite.data)
            })
            .catch(function(err) {
              reject(err)
            })
        })
        .catch(function(err) {
          reject(err)
        })
    } catch (err) {
      reject(err)
    }
  })
}

export const getFavorites = () => {
  return new Promise((resolve, reject) => {
    try {
      Auth.currentSession()
        .then(function(session) {
          axios
            .get(restPath + '/favorites/list/surface',
              {headers: {'Content-Type': 'application/json',
              'Authorization': session.idToken.jwtToken }})
            .then(function(favorites) {
              resolve(favorites.data)
            })
            .catch(function(err) {
              reject(err)
            })
        })
        .catch(function(err) {
          reject(err)
        })
    } catch (err) {
      reject(err)
    }
  })
}
