import React from 'react'
import styles from '../SurfacesSearch/surfaces-search.module.css'
import SearchRow from '../SurfacesSearch/search-row'
import SearchPreview from '../SurfacesSearch/search-preview'
import CeramilocSelections from '../SurfacesSearch/ceramiloc-selections'
import PanelOptions from '../SurfacesSearch/panel-options'

class MaterialSearch extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeMaterialGroup: {},
      activeMaterial: {},
      activeFinish: {},
      ceramilocActive: false
    }
  }

  componentDidMount() {
    if(this.props.materialGroup){
      let activeMaterial = this.props.materialGroup
      let ceramilocActive = this.hasCeramiloc()
      this.setState({
        activeMaterialGroup: this.props.materialGroup,
        activeMaterial: activeMaterial,
        activeFinish: this.props.material,
        ceramilocActive: ceramilocActive
      })
    }
  }

  onFinishSelect = finish => {
    this.setState({
      activeFinish: finish
    })
  }

  onMaterialSelect = material => {
    this.setState({
      activeMaterial: material
    })
  }

  onCloseButton = () => {
    const { onCloseButton } = this.props
    onCloseButton()
  }

  hasCeramiloc = () => {
    let ceramiloc = false
    if(this.props.activePanel){
      this.props.activePanel.panelCoords.forEach((coord, i) => {
        if(coord.ceramiloc && coord.active){
          ceramiloc = true
        }
      })
    }
    return ceramiloc
  }

  applyCeramiloc = (ceramiloc) => {
    this.setState({
      ceramilocActive: ceramiloc
    })
  }

  applySurface = surface => {
    const { applySurface } = this.props
    applySurface(surface, false, this.state.ceramilocActive)
  }

  render(){
    let materials = ''
    let finishes = ''
    let preview = ''
    if(this.state.activeMaterial.name){
      finishes = <SearchRow
        objects={this.state.activeMaterial.surfaces}
        activeObject={this.state.activeFinish}
        overrideName={this.state.activeFinish.finish.name}
        objectType={this.state.activeMaterial.name}
        onClick={this.onFinishSelect}
      />
      preview = <SearchPreview
        object={this.state.activeFinish}
        configuration={this.props.configuration}
        applySurface={this.applySurface}
        activePanel={this.props.activePanel}
        activeOption={this.props.activeOption}
      />
    }
    let ceramilocSelections = null
    if(this.state.activeFinish.ceramilocCompatible){
      ceramilocSelections = <CeramilocSelections
        activeObject={this.state.activeFinish}
        applyCeramiloc={this.applyCeramiloc}
        ceramilocActive={this.state.ceramilocActive}
        activePanel={this.props.activePanel}
      />
    }
    let panelOptions = null
    if(this.props.activePanel.panelOptions?.length){
      panelOptions = <PanelOptions
      activePanel={this.props.activePanel}
      onClickOption={this.props.onClickOption}
      activeOption={this.props.activeOption}
      />
    }
    return (
      <div className={styles['material__search__submaterials']}>
        <button title="Close" className={styles['materal__search__preview__close']} onClick={this.onCloseButton}>×</button>
        {preview}
        {panelOptions}
        {materials}
        {finishes}
        {ceramilocSelections}
      </div>
    )
  }

}

export default MaterialSearch
