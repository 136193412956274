let BondedAluminum = [
  {
    id: '5d7f8059f481172719166282',
    nid: 161,
    name: 'Dark',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/dark/dark.jpg',
    surfaceLabel: "Pattern",
    patterns: [
      {
        id: '5d7f806acc0dc4ae9d50ed52',
        nid: 555,
        name: 'Austin',
        fullName: 'Bonded Aluminum with Dark Patina and Austin pattern ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/dark/austin.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/dark/austin-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/dark/austin-full.jpg',
      },
      {
        id: '5d7f8072d1b4531a6ffb8157',
        nid: 155,
        name: 'Charleston',
        fullName: 'Bonded Aluminum with Dark Patina and Charleston pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/dark/charleston.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/dark/charleston-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/dark/charleston-full.jpg',
      },
      {
        id: '5d7f807b9cb19cc6f542b239',
        nid: 13215,
        name: 'Dash',
        fullName: 'Bonded Aluminum with Dark Patina and Dash pattern ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/dark/dash.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/dark/dash-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/dark/dash-full.jpg',
      },
      {
        id: '5d7f80866781cb82ca6b7682',
        nid: 521,
        name: 'Delta',
        fullName: 'Bonded Aluminum with Dark Patina and Delta pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/dark/delta.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/dark/delta-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/dark/delta-full.jpg',
      },
      {
        id: '5d7f809f70b77f463c9bc218',
        nid: 13216,
        name: 'Glint',
        fullName: 'Bonded Aluminum with Dark Patina and Glint pattern ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/dark/glint.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/dark/glint-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/dark/glint-full.jpg',
      },
      {
        id: '5d7f8090bc0545c535e446c6',
        nid: 518,
        name: 'Grass',
        fullName: 'Bonded Aluminum with Dark Patina and Grass pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/dark/grass.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/dark/grass-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/dark/grass-full.jpg',
      },
      {
        id: '5d7f80a7c4cc612bcebe76bd',
        nid: 1083,
        name: 'Herringbone',
        fullName: 'Bonded Aluminum with Dark Patina and Herringbone pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/dark/herringbone.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/dark/herringbone-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/dark/herringbone-full.jpg',
      },
      {
        id: '5d7f80b08d7c08c54d148bcf',
        nid: 1084,
        name: 'Loft',
        fullName: 'Bonded Aluminum with Dark Patina and Loft pattern',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/dark/loft.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/dark/loft-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/dark/loft-full.jpg',
      },
      {
        id: '5d7f80b900e47d6ef71bb30d',
        nid: 1085,
        name: 'Rain',
        fullName: 'Bonded Aluminum with Dark Patina and Rain pattern ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/dark/rain.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/dark/rain-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/dark/rain-full.jpg',
      },
      {
        id: '5d7f80c235c74cc8772af5de',
        nid: 1086,
        name: 'Talus',
        fullName: 'Bonded Aluminum with Dark Patina and Talus pattern ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/dark/talus.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/dark/talus-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/dark/talus-full.jpg',
      },
      {
        id: '5d7f80cc41f1d5db69bb8a5f',
        nid: 13217,
        name: 'Tilt',
        fullName: 'Bonded Aluminum with Dark Patina and Tilt pattern ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/dark/tilt.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/dark/tilt-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/dark/tilt-full.jpg',
      },
      {
        id: '5d7f80d5aef23bcbeffd0d36',
        nid: 1087,
        name: 'Trevia',
        fullName: 'Bonded Aluminum with Dark Patina and Trevia pattern ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/dark/trevia.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/dark/trevia-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/dark/trevia-full.jpg',
      },
      {
        id: '5d7f80e13140d13dc9330095',
        nid: 1088,
        name: 'Vancouver',
        fullName: 'Bonded Aluminum with Dark Patina and Vancouver pattern ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/dark/vancouver.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/dark/vancouver-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/dark/vancouver-full.jpg',
      },
    ],
  },
  {
    id: '5d7f80ea213a3ceba5496922',
    nid: 160,
    name: 'Natural',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/natural.jpg',
    surfaceLabel: "Pattern",
    patterns: [
      {
        id: '5d7f80f320465139acdd7e5e',
        nid: 1089,
        name: 'Austin',
        fullName: 'Bonded Aluminum with Natural Patina and Austin pattern ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/austin.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/austin-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/austin-full.jpg',
      },
      {
        id: '5d7f80fc0c2b54275519814d',
        nid: 1091,
        name: 'Chardonnay',
        fullName: 'Bonded Aluminum with Natural Patina and Chardonnay pattern ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/chardonnay.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/chardonnay-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/chardonnay-full.jpg',
        captureOnly: true,
      },
      {
        id: '5d7f81057973d0d4ed7e66ff',
        nid: 1090,
        name: 'Champagne',
        fullName: 'Bonded Aluminum with Natural Patina and Champagne pattern ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/champagne.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/champagne-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/champagne-full.jpg',
        captureOnly: true,
      },
      {
        id: '5d7f810da8677c6e552ff64e',
        nid: 1092,
        name: 'Charleston',
        fullName: 'Bonded Aluminum with Natural Patina and Charleston pattern ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/charleston.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/charleston-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/charleston-full.jpg',
      },
      {
        id: '5d7f81158fbd6755cdbd445d',
        nid: 13212,
        name: 'Dash',
        fullName: 'Bonded Aluminum with Natural Patina and Dash pattern ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/dash.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/dash-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/dash-full.jpg',
      },
      {
        id: '5d7f811e403024803361bf95',
        nid: 1093,
        name: 'Delta',
        fullName: 'Bonded Aluminum with Natural Patina and Delta pattern ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/delta.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/delta-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/delta-full.jpg',
      },
      {
        id: '5d7f8126484c15c9c5a0abb3',
        nid: 13214,
        name: 'Glint',
        fullName: 'Bonded Aluminum with Natural Patina and Glint pattern ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/glint.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/glint-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/glint-full.jpg',
      },
      {
        id: '5d7f81302d23b092c19243b5',
        nid: 1094,
        name: 'Grass',
        fullName: 'Bonded Aluminum with Natural Patina and Grass pattern ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/grass.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/grass-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/grass-full.jpg',
      },
      {
        id: '5d7f813c0615cf70e1c2aaa5',
        nid: 1095,
        name: 'Herringbone',
        fullName:
          'Bonded Aluminum with Natural Patina and Herringbone pattern ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/herringbone.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/herringbone-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/herringbone-full.jpg',
      },
      {
        id: '5d7f8146c6b429bb113bc818',
        nid: 1096,
        name: 'Kalahari',
        fullName: 'Bonded Aluminum with Natural Patina and Kalahari pattern ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/kalahari.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/kalahari-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/kalahari-full.jpg',
        captureOnly: true,
      },
      {
        id: '5d7f814e2b5e08416f6d6f19',
        nid: 1097,
        name: 'Loft',
        fullName: 'Bonded Aluminum with Natural Patina and Loft pattern ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/loft.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/loft-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/loft-full.jpg',
      },
      {
        id: '5d7f8157741d46e7b01ae875',
        nid: 1098,
        name: 'Mara',
        fullName: 'Bonded Aluminum with Natural Patina and Mara pattern ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/mara.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/mara-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/mara-full.jpg',
        captureOnly: true,
      },
      {
        id: '5d7f816010f63efaeef196de',
        nid: 1099,
        name: 'Rain',
        fullName: 'Bonded Aluminum with Natural Patina and Rain pattern ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/rain.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/rain-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/rain-full.jpg',
      },
      {
        id: '5d7f816aa1d5258ea3674cba',
        nid: 1100,
        name: 'Sahara',
        fullName: 'Bonded Aluminum with Natural Patina and Sahara pattern ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/sahara.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/sahara-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/sahara-full.jpg',
      },
      {
        id: '5d7f8173e3e5b2c7cd193ade',
        nid: 1101,
        name: 'Talus',
        fullName: 'Bonded Aluminum with Natural Patina and Talus pattern ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/talus.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/talus-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/talus-full.jpg',
      },
      {
        id: '5d7f81817ef18d46e091fd1f',
        nid: 1102,
        name: 'Terrane',
        fullName: 'Bonded Aluminum with Natural Patina and Terrane pattern ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/terrane.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/terrane-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/terrane-full.jpg',
      },
      {
        id: '5d7f8189e012c35828fb77e6',
        nid: 13213,
        name: 'Tilt',
        fullName: 'Bonded Aluminum with Natural Patina and Tilt pattern ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/tilt.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/tilt-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/tilt-full.jpg',
      },
      {
        id: '5d7f819189d37d0562beb657',
        nid: 1103,
        name: 'Trevia',
        fullName: 'Bonded Aluminum with Natural Patina and Trevia pattern ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/trevia.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/trevia-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/trevia-full.jpg',
      },
      {
        id: '5d7f819a395d71e012fd0085',
        nid: 13211,
        name: 'Undercurrent',
        fullName:
          'Bonded Aluminum with Natural Patina and Undercurrent pattern ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/undercurrent.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/undercurrent-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/undercurrent-full.jpg',
      },
      {
        id: '5d7f81a2a6a591fb9e26c36f',
        nid: 1104,
        name: 'Vancouver',
        fullName: 'Bonded Aluminum with Natural Patina and Vancouver pattern ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/vancouver.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/vancouver-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/vancouver-full.jpg',
      },
      {
        id: '5d7f81aaff6e0d212b029474',
        nid: 1105,
        name: 'Waterfall',
        fullName: 'Bonded Aluminum with Natural Patina and Waterfall pattern ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/waterfall.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/waterfall-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/bonded-aluminum/natural/waterfall-full.jpg',
        captureOnly: true,
      },
    ],
  },
]

export default BondedAluminum
