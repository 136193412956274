import materials from '../../objects/surfaces/data/materials'

export const crossref = nid => {
  var xref = populate()
  var found = xref.find(function(element) {
    return element.nid === nid
  })
  if (found) {
    return found.id
  } else {
    return null
  }
}

var populate = function() {
  var Xref = []
  for (let i = 0; i < materials.length; i++) {
    Xref.push(materials[i])
    for (let j = 0; j < materials[i].subMaterials.length; j++) {
      Xref.push(materials[i].subMaterials[j])
      for (let k = 0; k < materials[i].subMaterials[j].finishes.length; k++) {
        Xref.push(materials[i].subMaterials[j].finishes[k])
        for (
          let l = 0;
          l < materials[i].subMaterials[j].finishes[k].patterns.length;
          l++
        ) {
          Xref.push(materials[i].subMaterials[j].finishes[k].patterns[l])
        }
      }
    }
  }
  return Xref
}
