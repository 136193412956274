import React from 'react'
import Lightbox from 'lightbox-react'
import Lsbwrs from './lsbwrs'
import Fdls from './fdls'
import styles from './lsbwrs/cabview.module.css'
import WaitLoader from '../WaitLoader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
class DesignRenderMulti extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      lightBoxOpen: false,
      lsbwrsImage: null,
      lsbwrsLoading: true,
      fdlsImage: null,
      fdlsLoading: true,
      viewType: 'back',
    }
  }

  componentDidMount() {

  }

  getLsbwrsImage = imageUrl => {
    if (this.state.lsbwrsImage) {
      URL.revokeObjectURL(this.state.lsbwrsImage)
    }
    this.setState(
      {
        lsbwrsImage: imageUrl,
        lsbwrsLoading: false,
      }
    )
  }

  getFdlsImage = imageUrl => {
    if (this.state.fdlsImage) {
      URL.revokeObjectURL(this.state.fdlsImage)
    }
    this.setState({
      fdlsImage: imageUrl,
      fdlsLoading: false,
    })
  }

  openLightbox() {
    this.setState({
      lightBoxOpen: true
    }, function(){

    })
  }

  closeLightbox = () => {
    this.setState({
      lightBoxOpen: false,
      lsbwrsLoading: true,
      fdlsLoading: true })
  }

  downloadStraightImage = (el) => {
    el.preventDefault()
    var url = this.state.lsbwrsImage
    el.href = url

    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = url;
    a.download = 'elevator-design-straight.jpg';
    a.click();
  }

  downloadFrontImage = (el) => {
    el.preventDefault()
    var url = this.state.fdlsImage
    el.href = url

    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = url;
    a.download = 'elevator-design-front.jpg';
    a.click();
  }

  downloadBackImage = (el) => {
    el.preventDefault()
    var url = this.props.standardImage
    el.href = url

    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = url;
    a.download = 'elevator-design.jpg';
    a.click();
  }

  render(){
    let lsbwrsImage = <div><img className={styles['canvas__image']}
      src={this.state.lsbwrsImage} alt="elevator design" />
      </div>
    let fdlsImage = <div><img className={styles['canvas__image']}
      src={this.state.fdlsImage} alt="elevator design" />
      </div>
    if(this.state.lsbwrsLoading){
      lsbwrsImage = <WaitLoader />
    }
    if(this.state.fdlsLoading){
      fdlsImage = <WaitLoader />
    }
    let lightboxContent = ''
    let backActive = ''
    let straightActive = ''
    let frontActive = ''
    let backButton = null
    let frontButton = null
    let straightButton = null
    if(!(this.props.configuration.hidelsbwrs && this.props.configuration.hidefdls)){
      if(this.state.viewType === 'back'){
        backActive = ' ' + styles['active__view']
        lightboxContent = <div className={"col-xs-6 " +
          styles['canvas__image__wrapper']}>
          <div>
            <img className={styles['canvas__image']}
              src={this.props.standardImage}
              alt="elevator design" />
          </div>
        </div>
        backButton = <div className="row">
          <div className="col-xs-12">
            <div className={styles['button__wrap']}>
              <a href="/" download="eds-design-front.jpg"
              onClick={(el) => this.downloadBackImage(el)}
              className={styles['canvas__download__link'] + ' fadeHover'}>
              <i className="las la-download"></i> Download
              </a>
            </div>
          </div>
        </div>
      }
      if(this.state.viewType === 'straight') {
        straightActive = ' ' + styles['active__view']
        lightboxContent = <div className={"col-xs-6 " +
          styles['canvas__image__wrapper']}>
          <Lsbwrs
            configuration={this.props.configuration}
            getCanvasImage={this.getLsbwrsImage}
            renderCanvas={this.state.lightBoxOpen}
            hideWatermark={this.props.hideWatermark}
            id="multi-view-lsbwrs" />
          {lsbwrsImage}
        </div>
        if(!this.state.lsbwrsLoading){
          straightButton = <div className="row">
            <div className="col-xs-12">
              <div className={styles['button__wrap']}>
                <a href="/" download="eds-design-front.jpg"
                onClick={(el) => this.downloadStraightImage(el)}
                className={styles['canvas__download__link'] + ' fadeHover'}>
                <i className="las la-download"></i> Download
                </a>
              </div>
            </div>
          </div>
        }
      }
      if(this.state.viewType === 'front'){
        frontActive = ' ' + styles['active__view']
        lightboxContent = <div className={"col-xs-6 " +
          styles['canvas__image__wrapper']}>
          <Fdls
            configuration={this.props.configuration}
            getCanvasImage={this.getFdlsImage}
            renderCanvas={this.state.lightBoxOpen}
            hideWatermark={this.props.hideWatermark}
            id="multi-view-fdls"
          />
          {fdlsImage}
        </div>
        if(!this.state.fdlsLoading){
          frontButton = <div className="row">
            <div className="col-xs-12">
              <div className={styles['button__wrap']}>
                <a href="/" download="eds-design-front.jpg"
                onClick={(el) => this.downloadFrontImage(el)}
                className={styles['canvas__download__link'] + ' fadeHover'}>
                <i className="las la-download"></i> Download
                </a>
              </div>
            </div>
          </div>
        }
      }
    }else{
      lightboxContent = <div className={"col-xs-6 " +
      styles['canvas__image__wrapper']}>
      <button className={styles['open__views__button']}
      onClick={this.closeLightbox}>
      <i className="las la-compress-arrows-alt"></i></button>
        <img className={styles['canvas__image']}
          src={this.props.standardImage}
          alt="elevator design" />
      </div>
    }
    let activeCheck = <i className="las la-check"></i>
    let leftContent = <div className="col-xs-6">
      <div className={styles['multi__render__left']}>
        <h2>View Options</h2>
        <p>Select any of the available option below to preview your elevator
        interior design progress. Just close this window to continue editing
        your design.</p>
        <div className="row">
          <div className="col-xs-4">
            <button
              className={styles['multi__render__button'] + ' '
              + styles['multi__render__back'] + backActive}
              onClick={() => this.setState({
              viewType: 'back'
            })}>
            {backActive ? activeCheck : ''}
            <div>Back</div>
            </button>
            {backButton}
          </div>
          <div className="col-xs-4">
            <button
              className={styles['multi__render__button'] + ' '
              + styles['multi__render__straight'] + straightActive}
              onClick={() => this.setState({
              viewType: 'straight'
            })}>
            {straightActive ? activeCheck : ''}
            <div>Straight</div>
            </button>
            {straightButton}
          </div>
          <div className="col-xs-4">
            <button
              className={styles['multi__render__button'] + ' '
               + styles['multi__render__front'] + frontActive}
              onClick={() => this.setState({
              viewType: 'front'
            })}>
            {frontActive ? activeCheck : ''}
            <div>Front</div>
            </button>
            {frontButton}
          </div>
        </div>
      </div>
    </div>

    lightboxContent = <div className={'row ' +
    styles['multi__render__wrapper']}>{leftContent}{lightboxContent}</div>
    let backActiveClass = ''
    let backActiveCheck = ''
    let straightActiveClass = ''
    let straightActiveCheck = ''
    let frontActiveClass = ''
    let frontActiveCheck = ''
    if(this.state.viewType === 'back'){
      backActiveClass = ' ' + styles['active']
      backActiveCheck = <FontAwesomeIcon icon="check-circle" />
    }else if(this.state.viewType === 'straight'){
      straightActiveClass = ' ' + styles['active']
      straightActiveCheck = <FontAwesomeIcon icon="check-circle" />
    }else if(this.state.viewType === 'front'){
      frontActiveClass = ' ' + styles['active']
      frontActiveCheck = <FontAwesomeIcon icon="check-circle" />
    }
    if((this.props.showMultiView) && !(this.props.configuration.hidelsbwrs && this.props.configuration.hidefdls)){
      return (
        <div className="col-lg-12">
        <div className={styles['viewsButtons']}>
        <button
          type='button'
          data-tip data-for='new3ViewOptions'
          data-event='hover'
          title='Back View'
          className={styles['open__views__button'] + backActiveClass}
          onClick={() => {
            this.setState({
              viewType: 'back'
            }, function(){
              this.openLightbox()
            })
          }}
        >
        {backActiveCheck}
        </button>
        <button
          type='button'
          data-tip data-for='new3ViewOptions'
          data-event='hover'
          className={styles['open__views__button'] + ' ' + styles['lsbdrs'] + straightActiveClass}
          onClick={() => {
            this.setState({
              viewType: 'straight'
            }, function(){
              this.openLightbox()
            })
          }}
        >
        {straightActiveCheck}
        </button>
        <button
          type='button'
          data-tip data-for='new3ViewOptions'
          data-event='hover'
          className={styles['open__views__button'] + ' ' + styles['fdls'] + frontActiveClass}
          onClick={() => {
            this.setState({
              viewType: 'front'
            }, function(){
              this.openLightbox()
            })
          }}
        >
        {frontActiveCheck}
        </button>
        {this.state.lightBoxOpen && (
          <Lightbox
            mainSrc={lightboxContent}
            onCloseRequest={() => this.setState({
              lightBoxOpen: false,
              lsbwrsLoading: true,
              fdlsLoading: true })}
            enableZoom={false}
            clickOutsideToClose={true}
          />
        )}
        </div>
        </div>
      )
    }else{
      return(null)
    }
  }
}

export default DesignRenderMulti
