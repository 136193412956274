let VivistoneHoneyOnyx = [
  {
    id: '5d7f96b78096248b4035f600',
    nid: 195,
    name: 'Slab A-1',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-a-1/slab-a-1.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f96b83e7e9584b26923bf',
        nid: 226,
        name: 'Pearlex',
        fullName:
          'ViviStone Honey Onyx glass with slab A-1 interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-a-1/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-a-1/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-a-1/pearlex-full.jpg',
        finish: {
          id: '5d7f96b9a0b37adaaee1015c',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f96baee0d0f95555da7d4',
        nid: 227,
        name: 'Standard',
        fullName:
          'ViviStone Honey Onyx glass with slab A-1 interlayer and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-a-1/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-a-1/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-a-1/standard-full.jpg',
        finish: {
          id: '5d7f96bb29948c5a4548c5fc',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f96bc6dfad2aa1fe41214',
    nid: 240,
    name: 'Slab A-2',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-a-2/slab-a-2.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f96bd52ef0152bcdf4c4d',
        nid: 2062,
        name: 'Pearlex',
        fullName:
          'ViviStone Honey Onyx glass with slab A-2 interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-a-2/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-a-2/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-a-2/pearlex-full.jpg',
        finish: {
          id: '5d7f96be1f72467e6d210910',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f96bfced8a807d2b0140b',
        nid: 2063,
        name: 'Standard',
        fullName:
          'ViviStone Honey Onyx glass with slab A-2 interlayer and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-a-2/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-a-2/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-a-2/standard-full.jpg',
        finish: {
          id: '5d7f96c0c2498deb41f45ba5',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f96c1fd0c996c5be2ea10',
    nid: 241,
    name: 'Slab A-3',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-a-3/slab-a-3.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f96c219fc109fed0f4078',
        nid: 2064,
        name: 'Pearlex',
        fullName:
          'ViviStone Honey Onyx glass with slab A-3 interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-a-3/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-a-3/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-a-3/pearlex-full.jpg',
        finish: {
          id: '5d7f96c3fb285658be6b7034',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f96c436adb77725347474',
        nid: 2065,
        name: 'Standard',
        fullName:
          'ViviStone Honey Onyx glass with slab A-3 interlayer and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-a-3/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-a-3/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-a-3/standard-full.jpg',
        finish: {
          id: '5d7f96c5eeb056da284a95ad',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f96c6c53714aa96499cf1',
    nid: 243,
    name: 'Slab B-4',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-b-4/slab-b-4.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f96c710ad0eb98b58524a',
        nid: 2066,
        name: 'Pearlex',
        fullName:
          'ViviStone Honey Onyx glass with slab B-4 interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-b-4/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-b-4/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-b-4/pearlex-full.jpg',
        finish: {
          id: '5d7f96c81531b3211626dd1c',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f96c9a51d1ba5afcae17b',
        nid: 2067,
        name: 'Standard',
        fullName:
          'ViviStone Honey Onyx glass with slab B-4 interlayer and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-b-4/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-b-4/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-b-4/standard-full.jpg',
        finish: {
          id: '5d7f96cac8fcd302b9bc675c',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f96cbbb8abb522fd529bf',
    nid: 244,
    name: 'Slab B-5',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-b-5/slab-b-5.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f96cc6ac84ac533434275',
        nid: 2068,
        name: 'Pearlex',
        fullName:
          'ViviStone Honey Onyx glass with slab B-5 interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-b-5/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-b-5/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-b-5/pearlex-full.jpg',
        finish: {
          id: '5d7f96cdf6a83d932b5fbcd3',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f96cee9f3848cd519ee77',
        nid: 2069,
        name: 'Standard',
        fullName:
          'ViviStone Honey Onyx glass with slab B-5 interlayer and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-b-5/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-b-5/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-b-5/standard-full.jpg',
        finish: {
          id: '5d7f96cf0f0a514aa674905b',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f96d0a5453dbd16fd86e0',
    nid: 245,
    name: 'Slab B-6',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-b-6/slab-b-6.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f96d196d951fca2107072',
        nid: 2070,
        name: 'Pearlex',
        fullName:
          'ViviStone Honey Onyx glass with slab B-6 interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-b-6/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-b-6/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-b-6/pearlex-full.jpg',
        finish: {
          id: '5d7f96d2aaa86693fb411870',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f96d3cdb570e27ea576d3',
        nid: 2071,
        name: 'Standard',
        fullName:
          'ViviStone Honey Onyx glass with slab B-6 interlayer and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-b-6/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-b-6/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-b-6/standard-full.jpg',
        finish: {
          id: '5d7f96d48a6c37145e247fcd',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f96d524351862b9409d81',
    nid: 246,
    name: 'Slab C-7',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-c-7/slab-c-7.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f96d6b5a6c62cf03997c0',
        nid: 2072,
        name: 'Pearlex',
        fullName:
          'ViviStone Honey Onyx glass with slab C-7 interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-c-7/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-c-7/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-c-7/pearlex-full.jpg',
        finish: {
          id: '5d7f96d7d742fad9762fbb8d',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f96d811c0d487f41e9396',
        nid: 2073,
        name: 'Standard',
        fullName:
          'ViviStone Honey Onyx glass with slab C-7 interlayer and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-c-7/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-c-7/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-c-7/standard-full.jpg',
        finish: {
          id: '5d7f96d9310dc46fdac9747d',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f96da0eef67c04ba602d9',
    nid: 247,
    name: 'Slab C-8',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-c-8/slab-c-8.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f96db380e1cbc47bc2e66',
        nid: 2074,
        name: 'Pearlex',
        fullName:
          'ViviStone Honey Onyx glass with slab C-8 interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-c-8/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-c-8/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-c-8/pearlex-full.jpg',
        finish: {
          id: '5d7f96dcdb96d0565c2247d8',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f96dd744a226dc854fb79',
        nid: 2075,
        name: 'Standard',
        fullName:
          'ViviStone Honey Onyx glass with slab C-8 interlayer and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-c-8/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-c-8/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-c-8/standard-full.jpg',
        finish: {
          id: '5d7f96def2c3b7aac9f95055',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f96df2a6a9df8259165b9',
    nid: 248,
    name: 'Slab C-9',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-c-9/slab-c-9.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f96e0ac311385abaa695f',
        nid: 2076,
        name: 'Pearlex',
        fullName:
          'ViviStone Honey Onyx glass with slab C-9 interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-c-9/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-c-9/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-c-9/pearlex-full.jpg',
        finish: {
          id: '5d7f96e139075fc670f8d018',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f96e237cd7b5caae2c4f7',
        nid: 2077,
        name: 'Standard',
        fullName:
          'ViviStone Honey Onyx glass with slab C-9 interlayer and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-c-9/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-c-9/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-c-9/standard-full.jpg',
        finish: {
          id: '5d7f96e3c32a1b8145299b00',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f96e43ccb293f969405a9',
    nid: 249,
    name: 'Slab D-10',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-d-10/slab-d-10.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f96e5bdd35c07dbd1ba35',
        nid: 2078,
        name: 'Pearlex',
        fullName:
          'ViviStone Honey Onyx glass with slab D-10 interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-d-10/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-d-10/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-d-10/pearlex-full.jpg',
        finish: {
          id: '5d7f96e65a0fe437636d5ce9',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f96e716ad0d53e88558ee',
        nid: 2079,
        name: 'Standard',
        fullName:
          'ViviStone Honey Onyx glass with slab D-10 interlayer and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-d-10/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-d-10/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-d-10/standard-full.jpg',
        finish: {
          id: '5d7f96e8f6de7acd5dcb014b',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f96e93833e78e670b838f',
    nid: 250,
    name: 'Slab D-11',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-d-11/slab-d-11.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f96ead0605ccfd552e185',
        nid: 2080,
        name: 'Pearlex',
        fullName:
          'ViviStone Honey Onyx glass with slab D-11 interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-d-11/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-d-11/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-d-11/pearlex-full.jpg',
        finish: {
          id: '5d7f96eb18e8d120afeeb7a7',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f96ec489bdd19d084e0ce',
        nid: 2081,
        name: 'Standard',
        fullName:
          'ViviStone Honey Onyx glass with slab D-11 interlayer and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-d-11/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-d-11/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-d-11/standard-full.jpg',
        finish: {
          id: '5d7f96ed1afc70cfbd1b737b',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
  {
    id: '5d7f96eeee70ecdcbbf00774',
    nid: 251,
    name: 'Slab D-12',
    swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-d-12/slab-d-12.jpg',
    surfaceLabel: "Finish",
    patterns: [
      {
        id: '5d7f96ef5e722a6913fd7790',
        nid: 2082,
        name: 'Pearlex',
        fullName:
          'ViviStone Honey Onyx glass with slab D-12 interlayer and Pearlex finish',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-d-12/pearlex.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-d-12/pearlex-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-d-12/pearlex-full.jpg',
        finish: {
          id: '5d7f96f0aaf018a538545e81',
          nid: 166,
          name: 'Pearlex',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
      },
      {
        id: '5d7f96f103038e57920ed71f',
        nid: 2083,
        name: 'Standard',
        fullName:
          'ViviStone Honey Onyx glass with slab D-12 interlayer and Standard finish ',
        swatch: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-d-12/standard.jpg',
        preview: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-d-12/standard-preview.jpg',
        full: 'https://library.elevatordesignstudio.com/objects/surfaces/img/materials/vivistone-honey-onyx/slab-d-12/standard-full.jpg',
        finish: {
          id: '5d7f96f2243ecec8305162e0',
          nid: 162,
          name: 'Standard',
        },
        glass: true,
        lightplaneCompatible: true,
        lightplaneOpacity: 0.2,
        effectOverrides: [
          {
            effect: {
              id: '5d7f9b9bb2b225d2f2de2f12',
              nid: 1648,
              title: 'VividGlass, Standard Finish, Shadows',
            },
            opacity: 0.5
          },
        ]
      },
    ],
  },
]

export default VivistoneHoneyOnyx
